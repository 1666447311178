import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Axios from "utils/axios";
import { toast } from "react-toastify";
import { setSwitchUser } from "redux/authSlice";

const useHeader = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);

  const [isHeaderSwitching, setHeaderSwitching] = useState(false);

  const switchUser = async (switchTo, redirectUrl) => {
    let switchUsrObj = { ...user };

    if (switchUsrObj.userType.includes(switchTo)) {
      switchUsrObj.currentType = switchTo;

      dispatch(setSwitchUser(switchUsrObj));
      window.location.href = redirectUrl;
    } else {
      let params = { newRole: switchTo };
      try {
        setHeaderSwitching(true);
        const switchResp = await Axios.post("/auth/switch-account", params);

        if (!switchResp.data.success) {
          throw new Error("Error occured while switching the user.");
        }

        switchUsrObj.userType = [...switchUsrObj.userType, switchTo];
        switchUsrObj.currentType = switchTo;

        dispatch(setSwitchUser(switchUsrObj));
        window.location.href = redirectUrl;
      } catch (err) {
        toast.error(err.message, { position: "bottom-left" });
        setHeaderSwitching(true);
      }
    }
  };

  return {
    isHeaderSwitching,
    setHeaderSwitching,
    switchUser,
  };
};

export default useHeader;
