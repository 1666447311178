import * as React from 'react';
import styles from './ForgotPassword.module.css';
import TextInput from '../../../../components/form/TextInput';
import Button from '../../../../components/Button';


function ForgotPassword({ onClose, onNavigate }) {




    return (

        <>
            <div className={styles.modal_header} >
                <h2 className={styles.modal_title_big} >Forgot Password</h2>
            </div>

            <TextInput
                customClass={`w-100 mb-3 ${styles.formInput}`}
                type={'email'}
                onChange={(e) => { '' }}
                placeholder={'Enter Your Email Address'}
            />

            <Button
                buttonText={'Reset Password'}
                variant={'contained'}
                customClass={styles.booking_btn}
            />

            <button onClick={() => { onNavigate('signin') }} className={styles.already_link}>Return to Login</button>

            <button type="button" className={styles.close} onClick={onClose}>×</button>
        </>

    );
}

export default ForgotPassword;