import * as React from 'react';



const ModalHeading = ({ closeModal, title }) => {


  return (
    <>
      <div
        className='modal-title p-3 px-md-4 border-bottom mb-0'>
        <span className="d-flex align-items-center">

          <h4 className='margin-b-0'>{title}</h4>
        </span>
        <button
          className='grow'
          style={{ background: "none", border: "none", fontSize: "20px", fontWeight: "700" }}
          onClick={closeModal}  >
          X
        </button>
      </div>
    </>


  );
}

export default ModalHeading;