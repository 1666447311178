import React, { lazy } from "react";

import SLAdminLayout from "../layouts/SLAdminLayout";
import AuthGuard from "../utils/guards/AuthGuard";
import Role from "../utils/guards/Role";
import UsersList from "pages/shortLetting/Admin/userList";
import UsersDetails from "pages/shortLetting/Admin/userDetail";
import MyProfile from "pages/public/MyProfile";

const FranchiseDashboard = lazy(() =>
  import("../pages/shortLetting/Dashboard")
);
const Hosts = lazy(() => import("../pages/shortLetting/Host"));
// const MyProfile = lazy(() => import('../pages/shortLetting/MyProfile'));
const SupportPage = lazy(() => import("../pages/public/guestPortal/Support"));
const BookingList = lazy(() => import("../pages/shortLetting/BookingList"));
const AddIssue = lazy(() => import("../pages/public/guestPortal/Support/AddTicket"));
const SupportIssueDetail = lazy(() => import("../pages/public/guestPortal/Support/SupportIssueDetail"));

const OnBoarding = lazy(() => import("../pages/shortLetting/OnBoarding"));
const Properties = lazy(() =>
  import("../pages/shortLetting/property/PropertyList")
);
const AddProperty = lazy(() =>
  import("../pages/shortLetting/property/AddProperty")
);
const PropertyDetails = lazy(() =>
  import("../pages/shortLetting/property/PropertyDetails")
);
const MyCalendar = lazy(() =>
  import("../pages/shortLetting/calendar/TimelineCalendar")
);
const DetailCalendar = lazy(() =>
  import("../pages/shortLetting/calendar/InternalCalendar")
);
const TransactionList = lazy(() =>
  import("../pages/shortLetting/transactions/TransactionList")
);
const ReservationDetails = lazy(() =>
  import("../pages/shortLetting/transactions/ReservationDetails")
);
const Messages = lazy(() => import("../pages/shortLetting/messaging/index"));
const OccupancyList = lazy(() => import("../pages/shortLetting/OccupancyList"));
const Reports = lazy(() => import("../pages/shortLetting/reports/index"));
const RevenueReport = lazy(() =>
  import("../pages/shortLetting/reports/RevenueReport")
);
const MaintenanceReport = lazy(() =>
  import("../pages/shortLetting/reports/MaintenanceReport")
);
const ReviewReport = lazy(() =>
  import("../pages/shortLetting/reports/ReviewReport")
);
const CommisionReport = lazy(() =>
  import("../pages/shortLetting/reports/CommissionReport")
);
const CleaningAnalysis = lazy(() =>
  import("../pages/shortLetting/reports/CleaningAnalysis")
);
const Analytics = lazy(() => import("../pages/shortLetting/Analytics"));
const Maintenance = lazy(() => import("../pages/shortLetting/Maintenance"));
const Notifications = lazy(() => import("../pages/shortLetting/Notifications"));
const ReduceExpenses = lazy(() =>
  import("pages/longLetting/main/reduceExpenses")
);
const EditProperty = lazy(() =>
  import("pages/shortLetting/property/EditProperty")
);
const UserDetails = lazy(() => import("pages/shortLetting/Users/UserDetails"));
const EditUser = lazy(() => import("pages/shortLetting/Users/EditUser"));
const AddUsers = lazy(() => import("pages/shortLetting/Users/AddUser"));
const Users = lazy(() => import("pages/shortLetting/Users"));
const Tasks = lazy(() => import('pages/shortLetting/tasks'));
const MainAddPage = lazy(() => import('pages/shortLetting/tasks/MainAddPage'));
const TaskDetails = lazy(() => import('pages/shortLetting/tasks/TaskDetails'));


const shortLettingRoutes = [
    {
        path: '/sl',
        element: <AuthGuard><SLAdminLayout /></AuthGuard>,
        children: [
            {
                path: 'dashboard',
                element: <Role roles={['landlord', 'sml', 'franchise']}><FranchiseDashboard /></Role>
            },
            {
                path: 'hosts',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Hosts /></Role>
            },
            {
                path: 'my-profile',
                element: <Role roles={['landlord', 'sml', 'franchise']}><MyProfile /></Role>
            },
            {
                path: 'all-bookings',
                element: <Role roles={['landlord', 'sml', 'franchise']}><BookingList /></Role>
            },
            {
                path: 'onboardings',
                element: <Role roles={['landlord', 'sml', 'franchise']}><OnBoarding /></Role>
            },
            {
                path: 'properties/listing',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Properties /></Role>
            },
            {
                path: 'properties/add-property/:tab',
                element: <Role roles={['landlord', 'sml', 'franchise']}><AddProperty /></Role>
            },
            {
                path: 'properties/edit-property/:tab',
                element: <Role roles={['landlord', 'sml', 'franchise']}><EditProperty /></Role>
            },
            {
                path: 'properties/detail',
                element: <Role roles={['landlord', 'sml', 'franchise']}><PropertyDetails /></Role>
            },
            {
                path: 'users/listing',
                element: <Role roles={['sml']}><Users /></Role>
            },
            {
                path: 'users/add-user',
                element: <Role roles={['sml']}><AddUsers /></Role>
            },
            {
                path: 'users/edit-property',
                element: <Role roles={['sml']}><EditUser /></Role>
            },
            {
                path: 'users/detail',
                element: <Role roles={['sml']}><UserDetails /></Role>
            },
            {
                path: 'calendar',
                element: <Role roles={['landlord', 'sml', 'franchise']}><MyCalendar /></Role>
            },
            {
                path: 'detail-calendar',
                element: <Role roles={['landlord', 'sml', 'franchise']}><DetailCalendar /></Role>
            },
            {
                path: 'transactions',
                element: <Role roles={['landlord', 'sml', 'franchise']}><TransactionList /></Role>
            },
            {
                path: 'reservation-details',
                element: <Role roles={['landlord', 'sml', 'franchise']}><ReservationDetails /></Role>
            },

            {
                path: 'messages',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Messages /></Role>
            },
            {
                path: 'occupancies',
                element: <Role roles={['landlord', 'sml', 'franchise']}><OccupancyList /></Role>
            },
            {
                path: 'reports',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Reports /></Role>
            },
            {
                path: 'revenue-report',
                element: <Role roles={['landlord', 'sml', 'franchise']}><RevenueReport /></Role>
            },
            {
                path: 'maintenance-report',
                element: <Role roles={['landlord', 'sml', 'franchise']}><MaintenanceReport /></Role>
            },
            {
                path: 'review-report',
                element: <Role roles={['landlord', 'sml', 'franchise']}><ReviewReport /></Role>
            },
            {
                path: 'commision-report',
                element: <Role roles={['landlord', 'sml', 'franchise']}><CommisionReport /></Role>
            },
            {
                path: 'maintenance',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Maintenance /></Role>
            },
            {
                path: 'analytics',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Analytics /></Role>
            },
            {
                path: 'cleaning-analysis',
                element: <Role roles={['landlord', 'sml', 'franchise']}><CleaningAnalysis /></Role>
            },
            {
                path: 'notifications',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Notifications /></Role>
            },
            {
                path: 'reduce-expence',
                element: <Role roles={['landlord', 'sml', 'franchise']}><ReduceExpenses /></Role>
            },
            {
                path: 'users/all-users',
                element: <Role roles={['sl-admin']}><UsersList /></Role>
            },
            {
                path: 'users/view',
                element: <Role roles={['sl-admin']}><UsersDetails /></Role>
            },
            {
                path: 'tasks/all-tasks',
                element: <Role roles={['landlord', 'sml', 'franchise']}><Tasks /></Role>
            },
            {
                path: 'tasks/add-task',
                element: <Role roles={['landlord', 'sml', 'franchise']}><MainAddPage /></Role>
            },
            {
                path: 'task/view',
                element: <Role roles={['landlord', 'sml', 'franchise']}><TaskDetails /></Role>
            },
            {
                path: "support",
                element: (
                  <Role roles={["sl-admin"]}>
                    <SupportPage />
                  </Role>
                ),
              },
              {
                path: "add-issue",
                element: (
                  <Role roles={["sl-admin"]}>
                    {" "}
                    <AddIssue />{" "}
                  </Role>
                ),
              },
              {
                path: "support-issue-detail",
                element: (
                  <Role roles={["sl-admin"]}>
                    <SupportIssueDetail />
                  </Role>
                ),
              },
        ]
    },

]

export default shortLettingRoutes;
