import React from 'react';
// import './Loader.css'; // You can define the styling for the loader in this CSS file

const Loader = ({type}) => {
  return (
    <div className={type === 'button' ? 'loader2-container' : "loader-container"}>
      <div className={type === 'button' ? 'loader2' :"loader"}></div>
    </div>
  );
};

export default Loader;
