import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { FormControl, InputLabel } from "@mui/material";

export default function MultipleSelectCheckmarks({
  customClass,
  options,
  value,
  onSelectionChange,
  label,
}) {
  // const [personName, setPersonName] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState(value ? value : []);
  const menuStyle = { maxHeight: "400px"};
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedOptions(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    onSelectionChange(typeof value === "string" ? value.split(",") : value);
  };

  return (
    // <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {label ? label : ""}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={selectedOptions}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => selected.join(", ")}
        className={`${customClass} w-100`}
        MenuProps={{ style: menuStyle }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} className='menu-item' >
            <Checkbox style={{padding:"0rem" , paddingRight:"0.5rem"}} checked={selectedOptions.includes(option.value)} />
            {option.icon}
            <ListItemText className="menu-item" primary={option.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
