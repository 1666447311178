import React from "react";
import styles from "../newProperty.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ownerSelectListAsync } from "redux/propertyOwners/Services";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "components/form/ButtonLoader";
import { useState } from "react";
import Axios from "utils/axios";
import { values } from "lodash";
import ErrorMsg from "components/ErrorMsg";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AddOwner from "../../propertyOwners/AddOwner";
import UploadOwnership from "./UploadOwnership";
import { FaExclamationCircle } from "react-icons/fa";
import { clearOwnerForm } from "redux/propertyOwners/OwnersSlice";
export const AddOwnership = ({
  handleNext,
  handleBack,
  property,
  setProperty,
  fatchProperty,
  modal,
  onCloseModal
}) => {
  const userObj = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [hostlist, setHostlist] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const dispatch = useDispatch();
  const handleShow = () => {
    setShow(true);
  };
  const [showdelete, setShowdelete] = useState();
  const handleCloseDelete = () => {
    setShowdelete();
  };

  const handleShowDelete = (id) => {
    setShowdelete(id);
  };
  const [showdocument, setShowdocument] = useState(false);
  const handleClose1 = () => {
    setShowdocument(false);
  };

  const handleShowdocument = () => {
    setShowdocument(true);
  };
  const fetchHostList = () => {
    Axios.get(
      `/propertyOwners/select-owner-list?_id=${userObj._id}&userType=${userObj.currentType}`
    )
      .then((res) => {
        
        setHostlist(res.data.data);
      })
      .catch(() => { });
  };

  useEffect(() => {
    fetchHostList();
    if (property?.host) {
      formik.setFieldValue(
        "host",
        typeof property?.host === "object"
          ? property?.host?._id
          : property?.host
      );
      formik.setFieldValue(
        "hostType",
        (typeof property?.host === "object"
          ? property?.host?._id
          : property?.host) === userObj?._id
          ? "me"
          : "other"
      );
      formik.setFieldValue("host_name", property?.host_name);
    }
  }, [property]);

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const payload = {
      propertyId: property._id,
      host: values.host,
      host_name: values.host_name,
      status: 1,
    };
    setLoading(true);
    Axios.post("longlettingProperties/addOwnership", payload)
      .then((response) => {
        if (modal) {
        
          onCloseModal();
        } else {
          if (property.status === 0) {
            navigate(-1);
          }
          else {

            navigate("/ll/properties/new-properties");
          }
        }

        fatchProperty();
        setLoading(false);
      })
      .catch((err) => {
        setErrors({ submit: err });
        setLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      hostType: "",
      host: "",
      host_name: "",
    },
    validationSchema: Yup.object().shape({
      host: Yup.string().required("Please select property owner"),
    }),
    onSubmit: handleSubmit,
  });

  function handleDelete(id) {
    const payload = {
      _id: id,
      userId: userObj._id,
    };
    Axios.post(`/propertyOwners/deleteOwner`, payload)
      .then((res) => {
        if (formik.values.host === id) {
          formik.setFieldValue("host", "");
        }
        handleCloseDelete();
        fetchHostList();
      })
      .catch(() => { });
  }

  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className={styles.main_heading + " text-center mb-4"}>
            <h3 className="fw-bold h4 mb-0">Ownership</h3>
            {/* <p>What kind of property do you manage?</p> */}
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div
            className={
              styles.custom_radio +
              " d-flex center gap-4 justify-content-center"
            }
          >
            <div
              onClick={() => {
                formik.setFieldValue("host", "");
                formik.setFieldValue("hostType", "other");
                formik.setFieldValue("host_name", "");
              }}
              className={styles.readio_design}
            >
              <input
                type="radio"
                name="radio"
                id="redio2"
                className="d-none"
                checked={formik.values.hostType === "other"}
              />
              <label
                for="redio2"
                className="align-content-center d-grid gap-2 text-center"
              >
                <img
                  className="mw-100"
                  src="/images/residential.png"
                  alt="wrong Url"
                />
                <p>Owned by someone else</p>
              </label>
            </div>
            <div
              onClick={() => {
                formik.setFieldValue("host", userObj._id);
                formik.setFieldValue("hostType", "me");
                formik.setFieldValue("host_name", userObj.name);
              }}
              className={styles.readio_design}
            >
              <input
                type="radio"
                name="radio"
                id="redio1"
                className="d-none"
                checked={
                  formik.values.hostType === "me" &&
                  formik.values.host === userObj._id
                }
              />
              <label
                for="redio1"
                className="align-content-center d-grid gap-2 text-center"
              >
                <img
                  className="mw-100"
                  src="/images/commercial.png"
                  alt="wrong Url"
                />
                <p>Owned by me</p>
              </label>
            </div>
          </div>
        </div>

        <ErrorMsg hasError={formik.touched.host && formik.errors.host}>
          {formik.errors.host}
        </ErrorMsg>
        {formik.values.hostType === "other" && (
          <div className="col-md-12 mt-5">
            <div className={styles.main_heading + " text-center mb-4"}>
              {/* <h3 className="fw-bold h4 mb-0">Ownership</h3> */}
              <p>Add one or more owners and set their ownership percentage</p>
            </div>
          </div>
        )}
        {formik.values.hostType === "other" && (
          <div className="col-md-12 mt-4">
            <div
              className={
                styles.add_document + " d-flex gap-3 justify-content-center"
              }
            >
              {hostlist?.length > 0 &&
                hostlist.map((hostdata) => {
                  return (
                    <div
                      className={
                        styles.card_part +
                        " border" +
                        (hostdata._id === formik.values.host
                          ? " border-danger"
                          : "") +
                        " rounded pointer"
                      }
                    >
                      <img
                        onClick={() => {
                          formik.setFieldValue("host", hostdata?._id);
                          formik.setFieldValue(
                            "host_name",
                            hostdata.firstName + " " + hostdata.lastName
                          );
                        }}
                        className="profile-img2"
                        src="/images/user-demo.jpg"
                        alt="Wrong Url"
                      />
                      <h5
                        onClick={() => {
                          formik.setFieldValue("host", hostdata?._id);
                          formik.setFieldValue(
                            "host_name",
                            hostdata.firstName + " " + hostdata.lastName
                          );
                        }}
                      >
                        {hostdata.firstName + " " + hostdata.lastName}
                      </h5>
                      <button
                        onClick={() => {
                          formik.setFieldValue("host", hostdata?._id);
                          formik.setFieldValue(
                            "host_name",
                            hostdata.firstName + " " + hostdata.lastName
                          );
                        }}
                      >
                        Ownership ( {hostdata?.ownership} %)
                      </button>
                      <img
                        onClick={() => setShowdelete(hostdata?._id)}
                        className={styles.delete_icon}
                        src="/images/delete.svg"
                        alt=""
                      />
                    </div>
                  );
                })}
              <div className={styles.card_part + " border rounded pointer"}>
                <div
                  onClick={() => {
                    dispatch(clearOwnerForm());

                    handleShow();
                  }}
                  className={
                    styles.card_grids +
                    " d-grid align-items-center text-center gap-4 p-3"
                  }
                >
                  <AddCircleIcon />
                  <h5 className="small m-0">Add Owner</h5>
                </div>
              </div>
            </div>
          </div>
        )}

        {!property?.propertyDocuments?.type ? (
          <div className="text-left mt-3">
            <p
              onClick={() => handleShowdocument()}
              className="d-flex align-items-center gap-2 text-primary justify-content-center justify-content-sm-start pointer"
              style={{ lineHeight: "18px" }}
            >
              <span>+</span> Add Property Ownership document
            </p>
          </div>
        ) : (
          <div className="text-left mt-3">
            <p
              // onClick={() => addEmptyUnit()}
              className="d-flex align-items-center gap-2 text-primary justify-content-center justify-content-sm-start pointer"
            >
              {property?.propertyDocuments?.type} added
            </p>
          </div>
        )}

        <div className=" mt-3 d-flex  justify-content-center justify-content-md-end">
          <button
            className="gray-btn grow "
            onClick={() => {
              handleBack();
            }}
          >
            Back
          </button>
          <ButtonLoader
            type="submit"
            className="primary-btn grow  ml-3"
            onClickhandle={formik.handleSubmit}
            isLoading={isLoading}
            buttonName={"Save"}
            loaderColor={"white"}
          >
            Save
          </ButtonLoader>
        </div>
      </div>
      {show && (
        <div className="popup-overlay">
          <div
            className="bg-card report-card  overflow-y owner-width"
            style={{ padding: "0.51rem" }}
          >
            <AddOwner onClose={handleClose} fetchHostList={fetchHostList} />
          </div>
        </div>
      )}
      {showdocument && (
        <div className="popup-overlay">
          <div
            className="bg-card report-card  overflow-y owner-width"
            style={{ padding: "0.51rem" }}
          >
            <UploadOwnership
              onClose={handleClose1}
              setProperty={setProperty}
              property={property}
              fatchProperty={fatchProperty}
            />
          </div>
        </div>
      )}
      {showdelete && (
        <div className="card modal" style={{ width: "30%" }}>
          <div className="modal_box" style={{ textAlign: "center" }}>
            <div style={{ fontSize: "50px" }}>
              <FaExclamationCircle />
            </div>
            <h3>Are You Sure ?</h3>
            <p>You wanna delete this owner</p>
            <div className="modal-btn mt-3">
              <button
                type="submit"
                className="btn form-btn grow "
                onClick={(e) => handleDelete(showdelete)}
              >
                Yes
              </button>
              <button
                type="submit"
                className="btn form-btn2 grow "
                onClick={(e) => handleCloseDelete()}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
