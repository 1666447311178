import React from "react";
import { CircularProgress } from "@mui/material";
import styled from "styled-components";

const Buttonstyle = styled.button`
  width: fit-content;
  height: auto;
  border-radius: 4px ;
  background-color: #3874ff ;
  text-transform: none ;
  padding: 0.7rem 0rem ;
  font-size: 15px ;
  font-weight: 700 ;
  margin-bottom: 10px ;
  outline: none;
  border: none;
  color:white;
`;

export const ButtonLoader = ({
  className,
  onClickhandle,
  buttonName,
  isLoading,
  loaderColor,
  type,
  style,
  icon,
  ...props
}) => {
  const propsObj = { type };
  return (
    <>
     { className ?
      <button
        disabled={isLoading}
        {...propsObj}
        className={className}
        onClick={onClickhandle}
      >
        {buttonName}{" "}{icon ? icon : ""}
        {isLoading && (
          <CircularProgress
            style={{
              color: loaderColor ? loaderColor : "#3874ff",
              width: "16px",
              height: "16px",
              position: "relative",
              top: "3px",
              marginLeft: "3px",
            }}
          />
        )}
      </button>
      :
      <Buttonstyle
        disabled={isLoading}
        {...propsObj}
        className={className}
        style={{ ...style  }}
        onClick={onClickhandle}
      >
        {buttonName}{" "}{icon ? icon : ""}
        {isLoading && (
          <CircularProgress
            style={{
              color: loaderColor ? loaderColor : "#3874ff",
              width: "19px",
              height: "19px",
              position: "relative",
              top: "3px",
              marginLeft: "3px",
            }}
          />
        )}
      </Buttonstyle>}
    </>

  );
};
