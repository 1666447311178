import React from 'react';
import {useSelector} from 'react-redux';

import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
// import useAuth from '../hooks/useAuth';

const AuthGuard = ({ children }) => {

  const token = useSelector(state => state.auth.token);
  // const user = useSelector(state => state.auth.user);

  if (!token) {
    return <Navigate to="/" />;
  }

  return children ? children : <Outlet />;

};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;