import {createSlice } from "@reduxjs/toolkit";
import {sendMailAsync} from "./Services"
import _ from "lodash";

const sendMailSlice = createSlice({
  name: "sendMailSlice",
  initialState: {
    loading: false,
    sendMailForm: {},
  },
  reducers: {
    changeSendMailForm:(state, action) => {
      let update_form = _.cloneDeep(state.sendMailForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        sendMailForm:{
          ...state.sendMailForm,
          ...update_form,
        }
      }
    },
    clearSendMailForm: (state, action) => {
      state.sendMailForm = {};
    },
  },
  extraReducers: {
    [sendMailAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [sendMailAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [sendMailAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default sendMailSlice.reducer;

export const {
  changeSendMailForm,
  clearSendMailForm,
} = sendMailSlice.actions;
