import * as React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { propertyTypeListAsync } from "redux/propertyType/Services";
import MultiSelectWithCheckbox from "components/form/MultiSelectWithCheckbox";
import TextInput from "components/form/TextInput";
import Checkboxes from "components/form/Checkbox";
import { ownerSelectListAsync } from "redux/propertyOwners/Services";
import Axios from "utils/axios";
import styles from "../newProperty.module.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";
import { useNavigate } from "react-router-dom";

function AddGeneralInfo({ handleNext, property, setProperty }) {
  const [isLoading, setIsLoading] = useState(false);
  const userObj = useSelector((state) => state.auth.user);
  const userType = userObj?.currentType;

  const navigate = useNavigate();

  useEffect(() => {
    // fetchHostList();
    // fetchPropertyType();
    // Update the state with the accumulated data

    if (property?._id) {
      formik.setFieldValue("propertyKind", property.propertyKind);
      formik.setFieldValue("propertyType", property.propertyType);
      formik.setFieldValue("title", property.title);
      formik.setFieldValue("certificateType", property.certificateType);
      formik.setFieldValue("propertyHMO", property.propertyHMO);
    }
  }, [property]);

  const propertyCertificateList = [
    { label: "Gas Certificate", value: "Gas Certificate" },
    { label: "PAT Test", value: "PAT Test" },
    { label: "Electricity Certificate", value: "Electricity Certificate" },
    { label: "EPC", value: "EPC" },
    { label: "Insurance Certificate", value: "Insurance Certificate" },
    {
      label: "Legionella Risk Assessment Date",
      value: "Legionella Risk Assessment Date",
    },
    {
      label: "Smoke & CO Alarm Expiry Date",
      value: "Smoke & CO Alarm Expiry Date",
    },
    {
      label: "Property Licence Expiry Date",
      value: "Property Licence Expiry Date",
    },
    { label: "HMO Certificate", value: "HMO Certificate" },
    { label: "Fire Alarm", value: "Fire Alarm" },
    { label: "Fire Risk Assessment", value: "Fire Risk Assessment" },
    { label: "Smoke Detector", value: "Smoke Detector" },
    { label: "TV License", value: "TV License" },
    { label: "Council Tax Exemption", value: "Council Tax Exemption" },
    { label: "Locality Risk Assessment", value: "Locality Risk Assessment" },
    { label: "Boiler Warranty", value: "Boiler Warranty" },
    { label: "Appliance Risk Assessment", value: "Appliance Risk Assessment" },
    { label: "Deposit", value: "Deposit" },
  ];

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const data = new FormData();

    property?._id && data.append("propertyId", property?._id);
    data.append("lettingType", "sml-ll");
    data.append("user_id", userObj.id);
    data.append("propertyHMO", values.propertyHMO);
    data.append("propertyType", values.propertyType);
    data.append("propertyKind", values.propertyKind);
    data.append("certificateType", JSON.stringify(values.certificateType));
    data.append("title", values.title);

    setIsLoading(true);

    const payload = data;

    if (property?._id) {
      Axios.post("/longlettingProperties/editGeneralInfo", payload)
        .then((response) => {
          handleNext();

          setProperty(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrors({ submit: err });
          setIsLoading(false);
        });
    } else {
      Axios.post("/longlettingProperties/addGeneralInfo", payload)
        .then((response) => {
          handleNext();

          setProperty(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrors({ submit: err });
          setIsLoading(false);
        });
    }
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      propertyType: "",
      propertyKind: "",
      certificateType: [],
      propertyHMO: false,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Please enter property name"),
      propertyType: Yup.string().required("Please Select property type"),
      propertyKind: Yup.string().required("Please select property kind"),
      certificateType: Yup.array().min(
        1,
        "Please select at least one certificate"
      ),
    }),
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className={styles.main_heading + " text-center mb-4"}>
            <h3 className="fw-bold h4 mb-0">General Info:</h3>
            <p>What kind of property do you manage?</p>
          </div>
        </div>
        <div className="col-md-12 col-lg-12">
          <div
            className={
              styles.custom_radio + " d-flex gap-4 justify-content-center"
            }
          >
            <div className={styles.readio_design}>
              <input
                type="radio"
                name="propertyKind"
                id="redio2"
                className="d-none"
                checked={formik.values.propertyKind === "Residential"}
                onChange={(e) => {
                  formik.setFieldValue("propertyKind", "Residential");
                }}
              />
              <label
                for="redio2"
                className="align-content-center d-grid gap-2 text-center"
              >
                <img
                  className="mw-100"
                  src="/images/residential.png"
                  alt="wrong Url"
                />
                <p>Residential</p>
              </label>
            </div>
            <div className={styles.readio_design}>
              <input
                type="radio"
                name="propertyKind"
                id="redio1"
                className="d-none"
                checked={formik.values.propertyKind === "Commercial"}
                onChange={(e) => {
                  formik.setFieldValue("propertyKind", "Commercial");
                }}
              />
              <label
                for="redio1"
                className="align-content-center d-grid gap-2 text-center"
              >
                <img
                  className="mw-100"
                  src="/images/commercial.png"
                  alt="wrong Url"
                />
                <p>Commercial</p>
              </label>
            </div>
          </div>
          <ErrorMsg
            hasError={formik.touched.propertyKind && formik.errors.propertyKind}
          >
            {formik.errors.propertyKind}
          </ErrorMsg>
        </div>
        {formik.values.propertyKind && (
          <div className="col-md-12 mt-4">
            <div className={styles.main_heading + " text-center mb-2"}>
              <p>What types best describe your property?</p>
            </div>
          </div>
        )}
        {formik.values.propertyKind === "Residential" && (
          <div className="col-md-12 col-lg-12">
            <div
              className={
                styles.custom_radio +
                " d-flex gap-4 justify-content-center flex-wrap"
              }
            >
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="propertyType"
                  id="single_family"
                  className="d-none"
                  checked={formik.values.propertyType === "Single Family"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Single Family");
                  }}
                />
                <label
                  for="single_family"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/home-icon.png"
                    alt="wrong Url"
                  />
                  <p>Single Family</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="residential"
                  id="multiple_family"
                  className="d-none"
                  checked={formik.values.propertyType === "Multiple Family"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Multiple Family");
                  }}
                />
                <label
                  for="multiple_family"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/multiple-family.png"
                    alt="wrong Url"
                  />
                  <p>Multiple Family</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="residential"
                  id="flat_condo"
                  className="d-none"
                  checked={formik.values.propertyType === "Flat Condo"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Flat Condo");
                  }}
                />
                <label
                  for="flat_condo"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/flat-condo.png"
                    alt="wrong Url"
                  />
                  <p>Flat Condo</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="residential"
                  id="townhouse"
                  className="d-none"
                  checked={formik.values.propertyType === "Townhouse"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Townhouse");
                  }}
                />
                <label
                  for="townhouse"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/townhouse.png"
                    alt="wrong Url"
                  />
                  <p>Townhouse</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="residential"
                  id="other"
                  className="d-none"
                  checked={formik.values.propertyType === "Other"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Other");
                  }}
                />
                <label
                  for="other"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/other.png"
                    alt="wrong Url"
                  />
                  <p>Other</p>
                </label>
              </div>
            </div>
            <ErrorMsg
              hasError={
                formik.touched.propertyType && formik.errors.propertyType
              }
            >
              {formik.errors.propertyType}
            </ErrorMsg>
          </div>
        )}
        {formik.values.propertyKind === "Commercial" && (
          <div className="col-md-12 col-lg-12">
            <div
              className={
                styles.custom_radio +
                " d-flex gap-4 justify-content-center flex-wrap"
              }
            >
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="industrial"
                  className="d-none"
                  checked={formik.values.propertyType === "Industrial"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Industrial");
                  }}
                />
                <label
                  for="industrial"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/industrial.png"
                    alt="wrong Url"
                  />
                  <p>Industrial</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="office"
                  className="d-none"
                  checked={formik.values.propertyType === "Office"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Office");
                  }}
                />
                <label
                  for="office"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/office.png"
                    alt="wrong Url"
                  />
                  <p>Office</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="shoppingcenter"
                  className="d-none"
                  checked={formik.values.propertyType === "Shopping Center"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Shopping Center");
                  }}
                />
                <label
                  for="shoppingcenter"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/shopping_center.png"
                    alt="wrong Url"
                  />
                  <p>Shopping Center</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="storage"
                  className="d-none"
                  checked={formik.values.propertyType === "Storage"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Storage");
                  }}
                />
                <label
                  for="storage"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/home_storage.png"
                    alt="wrong Url"
                  />
                  <p>Storage</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="parkingspace"
                  className="d-none"
                  checked={formik.values.propertyType === "Parking Space"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Parking Space");
                  }}
                />
                <label
                  for="parkingspace"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/parkingspace.png"
                    alt="wrong Url"
                  />
                  <p>Parking Space</p>
                </label>
              </div>
              <div className={styles.readio_design}>
                <input
                  type="radio"
                  name="select_commercial"
                  id="Other2"
                  className="d-none"
                  checked={formik.values.propertyType === "Other"}
                  onChange={(e) => {
                    formik.setFieldValue("propertyType", "Other");
                  }}
                />
                <label
                  for="Other2"
                  className="align-content-center d-grid gap-2 text-center"
                >
                  <img
                    className="mw-100"
                    src="/images/residential.png"
                    alt="wrong Url"
                  />
                  <p>Other </p>
                </label>
              </div>
            </div>
            <ErrorMsg
              hasError={
                formik.touched.propertyType && formik.errors.propertyType
              }
            >
              {formik.errors.propertyType}
            </ErrorMsg>
          </div>
        )}
        {formik.values.propertyType && (
          <div className="col-md-12 mt-4 mb-2">
            <div className={styles.swicht_form}>
              <p className="d-flex gap-3 align-items-center justify-content-start">
                Is this Property an HMO?
                <div className="d-flex align-items-center">
                  <Checkboxes
                    customClass={"amenities-checked"}
                    value={formik.values.propertyHMO}
                    name="propertyHMO"
                    onChange={formik.handleChange}
                  />
                  <label className="input-heading check-label">Yes</label>
                </div>
              </p>
            </div>
          </div>
        )}
        {formik.values.propertyType && (
          <div className="col-md-12 mt-1">
            <div className="row g-3">
              <div className="col-md-6">
                <TextInput
                  customClass={"w-100"}
                  label={"Property Name or Ref* "}
                  name="title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg
                  hasError={formik.touched.title && formik.errors.title}
                >
                  {formik.errors.title}
                </ErrorMsg>
              </div>
              <div className="col-md-6">
                <MultiSelectWithCheckbox
                  customClass={"w-100"}
                  label="Required Certificate Type*"
                  options={propertyCertificateList}
                  value={formik.values.certificateType}
                  onSelectionChange={(e) => {
                    formik.setFieldValue("certificateType", e);
                  }}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.certificateType &&
                    formik.errors.certificateType
                  }
                >
                  {formik.errors.certificateType}
                </ErrorMsg>
              </div>
            </div>
          </div>
        )}
        <p style={{ marginTop: "1rem" }}>
          <ErrorMsg hasError={formik.errors.submit}>
            {formik.errors.submit}
          </ErrorMsg>
        </p>
        <div className="mt-3 d-flex justify-content-center justify-content-md-end">
          <div className="mt-3 d-flex justify-content-end">
            <button
              className="gray-btn grow "
              onClick={() => {
                navigate(-1);
              }}
            >
              Back
            </button>
            <ButtonLoader
              type="button"
              className="primary-btn grow  ml-3"
              onClickhandle={formik.handleSubmit}
              buttonName={"Next"}
              loaderColor={"white"}
              isLoading={isLoading}
            ></ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGeneralInfo;
