import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL} from '../../constants/constants';


//get Api



export const tenantStatementListAsync = createAsyncThunk(
  'tenantStatementSlice/tenantStatementListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/tenant-statements?id=${post.userId}&userType=${post.userType}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const allTenantStatementListAsync = createAsyncThunk(
  'tenantStatementSlice/allTenantStatementListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/tenant-statements`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);




// // post Api
export const addTenantStatementAsync = createAsyncThunk(
  'tenantStatementSlice/addTenantStatementAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/tenant-statements/add-tenant-statement`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');

        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();


        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


// export const editTenanciesAsync = createAsyncThunk(
//   'tenantStatementSlice/editTenanciesAsync',
//   async (post, {rejectWithValue}, thunkAPI) => {
//     try {
//       const access_token = localStorage.getItem('token');
// 
// const navigation = useNavigation();
//       const response = await fetch(
//         `${NEW_NEW_API_BASE_URL}/tenantStatement/edittenancy`,
//         {
//           method: 'POST',
//           body: JSON.stringify(post),
//           headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'source': 'web',
// 'token': `${access_token}`,
//           },
//         },
//       );

//       if (response.status === 200) {
//         // if HTTP-status is 200-299
//         const data = await response.json();

//         // navigate('BottomNav');
//         // navigation.navigate('BottomNav');
//         return data;
//       } else {
//         const data = await response.json();

//         // Toast.show(data.message);
//         return data;
//       }
//     } catch (err) {

//       // You can choose to use the message attached to err or write a custom error
//       return rejectWithValue('Opps there seems to be an error');
//     }
//   },
// );

// // Delete Api

export const deleteTenantStatementAsync = createAsyncThunk(
  'tenantStatementSlice/deleteTenantStatementAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/tenant-statements/delete-tenant-statement`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = response;

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

