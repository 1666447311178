import * as React from "react";
import styles from "./SignupPage.module.css";
import TextInput from "../../../../components/form/TextInput";
import PasswordInputWithToggle from "../../../../components/form/PasswordInput";
import Button from "../../../../components/Button";
import Checkboxes from "../../../../components/form/Checkbox";
import axios from "axios";
import { useEffect, useState } from "react";
import { setCredentials } from "redux/authSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "utils/axios";
import CountryCodeSelect from "components/form/CountryCode";
import * as Yup from "yup";
import { useFormik } from "formik";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";

function SignUpPage({ onNavigate, onClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const [showotp, setshowotp] = useState(false);
  const [otp, setotp] = useState();

  const handleSubmit1 = () => {
    setError();

    if (!otp) {
      setError("Please Enter  OTP ");
      return;
    }

    const data = {
      otp: otp,
      email: formik.values.email.toLowerCase(),
    };

    Axios.post(`/guestAuth/verify-otp`, data)
      .then((res) => {
        dispatch(setCredentials(res?.data));
        onClose();
      })
      .catch((error) => {
        if (error) {
          setError(error ? error : "Error occured while signup");
        }
      });
  };

  const signuphandle = (values, { setSubmitting, setErrors }) => {
    const data = {
      fName: values.fName,
      lName: values.lName,
      email: values.email.toLowerCase(),
      mobileNumber: values.mobileNumber,
      password: values.password,
      countryCode: values.countryCode,
    };

    setIsLoading(true);

    Axios.post(`/guestAuth/authenticate`, data)
      .then((res) => {
        setIsLoading(false);
        if (res.error) {
          setErrors(res.error);
        } else {
          setshowotp(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors({ submit: err });
      });
  };

  const formik = useFormik({
    initialValues: {
      fName: "",
      lName: "",
      email: "",
      countryCode: "+44",
      mobileNumber: "",
      password: "",
      check: false,
    },
    validationSchema: Yup.object().shape({
      fName: Yup.string()
        .matches(
          /^[a-zA-Z\s]*$/,
          "First name should only contain letters and spaces"
        )
        .required("Please add first name"),
      lName: Yup.string()
        .matches(
          /^[a-zA-Z\s]*$/,
          "Last name should only contain letters and spaces"
        )
        .required("Please add last name"),
      check: Yup.boolean().oneOf([true], "This field must be checked"),

      email: Yup.string()
        .email("Invalid email address")
        .test("custom", "Please enter a valid email address", (value) => {
          // Regular expression to validate email format
          const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

          // Check if the email format is valid
          if (!emailRegex.test(value)) {
            return false;
          }

          // Check if a dot (.) is placed before @
                 // Check if a dot (.) is placed before @
                 if (value[value.indexOf("@")-1]==='.') {
                  return false;
                }
          // Check if there is a space at the beginning
          if (value.trim() !== value) {
            return false;
          }

          // Check if there are no more than 2 domains (e.g., example.com)
          const parts = value.split("@");
          if (parts[1].split(".").length > 3) {
            return false;
          }

          return true;
        })
        .required("Please enter email"),
      mobileNumber: Yup.number("Please enter numeric value")
        .typeError("Mobile number must be a number")
        .test(
          "len",
          "Mobile number sholud contain maximum  20 number ",
          (val) => val.toString().length <= 20
        )
        .required("Please enter mobile number"),
      password: Yup.string()
        .test(
          "len",
          "Password sholud contain minimum  8 characters ",
          (val) => val.toString().length >= 8
        )
        .test(
          "specialChar",
          "Password should contain at least one special character",
          (val) => /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]/.test(val)
        )
        .required("Please enter password"),
    }),
    onSubmit: signuphandle,
  });

  // console.log(formik.errors);

  return !showotp ? (
    <>
      <div className={styles.modal_header}>
        <h2 className={styles.modal_title_big}>Create an account</h2>
      </div>
      {error && (
        <div
          className="mb-2"
          style={{
            backgroundColor: "#f06a6a",
            color: "black",
            padding: "0.5rem",
            textAlign: "center",
            fontWeight: "600",
          }}
        >
          {error}
        </div>
      )}

      <TextInput
        customClass={`w-100 mb-2 ${styles.formInput}`}
        type={""}
        name="fName"
        value={formik.values.fName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"First Name"}
      />

      <ErrorMsg hasError={formik.touched.fName && formik.errors.fName}>
        {formik.errors.fName}
      </ErrorMsg>

      <TextInput
        customClass={`w-100 mb-2 ${styles.formInput}`}
        type={""}
        name="lName"
        value={formik.values.lName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"Last Name"}
      />
      <ErrorMsg hasError={formik.touched.lName && formik.errors.lName}>
        {formik.errors.lName}
      </ErrorMsg>
      <TextInput
        customClass={`w-100 mb-2 ${styles.formInput}`}
        type={"email"}
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"Email"}
      />
      <ErrorMsg hasError={formik.touched.email && formik.errors.email}>
        {formik.errors.email}
      </ErrorMsg>
      <div className="d-flex justify-content-between">
        <CountryCodeSelect
          customClass="w-25"
          name="countryCode"
          selectedValue={formik.values.countryCode}
          onChange={formik.handleChange}
        />
        <TextInput
          customClass={`w-75 mb-2 ${styles.formInput}`}
          type={"tel"}
          name="mobileNumber"
          value={formik.values.mobileNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"mobile"}
        />
      </div>
      <ErrorMsg
        hasError={formik.touched.countryCode && formik.errors.countryCode}
      >
        {formik.errors.countryCode}
      </ErrorMsg>
      <ErrorMsg
        hasError={formik.touched.mobileNumber && formik.errors.mobileNumber}
      >
        {formik.errors.mobileNumber}
      </ErrorMsg>
      <PasswordInputWithToggle
        // setPassword={setPassword}
        password={formik.values.password}
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        customClass={` mb-2 ${styles.formInput}`}
      />
      <ErrorMsg hasError={formik.touched.password && formik.errors.password}>
        {formik.errors.password}
      </ErrorMsg>
      <div className={styles.terms_check}>
        <Checkboxes
          name="check"
          value={formik.values.check}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          customClass={styles.terms_checkbox}
        />
        I agree with terms & conditions
      </div>
      <ErrorMsg hasError={formik.touched.check && formik.errors.check}>
        {formik.errors.check}
      </ErrorMsg>
      <ErrorMsg hasError={formik.errors.submit}>
        {formik.errors.submit}
      </ErrorMsg>
      <ButtonLoader
        buttonName={"Create an account"}
        variant={"contained"}
        className={styles.booking_btn}
        onClickhandle={formik.handleSubmit}
        isLoading={isLoading}
        loaderColor={"yellow"}
      />

      {/* <Button
              hidden
                buttonText={'Signup with Google'}
                variant={'contained'}
                customClass={styles.google_btn}
            />
            <Button
              hidden
                buttonText={'Signup with Facebook'}
                variant={'contained'}
                customClass={styles.facebook_btn}
            /> */}

      <button
        onClick={() => {
          onNavigate("signin");
        }}
        className={styles.already_link}
      >
        Already a member? Sign in!
      </button>

      {/* <button type="button" className={styles.close} onClick={onClose}>
        ×
      </button> */}
    </>
  ) : (
    <div>
      <div className={styles.modal_header}>
        <h2 className={styles.modal_title_big}>Confirm OTP</h2>
      </div>

      <PasswordInputWithToggle
        password={otp}
        onChange={(e) => {
          setotp(e.target.value);
          setError("");
        }}
        placeholder={"OTP"}
        customClass={` mb-3 ${styles.formInput}`}
      />
      <ErrorMsg hasError={error}>{error}</ErrorMsg>
      <Button
        buttonText={"Verify OTP"}
        variant={"contained"}
        customClass={styles.booking_btn}
        onClick={handleSubmit1}
      />
      <ButtonLoader
        buttonName={"Resend OTP"}
        onClickhandle={formik.handleSubmit}
        isLoading={isLoading}
        className={styles.already_link}
      >
        Resend OTP
      </ButtonLoader>
    </div>
  );
}

export default SignUpPage;
