import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import styles from "./header.module.css";
import { AiOutlineLock, AiOutlinePlus } from "react-icons/ai";
import { useState } from "react";
import LoginPage from "../../loginPage";
import SignUpPage from "../../signUp";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { userAsync } from "redux/users/Services";
import Popover from "@mui/material/Popover";
import { AiOutlineUser } from "react-icons/ai";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { BsBoxArrowDownRight, BsDashSquareDotted } from "react-icons/bs";
import { GrDashboard } from "react-icons/gr";
import { MdOutlineDashboard } from "react-icons/md";
import { signoutAsync } from "redux/signOut/Services";

const pages = ["Home", "Demos", "Properties", "Owners", "Features", "Pages"];

function ResponsiveAppBar({ headerBackground, textColor, logo }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const [openSigninForm, setOpenSigninForm] = useState(false);

  const [openSignupForm, setOpenSignupForm] = useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj?.id;

  const dispatch = useDispatch();
  // const { user } = useSelector((state) => {
  //   const { myProfileReducer, userReducer } = state;
  //   return { loading: myProfileReducer.loading, user: userReducer.user };
  // });

  // const fetchData = () => {
  //   try {
  //     const payload = {
  //       userId: userId,
  //     };
  //     dispatch(userAsync(payload))
  //       .unwrap()
  //       .then((res) => {});
  //   } catch (error) {
  //     // toast.error("Program details could not be fetched, please try again!");
  //   }
  // };

  // useEffect(
  //   () => {
  //     if (userObj?._id) {
  //       fetchData();
  //     }
  //   }, // eslint-disable-next-line
  //   [userObj]
  // );

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function signOut() {
    try {
      const payload = {
        id: userId,
      };
      dispatch(signoutAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            alert(res.error);
          } else {
            navigate("/guest/homepage");
            navigate(0);
            localStorage.clear();
            handleClose();
          }
        });
    } catch (error) {
      alert(error);
    }
  }

  return (
    <>
      <AppBar
        position="static"
        className={styles.header_background}
        style={headerBackground}
      >
        <Container maxWidth="xl" className={styles.header_container}>
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Link className="navbar-brand" to="/guest/homepage">
                <img
                  className={styles.header_logo}
                  src={`/images/${logo}`}
                  alt="wrong Url"
                ></img>
              </Link>
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color={textColor}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page} onClick={handleCloseNavMenu}>
                    <Typography textAlign="center" color={textColor}>
                      {page}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <Link className="navbar-brand" to="/guest/homepage">
                <img
                  className={styles.header_logo_sm}
                  src={`/images/${logo}`}
                  alt="wrong Url"
                ></img>
              </Link>
            </Typography>

            {userObj?.name && userObj?.currentType==='guest' ? (
              <Typography
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                
                textDecoration: "none",
              }}
              >
                <img
                  className="user-img3"
                  onClick={handleClick}
                  src={
                    userObj.profilePic !== "" && userObj.profilePic !== null
                      ? userObj.profilePic
                      : "/images/user-demo.jpg"
                  }
                  alt="Wrong Url"
                ></img>
              </Typography>

            ) :
              <>
                <Typography
                  variant="h5"
                  wrap
                  component="a"
                  sx={{
                    mr: 1,
                    flexGrow: 1,
                    fontWeight: 700,
                    textDecoration: "none",
                    display: { xs: "flex", md: "none" },
                    alignItems: "center",
                    backgroundColor: "none",
                  }}
                  style={{ color: `${textColor}` }}
                  onClick={() => {
                    setOpenSigninForm(true);
                  }}
                >
                  <AiOutlineLock
                    color="#3874ff"
                    size={18}
                    style={{ marginRight: "5px" }}
                  />
                  <span style={{ fontSize: "14px" }}>Login</span>
                </Typography>

                <Typography
                  variant="h5"
                  wrap
                  component="a"
                  sx={{
                    flexGrow: 1,
                    fontWeight: 700,
                    textDecoration: "none",
                    display: { xs: "flex", md: "none" },
                    alignItems: "center",
                  }}
                  style={{ color: `${textColor}` }}
                  onClick={() => {
                    setOpenSignupForm(true);
                  }}
                >
                  <AiOutlinePlus
                    color="#3874ff"
                    size={18}
                    style={{ marginRight: "5px" }}
                  />
                  <span style={{ fontSize: "14px" }}> SignUp </span>
                </Typography>
              </>
            }
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "none", md: "flex" },
                justifyContent: "end",
              }}
            >
              {/* {pages.map((page) => (
                            <Button
                                key={page}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2,  display: 'block', fontWeight: '600', textTransform: 'capitalize', marginRight: "1rem", color:{textColor} }}
                                className={styles.header_link}
                                style={{color:`${textColor}`}}
                            >
                                {page}
                            </Button>
                        ))} */}
              {userObj?.name && userObj?.currentType==='guest' ? (
                <img
                  className="user-img3"
                  onClick={handleClick}
                  src={
                    userObj.profilePic !== "" && userObj.profilePic !== null
                      ? userObj.profilePic
                      : "/images/user-demo.jpg"
                  }
                  alt="Wrong Url"
                ></img>
              ) : (
                <span style={{ display: "flex" }}>
                  <Button
                    onClick={() => {
                      setOpenSigninForm(true);
                    }}
                    sx={{
                      my: 2,
                      display: "block",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    className={styles.header_link}
                    style={{ color: `${textColor}` }}
                  >
                    <AiOutlineLock
                      color="#3874ff"
                      size={18}
                      style={{ marginRight: "5px" }}
                    />
                    Login
                  </Button>
                  <Button
                    onClick={() => {
                      setOpenSignupForm(true);
                    }}
                    sx={{
                      my: 2,
                      display: "block",
                      fontWeight: "600",
                      textTransform: "capitalize",
                    }}
                    className={styles.header_link}
                    style={{ color: `${textColor}` }}
                  >
                    <AiOutlinePlus
                      color="#3874ff"
                      size={18}
                      style={{ marginRight: "5px" }}
                    />
                    SignUp
                  </Button>
                </span>
              )}

              {/* <Button
                            onClick={handleCloseNavMenu}
                            sx={{ my: 2, color: '#ffffff', backgroundColor: "#3874ff", display: 'block', fontWeight: '600', textTransform: 'capitalize', borderRadius: "20px", padding: "0px 20px", marginLeft: "2rem" }}
                            className={styles.header_link}
                        >
                            Submit Property
                        </Button> */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          style: {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Customize the box-shadow here
          },
        }}
      >
        <div className={styles.popoverpadding}>
          <NavLink
            className="profile-url  ml-1"
            to="/guest/dashbaord/booking"
            onClick={() => {
              handleClose();
            }}
            style={{ padding: "5px 15px" }}
          >
            <MdOutlineDashboard />
            <span>Dashboard</span>
          </NavLink>
          <NavLink
            className="profile-url ml-1"

            onClick={() => {
              signOut();
              handleClose();
            }}
            style={{ padding: "5px 15px" }}
          >
            <BsBoxArrowDownRight />
            <span>Sign Out</span>
          </NavLink>
        </div>
      </Popover>

      {openSigninForm && (
        <LoginPage
          formType={"signin"}
          onClose={() => {
            setOpenSigninForm(false);
          }}
        />
      )}

      {openSignupForm && (
        <LoginPage
          formType={"signup"}
          onClose={() => {
            setOpenSignupForm(false);
          }}
        />
      )}
    </>
  );
}
export default ResponsiveAppBar;
