import { AiOutlineFileSearch } from "react-icons/ai";
import { BiSupport } from "react-icons/bi";

import { HiOutlineUsers } from "react-icons/hi";
import store from "redux/Store";

let GuestNavitems = [];
const state = store.getState();

// let role = 'admin';
let role = "guest";

if (role === "guest") {
  GuestNavitems.push(
    {
      path: "booking",
      name: "Bookings",
      icon: <AiOutlineFileSearch />,
      subRoutes: [
        {
          path: "booking",
          name: "Bookings",
          icon: <HiOutlineUsers />,
        },
      ],
    },
    {
      path: "support",
      name: "Support system",
      icon: <BiSupport />,
      subRoutes: [
        {
          path: "support",
          name: "Support system",
          icon: <BiSupport />,
        },
      ],
    }
  );
}

export default GuestNavitems;
