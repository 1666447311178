import { useEffect, useRef, useState } from "react"
import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import "../App.css";
import '../Admin.css'
import LLHeader from "./headers/LLHeader";
import LongLettingSidebar from "./navigation/LongLettingSidebar";
import useSocket from "hooks/useSocket";

function LLAdminLayout(props) {

  const { socketConnect } = useSocket();
  const scrollTop = useSelector(state => state.scroll.llScroll);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  // Callback function to update the isOpen state in the parent component
  const handleSidebarToggle = (newIsOpen) => {
    setIsOpen(newIsOpen);
  };

  const location = useLocation();

  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [location.pathname, scrollTop]);
  
  useEffect(() => {
    socketConnect();
  }, []);

  return (
    <>
      <LLHeader changeToggle={() => toggle()} />
      <div className={`${isOpen ? "main-container v-myopct" : "main-container "}`}>
        <LongLettingSidebar open={isOpen} checkSidebar={handleSidebarToggle} closeModal={() => { setIsOpen(false) }} />

        <div ref={containerRef} className={`${isOpen ? "main2 w-100 wdh1" : "main2 w-100 wdh2"}`}>
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default LLAdminLayout;
