import {createSlice } from "@reduxjs/toolkit";
import {addManagerAsync, editManagerAsync, deleteManagerAsync,  managerListAsync, allManagerListAsync} from "./Services"
import _ from "lodash";

const managerSlice = createSlice({
  name: "managerSlice",
  initialState: {
    loading: false,
    managerForm: {},
    managerList: [],
    allManagerList: [],
  },
  reducers: {
    changeManagerForm:(state, action) => {
      let update_form = _.cloneDeep(state.managerForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        managerForm:{
          ...state.managerForm,
          ...update_form,
        }
      }
    },
    selectManager: (state, {payload}) => {

      return {
        ...state,
        managerForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearManagerForm: (state, action) => {
      state.managerForm = {};
    },
  },
  extraReducers: {
    [addManagerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addManagerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addManagerAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editManagerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editManagerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editManagerAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteManagerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteManagerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteManagerAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [managerListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [managerListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.managerList = action?.payload.data;
    //   state.program = action.payload;
    },
    [managerListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allManagerListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allManagerListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allManagerList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allManagerListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default managerSlice.reducer;

export const {
  changeManagerForm,
  selectManager,
  clearManagerForm,
} = managerSlice.actions;
