import {createSlice } from "@reduxjs/toolkit";
import {mailSettingAsync, mailListAsync} from "./Services"
import _ from "lodash";

const mailSettingSlice = createSlice({
  name: "mailSettingSlice",
  initialState: {
    loading: false,
    mailSettingForm: {},
    mailList: []
  },
  reducers: {
    changeMailSettingForm:(state, action) => {
      let update_form = _.cloneDeep(state.mailSettingForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        mailSettingForm:{
          ...state.mailSettingForm,
          ...update_form,
        }
      }
    },
    clearMailSettingForm: (state, action) => {
      state.mailSettingForm = {};
    },
  },
  extraReducers: {
    [mailSettingAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [mailSettingAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    
    },
    [mailSettingAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [mailListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [mailListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.mailList = action?.payload.data;
    //   state.program = action.payload;
    
    },
    [mailListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

   
  },
});

export default mailSettingSlice.reducer;

export const {
  changeMailSettingForm,
  clearMailSettingForm,
} = mailSettingSlice.actions;
