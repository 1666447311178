import { Box, Grid } from "@mui/material";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import RadioButtons from "components/form/RadioButton";
import SelectInput from "components/form/SelectInput";
import { useFormik } from "formik";
import * as React from "react";
import { useState } from "react";
import { useRef } from "react";
import { HiOutlineDocumentText } from "react-icons/hi";
import { useSelector } from "react-redux";
import Axios from "utils/axios";
import * as Yup from "yup";
import useStaticData from "./hooks/useStaticData";
import Toast from "components/Toast";

const UploadIdentity = ({ closeModal, parentFormik }) => {
  const userObj = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = React.useState(false);

  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [selectedImagePreview2, setSelectedImagePreview2] = useState(null);
  const [selectedImagePreview3, setSelectedImagePreview3] = useState(null);

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);

  const { identiTyDocumentTypes } = useStaticData();

  const formik = useFormik({
    initialValues: {
      identityType: "passport",
      document: [],
      documentType: "",
    },
    validationSchema: Yup.object().shape({
      // documentType: formik.values.identityType === 'passport' ? Yup.string().required("Please select document type") : Yup.string()
      documentType: Yup.string().when("identityType", {
        is: "passport",
        then: () => Yup.string().required("Please select document type"),
      }),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleClick2 = () => {
    fileInputRef2.current.click();
  };

  const handleClick3 = () => {
    fileInputRef3.current.click();
  };

  const handleImageChange = (identifier, e) => {
    const selectedImage = e.target.files[0];

    // Check if an object with the same name already exists
    const existingIndex = formik.values.document.findIndex(
      (item) => item.name === identifier
    );

    if (existingIndex !== -1) {
      // Update the existing object
      formik.values.document[existingIndex] = {
        name: identifier,
        file: selectedImage,
      };
    } else {
      // Add a new object
      formik.setFieldValue("document", [
        ...formik.values.document,
        {
          name: identifier,
          file: selectedImage,
        },
      ]);
    }

    const imagePreviewURL = URL.createObjectURL(selectedImage);
    setPreviewState(identifier, imagePreviewURL);
  };

  const setPreviewState = (identifier, imagePreviewURL) => {
    switch (identifier) {
      case "passport":
        setSelectedImagePreview(imagePreviewURL);
        break;
      case "Proof of address":
        setSelectedImagePreview2(imagePreviewURL);
        break;
      case "Driving license":
        setSelectedImagePreview3(imagePreviewURL);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("identityType", formik.values.identityType);
    formData.append("documentType", formik.values.documentType);
    formData.append("user_id", userObj?._id);
    formik.values?.document?.forEach((fileData) => {
      formData.append("files", fileData.file);
    });

    Axios.post(`/users/updateUser`, formData)
      .then((res) => {
        parentFormik.setFieldValue("identity", true);
        closeModal();
        setIsLoading(false);
      })
      .catch((error) => {
        Toast(error, 'error');
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="p-4 modal-h overflow-y">
        <div className="block-info" style={{ fontSize: "14px" }}>
          <b>Required documents to upload </b> Passport AND proof of address
          (Utility bill, bank statement, mortgage statement, council tax)
          <b>OR</b>
          Photocard driving license
          <p className="mt-2">
            We support: PDF, DOC, DOCX, JPG, JPEG & PNG files - Max 5MB
          </p>
        </div>
        <div className="d-flex mb-3">
          <div className="d-flex align-items-center w-50">
            <RadioButtons
              customClass={"radio-size"}
              value="passport"
              checked={formik.values.identityType}
              onChange={() => formik.setFieldValue("identityType", "passport")}
            />
            <p style={{ marginLeft: "10px" }}>Passport and Proof of address</p>
          </div>
          <div className="d-flex align-items-center w-50">
            <RadioButtons
              customClass={"radio-size"}
              value="license"
              checked={formik.values.identityType}
              onChange={() => formik.setFieldValue("identityType", "license")}
            />
            <p style={{ marginLeft: "10px" }}>Proof of address</p>
          </div>
        </div>
        {formik.values.identityType === "passport" ? (
          <div>
            <div className="d-flex">
              <Box sx={{ flexGrow: 1 }}>
                <Grid
                  container
                  spacing={2}
                  className="form-field__wrapper mb-4"
                >
                  <Grid item xs={12} sm={12} md={6} className="">
                    <div className="document_upload">
                      <h5 className="mt-2">Passport</h5>
                      {!selectedImagePreview && (
                        <div>
                          <HiOutlineDocumentText />
                          <p>Drag & drop file here</p>
                          <p>or</p>
                          <button
                            className="add_new_btn2"
                            onClick={handleClick}
                          >
                            Browse file
                          </button>
                          <input
                            class="form-control"
                            className="hidden-input"
                            ref={fileInputRef}
                            type="file"
                            id="formFile"
                            onChange={(e) => {
                              handleImageChange("passport", e);
                            }}
                          />
                        </div>
                      )}
                      {selectedImagePreview && selectedImagePreview && (
                        <div>
                          {selectedImagePreview.endsWith(".pdf") ? (
                            <div>
                              <img src="../images/pdf.png" alt="PDF" />
                              {/* <p>{selectedImagePreview.slice(60)}</p> */}
                            </div>
                          ) : (
                            <div>
                              <img
                                className="image-object2"
                                src={selectedImagePreview}
                                alt="Selected Image Preview"
                              />
                              {/* <p>{selectedImagePreview}</p> */}
                            </div>
                          )}
                          <div>
                            <button
                              className="mt-3 edit_btn"
                              onClick={handleClick}
                            >
                              Edit
                            </button>
                          </div>
                          <input
                            class="form-control"
                            className="hidden-input"
                            ref={fileInputRef}
                            type="file"
                            id="formFile"
                            onChange={(e) => {
                              handleImageChange("passport", e);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className="">
                    <div className="document_upload">
                      <h5 className="mt-2">Proof of address</h5>
                      {!selectedImagePreview2 && (
                        <div>
                          <HiOutlineDocumentText />
                          <p>Drag & drop file here</p>
                          <p>or</p>
                          <button
                            className="add_new_btn2"
                            onClick={handleClick2}
                          >
                            Browse file
                          </button>
                          <input
                            class="form-control"
                            className="hidden-input"
                            ref={fileInputRef2}
                            type="file"
                            id="formFile"
                            onChange={(e) => {
                              handleImageChange("Proof of address", e);
                            }}
                          />
                        </div>
                      )}
                      {selectedImagePreview2 && selectedImagePreview2 && (
                        <div>
                          {selectedImagePreview2.endsWith(".pdf") ? (
                            <div>
                              <img src="../images/pdf.png" alt="PDF" />
                              {/* <p>{selectedImagePreview.slice(60)}</p> */}
                            </div>
                          ) : (
                            <div>
                              <img
                                className="image-object2"
                                src={selectedImagePreview2}
                                alt="Selected Image Preview"
                              />
                              {/* <p>{selectedImagePreview}</p> */}
                            </div>
                          )}
                          <div>
                            <button
                              className="mt-3 edit_btn"
                              onClick={handleClick2}
                            >
                              Edit
                            </button>
                          </div>
                          <input
                            class="form-control"
                            className="hidden-input"
                            ref={fileInputRef2}
                            type="file"
                            id="formFile"
                            onChange={(e) => {
                              handleImageChange("Proof of address", e);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className="mt-2 d-flex justify-content-between">
              <div className="w-50"></div>
              <div className=" w-50">
                <SelectInput
                  customClass={"w-100"}
                  label="Document Type*"
                  options={identiTyDocumentTypes}
                  selectedValue={formik.values.documentType}
                  onChange={(e) => {
                    formik.setFieldValue("documentType", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.documentType && formik.errors.documentType
                  }
                >
                  {formik.errors.documentType}
                </ErrorMsg>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex">
              <div className="document_upload w-100">
                <h5 className="mt-2">Driving license</h5>
                {!selectedImagePreview3 && (
                  <div>
                    <HiOutlineDocumentText />
                    <p>Drag & drop file here</p>
                    <p>or</p>
                    <button className="add_new_btn2" onClick={handleClick3}>
                      Browse file
                    </button>
                    <input
                      class="form-control"
                      className="hidden-input"
                      ref={fileInputRef3}
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        handleImageChange("Driving license", e);
                      }}
                    />
                  </div>
                )}
                {selectedImagePreview3 && selectedImagePreview3 && (
                  <div>
                    {selectedImagePreview3.endsWith(".pdf") ? (
                      <div>
                        <img src="/images/pdf.png" alt="PDF" />
                        {/* <p>{selectedImagePreview.slice(60)}</p> */}
                      </div>
                    ) : (
                      <div>
                        <img
                          className="image-object2"
                          src={selectedImagePreview3}
                          alt="Selected Image Preview"
                        />
                        {/* <p>{selectedImagePreview}</p> */}
                      </div>
                    )}
                    <div>
                      <button className="mt-3 edit_btn" onClick={handleClick3}>
                        Edit
                      </button>
                    </div>
                    <input
                      class="form-control"
                      className="hidden-input"
                      ref={fileInputRef3}
                      type="file"
                      id="formFile"
                      onChange={(e) => {
                        handleImageChange("Driving license", e);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end mt-4">
          <button type="button" className="cancel_btn" onClick={closeModal}>
            Cancel
          </button>
          <div style={{ marginLeft: "10px" }}>
            <ButtonLoader
              buttonName="Save"
              variant={"contained"}
              className={"add_new_btn grow"}
              onClickhandle={formik.handleSubmit}
              loaderColor={"yellow"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadIdentity;
