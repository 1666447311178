import { Navigate, Outlet } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import {useSelector} from 'react-redux';
// import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {

  const token = useSelector(state => state.auth.token);
  const user = useSelector(state => state.auth.user);
  // console.log(user?.userType);
  if (!token || !user.userType.includes('guest')) {
    return <Navigate to="/guest/homepage" />;
  }

  return children ? children : <Outlet />;

};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;