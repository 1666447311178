import React from "react";
import { TextareaAutosize } from "@mui/material";

const Textarea = ({ customClass, value, rows, onChange ,label,name }) => {
  return (
    <TextareaAutosize
      className={customClass}
      value={value}
      label={label}
      name={name}
      onChange={onChange}
      minRows={rows} // Set the minimum number of rows to show
      style={{
        width: "100%",
        padding: "8px",
        fontSize: "16px",
        resize: "vertical",
      }}
    />
  );
};

export default Textarea;
