import { useEffect, useState } from "react";
import Axios from "utils/axios";

import BookingDetail from "../Bookings/BookingDetail";
import {useNavigate, useSearchParams} from "react-router-dom";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";

function ThankyouPage({ onClose, formType }) {

  const navigate = useNavigate();

  const userObj = useSelector((state) => state.auth.user);

  // const {orderid} = useParams();
  const [searchParams] = useSearchParams();
  const orderid = searchParams.get("orderid");
  const [hasLoaded, setLoaded] = useState(false);
  const [orderDbId, setOrderDbId] = useState("");

  const [showdetails, setShowdetails] = useState(false)

  const confirmOrder = () => {
    const payLoad = {
      id: orderid,
      txnId: orderid,
      paymentType: "PayPal",
    };

    Axios.post("/bookingOrders/confirmPayment", payLoad)
      .then((res) => {

        setOrderDbId(res.data.bookingId);
        setLoaded(true);
        setShowdetails(true)
      })
      .catch((err) => {
        setLoaded(false);
        alert(err);
      });
  };

  useEffect(() => {
    if (orderid) {
      confirmOrder();
    }
  }, []);

  return (
    <>{ orderDbId ?
      <div className="thankyou-page">
        <div className="heading reservation-card1">
          <TiTick className="confirm-icon" />
          <h3>Your Booking is Confirmed</h3>
          <p className="guest_name">Hi {userObj?.name},</p>
          <p className="booking-summary">For your reference, the booking id is <b>{orderid}</b>. To view, cancel your booking use our easy self service option mentioned below.</p>
          <div className="mt-3 mb-3">
            <button type="button" className="thankyou-btn grow" onClick={() => {navigate('/guest/dashbaord/booking')}}>My Bookings</button>
            {/* <button type="button" className="thankyou-btn2 grow" onClick={() => {navigate('/guest/dashbaord/booking')}}>Dashboard</button> */}
          </div>
        </div>

        {/* <div className="_header">
          <div className="logo">
            <img src="https://codexcourier.com/images/banner-logo.png" alt="" />
          </div>
          <h1>Thank You!</h1>
        </div>
        <div className="_body">
          <div className="_box">
            <h2>
              <strong>Please check your email</strong> for Confirmation of your
              booking .
            </h2>
            <p>
              Thanks a bunch for filling that out. It means a lot to us, just
              like you do! We really appreciate you giving us a moment of your
              time today. Thanks for being you.
            </p>
          </div>
        </div>
        <div className="_footer">
          <a className="btn" href="/guest/homepage">
            Back to homepage
          </a>
        </div> */}

        {hasLoaded && orderDbId && <BookingDetail booking_id={orderDbId} />}
      </div>
      :
   showdetails&&
      <div className="min-vh-100 d-flex align-items-center justify-content-center">
        <div id="error-page">
          <div className="content">
           
            <h4 data-text="Opps! Page not found">Opps! Booking not found</h4>
            <p>
              Sorry, the bookiing you're looking for   doesn't exist. If you think
              something is wrong, report a problem.
            </p>
            <div className="btns">
              <a
                href="/guest/homepage"
                className="btn btn-primary text-uppercase px-4 py-3 fw-bold"
              >
                return home
              </a>
            </div>
          </div>
        </div>
      </div>}
    </>
  );
}

export default ThankyouPage;
