import TextInput from "components/form/TextInput";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import Axios from "utils/axios";
import { useSelector } from "react-redux";
import Toast from "components/Toast";

const AddBankDetails = ({ closeModal, buttonText, updateList }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const userObj = useSelector((state) => state.auth.user);
  const formik = useFormik({
    initialValues: {
      accountHolder: "",
      accountNumber: "",
      bankName: "",
      sortCode: "",
    },
    validationSchema: Yup.object().shape({
      accountHolder: Yup.string().required("Please enter account holder name."),
      accountNumber: Yup.number()
        .typeError("Please enter a valid number.")
        .required("Please enter account number.")
        .test(
          "is-eight-digits",
          "Account number must be exactly 8 digits.",
          (val) => val && val.toString().length === 8
        ),
      bankName: Yup.string().required("Please enter bank name."),
      sortCode: Yup.number()
        .typeError("Please enter a valid number.")
        .required("Please enter account number.")
        .test(
          "is-eight-digits",
          "Sort code must be exactly 6 digits.",
          (val) => val && val.toString().length === 6
        ),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleSubmit = () => {
    setIsLoading(true);
    const bankData = {
      bankData: {
        ...formik.values,
      },
      user_id: userObj._id,
    };

    Axios.post(`/tenancies/addBank`, bankData)
      .then((res) => {
        // parentFormik.setFieldValue('identity', true);
        updateList();
        closeModal();
        setIsLoading(false);
      })
      .catch((error) => {
        Toast(error, 'error');
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="">
        <TextInput
          customClass={"w-100 mb-1 mt-3"}
          type={"text"}
          name={"accountHolder"}
          value={formik.values.accountHolder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          label="Account holder name*"
        />
        <ErrorMsg
          hasError={formik.touched.accountHolder && formik.errors.accountHolder}
        >
          {formik.errors.accountHolder}
        </ErrorMsg>
        <TextInput
          customClass={"w-100 mb-1 mt-3"}
          type={"number"}
          variant="outlined"
          label="Account number*"
          name={"accountNumber"}
          value={formik.values.accountNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder={"Account Number"}
        />
        <ErrorMsg
          hasError={formik.touched.accountNumber && formik.errors.accountNumber}
        >
          {formik.errors.accountNumber}
        </ErrorMsg>
        <TextInput
          customClass={"w-100 mb-1 mt-3"}
          type={"text"}
          name={"bankName"}
          value={formik.values.bankName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          label="Bank name*"
        />
        <ErrorMsg hasError={formik.touched.bankName && formik.errors.bankName}>
          {formik.errors.bankName}
        </ErrorMsg>
        <TextInput
          customClass={"w-100 mb-1 mt-3"}
          type={"text"}
          name={"sortCode"}
          value={formik.values.sortCode}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          variant="outlined"
          label="Sort code*"
        />
        <ErrorMsg hasError={formik.touched.sortCode && formik.errors.sortCode}>
          {formik.errors.sortCode}
        </ErrorMsg>
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn_verify mt-3 grow"
            onClick={() => {
              closeModal(false);
            }}
          >
            <b>X</b> Close
          </button>
          <ButtonLoader
            buttonName={buttonText ? buttonText : "Save"}
            variant={"contained"}
            className={"add_new_btn grow mt-3"}
            // style={{width:"100%"}}
            onClickhandle={formik.handleSubmit}
            isLoading={isLoading}
            loaderColor={"yellow"}
          />
        </div>
      </div>
    </>
  );
};

export default AddBankDetails;
