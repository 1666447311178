import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL} from '../../constants/constants';


//get Api


export const paymentMethodsAsync = createAsyncThunk(
  'LandlordStatementSlice/paymentMethodsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/payment-methods`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        // 
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const balanceListAsync = createAsyncThunk(
  'LandlordStatementSlice/balanceListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/landlord-balance?id=${post.userId}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const statementListAsync = createAsyncThunk(
  'LandlordStatementSlice/statementListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/landlord-statements?id=${post.userId}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        // 
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const allStatementListAsync = createAsyncThunk(
  'LandlordStatementSlice/allStatementListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/landlord-statements`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        // 
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



// post Api

// add statement 

export const payLandlordAsync = createAsyncThunk(
  'LandlordStatementSlice/payLandlordAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-statements/addstatement`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();
        // 
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const editStatementAsync = createAsyncThunk(
  'LandlordStatementSlice/editStatementAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/api/edit-landlord-statement`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

// Delete Api

export const deleteStatementAsync = createAsyncThunk(
  'LandlordStatementSlice/deleteStatementAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-statements/deletestatement`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      // 
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = response;

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response;

        // Toast.show(data.message);
        return data
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

