import * as React from "react";
import ErrorMsg from "components/ErrorMsg";
import RadioWithBorder from "components/CustomComponents/RadioWithBorder";
import { useState } from "react";
import ModalHeading from "components/CustomComponents/ModalHeading";
import { ButtonLoader } from "components/form/ButtonLoader";
import AddPayment from "./AddPayment";
import { Box, Grid } from "@mui/material";
import { useEffect } from "react";
import SelectInput from "components/form/SelectInput";
import TextInput from "components/form/TextInput";
import moment from "moment";
import { AiOutlineDelete } from "react-icons/ai";
import DeleteModal from "components/CustomComponents/DeleteModal";
import useStaticData from "./hooks/useStaticData";

const RentScheduler = ({ handleBack, formik }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [newPaymentData, setNewPaymentData] = useState({});
  const [editTable, setEditTable] = useState(true);
  const [confirmError, setConfirmError] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePaymentId, setDeletePayemntId] = useState(null);

  const { statusData } = useStaticData();

  const handleStatusChange = (payment_id, status) => {
    const newArray = formik.values?.scheduleData?.map((item) => {
      if (item?.payment_id === payment_id) {
        return { ...item, rentStatus: status };
      }
      return item;
    });
    formik.setFieldValue("scheduleData", newArray);
  };

  const handleAmount = (payment_id, amount) => {
    const newArray = formik.values?.scheduleData?.map((item) => {
      if (item?.payment_id === payment_id) {
        return { ...item, rentAmount: amount };
      }
      return item;
    });
    formik.setFieldValue("scheduleData", newArray);
  };

  const handleDueDtae = (payment_id, date) => {
    const newArray = formik.values?.scheduleData?.map((item) => {
      if (item?.payment_id === payment_id) {
        return { ...item, dueDate: date };
      }
      return item;
    });
    formik.setFieldValue("scheduleData", newArray);
  };

  const handleDelete = (paymentId) => {
    const updatedData = formik.values?.scheduleData?.filter(
      (item) => item.payment_id !== paymentId
    );
    formik.setFieldValue("scheduleData", updatedData);
    setDeleteModal(false);
  };

  const handleDeleteModal = (id) => {
    setDeleteModal(true);
    setDeletePayemntId(id);
  };

  function getRemainingPriceForFirstMonth(startDate, monthlyPrice) {
    const currentDate = new Date(startDate);
    const currentMonth = currentDate?.getMonth();
    const nextMonth = (currentMonth + 1) % 12; // Get the next month

    // Set the date to the first day of the next month
    currentDate.setMonth(nextMonth, 1);
    currentDate.setDate(currentDate?.getDate() - 1); // Set it to the last day of the current month

    const lastDayOfMonth = currentDate?.getDate();
    const remainingDays = lastDayOfMonth - new Date(startDate).getDate() + 1;

    const dailyPrice = monthlyPrice / lastDayOfMonth;
    const remainingPrice = remainingDays * dailyPrice;

    return remainingPrice;
  }

  function getRemainingPriceForLastMonth(endDate, monthlyPrice) {
    const currentDate = new Date(endDate);
    const currentMonth = currentDate.getMonth();
    const lastDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentMonth + 1,
      0
    ).getDate();
    const remainingDays = currentDate.getDate();

    const dailyPrice = monthlyPrice / lastDayOfMonth;
    const remainingPrice = remainingDays * dailyPrice;

    return remainingPrice;
  }

  function getRemainingPriceForFirstQuarter(startDate, monthlyPrice) {
    const startMonth = new Date(startDate).getMonth();
    let remainingDays = 0;
    let totalRemainingDays = 0;

    for (let i = 0; i < 3; i++) {
      const currentDate = new Date(startDate);
      // Set the date to the first day of the current month
      currentDate.setMonth(startMonth + i, 1);

      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();

      // For the first month, calculate the remaining days from the start date
      if (i === 0) {
        remainingDays += lastDayOfMonth - new Date(startDate).getDate() + 1;
      } else {
        // For the subsequent months, add the total days in the month
        remainingDays += lastDayOfMonth;
      }
      totalRemainingDays += lastDayOfMonth;
    }

    const dailyPrice = monthlyPrice / totalRemainingDays;
    const remainingPrice = remainingDays * dailyPrice;

    return remainingPrice;
  }

  function getRemainingPriceForLastQuarter(lastQuarterEndDate, monthlyPrice) {
    const endMonth = new Date(lastQuarterEndDate).getMonth();
    let remainingDays = 0;
    let totalRemainingDays = 0;

    for (let i = 2; i >= 0; i--) {
      const currentDate = new Date(lastQuarterEndDate);
      // Set the date to the first day of the current month
      currentDate.setMonth(endMonth - i, 1);

      const lastDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      ).getDate();

      // For the last month, calculate the remaining days until the end date
      if (i === 0) {
        remainingDays += new Date(lastQuarterEndDate).getDate();
      } else {
        // For the previous months, add the total days in the month
        remainingDays += lastDayOfMonth;
      }
      totalRemainingDays += lastDayOfMonth;
    }

    const dailyPrice = monthlyPrice / totalRemainingDays;
    const remainingPrice = remainingDays * dailyPrice;

    return remainingPrice;
  }

  const handleRentScheduler = () => {
    const entries = [];
    let idCounter = 1;

    const startDate = moment(formik.values?.tenancyStartDate);
    const endDate = moment(formik.values?.tenancyEndDate);

    const dayOfMonth = endDate?.date();

    // Clone the startDate so it doesn't mutate the original moment object
    let currentDate = startDate.clone().date(dayOfMonth);

    while (currentDate.isSameOrBefore(endDate)) {
      // Add tenancy entry
      const daysInMonth = currentDate.daysInMonth();

      let formattedDueDate = currentDate
        .clone()
        .date(formik.values?.rentDueDate);

      if (formattedDueDate.daysInMonth() > daysInMonth) {
        formattedDueDate = currentDate;
      }

      let monthEntry = {};

      //For getting last month price
      if (
        currentDate.month() === endDate.month() &&
        currentDate.year() === endDate.year()
      ) {
        monthEntry = {
          month: currentDate.format("MMMM"),
          year: currentDate.format("YYYY"),
          rentType: `${formik.values?.rentFrequency} Rent`,
          dueDate: formik.values?.tenancyEndDate,
          rentStatus: "Scheduled",
          paymentType: "default",
          rentAmount:
            formik.values?.rentFrequency === "Monthly"
              ? parseFloat(
                  getRemainingPriceForLastMonth(
                    endDate,
                    formik.values?.rentAmount
                  )
                ).toFixed(2)
              : parseFloat(
                  getRemainingPriceForLastQuarter(
                    endDate,
                    formik.values?.rentAmount * 3
                  )
                ).toFixed(2),
          total:
            formik.values?.rentFrequency === "Monthly"
              ? parseFloat(
                  getRemainingPriceForLastMonth(
                    endDate,
                    formik.values?.rentAmount
                  )
                ).toFixed(2)
              : parseFloat(
                  getRemainingPriceForLastQuarter(
                    endDate,
                    formik.values?.rentAmount * 3
                  )
                ).toFixed(2),
          payment_id: idCounter++, // Incremental ID
        };
      } else {
        monthEntry = {
          month: currentDate.format("MMMM"),
          year: currentDate.format("YYYY"),
          rentType: `${formik.values?.rentFrequency} Rent`,
          dueDate:
            idCounter === 1 && formik.values?.firstMonthDueDate
              ? formik.values?.firstMonthDueDate
              : formattedDueDate.format("YYYY-MM-DD"),
          rentStatus: "Scheduled",
          paymentType: "default",
          rentAmount:
            formik.values?.rentFrequency === "Monthly"
              ? idCounter === 1
                ? parseFloat(
                    getRemainingPriceForFirstMonth(
                      formik?.values?.tenancyStartDate,
                      formik.values?.rentAmount
                    )
                  ).toFixed(2)
                : formik.values?.rentAmount
              : idCounter === 1
              ? parseFloat(
                  getRemainingPriceForFirstQuarter(
                    formik?.values?.tenancyStartDate,
                    formik.values?.rentAmount * 3
                  )
                ).toFixed(2)
              : parseFloat(formik.values?.rentAmount * 3).toFixed(2),
          total:
            formik.values?.rentFrequency === "Monthly"
              ? idCounter === 1
                ? parseFloat(
                    getRemainingPriceForFirstMonth(
                      formik?.values?.tenancyStartDate,
                      formik.values?.rentAmount
                    )
                  ).toFixed(2)
                : formik.values?.rentAmount
              : idCounter === 1
              ? parseFloat(
                  getRemainingPriceForFirstQuarter(
                    formik?.values?.tenancyStartDate,
                    formik.values?.rentAmount * 3
                  )
                ).toFixed(2)
              : parseFloat(formik.values?.rentAmount * 3).toFixed(2),
          payment_id: idCounter++, // Incremental ID
        };
      }
      entries.push(monthEntry);

      if (
        formik.values?.deposit === 1 &&
        currentDate.month() === moment(formik.values?.depositDueDate).month() &&
        currentDate.year() === moment(formik.values?.depositDueDate).year() &&
        entries.filter((item) => item.rentType === "Deposit")?.length === 0
      ) {
        const deposit_due_date = moment(formik.values?.depositDueDate);
        const depositEntry = {
          payment_id: idCounter++, // Incremental ID
          // year: deposit_due_date.format("YYYY"),
          rentType: "Deposit",
          paymentType: "default",
          dueDate: formik.values?.depositDueDate,
          rentStatus: "Scheduled",
          rentAmount: formik.values?.depositAmount,
          total: formik.values?.depositAmount,
        };
        entries.push(depositEntry);
      } else if (
        formik.values?.deposit === 1 &&
        currentDate.month() >= moment(formik.values?.depositDueDate).month() &&
        currentDate.year() >= moment(formik.values?.depositDueDate).year() &&
        entries.filter((item) => item.rentType === "Deposit")?.length === 0
      ) {
        const deposit_due_date = moment(formik.values?.depositDueDate);
        const depositEntry = {
          payment_id: idCounter++, // Incremental ID
          // year: deposit_due_date.format("YYYY"),
          rentType: "Deposit",
          dueDate: formik.values?.depositDueDate,
          rentStatus: "Scheduled",
          rentAmount: formik.values?.depositAmount,
          total: formik.values?.depositAmount,
        };

        // Calculate the index before the last element
        const index = entries.length - 1;

        entries.splice(index, 0, depositEntry);

        // entries.push(depositEntry);
      }

      // Move to the next month
      currentDate.add(
        formik.values?.rentFrequency === "Monthly" ? 1 : 3,
        "month"
      );

      currentDate.clone().date(dayOfMonth);

      const lastDayOfNextMonth = currentDate.clone().endOf("month");
      if (lastDayOfNextMonth.date() < formik.values?.rentDueDate) {
        currentDate.endOf("month");
      }
    }

    formik.setFieldValue("scheduleData", entries);
  };

  const handleNewPayment = () => {
    let idCounter = formik.values?.scheduleData?.length + 1;
    let entries = formik.values?.scheduleData;

    // Get the index to insert new data
    let indexToInsertNewData = entries?.findIndex(
      (entry) =>
        entry.month === moment(newPaymentData?.paymentDue).format("MMMM") &&
        entry.year === moment(newPaymentData?.paymentDue).format("YYYY")
    );

    // If the month entry doesn't exist, add it to the end
    if (indexToInsertNewData === -1) {
      indexToInsertNewData = entries?.length;
    }

    if (newPaymentData && Object.keys(newPaymentData).length > 0) {
      // Create new payment entry
      const newPaymentEntry = {
        month:
          indexToInsertNewData === entries?.length || indexToInsertNewData === 0
            ? moment(newPaymentData?.paymentDue).format("MMMM")
            : "",
        year:
          indexToInsertNewData === entries?.length || indexToInsertNewData === 0
            ? moment(newPaymentData?.paymentDue).format("YYYY")
            : "",
        rentType: newPaymentData?.paymentType,
        dueDate: newPaymentData?.paymentDue,
        rentStatus: "Scheduled",
        paymentType: "added",
        rentAmount: newPaymentData?.paymentAmount,
        total: newPaymentData?.paymentAmount,
        payment_id: idCounter++, // Incremental ID
      };

      // Insert the new payment entry
      entries = [...entries];
      entries?.splice(indexToInsertNewData + 1, 0, newPaymentEntry);
      entries.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
      indexToInsertNewData++;
    }

    // If payment is recurring, create entries for subsequent months
    if (newPaymentData?.paymentRecurring > 1) {
      if (newPaymentData?.paymentPeriod === "Monthly") {
        for (let i = 1; i < newPaymentData?.paymentRecurring; i++) {
          const nextDueDate = moment(newPaymentData?.paymentDue)
            .add(i, "months")
            .format("YYYY-MM-DD");
          // Get the index to insert new data
          let indexToInsertNewData2 = entries?.findIndex(
            (entry) =>
              entry.month === moment(nextDueDate).format("MMMM") &&
              entry.year === moment(nextDueDate).format("YYYY")
          );

          // If the month entry doesn't exist, add it to the end
          if (indexToInsertNewData2 === -1) {
            indexToInsertNewData2 = entries?.length;
          }
          const nextPaymentEntry = {
            month:
              indexToInsertNewData2 === entries?.length ||
              indexToInsertNewData2 === 0
                ? moment(nextDueDate).format("MMMM")
                : "",
            year:
              indexToInsertNewData2 === entries?.length ||
              indexToInsertNewData2 === 0
                ? moment(nextDueDate).format("YYYY")
                : "",
            rentType: newPaymentData?.paymentType,
            dueDate: nextDueDate,
            rentStatus: "Scheduled",
            paymentType: "added",
            rentAmount: newPaymentData?.paymentAmount,
            total: newPaymentData?.paymentAmount,
            payment_id: idCounter++, // Incremental ID
          };
          entries.splice(indexToInsertNewData2 + 1, 0, nextPaymentEntry);
          entries.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        }
      } else {
        for (let i = 1; i <= newPaymentData?.paymentRecurring; i++) {
          const quarter = 3;
          const nextDueDate = moment(newPaymentData?.paymentDue)
            .add(i * quarter, "months")
            .format("YYYY-MM-DD");

          // Get the index to insert new data
          let indexToInsertNewData2 = entries?.findIndex(
            (entry) =>
              entry.month === moment(nextDueDate).format("MMMM") &&
              entry.year === moment(nextDueDate).format("YYYY")
          );

          // If the month entry doesn't exist, add it to the end
          if (indexToInsertNewData2 === -1) {
            indexToInsertNewData2 = entries?.length;
          }
          const nextPaymentEntry = {
            month:
              indexToInsertNewData2 === entries?.length ||
              indexToInsertNewData2 === 0
                ? moment(nextDueDate).format("MMMM")
                : "",
            year:
              indexToInsertNewData2 === entries?.length ||
              indexToInsertNewData2 === 0
                ? moment(nextDueDate).format("YYYY")
                : "",
            rentType: newPaymentData?.paymentType,
            dueDate: nextDueDate,
            rentStatus: "Scheduled",
            paymentType: "added",
            rentAmount: newPaymentData?.paymentAmount,
            total: newPaymentData?.paymentAmount,
            payment_id: idCounter++, // Incremental ID
          };

          entries.splice(indexToInsertNewData2 + i, 0, nextPaymentEntry);

          entries.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));
        }
      }
    }

    formik.setFieldValue("scheduleData", entries);
  };

  useEffect(() => {
    if (newPaymentData && Object.keys(newPaymentData).length > 0) {
      handleNewPayment();
    }
  }, [newPaymentData]);

  const rearrangedDate = (date) => {
    const dateComponents = date.split("-");
    return `${dateComponents[2]}-${dateComponents[1]}-${dateComponents[0]}`;
  };

  return (
    <>
      <h5 style={{ fontWeight: "700" }}>Rent Schedule</h5>
      <p>Review and edit the rent schedule for this tenancy.</p>
      <div className="">
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            className="form-field__wrapper mt-4 mb-4 mx-0 w-100"
          >
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">
                Do you want to create payments for this tenancy?
              </h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <Grid container spacing={2} className="">
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={1}
                    checked={formik.values.rentSchedule}
                    onChange={() => {
                      formik.setFieldValue("rentSchedule", 1);
                      // if (formik.values?.scheduleData?.length <= 0) {
                      handleRentScheduler();
                      // }
                    }}
                    label="Yes"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={0}
                    checked={formik.values.rentSchedule}
                    onChange={() => formik.setFieldValue("rentSchedule", 0)}
                    label="No"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
              </Grid>
              <ErrorMsg
                hasError={
                  formik.touched.rentSchedule && formik.errors.rentSchedule
                }
              >
                {formik.errors.rentSchedule}
              </ErrorMsg>
            </Grid>
          </Grid>
        </Box>
        {formik.values.rentSchedule === 1 && (
          <>
            <button
              type="button"
              className="modal_link"
              onClick={() => {
                setPaymentModal(true);
              }}
            >
              + Add another payment
            </button>
            <p className="sub_heading mb-3">
              Add all parking, pet and any additional payments here before you
              edit your rent schedule.
            </p>
            <div className="border">
              <div className="table-responsive">
                <table className="table table-striped border mb-0 custom-table">
                  <thead>
                    <tr className={`${editTable ? "tb-row" : ""}`}>
                      <th scope="col" style={{ width: "12.5%", minWidth: '130px' }}>
                        Month
                      </th>
                      <th scope="col" style={{ width: "20%", minWidth: '130px' }}>
                        Due Date
                      </th>
                      <th scope="col" style={{ width: "17.5%", minWidth: '130px' }}>
                        Type
                      </th>
                      <th
                        scope="col"
                        style={{ width: "20%", minWidth: "120px" }}
                      >
                        Rent Amount
                      </th>
                      <th scope="col" style={{ width: "10%", minWidth: '80px' }}>
                        Total
                      </th>
                      <th scope="col" style={{ width: "20%", minWidth: '100px' }}>
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {formik.values?.scheduleData?.map((item, index) => (
                      <tr
                        key={index}
                        className={
                          index % 2 === 0
                            ? ` even ${editTable ? "tb-row" : ""} `
                            : `odd ${editTable ? "tb-row" : ""}`
                        }
                      >
                        <td>
                          {item?.month} {item?.year}
                        </td>
                        <td>
                          {editTable ? (
                            <TextInput
                              customClass={"updated-form-input"}
                              type={"date"}
                              label="Due Date"
                              value={item?.dueDate}
                              onChange={(e) => {
                                handleDueDtae(item?.payment_id, e.target.value);
                              }}
                            />
                          ) : (
                            rearrangedDate(item?.dueDate)
                          )}
                        </td>
                        <td>{item?.rentType}</td>
                        <td>
                          {editTable ? (
                            <TextInput
                              customClass={"updated-form-input"}
                              label="Amount"
                              type={"number"}
                              value={item?.rentAmount}
                              onChange={(e) => {
                                {
                                  handleAmount(
                                    item?.payment_id,
                                    e.target.value
                                  );
                                }
                              }}
                            />
                          ) : (
                            item?.rentAmount
                          )}
                        </td>
                        <td>
                          <div className="d-flex justify-content-between align-items-center delete-icon">
                            <span>£{item?.total}</span>
                            {item?.paymentType === "added" && (
                              <AiOutlineDelete
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleDeleteModal(item?.payment_id);
                                }}
                              />
                            )}
                          </div>
                        </td>
                        <td>
                          {editTable ? (
                            <SelectInput
                              customClass={"w-100 custom-select-input"}
                              label="Status"
                              options={statusData}
                              selectedValue={item?.rentStatus}
                              onChange={(e) => {
                                handleStatusChange(
                                  item?.payment_id,
                                  e.target.value
                                );
                              }}
                            />
                          ) : (
                            item?.rentStatus
                          )}
                        </td>
                      </tr>
                    ))}
                    <tr className={editTable ? "tb-row" : ""}>
                      <td style={{ fontWeight: "700" }} colSpan={"3"}>
                        Total Expected
                      </td>
                      <td style={{ fontWeight: "700" }}>
                        {`£ ${parseFloat(
                          formik.values?.scheduleData?.reduce(
                            (total, entry) =>
                              total + parseFloat(entry.rentAmount),
                            0
                          )
                        ).toFixed(2)}`}
                      </td>
                      <td style={{ fontWeight: "700" }} colSpan={"2"}>
                        {`£ ${parseFloat(
                          formik.values?.scheduleData?.reduce(
                            (total, entry) =>
                              total + parseFloat(entry.rentAmount),
                            0
                          )
                        ).toFixed(2)}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="confirm-payment d-flex gap-2 px-3 justify-content-center justify-content-md-end">
                {editTable ? (
                  <p className="m-0">
                    Please confirm the payments are correct before proceeding
                  </p>
                ) : (
                  <button
                    className="edit-payment-btn"
                    onClick={() => {
                      setEditTable(true);
                    }}
                  >
                    Edit Payments
                  </button>
                )}
                <button
                  className={
                    editTable
                      ? " confirm-payment-btn grow"
                      : "confirm-payment-btn2"
                  }
                  onClick={() => {
                    setEditTable(false);
                    setConfirmError(false);
                  }}
                >
                  {editTable ? "Confirm" : "Confirmed"}
                </button>
              </div>
              {confirmError && (
                <p
                  style={{
                    color: "red",
                    marginRight: "20px",
                    marginBottom: "10px",
                    textAlign: "right",
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  *Plesae confirm your payment schedule first.
                </p>
              )}
            </div>
          </>
        )}
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn_verify mt-4 grow"
            onClick={() => {
              handleBack();
              formik.setFieldValue("rentSchedule", 0);
            }}
          >
            Back
          </button>
          <ButtonLoader
            buttonName="Next"
            variant={"contained"}
            className={"add_new_btn grow mt-4"}
            onClickhandle={() => {
              if (editTable && formik.values?.rentSchedule === 1) {
                setConfirmError(true);
              } else {
                formik.handleSubmit();
              }
            }}
            isLoading={isLoading}
            loaderColor={"yellow"}
          />
        </div>
      </div>
      {paymentModal && (
        <div className="popup-overlay">
          <div className="document_modal">
            <div className="modal-card mg10 ">
              <ModalHeading
                title="Add Payment"
                closeModal={() => {
                  setPaymentModal(false);
                }}
              />
              <AddPayment
                closeModal={() => {
                  setPaymentModal(false);
                }}
                handleData={(val) => {
                  setNewPaymentData(val);
                }}
              />
            </div>
          </div>
        </div>
      )}
      {deleteModal && (
        <DeleteModal
          image={deletePaymentId}
          text={"payment"}
          closeModal={(val) => {
            setDeleteModal(val);
          }}
          handleDelete={(id) => {
            handleDelete(id);
          }}
        />
      )}
    </>
  );
};

export default RentScheduler;
