

// import "./App.css"
import { ButtonLoader } from "components/form/ButtonLoader";
import SelectInput from "components/form/SelectInput";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Axios from "utils/axios";

const UsersDetails = () => {

    const { state } = useLocation();
    const data = state.row;

    const userObj = useSelector(state => state.auth.user);

    const navigate = useNavigate();

    const [propertyStatus, setPropertyStatus] = useState(data?.status);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {

        setIsLoading(true);

        const payload = {
            status: propertyStatus,
            user_id: data?._id
        }
        Axios.post('/users/changeUserStatus', payload)
            .then((res) => {
                navigate( userObj?.currentType === 'sl-admin' ? '/sl/users/all-users' : '/ll/users/all-users');
                setIsLoading(false);
            }).catch((err) => {
                alert(err);
                setIsLoading(false);
            })
    }


    return (
        <>
            <div className="card mg10 table-h mb5 overflow-y">

                <div>
                    <h4 className='heading'>User Details :</h4>
                    <div className='d-flex align-items-center justify-content-end mt-1 mb-4 w-100'>
                        <SelectInput
                            customClass={'property-select-input w-25'}
                            options={[
                                {
                                    label: "Active",
                                    value: 1
                                },
                                {
                                    label: "In-active",
                                    value: 2
                                }
                            ]}
                            selectedValue={propertyStatus}
                            onChange={(e) => { setPropertyStatus(e.target.value) }} />

                        {/* onClick={handleApproval} */}
                        <ButtonLoader
                            className={"approved-btn grow"}
                            type={"button"}
                            onClickhandle={handleSubmit}
                            buttonName={"Save"}
                            isLoading={isLoading}
                            loaderColor={"white"}
                        >
                            Save
                        </ButtonLoader>
                        {/* <button type='button' className='approved-btn'  >Save</button> */}
                    </div>
                    {data?.image ? <div style={{ padding: "1rem" }} >
                        <img style={{ width: "10%" }} src={data?.image} alt="Wrong Url" />
                    </div>
                        :
                        <img style={{ width: "10%" }} src={"/images/user-demo.jpg"} alt="Wrong Url" />
                    }
                    <div className='row mt-3' style={{ padding: "1rem" }}>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3 '>
                            <div className='detail'>
                                <h6>User Id :</h6>
                                <h6 className='data'>{data?.user_id}</h6>
                            </div>
                            <div className='detail'>
                                <h6>Full Name :</h6>
                                <h6 className='data'>{data?.title} {data?.firstName} {data?.lastName}</h6>
                            </div>
                            <div className='detail'>
                                <h6>Email Address :</h6>
                                <h6 className='data'>{data?.email}</h6>
                            </div>
                            <div className='detail'>
                                <h6>Mobile Number :</h6>
                                <h6 className='data'>{data?.mobileNumber}</h6>
                            </div>
                            <div className='detail'>
                                <h6>Status :</h6>
                                <h6 className='data'>{data?.status === "1" ? 'Active' : 'In Active'}</h6>
                            </div>



                        </div>


                    </div>

                </div>


            </div>
        </>
    )
}

export default UsersDetails