import {createSlice } from "@reduxjs/toolkit";
import {addApplicantsAsync, editApplicantsAsync, deleteApplicantsAsync, applicantStatusAsync, applicantsListAsync, allApplicantsListAsync, applicantsChartDataAsync, allApplicantsChartDataAsync, applicantSelectListAsync} from "./Services"
import _ from "lodash";

const applicantSlice = createSlice({
  name: "applicantSlice",
  initialState: {
    loading: false,
    tenant: null,
    applicantForm: {},
    applicantStatus: [],
    applicantList: [],
    applicantSelectList:[],
    allApplicantList: [],
    applicantsChartData: [],
    allApplicantsChartData: [],
  },
  reducers: {
    changeApplicantForm:(state, action) => {
      let update_form = _.cloneDeep(state.applicantForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        applicantForm:{
          ...state.applicantForm,
          ...update_form,
        }
      }
    },
    selectApplicant: (state, {payload}) => {

      return {
        ...state,
        applicantForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearApplicantForm: (state, action) => {
      state.applicantForm = {};
    },
  },
  extraReducers: {
    [addApplicantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addApplicantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addApplicantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editApplicantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editApplicantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editApplicantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteApplicantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteApplicantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteApplicantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [applicantStatusAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [applicantStatusAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.applicantStatus = action?.payload.data;
    //   state.program = action.payload;
    },
    [applicantStatusAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [applicantsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [applicantsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.applicantList = action?.payload.data;
    //   state.program = action.payload;
    },
    [applicantsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [applicantSelectListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [applicantSelectListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.applicantSelectList = action?.payload.data;
    //   state.program = action.payload;
    },
    [applicantSelectListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [allApplicantsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allApplicantsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allApplicantList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allApplicantsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [applicantsChartDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [applicantsChartDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.applicantsChartData = action?.payload.data;
    //   state.program = action.payload;
    },
    [applicantsChartDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allApplicantsChartDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allApplicantsChartDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allApplicantsChartData = action?.payload.data;
    //   state.program = action.payload;
    },
    [allApplicantsChartDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default applicantSlice.reducer;

export const {
  changeApplicantForm,
  selectApplicant,
  clearApplicantForm,
} = applicantSlice.actions;
