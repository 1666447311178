// import "./App.css"
import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import AddTenantForm from "../tenants/AddForm";
import { } from "react-icons/io";
import SelectAndAdd from "components/CustomComponents/SelectAndAdd";
import AddUnits from "../units/AddUnits";
import RadioButtons from "components/form/RadioButton";
import AddBankDetails from "./AddBankDetails";
import UploadOwnership from "./UploadOwnership";
import ModalHeading from "components/CustomComponents/ModalHeading";
import UploadIdentity from "./UploadIdentity";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import useSelectionData from "./hooks/useSelectionData";
import AddForm from "../newProperties/AddForm";

function TenancyDetailForm({
  formik,
  bankList,
  properties,
  getPropertyList,
  getBankList,
  propertyId,
}) {

  // console.log("formik", formik)
  const [bankDetail, setBankDetail] = useState({});
  const [units, setUnits] = useState([]);
  const [propertyModal, setPropertyModal] = useState(false);
  const [tenantModal, setTenantModal] = useState(false);
  const [unitModal, setUnitModal] = useState(false);
  const [identityModal, setIdentityModal] = useState(false);
  const [documentModal, setDocumentModal] = useState(false);
  const [bankModal, setBankModal] = useState(false);
  const [selectBankAccount, setSelectBankAccount] = useState(false);
  const [propertyData, setPropertyData] = useState(null);

  const {
    tenants,
    getTenantsByPropertyid,
    convertPropertyToOptions,
    convertTenantToOptions,
    convertUnitToOptions,
  } = useSelectionData();

  const handleProperty = (val) => {
    formik.setFieldValue("property", val);
    const filterProperty = properties.filter((item) => item._id === val);
    setPropertyData(filterProperty[0]);

    if (val !== "") {
      const filteredData = properties.filter((item) => item._id === val);
      setUnits(filteredData[0]?.ll_units);

      if (
        filteredData[0]?.propertyDocuments &&
        Object.keys(filteredData[0]?.propertyDocuments).length > 0
      ) {
        formik.setFieldValue("ownership", true);
      } else {
        formik.setFieldValue("ownership", false);
      }
    } else {
      setUnits([]);
    }
  };

  useEffect(() => {
    if (propertyId !== 'undefined' && propertyId !== 'null') {
      // console.log("chk");
      formik.setFieldValue("property", propertyId);
    }
  }, [propertyId]);

  useEffect(() => {
    if (properties?.length > 0 && formik.values?.property) {
      handleProperty(formik.values?.property);
    }
  }, [properties, formik.values?.property]);

  useEffect(() => {
    if (units?.length > 0 && formik.values?.property && formik.values?.unit) {
      getTenantsByPropertyid(formik.values?.property, formik.values?.unit);
    }
  }, [units, formik.values?.unit]);

  useEffect(() => {
    if (formik.values?.bank) {
      const filterData = bankList.filter(
        (item) => item._id === formik.values?.bank
      );
      setBankDetail(filterData[0]);
    }
  }, [formik.values?.bank]);

  return (
    <>
      <div className="v-left-select-right-button mb-3">
        <label className="tenancy-head mb-3">Select a property</label>
        <SelectAndAdd
          label="Select property"
          id="property"
          name="property"
          value={formik.values?.property}
          options={properties ? convertPropertyToOptions(properties) : []}
          buttonText="Add New"
          variant="outlined"
          openModal={() => {
            setPropertyModal(true);
          }}
          onChange={(e) => {
            handleProperty(e.target.value);
          }}
          onBlur={formik.handleBlur}
        />
        <ErrorMsg
          hasError={formik.touched?.property && formik.errors?.property}
        >
          {formik.errors?.property}
        </ErrorMsg>
      </div>
      <div className="v-left-select-right-button mb-3">
        <label className="tenancy-head mb-3">Select a unit</label>
        <SelectAndAdd
          label="Select unit"
          openModal={() => {
            setUnitModal(true);
          }}
          onChange={(e) => {
            formik.setFieldValue("unit", e.target.value);
            getTenantsByPropertyid(formik.values?.property, e.target.value);
          }}
          value={formik.values.unit}
          options={units ? convertUnitToOptions(units) : []}
          buttonText="Add New"
          onBlur={formik.handleBlur}
        />
        <ErrorMsg hasError={formik.touched?.unit && formik.errors?.unit}>
          {formik.errors?.unit}
        </ErrorMsg>
      </div>
      <div className="v-left-select-right-button mb-3">
        <label className="tenancy-head">Add a new tenant</label>
        <p className="mb-3 sub_heading">
          Add tenants to the tenancy that are already registered with
          PropertyJinni using the selection below or add tenants that are not
          registered using the button below.
        </p>
        <SelectAndAdd
          label="Select tenant"
          openModal={() => {
            setTenantModal(true);
          }}
          onChange={(e) => {
            formik.setFieldValue("tenant", e.target.value);
          }}
          value={formik.values.tenant}
          options={tenants ? convertTenantToOptions(tenants) : []}
          buttonText="Add New"
        />
        <ErrorMsg hasError={formik.touched?.tenant && formik.errors?.tenant}>
          {formik.errors?.tenant}
        </ErrorMsg>
      </div>
      {!formik.values?.identity && (
        <div className="v-left-select-right-button mb-3 mt-4">
          <h5 style={{ fontWeight: "700" }}>Proof of identity</h5>
          <p className="mb-3 sub_heading">
            To ensure the prevention of possible fraud, the PropertyJinni asks
            that you provide proof of identity and property ownership to list a
            property or create a tenancy.
          </p>
          <ErrorMsg
            hasError={formik.touched?.identity && formik.errors?.identity}
          >
            {formik.errors?.identity}
          </ErrorMsg>
          <div className="block-info">
            Proof of identity is required to create an esignature tenancy
            agreement.
          </div>
          <button
            className="btn_verify"
            onClick={() => {
              setIdentityModal(true);
            }}
          >
            Verify Identity
          </button>
        </div>
      )}

      {formik.values.property && !formik.values?.ownership && (
        <div className="mb-3 mt-4">
          <h5 style={{ fontWeight: "700" }}>Proof of ownership</h5>
          <ErrorMsg
            hasError={formik.touched?.ownership && formik.errors?.ownership}
          >
            {formik.errors?.ownership}
          </ErrorMsg>
          <div className="block-info">
            Please provide one of the following documents, so we can verify your
            ownership of the property:
            <ul>
              <li>HM Land Registry title</li>
              <li>Title deed</li>
              <li>Mortgage agreement</li>
              <li>Certificate of insurance</li>
              <li>Signed solicitor's letter</li>
            </ul>
          </div>
          <button
            className="btn_verify"
            onClick={() => {
              setDocumentModal(true);
            }}
          >
            Upload Document
          </button>
        </div>
      )}
      <div className="mb-3 mt-4">
        <h5 style={{ fontWeight: "700" }}>Add your bank details here</h5>
        <p className="mb-3 sub_heading">
          This is where we will tell the tenant(s) to pay the rent for this
          tenancy.
        </p>
        <div className="block-info">
          {" "}
          Please note - We only support banks held in the UK.
        </div>
        {!selectBankAccount && !bankModal && (
          <div className="payment__card">
            <div className="d-flex justify-content-between border-bottom pb-2">
              <p>
                {bankDetail?.bankName ? `${bankDetail?.bankName} ending in${" ****"}` : '' } 
                {bankDetail?.accountNumber?.slice(-4)}
              </p>
            </div>
            <div className="d-flex justify-content-between pt-4">
              <div>
                <p className="sub_heading">Account holder</p>
                <h6>{bankDetail?.accountHolder}</h6>
              </div>

              <button
                className="add_new_btn v-add_new_btn"
                onClick={() => {
                  setSelectBankAccount(true);
                }}
              >
                Change bank
              </button>
            </div>
          </div>
        )}
        {selectBankAccount && (
          <div className=" mb-3">
            {bankList &&
              bankList.map((bank) => {
                return (
                  <div className="d-flex align-items-center w-100 mb-3">
                    <RadioButtons
                      customClass={"radio-size"}
                      value={formik.values.bank}
                      checked={bank._id}
                      onChange={(e) => {
                        formik.setFieldValue("bank", bank._id);
                        setBankDetail(bank);
                      }}
                      id={`${bank._id}`}
                    />
                    <label
                      htmlFor={`${bank._id}`}
                      className="payment__card w-100"
                      style={{ marginLeft: "25px", cursor: "pointer" }}
                    >
                      <div className="d-flex justify-content-between border-bottom pb-2">
                        <p>
                          {bank?.bankName} ending in{" ****"}
                          {bank?.accountNumber?.slice(-4)}
                        </p>
                      </div>
                      <div className="d-flex justify-content-between pt-4">
                        <div>
                          <p className="sub_heading">Account holder</p>
                          <h6>{bank?.accountHolder}</h6>
                        </div>
                      </div>
                    </label>
                  </div>
                );
              })}
            <div style={{ textAlign: "end" }}>
              <button
                className="add_new_btn"
                onClick={() => {
                  setSelectBankAccount(false);
                }}
              >
                Confirm bank
              </button>
            </div>
          </div>
        )}
        {!bankModal && (
          <button
            className="btn_verify mt-3 grow"
            onClick={() => {
              setSelectBankAccount(false);
              setBankModal(true);
            }}
          >
            <FaPlus /> Add bank account
          </button>
        )}
        {bankModal && (
          <div className="payment__card">
            <h6 style={{ fontWeight: "700" }}>Add new bank</h6>
            <AddBankDetails
              closeModal={(val) => {
                setBankModal(val);
              }}
              updateList={() => {
                getBankList();
              }}
            />
          </div>
        )}
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <div></div>
        <ButtonLoader
          buttonName={"Next"}
          variant={"contained"}
          className={"add_new_btn grow mt-4"}
          onClickhandle={formik.handleSubmit}
          loaderColor={"yellow"}
        />
      </div>
      {propertyModal && (
        <div className="popup-overlay">
          <div className=" modal2">
            <div className="modal-card mg10 table-h overflow-y ">
              <ModalHeading
                title="Add Property"
                closeModal={() => {
                  setPropertyModal(false);
                }}
              />
              <div className="p-3 px-md-4">
                <AddForm
                  modal={true}
                  onClose={() => {
                    setPropertyModal(false);
                    getPropertyList();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {unitModal && (
        <div className="popup-overlay">
          <div className=" modal2">
            <div className="modal-card mg10 table-h overflow-y">
              <ModalHeading
                title="Add a unit"
                closeModal={() => {
                  setUnitModal(false);
                }}
              />
              <div className="p-3 px-md-4">
                <AddUnits
                  isunitAdd={async () => {
                    setUnitModal(false);
                    getPropertyList();
                  }}
                  modal={true}
                  data={{
                    property_id: propertyData?.property_id,
                    address: propertyData?.address,
                    city: propertyData?.city,
                    country: propertyData?.country,
                    zip: propertyData?.zip,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      {tenantModal && (
        <div className="popup-overlay">
          <div className=" modal2">
            <div className="modal-card mg10 table-h overflow-y">
              <ModalHeading
                title="Add a Tenant"
                closeModal={() => {
                  setTenantModal(false);
                }}
              />
              <div className="p-3 px-md-4">
                <AddTenantForm
                  data={{
                    property: formik.values?.property,
                    unit: formik.values?.unit,
                  }}
                  modal={true}
                  onCancel={(id) => {
                    setTenantModal(false);
                    getTenantsByPropertyid(
                      formik.values?.property,
                      formik.values?.unit
                    );
                    formik.setFieldValue("tenant", id);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {documentModal && (
        <div className="popup-overlay">
          <div className="document_modal">
            <div className="modal-card mg10 ">
              <ModalHeading
                title="Proof of ownership"
                closeModal={() => {
                  setDocumentModal(false);
                }}
              />
              <UploadOwnership
                parentFormik={formik}
                closeModal={() => {
                  getPropertyList();
                  setDocumentModal(false);
                }}
              />
            </div>
          </div>
        </div>
      )}

      {identityModal && (
        <div className="popup-overlay">
          <div className="document_modal">
            <div className="modal-card mg10 ">
              <ModalHeading
                title="Identity verification"
                closeModal={() => {
                  setIdentityModal(false);
                }}
              />
              <UploadIdentity
                parentFormik={formik}
                closeModal={() => {
                  setIdentityModal(false);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default TenancyDetailForm;
