
export const API_HOST = process.env.REACT_APP_API_HOST;
export const API_BASE_URL = `${API_HOST}/api`;
export const NEW_API_BASE_URL = `${API_BASE_URL}/v1`;
export const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

// To be deleted by Sachin
// export const MONGO_DB_STATUS = true;
// export const accountName = '';
// export const containerName = '';
// export const sasToken = '';