import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';
import 'react-toastify/dist/ReactToastify.css';





// get Api

export const mailListAsync = createAsyncThunk(
  'mailSettingSlice/mailListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/mail-settings?id=${post.userId}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {

        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


// post Api
export const mailSettingAsync = createAsyncThunk(
  'mailSettingSlice/mailSettingAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/mail-settings/update`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');

        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();


        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



