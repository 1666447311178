import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';

import { Button as MuiButton } from '@mui/material';

const Button = ({ color, variant, onClick, buttonText, customClass, disable, isLoading  }) => {
  return (
    <MuiButton   
      variant={variant} 
      onClick={onClick} 
      style={{}} 
      className={customClass} 
      disabled={disable?true:false}
      >

      {buttonText}


      {isLoading? <span style={{marginLeft:'5px' }}> <Loader type='button'/> </span> : null}

    </MuiButton>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  variant: PropTypes.string,
  onClick: PropTypes.func,
  buttonText: PropTypes.string,
};

Button.defaultProps = {
  color: 'primary',
  variant: 'contained',
  onClick: () => {},
};

export default Button;
