import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';


//get Api

export const placeApiListAsync = createAsyncThunk(
  'placeApiSlice/placeApiListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/places/address-autocomplete?text=${post.text}`,
        {
          headers: {
            'source': 'web',
            // 'token': `${access_token}`,
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



export const placeDetailApiAsync = createAsyncThunk(
  'placeDetailApiSlice/placeDetailApiAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/places/place-detail-by-address-id?place_id=${post.place_id}`,
        {
          headers: {
            'source': 'web',
            // 'token': `${access_token}`,
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);