import {createSlice } from "@reduxjs/toolkit";
import { rentalPayemntAsync, allRentalPayemntAsync} from "./Services"
import _ from "lodash";

const rentalPayemntSlice = createSlice({
  name: "rentalPayemntSlice",
  initialState: {
    loading: false,
    rentalPayemntForm: {},
    rentalPayemntData: [],
    allRentalPayemntData: [],
  },
  reducers: {
    changeRentalPayemntForm:(state, action) => {
      let update_form = _.cloneDeep(state.rentalPayemntForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        rentalPayemntForm:{
          ...state.rentalPayemntForm,
          ...update_form,
        }
      }
    },
    clearRentalPayemntForm: (state, action) => {
      state.rentalPayemntForm = {};
    },
  },
  extraReducers: {
    
    [rentalPayemntAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [rentalPayemntAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentalPayemntData = action?.payload.data;
    //   state.program = action.payload;
    },
    [rentalPayemntAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allRentalPayemntAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allRentalPayemntAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRentalPayemntData = action?.payload.data;
    //   state.program = action.payload;
    },
    [allRentalPayemntAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default rentalPayemntSlice.reducer;

export const {
  changeRentalPayemntForm,
  clearRentalPayemntForm,
} = rentalPayemntSlice.actions;
