import { useEffect} from "react";
import { useSelector, useDispatch } from 'react-redux';

import { connectToSocket } from "redux/socketSlice";
import {setSocketConnect} from "redux/messagingSlice";

const useSocket=()=>{
    
    const dispatch = useDispatch();

    const socket = useSelector(state => state.socket.socket);
    const user = useSelector(state => state.auth.user);
    const token = useSelector(state => state.auth.token);

    const socketConnect = ()=>{
        // console.log("socket check")
        dispatch(connectToSocket(token));
    };


    useEffect(()=>{
        if(socket){

            socket.emit("join-user", user._id);
            socket.on("reconnect_error", (error) =>  console.log('Reconnect_error', error));
            socket.on("reconnect_failed", () => console.log('reconnect_failed'));
            socket.on("connect", () => console.log('Connected'));
            
            socket.on("disconnect", (reason) => {
                // console.log(`disconnect due to - ${reason}`);
                dispatch(setSocketConnect(false));
            });
        }
    }, [socket]);


    return {
        socketConnect
    }
}

export default useSocket;