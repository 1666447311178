import TextInput from "components/form/TextInput";
import * as React from "react";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import RadioWithBorder from "components/CustomComponents/RadioWithBorder";
import { AiOutlineDelete } from "react-icons/ai";
import { useRef } from "react";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";

const Agreement = ({ handleBack, formik, isLoading }) => {
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);

  const fileInputRef = useRef(null);
  const handleClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (formik.values?.astDocument) {
      if (formik.values?.astDocument instanceof File) {
        const imagePreviewURL = URL.createObjectURL(formik.values?.astDocument);
        setSelectedImagePreview(imagePreviewURL);
      } else {
        setSelectedImagePreview(formik.values?.astDocument);
      }
    }
  }, [formik.values?.astDocument]);

  // const handleImageChange = (e) => {
  //   const selectedImage = e.target.files[0];

  //   if (selectedImage) {
  //     if (selectedImage.type.startsWith("image/")) {
  //       const imagePreviewURL = URL.createObjectURL(selectedImage);
  //       setSelectedImagePreview(imagePreviewURL);
  //     } else if (selectedImage.type === "application/pdf") {
  //       const pdfImageURL = "/images/pdf.png";
  //       setSelectedImagePreview(pdfImageURL);
  //     } else {
  //       setSelectedImagePreview(null);
  //     }
  //     formik.setFieldValue("depositCertificate", selectedImage);
  //   } else {
  //     setSelectedImagePreview(null);
  //   }
  // };

  return (
    <>
      <h4 style={{ fontWeight: "700" }}>Select agreement</h4>
      <div className="w-100 d-grid gap-3 mb-3">
        <div className="">
          <RadioWithBorder
            customClass={"radio-size"}
            value={0}
            checked={formik.values.agreement}
            onChange={() => formik.setFieldValue("agreement", 0)}
            label="No Agreement Required"
            subHeading="Select if you already have a signed tenancy agreement. You will be asked to upload it."
            parentClass={"d-flex align-items-start"}
          />
        </div>
        <div className="">
          <RadioWithBorder
            customClass={"radio-size "}
            value={1}
            checked={formik.values.agreement}
            onChange={() => formik.setFieldValue("agreement", 1)}
            label="PropertyJinni Portfolio • Assured Shorthold Tenancy Agreement"
            subHeading="Simply send out the Assured Shorthold Tenancy Agreement to the tenant(s) with just a few clicks.
                        You will be able to customise dates, payments and even add special terms. Our standard tenancy agreement is fully compliant as per PropertyJinni standards."
            parentClass={"d-flex align-items-start"}
          />
        </div>
        <ErrorMsg
          hasError={formik.touched.agreement && formik.errors.agreement}
        >
          {formik.errors.agreement}
        </ErrorMsg>
      </div>
      {formik.values.agreement === 1 && (
        <>
          <Box sx={{ flexGrow: 1 }} className="d-grid gap-3">
            <Grid
              container
              spacing={2}
              className="form-field__wrapper w-100 m-0 mb-3"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">
                  Is the owner of the property an individual or a company?
                </h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <Grid container spacing={2} className="">
                  <Grid item xs={6} sm={6} md={6} className="">
                    <RadioWithBorder
                      customClass={"radio-size "}
                      value={"Individual"}
                      checked={formik.values.propertyOwnerType}
                      onChange={() =>
                        formik.setFieldValue("propertyOwnerType", "Individual")
                      }
                      label="Individual"
                      parentClass={"d-flex align-items-center w"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} className="">
                    <RadioWithBorder
                      customClass={"radio-size "}
                      value={"Company"}
                      checked={formik.values.propertyOwnerType}
                      onChange={() =>
                        formik.setFieldValue("propertyOwnerType", "Company")
                      }
                      label="Company"
                      parentClass={"d-flex align-items-center w"}
                    />
                  </Grid>
                </Grid>
                <ErrorMsg
                  hasError={
                    formik.touched.propertyOwnerType &&
                    formik.errors.propertyOwnerType
                  }
                >
                  {formik.errors.propertyOwnerType}
                </ErrorMsg>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="form-field__wrapper mx-0 w-100"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">
                  Are you the owner of the property as either an individual or a
                  ‘person of significant control’?
                </h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <Grid container spacing={2} className="">
                  <Grid item xs={6} sm={6} md={6} className="">
                    <RadioWithBorder
                      customClass={"radio-size "}
                      value={0}
                      checked={formik.values.mortgage}
                      onChange={() => formik.setFieldValue("mortgage", 0)}
                      label="Yes"
                      parentClass={"d-flex align-items-center"}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} className="">
                    <RadioWithBorder
                      customClass={"radio-size "}
                      value={1}
                      checked={formik.values.mortgage}
                      onChange={() => formik.setFieldValue("mortgage", 1)}
                      label="No"
                      parentClass={"d-flex align-items-center"}
                    />
                  </Grid>
                </Grid>
                <ErrorMsg
                  hasError={formik.touched.mortgage && formik.errors.mortgage}
                >
                  {formik.errors.mortgage}
                </ErrorMsg>
              </Grid>
            </Grid>
            {formik.values.agreement === 1 &&
              formik.values.propertyOwnerType === "Company" && (
                <Grid
                  container
                  spacing={2}
                  className="form-field__wrapper w-100 m-0"
                >
                  <Grid item xs={12} sm={12} md={6} className="p-0">
                    <h5 className="small">Company Details</h5>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} className="p-0">
                    <div className="mb-4">
                      <TextInput
                        customClass={"w-100 "}
                        type={"text"}
                        name={"companyDetails.registrationNo"}
                        value={formik.values.companyDetails.registrationNo}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "companyDetails.registrationNo",
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        placeholder={"Registration No"}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched?.companyDetails?.registrationNo &&
                          formik.errors?.companyDetails?.registrationNo
                        }
                      >
                        {formik.errors?.companyDetails?.registrationNo}
                      </ErrorMsg>
                    </div>
                    <div className="mb-4">
                      <TextInput
                        customClass={"w-100 "}
                        type={"text"}
                        name={"companyDetails.companyName"}
                        value={formik.values.companyDetails.companyName}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "companyDetails.companyName",
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        placeholder={"Company Name"}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.companyDetails?.companyName &&
                          formik.errors.companyDetails?.companyName
                        }
                      >
                        {formik.errors.companyDetails?.companyName}
                      </ErrorMsg>
                    </div>
                    <div className="mb-4">
                      <TextInput
                        customClass={"w-100 "}
                        type={"text"}
                        name={"companyDetails.companyEmail"}
                        value={formik.values.companyDetails?.companyEmail}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "companyDetails.companyEmail",
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        placeholder={"Email Address"}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.companyDetails?.companyEmail &&
                          formik.errors.companyDetails?.companyEmail
                        }
                      >
                        {formik.errors.companyDetails?.companyEmail}
                      </ErrorMsg>
                    </div>
                    <div className="mb-4">
                      <TextInput
                        customClass={"w-100 "}
                        type={"text"}
                        name={"companyDetails.address"}
                        value={formik.values.companyDetails.address}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "companyDetails.address",
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        placeholder={"Address"}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.companyDetails?.address &&
                          formik.errors.companyDetails?.address
                        }
                      >
                        {formik.errors.companyDetails?.address}
                      </ErrorMsg>
                    </div>
                    <div className="mb-4">
                      <TextInput
                        customClass={"w-100 "}
                        type={"text"}
                        name={"companyDetails.postCode"}
                        value={formik.values.companyDetails?.postCode}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "companyDetails.postCode",
                            e.target.value
                          );
                        }}
                        onBlur={formik.handleBlur}
                        variant="outlined"
                        placeholder={"Post Code"}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.companyDetails?.postCode &&
                          formik.errors.companyDetails?.postCode
                        }
                      >
                        {formik.errors.companyDetails?.postCode}
                      </ErrorMsg>
                    </div>
                  </Grid>
                </Grid>
              )}
          </Box>
        </>
      )}
      {formik.values.agreement === 0 && (
        <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4">
          <Grid item xs={12} sm={12} md={6} className="agreement">
            <h6>Add your agreement</h6>
            <h5>Upload your signed tenancy agreement</h5>
          </Grid>
          <Grid item xs={12} sm={12} md={6} className="">
            {formik.values?.astDocument ? (
              <div
                className="image_border p-2 d-flex justify-content-between align-items-center w-100"
                style={{ borderRadius: "4px" }}
              >
                {selectedImagePreview &&
                  (typeof formik.values?.astDocument === "string" ? (
                    selectedImagePreview.endsWith(".pdf") ? (
                      <div>
                        <img
                          className="image-object3"
                          src="/images/pdf.png"
                          alt="PDF"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          className="image-object3"
                          src={formik.values?.astDocument}
                          alt="Selected Image Preview"
                        />
                      </div>
                    )
                  ) : formik.values?.astDocument?.type === "application/pdf" ? (
                    <div>
                      <img
                        className="image-object3"
                        src="/images/pdf.png"
                        alt="PDF"
                      />
                    </div>
                  ) : (
                    <div>
                      <img
                        className="image-object3"
                        src={selectedImagePreview}
                        alt="Selected Image Preview"
                      />
                    </div>
                  ))}
                {typeof formik.values?.depositCertificate === "string" ? (
                  <p className="image_name">Agreement</p>
                ) : (
                  <p className="image_name">
                    {formik.values?.depositCertificate?.name}
                  </p>
                )}
                <AiOutlineDelete
                  fontSize={22}
                  onClick={() => {
                    formik.setFieldValue("astDocument", null);
                  }}
                  style={{ cursor: "pointer" }}
                />
              </div>
            ) : (
              <div>
                <button
                  type="button"
                  className="btn__info"
                  onClick={handleClick}
                >
                  + Add Document
                </button>
                <input
                  class="form-control"
                  className="hidden-input"
                  ref={fileInputRef}
                  type="file"
                  id="formFile"
                  onChange={(e) => {
                    formik.setFieldValue("astDocument", e.target.files[0]);
                  }}
                />
                <p className="image_summary">
                  We support: PDF, DOC, DOCX, JPG, JPEG, JPEG & PNG files
                </p>
                <ErrorMsg
                  hasError={
                    formik.touched.astDocument && formik.errors.astDocument
                  }
                >
                  {formik.errors.astDocument}
                </ErrorMsg>
              </div>
            )}
          </Grid>
        </Grid>
      )}
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn_verify mt-4 grow" onClick={handleBack}>
          Back
        </button>
        <ButtonLoader
          buttonName="Save"
          variant={"contained"}
          className={"add_new_btn grow mt-4"}
          onClickhandle={formik.handleSubmit}
          isLoading={isLoading}
          loaderColor={"yellow"}
        />
      </div>
    </>
  );
};

export default Agreement;
