import {createSlice } from "@reduxjs/toolkit";
import {myProfileAsync, adminMyProfileAsync} from "./Services"
import _ from "lodash";

const myProfileSlice = createSlice({
  name: "myProfileSlice",
  initialState: {
    loading: false,
    myProfileForm: {},
  },
  reducers: {
    changeMyProfileForm:(state, action) => {
      let update_form = _.cloneDeep(state.myProfileForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        myProfileForm:{
          ...state.myProfileForm,
          ...update_form,
        }
      }
    },
    clearMyProfileForm: (state, action) => {
      state.myProfileForm = {};
    },
  },
  extraReducers: {
    [myProfileAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [myProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    
    },
    [myProfileAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [adminMyProfileAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [adminMyProfileAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    
    },
    [adminMyProfileAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
   
  },
});

export default myProfileSlice.reducer;

export const {
  changeMyProfileForm,
  clearMyProfileForm,
} = myProfileSlice.actions;
