import Toast from "components/Toast";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "utils/axios";

const useSelectionData = () => {
  const navigate = useNavigate();

  const [tenants, setTenants] = useState([]);
  const [properties, setProperties] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [tenancies, setTennacies] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [tenancyData, setTennacyData] = useState({});
  const [overlappingData, setOverlappingData] = useState([]);
  const [isTenancyLoading, setIsTenancyLoading] = useState(false);

  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj?.id;
  var user_Id = userObj?._id;
  const userType = userObj?.currentType;
  const formData = new FormData();

  const getPropertyList = () => {
    Axios.get(`/properties/select-list?id=${userId}&userType=${userType}`)
      .then((res) => {
        setProperties(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const getTenantsByPropertyid = (property_id, unit_id) => {
    Axios.get(
      `/tenants/select-tenant-list?id=${user_Id}&property_id=${property_id}&unit=${unit_id}`
    )
      .then((res) => {
        setTenants(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const getPaymentMethods = () => {
    Axios.get(`/payment-methods`)
      .then((res) => {
        setPaymentMethods(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const getBankList = () => {
    Axios.get(`/tenancies/getBank?user_id=${userObj?._id}`)
      .then((res) => {
        setBankList(res?.data?.bankData);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const getTenanciesList = (page, limit) => {
    setIsTenancyLoading(true);
    Axios.get(
      `/tenancies?id=${userObj._id}&userType=${userObj?.currentType}&page=${page}&limit=${limit}`
    )
      .then((res) => {
        setTennacies(res?.data?.data);
        setTotalRows(res?.data?.total);
        setIsTenancyLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        Toast(error, 'error');
        setIsTenancyLoading(false);
      });
  };

  const getTenanciesListByProperty = (page, limit, property) => {
    setIsTenancyLoading(true);
    Axios.get(
      `/tenancies/getByProperty?id=${userObj._id}&userType=${userObj?.currentType}&page=${page}&limit=${limit}&propertyId=${property}`
    )
      .then((res) => {
        setTennacies(res?.data?.data);
        setTotalRows(res?.data?.total);
        setIsTenancyLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        Toast(error, 'error');
        setIsTenancyLoading(false);
      });
  };

  const AddTenancy = (values) => {
    setIsLoading(true);

    Object.keys(values).forEach((key) => {
      if (
        key !== "astDocument" &&
        key !== "depositCertificate" &&
        key !== "createdBy"
      ) {
        if (typeof values[key] === "object") {
          formData.append(key, JSON.stringify(values[key]));
        } else {
          formData.append(key, values[key]);
        }
      }
    });
    formData.append("astDocument", values?.astDocument);
    formData.append("depositCertificate", values?.depositCertificate);
    formData.append("createdBy", userObj._id);
    Axios.post(`/tenancies/addtenancy`, formData)
      .then((res) => {
        setIsLoading(false);
        navigate("/ll/tenancies/all-tenancies");
      })
      .catch((error) => {
        setIsLoading(false);
        Toast(error, 'error');
      });
  };

  const getTenancyById = (id) => {
    Axios.get(`/tenancies/getById?id=${id}`)
      .then((res) => {
        setTennacyData(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const checkOverlapping = (propertyId, unitId, startDate, endDate) => {
    Axios.get(
      `/tenancies/checkOverlapping?propertyId=${propertyId}&unitId=${unitId}&startDate=${startDate}&endDate=${endDate}`
    )
      .then((res) => {
        setOverlappingData(res?.data?.data);
      })
      .catch((error) => {
        Toast(error, 'error');
      });
  };

  const convertPropertyToOptions = (data) => {
    return data?.map((item) => ({
      label: item?.title,
      value: item?._id,
    }));
  };

  const convertTenantToOptions = (data) => {
    return data?.map((item) => ({
      label: `${item?.firstName} ${item?.lastName}`,
      value: item?._id,
    }));
  };

  const convertUnitToOptions = (data) => {
    return data?.map((item) => ({
      label: item?.unitName,
      value: item?._id,
    }));
  };

  const convertISODate = (isoDate) => {
    const date = new Date(isoDate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  return {
    getPropertyList,
    properties,
    getTenantsByPropertyid,
    tenants,
    getPaymentMethods,
    paymentMethods,
    convertPropertyToOptions,
    convertTenantToOptions,
    convertUnitToOptions,
    getBankList,
    bankList,
    AddTenancy,
    getTenanciesList,
    tenancies,
    totalRows,
    isLoading,
    getTenancyById,
    tenancyData,
    convertISODate,
    checkOverlapping,
    overlappingData,
    getTenanciesListByProperty,
    isTenancyLoading
  };
};
export default useSelectionData;
