import {createSlice } from "@reduxjs/toolkit";
import {deleteImageAsync, uploadImagesAsync} from "./Services"
import _ from "lodash";

const uploadImagesSlice = createSlice({
  name: "uploadImagesSlice",
  initialState: {
    loading: false,
    uploadImagesForm: {},
  },
  reducers: {
    changeUploadImagesForm:(state, action) => {
      let update_form = _.cloneDeep(state.uploadImagesForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        uploadImagesForm:{
          ...state.uploadImagesForm,
          ...update_form,
        }
      }
    },
    clearUploadImagesForm: (state, action) => {
      state.uploadImagesForm = {};
    },
  },
  extraReducers: {
    [uploadImagesAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [uploadImagesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [uploadImagesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteImageAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [deleteImageAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [deleteImageAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default uploadImagesSlice.reducer;

export const {
  changeUploadImagesForm,
  clearUploadImagesForm,
} = uploadImagesSlice.actions;
