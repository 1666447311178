import * as React from "react";
import styles from "./Login.module.css";
import TextInput from "components/form/TextInput";
import PasswordInputWithToggle from "components/form/PasswordInput";
import { useState } from "react";
import * as Yup from "yup";
import { setCredentials } from "redux/authSlice";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import Axios from "utils/axios";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";
function LoginForm({ onNavigate, onClose }) {
  const [password, setPassword] = React.useState();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const loginhandle = (values, { setSubmitting, setErrors }) => {
    setIsLoading(true);
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
    };

    Axios.post(`/guestAuth/signin`, data)
      .then((resp) => {
        if (resp.data?.success) {
          if (resp.data && resp.data.id) {
            setErrors("");
          }

          dispatch(setCredentials(resp.data));
          onClose();
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors({ submit: err });
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test("custom", "Please enter a valid email address", (value) => {
          // Regular expression to validate email format
          const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

          // Check if the email format is valid
          if (!emailRegex.test(value)) {
            return false;
          }

                 // Check if a dot (.) is placed before @
                 if (value[value.indexOf("@")-1]==='.') {
                  return false;
                }

          // Check if there is a space at the beginning
          if (value.trim() !== value) {
            return false;
          }

          // Check if there are no more than 2 domains (e.g., example.com)
          const parts = value.split("@");
          if (parts[1].split(".").length > 3) {
            return false;
          }

          return true;
        })
        .required("Please enter email"),
      password: Yup.string()
        .test(
          "len",
          "Password sholud contain minimum  8 character ",
          (val) => val.toString().length >= 8
        )
        .test(
          'specialChar',
          'Password should contain at least one special character',
          (val) => /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]/.test(val)
        )
        .required("Please enter password"),
    }),
    onSubmit: loginhandle,
  });

  // console.log(formik.errors);

  return (
    <>
      <TextInput
        customClass={`w-100 mb-2 ${styles.formInput}`}
        type={"text"}
        name={"email"}
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder={"Email"}
      />
      <ErrorMsg hasError={formik.touched.email && formik.errors.email}>
        {formik.errors.email}
      </ErrorMsg>
      <PasswordInputWithToggle
        password={formik.values.password}
        name="password"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        customClass={`mb-2 ${styles.formInput}`}
      />
      <ErrorMsg hasError={formik.touched.password && formik.errors.password}>
        {formik.errors.password}
      </ErrorMsg>

      <ErrorMsg hasError={formik.errors.submit}>
        {formik.errors.submit}
      </ErrorMsg>
      <ButtonLoader
        buttonName={"Login"}
        variant={"contained"}
        className={styles.booking_btn}
        // style={{width:"100%"}}
        onClickhandle={formik.handleSubmit}
        isLoading={isLoading}
        loaderColor={"yellow"}
      />
      {/* <Button
                buttonText={'Login with Google'}
                variant={'contained'}
                customClass={styles.google_btn}
            />
            <Button
                buttonText={'Login with Facebook'}
                variant={'contained'}
                customClass={styles.facebook_btn}
            /> */}

      <span className={styles.forgot_link} style={{ textAlign: "center" }}>
        <p
          onClick={() => {
            onNavigate("signup");
          }}
        >
          Don't have an account?
        </p>
        {/* <p onClick={() => { onNavigate('forgot') }}> Forgot Password?</p> */}
      </span>
      {/* <Button
                buttonText={'Login with Twitter'}
                variant={'contained'}
                customClass={styles.twitter_btn}
            /> */}
    </>
  );
}

export default LoginForm;
