import moment from "moment";
import { useEffect } from "react";
import { useState } from "react";
import Axios from "utils/axios";

const UserReport = () => {
  const [userList, setUserList] = useState([]);
  const [userCount, setUserCount] = useState([]);

  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = moment(date).utc().local().format('DD/MM/YYYY');

    return formattedDate;
  };

  const dateFormat2 = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = moment(date).utc().local().format('DD/MM/YYYY');

    return formattedDate;
  };

  //   const currentDate = new Date();
  //   const options = { month: "long", year: "numeric" };
  //   const formattedDate = currentDate.toLocaleString("en-US", options);

  const currentDate = new Date();

  currentDate.setDate(1);

  // Format the date to 'YYYY-MM-DD' for the input value
  let year = currentDate.getFullYear();
  let month = String(currentDate.getMonth() + 1).padStart(2, "0");
  let day = String(currentDate.getDate()).padStart(2, "0");
  let formattedDate = `${year}-${month}-${day}`;

  const [toDate, setToDate] = useState();
  const [fromDate, setFromDate] = useState();

  useEffect(() => {
    const resp = Axios.get(
      `/users/user-data?fromDate=${fromDate}&toDate=${toDate}`
    )
      .then((res) => {
        // console.log("resp", res);
        setUserList(res?.data?.data?.users);
        setUserCount(res?.data?.data?.count);
      })
      .catch((err) => {
        alert(err);
      });
  }, [toDate, fromDate]);
  return (
    <div
      className="top-part py-5"
      style={{ overflowY: "scroll", maxHeight: "100vh" }}
    >
      <div className="container">
        <h3 style={{marginBottom:"30px"}} >PropertyJinni Report</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex">
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                <label>Registration date (From)</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date"
                  value={fromDate}
                  onChange={(e) => {
                    setFromDate(e.target.value);
                  }}
                  required
                />
              </div>
              <div
                className="col-sm-12 col-md-6 col-lg-4 mb-3 "
                style={{ marginLeft: "20px" }}
              >
                <label>Registration date (To)</label>
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date"
                  value={toDate}
                  onChange={(e) => {
                    setToDate(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="card mb-5">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tr>
                      <th>Month of registration</th>
                      <th>No. of landlords</th>
                      <th>No. of property owner</th>
                      <th>No. of properties</th>
                    </tr>
                    {userCount.length > 0 &&
                      userCount?.map((user) => {
                        return (
                          <tr>
                            <td>
                              {new Date(
                                user?._id?.year,
                                user?._id?.month - 1,
                                1
                              ).toLocaleDateString("en-US", {
                                year: "numeric",
                                month: "short",
                              })}
                            </td>
                            <td>{user?.landlordCount}</td>
                            <td>{user?.propertyOwnerCount}</td>
                            <td>{user?.propertyCount}</td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <tr>
                      <th>S.N.</th>
                      <th>Full name</th>
                      <th>Email</th>
                      <th>Type </th>
                      <th>No. of properties</th>
                      <th>Term</th>
                      <th>Registration date</th>
                      <th>Last login</th>
                    </tr>
                    {userList &&
                      userList.map((user, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>
                              {user?.firstName} {user?.lastName}
                            </td>
                            <td>{user?.email}</td>
                            <td>
                              {user?.livingStatus
                                ? user?.livingStatus === "landlord"
                                  ? "Landlord"
                                  : "Property owner"
                                : ""}
                            </td>
                            <td style={{textAlign:"center"}}>{user?.propertyCount}</td>
                            <td>
                              {user?.userType[0]
                                ? user?.userType[0] === "sml"
                                  ? " Short letting"
                                  : "Long letting"
                                : ""}
                            </td>
                            <td>
                              {user?.created_on
                                ? dateFormat(user?.created_on)
                                : ""}
                            </td>
                            <td>
                              {user?.lastLogin
                                ? dateFormat2(user?.lastLogin)
                                : ""}
                            </td>
                          </tr>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserReport;
