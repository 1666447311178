import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notifications: []
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notifications = action.payload;
    },

  },
});

export const { setNotification} =
notificationSlice.actions;
export default notificationSlice.reducer;