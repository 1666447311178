import {createSlice } from "@reduxjs/toolkit";
import {addTenanciesAsync, editTenanciesAsync, deleteTenanciesAsync, tenanciesListAsync, allTenanciesListAsync} from "./Services"
import _ from "lodash";

const tenancySlice = createSlice({
  name: "tenancySlice",
  initialState: {
    loading: false,
    tenanciesForm: {},
    tenanciesList: [],
    allTenanciesList: [],
  },
  reducers: {
    changeTenanciesForm:(state, action) => {
      let update_form = _.cloneDeep(state.tenanciesForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        tenanciesForm:{
          ...state.tenanciesForm,
          ...update_form,
        }
      }
    },
    selectTenancy: (state, {payload}) => {

      return {
        ...state,
        tenanciesForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearTenanciesForm: (state, action) => {
      state.tenanciesForm = {};
    },
  },
  extraReducers: {
    [addTenanciesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addTenanciesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addTenanciesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editTenanciesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editTenanciesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editTenanciesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteTenanciesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTenanciesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteTenanciesAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [tenanciesListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tenanciesListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tenanciesList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tenanciesListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allTenanciesListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allTenanciesListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTenanciesList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allTenanciesListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default tenancySlice.reducer;

export const {
  changeTenanciesForm,
  selectTenancy,
  clearTenanciesForm,
} = tenancySlice.actions;
