import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';

export default function InputWithCurrency({label, value, onChange,name, customClass, min}) {
    

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', width:"100%" }}>
            
                <FormControl fullWidth>
                    <InputLabel htmlFor="outlined-adornment-amount">{label}</InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-amount"
                        startAdornment={<InputAdornment position="start">£</InputAdornment>}
                        label={label ? label : ''}
                        value={value}
                        onChange={onChange}
                        type='number'
                        name={name}
                        className={customClass}
                        inputProps={{min : min ? min : 0 }}
                    />
                </FormControl>
        </Box>
    );
}
