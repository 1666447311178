import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Axios from "utils/axios";
import Header from "../components/header";
export default function OrderCancel() {
  const [searchParams] = useSearchParams();
  const orderid = searchParams.get("orderid");

  const CancelOrder = () => {
    const payLoad = {
      id: orderid,
    };

    Axios.post("/bookingOrders/cancelpayemnt", payLoad)
      .then((res) => {})
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    if (orderid) {
      CancelOrder();
    }
  }, []);
  // const headerTextColor = 'black'
  // const logo = 'PropertyJinni-Logo.png';
  // const headerStyle = {
  //     position: 'fixed',
  //     top: 0,
  //     left: 0,
  //     width: '100%',
  //     background: 'white',
  //     boxShadow: `none !important`,
  //     transition: 'background-color 0.3s ease-in-out',
  //     borderBottom: '1px solid lightgray'
  // };

  return (
    <>
      {/* <Header headerBackground={headerStyle} textColor={headerTextColor} logo={logo} /> */}

      <div
        style={{ overflowY: "auto", maxHeight: "fit-content" }}
        className="thankyou-page"
      >
        <div
          style={{ background: "white", padding: "30px", marginTop: "65px" }}
          className="_header"
        >
          <div style={{ marginBottom: "20px" }} className="logo">
            <img
              style={{ maxWidth: "150px" }}
              src="/images/exclamation-xxl.png"
              alt=""
            />
          </div>
          <h1 style={{ color: "black", fontSize: "45px" }}>
            Booking Cancelled
          </h1>
          <div style={{ marginTop: "10px" }} className="_body">
            <div className="_box">
              <h2>Your booking has been cancelled due to payment failure.</h2>
              <div
                style={{ paddingBottom: "2px", paddingTop: "15px" }}
                className="_footer"
              >
                <a
                  style={{ background: "#3874ff" }}
                  className="btn"
                  href="/guest/homepage"
                >
                  Back to homepage
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
