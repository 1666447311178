import {createSlice } from "@reduxjs/toolkit";
import {addGuarantorAsync, editGuarantorAsync, deleteGuarantorAsync,  guarantorListAsync, allGuarantorListAsync, guarantorSelectListAsync} from "./Services"
import _ from "lodash";

const guarantorSlice = createSlice({
  name: "guarantorSlice",
  initialState: {
    loading: false,
    guarantorForm: {},
    guarantorList: [],
    guarantorSelectList: [],
    allGuarantorList: [],
  },
  reducers: {
    changeGuarantorForm:(state, action) => {
      let update_form = _.cloneDeep(state.guarantorForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        guarantorForm:{
          ...state.guarantorForm,
          ...update_form,
        }
      }
    },
    selectGuarantor: (state, {payload}) => {

      return {
        ...state,
        guarantorForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearGuarantorForm: (state, action) => {
      state.guarantorForm = {};
    },
  },
  extraReducers: {
    [addGuarantorAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addGuarantorAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addGuarantorAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editGuarantorAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editGuarantorAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editGuarantorAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteGuarantorAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteGuarantorAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteGuarantorAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [guarantorListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [guarantorListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.guarantorList = action?.payload.data;
    //   state.program = action.payload;
    },
    [guarantorListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [guarantorSelectListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [guarantorSelectListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.guarantorSelectList = action?.payload.data;
    //   state.program = action.payload;
    },
    [guarantorSelectListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [allGuarantorListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allGuarantorListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allGuarantorList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allGuarantorListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default guarantorSlice.reducer;

export const {
  changeGuarantorForm,
  selectGuarantor,
  clearGuarantorForm,
} = guarantorSlice.actions;
