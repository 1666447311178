import * as React from "react";

import styles from "./footer.module.css";
import commonStyle from "../../Common.module.css";
import { Box, Grid } from "@mui/material";
import SelectInput from "../../../../../components/form/SelectInput";
import { AiFillHome } from "react-icons/ai";
import { MdCall, MdOutlineDesktopWindows, MdOutlineMail } from "react-icons/md";
import { FaFacebookF } from "react-icons/fa";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { guestPropertiesListAsync } from "../../../../../redux/guestProperties/Services";
import { useEffect } from "react";

function Footer() {
  const [properties, setProperties] = useState([]);

  const [currency, setCurrency] = useState("EUR");

  const CurrencyData = [
    {
      label: "EUR",
      value: "EUR",
    },
    {
      label: "USD",
      value: "USD",
    },
    {
      label: "GBP",
      value: "GBP",
    },
  ];

  // Fetch Apis

  const dispatch = useDispatch();
  const { loading, guestPropertiesList } = useSelector((state) => {
    const { guestPropertiesReducer } = state;

    return {
      loading: guestPropertiesReducer.loading,
      guestPropertiesList: guestPropertiesReducer.guestPropertiesList,
    };
  });

  const fetchPropertyList = () => {
    try {
      const payload = {
        page: 1,
        limit: 3,
      };
      dispatch(guestPropertiesListAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            alert(res.error);
          } else {
            setProperties(res.data);
          }
        });
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
      alert(error);
    }
  };

  useEffect(
    () => {
      fetchPropertyList();
    }, // eslint-disable-next-line
    []
  );

  return (
    <div className={styles.footer_background}>
      <div className={commonStyle.section_container}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <h3 className={styles.widget_title_footer}>
                About Propertyjinni
              </h3>
              {/* <SelectInput
                                customClass={styles.select_currency}
                                selectedValue={currency}
                                options={CurrencyData}
                                onChange={(e) => { setCurrency(e.target.value) }}
                            /> */}
              {/* <h3 className={`${styles.footer_title}`}>About Propertyjinni</h3> */}

              <div className={styles.textwidget}>
                <p>
                  PropertyJinni was established in the United Kingdom in 2016
                  with the goal of offering comfortable accommodations that
                  mimic home.
                </p>
                <p>
                 PropertyJinni.co.uk/guest/homepage
                  smoothly connects numerous travelers with fantastic travel
                  opportunities, incredible places to stay, including private
                  houses and rooms, as well as many other experiences.
                </p>
                <p>
                  PropertyJinni makes it possible for properties all over the
                  United Kingdom to reach a worldwide audience and expand their
                  companies.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <h3 className={styles.widget_title_footer}>Featured Listings</h3>

              {properties &&
                properties.length > 0 &&
                properties.map((property) => {
                  return (
                    <div className={styles.property_listing}>
                      <div className={styles.footer_img_wrapper}>
                        <a
                          href={`/guest/properties?id=${property.property_id}`}
                        >
                          {property.images && property.images.length > 0 ? (
                            <img
                              width="400"
                              height="314"
                              itemprop="image"
                              src={property.images[0]}
                              class="b-lazy img-responsive wp-post-image lazy-hidden"
                              alt="image"
                            />
                          ) : (
                            <img
                              width="400"
                              height="314"
                              itemprop="image"
                              src="/images/property.jpg"
                              class="b-lazy img-responsive wp-post-image lazy-hidden"
                              alt="image"
                            />
                          )}
                        </a>
                      </div>
                      <div className={styles.title_container}>
                        <div className={styles.category_name}>
                          <a
                            itemprop="url"
                            href={`/guest/properties?id=${property.property_id}`}
                            className={styles.listing_title_unit}
                          >
                            {property.title}
                          </a>
                        </div>

                        <div className={styles.category_tagline}>
                          {property?.address}
                        </div>
                        <div className={styles.price_unit}>
                          £ {property.price}
                          <span className={styles.pernight}> /night</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <h3 className={styles.widget_title_footer}>Contact</h3>
              <div className={styles.contact_points}>
                <AiFillHome color="#5d6475" size={20} />
                <p>11 Thornton Grove, Pinner HA5 4HG, UK</p>
              </div>
              {/* <div className={styles.contact_points}>
                <a href="tel:(305) 555-4446">
                  <MdCall color="#5d6475" size={20} />
                  <p>(305) 555-4446</p>
                </a>
              </div> */}
              <div className={styles.contact_points}>
                <a href="mailto:info@propertyjinni.co.uk">
                  <MdOutlineMail color="#5d6475" size={20} />
                  <p>info@propertyjinni.co.uk</p>
                </a>
              </div>
              <div className={styles.contact_points}>
                <a href="https://www.propertyjinni.co.uk/">
                  <MdOutlineDesktopWindows color="#5d6475" size={20} />
                  <p>Propertyjinni</p>
                </a>
              </div>

              <h3 className={`${styles.widget_title_footer} mt-5`}>
                Social Links :
              </h3>

              <div>
                <a
                  href="https://www.facebook.com/PropertyJinni"
                  target="_blank"
                  className={styles.social_links}
                >
                  <FaFacebookF />
                </a>
                {/* <a href='' className={styles.social_links}>
                                    <BsWhatsapp />
                                </a> */}
                {/* <a href="https://www.instagram.com/propertyjinni/" target='_blank' className={styles.social_links}>
                                    <BsInstagram />
                                </a> */}
                <a
                  href="https://twitter.com/PropertyJinni"
                  target="_blank"
                  className={styles.social_links}
                >
                  <BsTwitter />
                </a>
                {/* <a href='#' className={styles.social_links}>
                                    <FaLinkedinIn />
                                </a> */}
              </div>
            </Grid>
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default Footer;
