

import { useEffect, useRef, useState } from "react"
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';


import Sidebar from "./navigation/Sidebar";

import "../franchise.css";
import ResponsiveAppBar from "pages/public/guestPortal/components/header";
import GuestSidebar from "./navigation/GuestSidebar";

function GauestLayout(props) {

  const [isOpen, setIsOpen] = useState(window.innerWidth <= 768 ? false : true);

  const toggle = () => setIsOpen(!isOpen);

  // Callback function to update the isOpen state in the parent component
  const handleSidebarToggle = (newIsOpen) => {
    setIsOpen(newIsOpen);
  };

  const location = useLocation();

  const containerRef = useRef(null);

  useEffect(() => {
    containerRef.current.scrollTo(0, 0);
  }, [location.pathname]);


  const headerTextColor = 'black'
  const logo = 'PropertyJinni-Logo.png';
  const headerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    background: 'white',
    boxShadow: `none !important`,
    transition: 'background-color 0.3s ease-in-out',
    borderBottom: '1px solid lightgray'
  };

  return (
    <div style={{ overflowY: "scroll" }}>


      <ResponsiveAppBar headerBackground={headerStyle} textColor={headerTextColor} logo={logo} />

      <div className="main-container " style={{ marginTop: "65px" }}>
        <GuestSidebar open={isOpen} checkSidebar={handleSidebarToggle} />

        <div ref={containerRef} className={`${isOpen ? "main2 w-100 wdh1" : "main2 w-100 wdh2"}`}>
          <Outlet />
        </div>
      </div>

      {/* This is used to show toast notifications */}
      <ToastContainer />
    </div>
  )
}

export default GauestLayout;
