import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "../../components/SidebarMenu";
import {
  BsArrowBarLeft,
  BsArrowBarRight,
  BsBoxArrowDownRight,
} from "react-icons/bs";

import GuestNavitems from "./Guestnavitems";
import { useDispatch, useSelector } from "react-redux";
import { signoutAsync } from "redux/signOut/Services";
import Toast from "components/Toast";

const GuestSidebar = ({ open, checkSidebar }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    checkSidebar(newIsOpen); // Call the callback function to update the parent component's state
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };


  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj?.id;

  const dispatch = useDispatch();
  const { user } = useSelector((state) => {
    const { myProfileReducer, userReducer } = state;
    return { loading: myProfileReducer.loading, user: userReducer.user };
  });
  function signOut() {
    try {
      const payload = {
        id: userId,
      };
      dispatch(signoutAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            Toast(res.error, 'error');
          } else {
            navigate("/guest/homepage");
            navigate(0);
            localStorage.clear();

          }
        });
    } catch (error) {
      Toast(error, 'error');
    }
  }
  return (
    <>
      <motion.div
        animate={{
          width: isOpen ? "250px" : "50px",

          transition: {
            duration: 0.5,
            type: "spring",
            damping: 10,
          },
        }}
        className={`sidebar ${isOpen ? "sidebar-size2" : "sidebar-size"}`}
      >
        <section className="routes border-bottom pb-3">
          {GuestNavitems.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                />
              );
            }

            return (
              <NavLink
                to={route.path}
                key={route.name}
                className={` link ${(navData) =>
                  navData.isActive ? "active" : ""}`}
              >
                <div className="route-icon">{route.icon}</div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
          <div>
            <NavLink
              onClick={() => {
                signOut();
              }}
              className={` link ${(navData) =>
                navData.isActive ? "noactive" : "noactive"}`}
            >
              <BsBoxArrowDownRight />
              <AnimatePresence>
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                  className="link_text"
                >
                  Sign Out
                </motion.div>
              </AnimatePresence>
            </NavLink>
          </div>
        </section>
        <div className="bottom_section" onClick={toggle}>
          <div className="bars">
            {isOpen === true ? <BsArrowBarLeft /> : <BsArrowBarRight />}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.a
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="collapse-bar mt-1"
              >
                Collapse View
              </motion.a>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {/* <main ref={containerRef} className={`${isOpen ? "main2 w-100 wdh1" : "main2 w-100 wdh2"}`} >{children}</main> */}
    </>
  );
};

export default GuestSidebar;
