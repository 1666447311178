import {createSlice } from "@reduxjs/toolkit";
import { addBookingsAsync, bookingListAsync, editBookingsAsync} from "./Services";
import _ from "lodash";


const bookingSlice = createSlice({
  name: "bookingSlice",
  initialState: {
    loading: false,
    bookingList: [],
    bookingForm: {},
  },
  reducers: {
    changeBookingForm:(state, action) => {
      let update_form = _.cloneDeep(state.bookingForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        bookingForm:{
          ...state.bookingForm,
          ...update_form,
        }
      }
    },
    clearBookingForm: (state, action) => {
      state.bookingForm = {};
    },
  },
  extraReducers: {
   

    
    [bookingListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [bookingListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.bookingList = action?.payload.data;
    //   state.program = action.payload;
    },
    [bookingListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [addBookingsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addBookingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addBookingsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editBookingsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editBookingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editBookingsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default bookingSlice.reducer;

export const {
  changeBookingForm,
  clearBookingForm,
} = bookingSlice.actions;

