import { createAsyncThunk } from '@reduxjs/toolkit';
import {  NEW_API_BASE_URL } from '../../constants/constants';


//get Api

export const documentCategoryListAsync = createAsyncThunk(
    'categorySlice/documentCategoryListAsync',
    async (post, { rejectWithValue }, thunkAPI) => {


        try {
            const access_token = localStorage.getItem('token');
            
            // const navigation = useNavigation();
            const response = await fetch(`${NEW_API_BASE_URL}/properties/document-category-list?_id=${post.userId}`,
                {
                    headers: {
                        'source': 'web',
                        'authorization': `Bearer ${access_token}`,
                    },
                },
            );

            if (response.status === 200) {
                // if HTTP-status is 200-299
                const data = await response.json();

                // navigate('BottomNav');
                // navigation.navigate('BottomNav');

                return data;
            } else {
                const data = await response.json();

                // Toast.show(data.message);
                return data;
            }
        } catch (err) {

            // You can choose to use the message attached to err or write a custom error
            return rejectWithValue('Opps there seems to be an error');
        }
    },
);



export const addDocumentCategories = createAsyncThunk(
    'documentSlice/addDocumentCategories',
    async (post, { rejectWithValue }, thunkAPI) => {
        try {
            const access_token = localStorage.getItem('token');
            
            // const navigation = useNavigation();
            const response = await fetch(
                `${NEW_API_BASE_URL}/properties/addDocumentCategory`,
                {
                    method: 'POST',
                    body: JSON.stringify(post),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'source': 'web',
                        // 'token': `${access_token}`,
                        'authorization': `Bearer ${access_token}`
                    },
                },
            );

            if (response.status === 200) {
                // if HTTP-status is 200-299
                const data = await response.json();

                // navigate('BottomNav');
                // navigation.navigate('BottomNav');
                return data;
            } else {
                const data = await response.json();

                // Toast.show(data.message);
                return data;
            }
        } catch (err) {

            // You can choose to use the message attached to err or write a custom error

            return rejectWithValue('Opps there seems to be an error');
        }
    },
);