import {createSlice } from "@reduxjs/toolkit";
import {addTenantsAsync, editTenantsAsync, deleteTenantsAsync, tenantsListAsync, allTenantsListAsync, tenantsSelectListAsync} from "./Services"
import _ from "lodash";

const tenantSlice = createSlice({
  name: "tenantSlice",
  initialState: {
    loading: false,
    tenant: null,
    tenantForm: {},
    tenantList: [],
    tenantSelectList: [],
    allTenantList: [],
  },
  reducers: {
    changeTenantForm:(state, action) => {
      let update_form = _.cloneDeep(state.tenantForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        tenantForm:{
          ...state.tenantForm,
          ...update_form,
        }
      }
    },
    clearTenantForm: (state, action) => {
      state.tenantForm = {};
    },
  },
  extraReducers: {
    [addTenantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addTenantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addTenantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editTenantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editTenantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editTenantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteTenantsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTenantsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteTenantsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [tenantsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tenantsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tenantList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tenantsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [tenantsSelectListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tenantsSelectListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tenantSelectList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tenantsSelectListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allTenantsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allTenantsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTenantList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allTenantsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default tenantSlice.reducer;

export const {
  changeTenantForm,
  clearTenantForm,
} = tenantSlice.actions;
