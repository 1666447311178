import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL} from '../../constants/constants';


//get Api



export const tenanciesListAsync = createAsyncThunk(
  'tenancySlice/tenanciesListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/tenancies?id=${post.userId}&userType=${post.userType}&page=${post.page}&limit=${post.limit}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const allTenanciesListAsync = createAsyncThunk(
  'tenancySlice/allTenanciesListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/tenancies`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);




// post Api
export const addTenanciesAsync = createAsyncThunk(
  'tenancySlice/addTenanciesAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/tenancies/addtenancy`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');

        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();


        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const editTenanciesAsync = createAsyncThunk(
  'tenancySlice/editTenanciesAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/tenancies/edittenancy`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

// Delete Api

export const deleteTenanciesAsync = createAsyncThunk(
  'tenancySlice/deleteTenanciesAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/tenancies/deletetenancy`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = response;

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

