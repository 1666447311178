import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changePropertiesForm,
  clearPropertiesForm,
} from "../../../../redux/properties/PropertiesSlice";
import AddUnitAmenities from "./Amenities";
import { AiOutlinePlus } from "react-icons/ai";
import DeleteModal from "components/CustomComponents/DeleteModal";
import Axios from "utils/axios";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { ButtonLoader } from "components/form/ButtonLoader";

import Toast from "components/Toast";

function AddUnits({ isunitAdd, data, modal }) {
  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj.id;

  const [amenitiesData, setAmenitiesData] = useState(false);
  const [document, setDocument] = useState([]);
  const [available, setavailable] = useState();
  const [minimumTenancyDuration, setminimumTenancyDuration] = useState();
  const [forStudent, setforStudent] = useState(false);
  const [selectedAmenitiesData, setSelectedAmenitiesData] = useState({});
  const [isloading, setIsloading] = useState(false);
  const dispatch = useDispatch();
  const { propertiesForm } = useSelector((state) => {
    const { propertyReducer } = state;

    return { propertiesForm: propertyReducer.propertiesForm };
  });

  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImage, setDeleteImage] = useState("");

  const openImageModal = (image) => {
    setDeleteImage(image);
    setDeleteModal(true);
  };

  useEffect(() => {
    dispatch(
      changePropertiesForm({
        edited_field: "address",
        edited_value: data?.address ? data.address : "",
      })
    );
    dispatch(
      changePropertiesForm({
        edited_field: "city",
        edited_value: data?.city ? data.city : "",
      })
    );
    dispatch(
      changePropertiesForm({
        edited_field: "state",
        edited_value: data?.state ? data.state : "",
      })
    );
    dispatch(
      changePropertiesForm({
        edited_field: "zip",
        edited_value: data?.zip ? data.zip : "",
      })
    );
    dispatch(
      changePropertiesForm({
        edited_field: "country",
        edited_value: data?.country ? data.country : "",
      })
    );
  }, []);

  let handleSubmit = (e) => {
    e.preventDefault();
    try {
      const formdata = new FormData();
      setIsloading(true);
      formdata.append("target", "units");
      formdata.append(
        "ll_units",
        JSON.stringify({
          unitName: propertiesForm.unitName,
          marketRent: propertiesForm.marketRent,
          size: propertiesForm.size,
          unitType: propertiesForm.unitType,
          unitBeds: propertiesForm.unitBeds,
          unitBathrooms: propertiesForm.unitBathrooms,
          description: propertiesForm.description,
          country: propertiesForm.country,
          unitAmenities: selectedAmenitiesData,
          forStudent: forStudent,
          minimumTenancyDuration: minimumTenancyDuration,
          available: available,
        })
      );
      formdata.append("updatedBy", userId);
      formdata.append("property_id", data?.property_id);
      Array.from(document).forEach((image) => {
        formdata.append("images", image);
      });
      Axios.post(`/properties/editProperty`, formdata)
        .then((res) => {
          clearForm();
          if (res.error) {
            // toast.error(res.error, { position: "bottom-left" });
            Toast(res.error, "error");
          } else {
            isunitAdd();
            if (modal) {
            } else {
              // navigate(0);
            }
          }
          setIsloading(false);
        })
        .catch((error) => {
          Toast(error, "error");
          setIsloading(false);
        });
    } catch (error) {
      Toast(error, "error");
      setIsloading(false);
    }
  };

  let clearForm = () => {
    isunitAdd();
    dispatch(clearPropertiesForm());
  };
  const handleRemoveImage = (index) => {
    const updatedSelectedImages = [...document];
    updatedSelectedImages.splice(index, 1);

    setDocument(updatedSelectedImages);
    setDeleteModal(false);
  };

  return (
    <>
      {/* <div className='add-heading2'><div className='icon-card'><FaUserPlus style={{ color: '#F4B905' }} /> </div><h3 className='margin-b-0'> Add a Property Type</h3></div> */}
      <div className=" x-hidden p-1">
        <form onSubmit={handleSubmit}>
          <div className="mt-3">
            <p className="unit-heading">What is the unit information?</p>
            <div className="row border-bottom pb-2">
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                <label className="label-size">Unit Name</label>
                <input
                  type="text"
                  className="form-control unit-input"
                  placeholder="Unit Name"
                  value={propertiesForm.unitName}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "unitName",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                  required
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                <label className="label-size">Market Rent (optional)</label>
                <input
                  type="number"
                  min="0"
                  className="form-control unit-input"
                  placeholder="£0.00"
                  value={propertiesForm.marketRent}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "marketRent",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                <label className="label-size">Size (optional)</label>
                <input
                  type="number"
                  min="0"
                  className="form-control unit-input"
                  placeholder="sq. ft."
                  value={propertiesForm.size}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "size",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>

              <div className="col-sm-12 col-md-12 col-lg-3 mb-1 ">
                <label>Images </label>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  accept=".jpg, .jpeg, .png, .gif"
                  onChange={(e) => {
                    if (e.currentTarget.files[0]) {
                      setDocument([...document, e.currentTarget.files[0]]);
                    }
                  }}
                />
              </div>

              {document?.length > 0 && (
                <div className="row">
                  {document?.map((image, index) => {
                    return (
                      <div className="col-sm-12 col-md-6 col-lg-3 mb-1 mt-2 ">
                        {typeof image === "string" ? (
                          <div className=" position-relative">
                            <img
                              className="w-100 image-object"
                              src={image}
                              alt="Selected Image Preview"
                            />
                            <button
                              className="img-del-btn mt-5"
                              style={{}}
                              onClick={() => {
                                openImageModal(index);
                              }}
                            >
                              x
                            </button>
                          </div>
                        ) : (
                          <div className=" position-relative">
                            <img
                              className="w-100 image-object"
                              src={URL.createObjectURL(image)}
                              alt="Selected Image Preview"
                            />
                            <button
                              className="img-del-btn mt-5"
                              style={{}}
                              onClick={(e) => {
                                e.preventDefault();
                                openImageModal(index);
                              }}
                            >
                              x
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>

          <div className="mt-3">
            <p className="unit-heading">What is the street address?</p>
            <div className="row border-bottom pb-2">
              <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
                <label className="label-size">Street Address</label>
                <input
                  type="text"
                  className="form-control unit-input"
                  placeholder=""
                  disabled
                  value={propertiesForm.address}
                  //   onChange={(e) => {
                  //     dispatch(
                  //       changePropertiesForm({
                  //         edited_field: "address",
                  //         edited_value: e.target.value,
                  //       })
                  //     );
                  //   }}
                />
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                  <label className="label-size">City</label>
                  <input
                    type="text"
                    className="form-control unit-input"
                    placeholder="City"
                    disabled
                    value={propertiesForm.city}
                  />
                </div>

                <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                  <label className="label-size">Post Code</label>
                  <input
                    type="text"
                    className="form-control unit-input"
                    placeholder="Post code"
                    disabled
                    value={propertiesForm.zip}
                  />
                </div>
                <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                  <label className="label-size">Country</label>
                  <input
                    type="text"
                    className="form-control unit-input"
                    placeholder="Country"
                    value={propertiesForm.country}
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-3">
            <p className="unit-heading">What is the listing information?</p>
            <div className="row ">
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3">
                <label className="label-size">Beds</label>
                <select
                  className="form-select unit-input"
                  aria-label="Default select example"
                  value={propertiesForm.unitBeds}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "unitBeds",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                  required
                >
                  <option value="">Select an option</option>
                  <option value={1}>1 Bed</option>
                  <option value={2}>2 Bed</option>
                  <option value={3}>3 Bed</option>
                  <option value={4}>4 Bed</option>
                  <option value={5}>5 Bed</option>
                  <option value={6}>6 Bed</option>
                  <option value={7}>7 Bed</option>
                  <option value={8}>8 Bed</option>
                  <option value={9}>9+ Bed</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3">
                <label className="label-size">Bathrooms</label>
                <select
                  className="form-select unit-input"
                  aria-label="Default select example"
                  value={propertiesForm.unitBathrooms}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "unitBathrooms",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                  required
                >
                  <option value="">Select an option</option>
                  <option value={1}>1 Bath</option>
                  <option value={1.5}>1.5 Bath</option>
                  <option value={2}>2 Bath</option>
                  <option value={2.5}>2.5 Bath</option>
                  <option value={3}>3 Bath</option>
                  <option value={3.5}>3.5 Bath</option>
                  <option value={4}>4 Bath</option>
                  <option value={4.5}>4.5 Bath</option>
                  <option value={5}>5 Bath</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-2 mb-3">
                <label className="label-size">Available Date</label>
                <input
                  type="date"
                  className="form-control unit-input"
                  placeholder="dd/mm/yyyy"
                  name="available"
                  value={available}
                  onChange={(e) => setavailable(e.target.value)}
                />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                <label className="label-size">Minimum Tenancy Duration</label>
                <select
                  className="form-select unit-input"
                  aria-label="Default select example"
                  name="minimumTenancyDuration"
                  value={minimumTenancyDuration}
                  onChange={(e) => setminimumTenancyDuration(e.target.value)}
                >
                  <option value={""}>Select an option</option>
                  <option value={6}>6 Months</option>
                  <option value={12}>12 Months</option>
                  <option value={24}>24 Months</option>
                </select>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3 mb-3">
                <label className="label-size">
                  Is the property suitable for students?
                </label>
                <div className="mt-1">
                  <Switch
                    name="forStudent"
                    checked={forStudent}
                    onChange={(event) => setforStudent(!forStudent)}
                    slotProps={{
                      track: {
                        children: (
                          <React.Fragment>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ ml: "5px", fontSize: "0.7rem" }}
                            >
                              Yes
                            </Typography>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ mr: "5px", fontSize: "0.7rem" }}
                            >
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      "--Switch-thumbSize": "20px",
                      "--Switch-trackWidth": "50px",
                      "--Switch-trackHeight": "23px",
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6 mb-3">
              <label className="label-size">Description (optional)</label>
              <textarea
                type="text"
                className="form-control "
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder=""
                value={propertiesForm.description}
                onChange={(e) => {
                  dispatch(
                    changePropertiesForm({
                      edited_field: "description",
                      edited_value: e.target.value,
                    })
                  );
                }}
              />
            </div>
            {Object.keys(selectedAmenitiesData).length === 0 && (
              <button
                type="button"
                className="add-amenities-btn"
                onClick={() => {
                  setAmenitiesData(true);
                }}
              >
                Add Amenities
              </button>
            )}

            {selectedAmenitiesData &&
              Object.keys(selectedAmenitiesData).length > 0 && (
                <div>
                  <label className="label-size">Amenities</label>
                  <div className="form-border p-3 mt-1">
                    <div className="row ">
                      {Object.keys(selectedAmenitiesData).map((item) => {
                        return (
                          <div className=" block-width mb-2 ">
                            <p
                              className="amenities-heading"
                              style={{
                                textTransform: "capitalize",
                                fontSize: "14px",
                              }}
                            >
                              <AiOutlinePlus /> {item}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div className=" mt-3 mb-3">
            <ButtonLoader
              type="submit"
              className="btn form-btn grow "
              buttonName={"Create Unit"}
              isLoading={isloading}
              loaderColor={"white"}
            ></ButtonLoader>
            <button
              type="button"
              onClick={() => {
                clearForm();
              }}
              className="btn form-btn2 grow"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
      {deleteModal && (
        <DeleteModal
          image={deleteImage}
          text={"image"}
          closeModal={(val) => {
            setDeleteModal(val);
          }}
          handleDelete={(image) => {
            handleRemoveImage(image);
          }}
        />
      )}
      {amenitiesData && (
        <div className="popup-overlay">
          <div className="amenities-popup" style={{ maxWidth: "1000px" }}>
            <div className="p-3">
              <AddUnitAmenities
                onClose={() => {
                  setAmenitiesData(false);
                }}
                selectedData={(val) => {
                  setSelectedAmenitiesData(val);
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AddUnits;
