import {createSlice } from "@reduxjs/toolkit";
import {addLandlordAsync, editLandlordAsync, deleteLandlordAsync, landlordListAsync, allLandlordListAsync} from "./Services"
import _ from "lodash";

const landlordSlice = createSlice({
  name: "landlordSlice",
  initialState: {
    loading: false,
    landlordForm: {},
    landlordList: [],
    allLandlordList: [],
  },
  reducers: {
    changeLandlordForm:(state, action) => {
      let update_form = _.cloneDeep(state.landlordForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        landlordForm:{
          ...state.landlordForm,
          ...update_form,
        }
      }
    },
    clearLandlordForm: (state, action) => {
      state.landlordForm = {};
    },
  },
  extraReducers: {
    [addLandlordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addLandlordAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addLandlordAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editLandlordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editLandlordAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editLandlordAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteLandlordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteLandlordAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteLandlordAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [landlordListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [landlordListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.landlordList = action?.payload.data;
    //   state.program = action.payload;
    },
    [landlordListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allLandlordListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allLandlordListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allLandlordList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allLandlordListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default landlordSlice.reducer;

export const {
  changeLandlordForm,
  clearLandlordForm,
} = landlordSlice.actions;
