import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePropertiesForm } from 'redux/properties/PropertiesSlice';
import Checkboxes from 'components/form/Checkbox';
import { useState } from 'react';
import Axios from 'utils/axios';
import { useEffect } from 'react';

const amenitiesBathroomInputs = [


    {
        title: "Bath",
        summary: "",
        name: "Bath"
    },
    {
        title: "Dryer",
        summary: "",
        name: "dryer"
    },
    {
        title: "Hair dryer",
        summary: "",
        name: "hair-dryer"
    },
    {
        title: "Bidet",
        summary: "",
        name: "bidet"
    },
    {
        title: "Body soap",
        summary: "",
        name: "body-soap"
    },
    {
        title: "Conditioner",
        summary: "",
        name: "conditioner"
    },
    {
        title: "Hot water",
        summary: "pots and pans, oil, salt and pepper.",
        name: "hot-water"
    },
    {
        title: "Outdoor shower",
        summary: "",
        name: "outdoor-shower"
    },
    {
        title: "Shampoo",
        summary: "",
        name: "shampoo"
    },
    {
        title: "Shower gel",
        summary: "",
        name: "shower-gel"
    },
    {
        title: "Washing machine",
        summary: "Free - in building.",
        name: "washing-machine"
    },
    {
        title: "Hot tub",
        summary: "",
        name: "hot-tub"
    },
    {
        title: "Cleaning products",
        summary: "",
        name: "cleaning-products"
    },

    {
        title: "Pool",
        summary: "",
        name: "pool"
    },


]

const amenitiesBedroomInputs = [
    {
        title: "Essential",
        summary: "towel, bedsheet, toilet paper, soap and pillow.",
        name: "essential"
    },
    {
        title: "Bed linen",
        summary: "Cotton",
        name: "bed-linen"
    },
    {
        title: "Clothes storage",
        summary: "Wardrobe",
        name: "clothes-storage"
    },
    {
        title: "Clothes dryer rack",
        summary: "",
        name: "clothes-dryer-rack"
    },
    {
        title: "Extra pillow and blankets",
        summary: "Space where guests can ccok their own meals.",
        name: "extra-pillow-blanckets"
    },
    {
        title: "Hangers",
        summary: "",
        name: "hangers"
    },
    {
        title: "Iron",
        summary: "",
        name: "iron"
    },
    {
        title: "Mosquito net",
        summary: "",
        name: "mosquito net"
    },
    {
        title: "Room-darkening shade",
        summary: "Available throughout the listing.",
        name: "room-darkening-shade"
    },
    {
        title: "Safe",
        summary: "",
        name: "safe"
    },

]

const amenitiesEntertaimentInputs = [
    {
        title: "Arcade games",
        summary: "",
        name: "arcade-games"
    },
    {
        title: "Batting cage",
        summary: "",
        name: "batting-cage"
    },
    {
        title: "TV",
        summary: "",
        name: "TV"
    },
    {
        title: "Books and reading material",
        summary: "",
        name: "books-and-reading-material"
    },
    {
        title: "Bowling alley",
        summary: "",
        name: "bolling-alley"
    },
    {
        title: "Climbing wall",
        summary: "",
        name: "climbing-wall"
    },
    {
        title: "Ethernet Connection",
        summary: "",
        name: "ethernet-connection"
    },
    {
        title: "Exercise Equipment",
        summary: "",
        name: "excercise-equipment"
    },
    {
        title: "Games Console",
        summary: "",
        name: "games-console"
    },
    {
        title: "Laser tag",
        summary: "",
        name: "laser-tag"
    },
    {
        title: "Life-size games",
        summary: "",
        name: "life-size-games"
    },
    {
        title: "Mini golf",
        summary: "",
        name: "mini-golf"
    },
    {
        title: "Cinema",
        summary: "",
        name: "cinema"
    },
    {
        title: "Piano",
        summary: "",
        name: "piano"
    },
    {
        title: "Ping pong table",
        summary: "",
        name: "ping-pong-table"
    },
    {
        title: "Pool table",
        summary: "",
        name: "pool-table"
    },
    {
        title: "Record player",
        summary: "",
        name: "record-player"
    },
    {
        title: "Skate ramp",
        summary: "",
        name: "skate-ramp"
    },
    {
        title: "Sound system",
        summary: "",
        name: "sound-system"
    },
    {
        title: "Theme room",
        summary: "",
        name: "theme-room"
    },

]

const amenitiesFamiltInputs = [


    {
        title: "Baby Bath",
        summary: "",
        name: "baby-bath"
    },
    {
        title: "Baby monitor",
        summary: "",
        name: "baby-monitor"
    },
    {
        title: "Children's room",
        summary: " a indoor room with toys, books and games.",
        name: "Childrens-room"
    },
    {
        title: "Baby safety gates",
        summary: "",
        name: "baby-safety-gates"
    },
    {
        title: "Babysitter recommendations",
        summary: "",
        name: "babysitter-recommendations"
    },
    {
        title: "Board games",
        summary: "",
        name: "board-games"
    },
    {
        title: "Changing tables",
        summary: "",
        name: "changing-tables"
    },
    {
        title: "Children's books and toys",
        summary: "",
        name: "childrens-books-and-toys"
    },
    {
        title: "Children's tableware",
        summary: "",
        name: "childrens-tableware"
    },
    {
        title: "Cot",
        summary: "",
        name: "cot"
    },
    {
        title: "Fireplace guards",
        summary: "",
        name: "fireplace-guards"
    },
    {
        title: "High chair",
        summary: "",
        name: "high-chair"
    },
    {
        title: "Outdoor Playground",
        summary: "A outdoor area equipped with a play structure for children.",
        name: "outdoor-playground"
    },
    {
        title: "Plug socket covers",
        summary: "",
        name: "plug-socket-covers"
    },
    {
        title: "Travel Cot",
        summary: "",
        name: "travel-cot"
    },
    {
        title: "Table corner guards",
        summary: "",
        name: "table-corner-guards"
    },
    {
        title: "Window guards",
        summary: "",
        name: "window-guards"
    },

]

const amenitiesHeatingInputs = [
    {
        title: "Air conditioning",
        summary: "",
        name: "air-conditioning"
    },
    {
        title: "Heating",
        summary: "",
        name: "heating"
    },
    {
        title: "Ceiling fan",
        summary: "",
        name: "ceiling-fan"
    },
    {
        title: "Portable fans",
        summary: "",
        name: "portable-fans"
    },
    {
        title: "Indoor fireplace",
        summary: "",
        name: "indoor-fireplace"
    },
]

const amenitiesHomeSafety = [
    {
        title: "Carbon monoxide alarm",
        summary: "",
        name: "carbon-monoxide-alrm"
    },
    {
        title: "Fire extinguisher",
        summary: "",
        name: "fire-extinguisher"
    },
    {
        title: "First aid kit",
        summary: "",
        name: "first-air kid"
    },
    {
        title: "Smoke Alarm",
        summary: "",
        name: "smoke-alarm"
    },
]

const amenitiesKitchen = [
    {
        title: "Kitchen",
        summary: "Space where guests can ccok their own meals.",
        name: "kitchen"
    },
    {
        title: "Cooking basics",
        summary: "pots and pans, oil, salt and pepper.",
        name: "cooking-basics"
    },

    {
        title: "Dishes and silverware",
        summary: "Bowls, chopsticks, plates, cups etc.",
        name: "dishes-silverware"
    },
    {
        title: "Baking sheet",
        summary: "",
        name: "baking-sheet"
    },
    {
        title: "Barbecue utensils",
        summary: "Grill, charcoal, bamboo skewers/iron skewer, etc. ",
        name: "barbecue-utensils"
    },
    {
        title: "Bread maker",
        summary: "",
        name: "bread-maker"
    },
    {
        title: "Blender",
        summary: "",
        name: "blender"
    },
    {
        title: "Coffee",
        summary: "",
        name: "Coffee"
    },
    {
        title: "Coffee maker",
        summary: "",
        name: "Coffee-maker"
    },
    {
        title: "Dining table",
        summary: "",
        name: "dining-table"
    },
    {
        title: "Dishwasher",
        summary: "",
        name: "dishwasher"
    },
    {
        title: "Freezer",
        summary: "",
        name: "freezer"
    },
    {
        title: "Kettle",
        summary: "",
        name: "kettle"
    },
    {
        title: "Kitchenette",
        summary: "",
        name: "kitchenette"
    },
    {
        title: "Microwave",
        summary: "",
        name: "microwave"
    },
    {
        title: "Mini fridge",
        summary: "",
        name: "mini-fridge"
    },
    {
        title: "Oven",
        summary: "",
        name: "oven"
    },
    {
        title: "Refrigerator",
        summary: "",
        name: "refrigerator"
    },
    {
        title: "Rice cooker",
        summary: "",
        name: "rice-cooker"
    },
    {
        title: "Stove",
        summary: "",
        name: "stove"
    },
    {
        title: "Toaster",
        summary: "",
        name: "toaster"
    },
    {
        title: "Waste Compactor",
        summary: "",
        name: "waste-compactor"
    },
    {
        title: "Wine glasses",
        summary: "",
        name: "wine-glasses"
    },
]

const amenitiesLocationInputs = [
    {
        title: "Beach access",
        summary: "Guest can enjoy a nearby beach.",
        name: "beach-access"
    },
    {
        title: "Lake access",
        summary: "guest can get to a lake using a path or dock.",
        name: "lake-access"
    },
    {
        title: "Launderette nearby",
        summary: "",
        name: "launderette-nearby"
    },
    {
        title: "Private entrance",
        summary: "Separate street or building",
        name: "private-entrance"
    },
    {
        title: "Resort access",
        summary: "Guest can use a nearby resort facilities.",
        name: "resort-access"
    },
    {
        title: "Sky-in/Sky-out",
        summary: "Guests can access sky lifts without driving or taking paid transport.",
        name: "sky-in-sky-out"
    },
    {
        title: "Water front",
        summary: "Right next to a body of water.",
        name: "water-front"
    },
    {
        title: "Garden",
        summary: "A open space on the property usually covered in grass.",
        name: "garden"
    },
    {
        title: "BBQ grill",
        summary: "",
        name: "bbq-grill"
    },
    {
        title: "Beach essentials",
        summary: "Beach towel, umbrella, beach blanket, snorkelling gear.",
        name: "beach-essentials"
    },
    {
        title: "Bikes",
        summary: "",
        name: "bikes"
    },
    {
        title: "Boat berth",
        summary: "",
        name: "boat-berth"
    },
    {
        title: "Fire pit",
        summary: "",
        name: "fire-pit"
    },
    {
        title: "Hammok",
        summary: "",
        name: "hammok"
    },
    {
        title: "Kayak",
        summary: "",
        name: "kayak"
    },
    {
        title: "Outdoor dining area",
        summary: "",
        name: "outdoor-dining-area"
    },
    {
        title: "Outdoor furniture",
        summary: "",
        name: "outdoor-furniture"
    },
    {
        title: "Outdoor kitchen",
        summary: "",
        name: "outdoor-kitchen"
    },
    {
        title: "Patio or balcony",
        summary: "",
        name: "patio-or-balcony"
    },
    {
        title: "Sun loungers",
        summary: "",
        name: "sun-loungers"
    },
]

const amenitiesParkingInputs = [
    {
        title: "Lift",
        summary: "",
        name: "lift"
    },
    {
        title: "EV charger",
        summary: "",
        name: "ev-charger"
    },
    {
        title: "Free parking on premises",
        summary: "",
        name: "free-parking-on-premises"
    },
    {
        title: "Hockey rink",
        summary: "",
        name: "hockey-rink"
    },
    {
        title: "Free on street-parking",
        summary: "",
        name: "free-on-street-parking"
    },
    {
        title: "Gym",
        summary: "",
        name: "gym"
    },
    {
        title: "Paid parking off premises",
        summary: "",
        name: "paid-parking-off-premises"
    },
    {
        title: "Paid parking on premises",
        summary: "",
        name: "paid-parking-on-premises"
    },
    {
        title: "Private living room",
        summary: "",
        name: "private-parking-room"
    },
    {
        title: "Sauna",
        summary: "",
        name: "sauna"
    },
    {
        title: "Breakfast",
        summary: "breakfast is provided.",
        name: "breakfast"
    },
    {
        title: "Cleaning available during stay",
        summary: "",
        name: "cleaning-available"
    },
    {
        title: "Long-term stays allowed",
        summary: "",
        name: "long-term-stays-allowed"
    },
    {
        title: "Luggage drop-off allowed",
        summary: "",
        name: "luggage-drop-off-allowed"
    }
]

const amenitiesInternet = [
    {
        title: "Dedicated WorkSpace",
        summary: "In a common space.",
        name: "dedicated-workspace"
    },
    {
        title: "Wifi",
        summary: "Available throughout the listing.",
        name: "wifi"
    },
    {
        title: "Pocket wifi",
        summary: "",
        name: "pocket-wifi"
    },
]



function AddAmenities({ handleForm, amenitiesData, handleTabSubmit }) {

    const dispatch = useDispatch();
    const { propertiesForm } = useSelector((state) => {
        const { propertyReducer } = state;

        return { propertiesForm: propertyReducer.propertiesForm, };
    });


    const [amenitiesData2, setAmenitiesData2] = useState(amenitiesData);


    const handleInputChange = (name) => {

        let updatedAmenitiesData = ({
            ...amenitiesData2,
            [name]: !amenitiesData2[name]
        });

        const selectedAmenities = Object.keys(updatedAmenitiesData).filter(key => updatedAmenitiesData[key] === true);
        const selectedAmenitiesObject = selectedAmenities.reduce((acc, amenity) => {
            acc[amenity] = true;
            return acc;
        }, {});

        setAmenitiesData2(selectedAmenitiesObject);

        dispatch(changePropertiesForm({ edited_field: "amenities", edited_value: selectedAmenitiesObject }));
    };


    // Function to update amenitiesData2 based on propertiesForm.amenities
    const updateAmenitiesData2 = (amenities) => {
        const updatedAmenitiesData2 = { ...amenitiesData2 };

        // Loop through propertiesForm.amenities and set corresponding keys to true
        for (const key in amenities) {
            if (amenities.hasOwnProperty(key)) {
                updatedAmenitiesData2[key] = amenities[key];
            }
        }

        setAmenitiesData2(updatedAmenitiesData2);
    };

    // Call the updateAmenitiesData2 function when propertiesForm.amenities changes
    useEffect(() => {
        updateAmenitiesData2(propertiesForm.amenities);
    }, [propertiesForm.amenities]);

    const handleSubmit = async (e) => {

        try {
            const payload = {

                property_id: propertiesForm.property_id,
                amenities: propertiesForm.amenities
            };
            const response = await Axios.post('/properties/addAmenities', payload);


            if (response.data?.success) {
                handleForm(5);
                handleTabSubmit(5);
            }
            else {
                alert(response.error);
            }
        } catch (error) {
            // toast.error("Program details could not be fetched, please try again!");
            alert(error);
        }
    };


    return (
        <div className=" mt-3 pt-3 ">
            <div className='form-border v-form-border p-3'>
                <div className=' '>
                    <h5 className='popup-heading mb-0'>Add Amenities</h5>
                    {/* <p className='popup-summary'>Here you need to fill your bank details for generating invoices.</p> */}
                </div>
                <div className=''>
                    <div className='amenities-list'>
                        <p className='mt-2 amenities-category mb-3'>Bedroom and laundry</p>
                        <div className='row'>
                            {amenitiesBedroomInputs && amenitiesBedroomInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Bathroom</p>
                        <div className='row'>
                            {amenitiesBathroomInputs && amenitiesBathroomInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Kitchen and dining</p>
                        <div className='row'>
                            {amenitiesKitchen && amenitiesKitchen.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Entertainment</p>
                        <div className='row'>
                            {amenitiesEntertaimentInputs && amenitiesEntertaimentInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Family</p>
                        <div className='row'>
                            {amenitiesFamiltInputs && amenitiesFamiltInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Heating and Cooling</p>
                        <div className='row'>
                            {amenitiesHeatingInputs && amenitiesHeatingInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Home safety</p>
                        <div className='row'>
                            {amenitiesHomeSafety && amenitiesHomeSafety.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Location features</p>
                        <div className='row'>
                            {amenitiesLocationInputs && amenitiesLocationInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Parking and facilities</p>
                        <div className='row'>
                            {amenitiesParkingInputs && amenitiesParkingInputs.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                        <p className='mt-3 amenities-category mb-3'>Internet and office</p>
                        <div className='row'>
                            {amenitiesInternet && amenitiesInternet.map((item) => {
                                return <div className='col-12 col-6 col-lg-3 mb-2'>
                                    <div className='d-flex align-items-center'>
                                        <Checkboxes
                                            customClass={'amenities-checked'}
                                            value={amenitiesData2[`${item.name}`]}
                                            onChange={() => handleInputChange(item.name)}
                                        />
                                        <label className='input-heading check-label'>{item.title}</label>
                                    </div>
                                </div>
                            })}
                        </div>

                    </div>
                    <div className='mt-3 d-flex justify-content-end'>
                        <button className='gray-btn grow ' onClick={() => { handleForm(3) }}>Back</button>
                        <button type='submit' className='primary-btn grow  ml-3' onClick={() => { handleSubmit() }} >Next</button>
                    </div>

                </div>
            </div>

        </div>


    );
}

export default AddAmenities;