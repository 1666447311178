import axios from 'axios';
import { NEW_API_BASE_URL } from '../constants/constants';
import store from '../redux/Store';


const Axios = axios.create({
  baseURL: NEW_API_BASE_URL,
  params: {
    apporigin: 'web'
  },
  headers: {
    source: 'web'
  }
});

// Adding authorization token in request header
Axios.interceptors.request.use((config) => {
  const state = store.getState(); // Getting the current state
  const token = state.auth.token; // get token from state
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

Axios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data.error) || 'Something went wrong')
);

export default Axios;