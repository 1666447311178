import {createSlice } from "@reduxjs/toolkit";
import {searchAsync} from "./Services"
import _ from "lodash";

const searchSlice = createSlice({
  name: "searchSlice",
  initialState: {
    loading: false,
    searchForm: {},
    searchList: []
  },
  reducers: {
    changeSearchForm:(state, action) => {
      let update_form = _.cloneDeep(state.searchForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        searchForm:{
          ...state.searchForm,
          ...update_form,
        }
      }
    },
    clearSearchForm: (state, action) => {
      state.searchForm = {};
    },
  },
  extraReducers: {
    [searchAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [searchAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.searchList = action?.payload.data;
      
    //   state.program = action.payload;
    
    },
    [searchAsync.rejected]: (state, action) => {
      state.loading = false;
    },

   
  },
});

export default searchSlice.reducer;

export const {
  changeSearchForm,
  clearSearchForm,
} = searchSlice.actions;
