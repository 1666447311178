import { BsCalendarMonth, BsJournalBookmark } from "react-icons/bs";
import {
  AiOutlineFileSearch,
  AiOutlineHome,
  AiOutlineMessage,
  AiOutlineUser,
} from "react-icons/ai";
import {
  MdOutlineAccountCircle,
  MdOutlineCalendarMonth,
  MdOutlineFileCopy,
} from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";

import { Roles } from "../../utils/constants/roles";
import { FaTasks } from "react-icons/fa";
import { HiOutlineUsers } from "react-icons/hi";
import { PiCurrencyGbp } from "react-icons/pi";
import { BiSupport } from "react-icons/bi";

const generateSLNavArr = (userObj) => {
  let SLNavArr = [];

  // let role = 'admin';
  let role = userObj && userObj.userType ? userObj.currentType : null;

  if (role === Roles.SL_ADMIN) {
    SLNavArr.push(
      {
        path: "properties/listing",
        name: "Properties",
        icon: <AiOutlineHome />,
        subRoutes: [
          {
            path: "properties/listing",
            name: "Properties ",
            icon: <AiOutlineHome />,
          },
        ],
      },
      {
        path: "users/all-users",
        name: "Users",
        icon: <AiOutlineUser />,
        subRoutes: [
          {
            path: "users/all-users",
            name: "Users",
            icon: <AiOutlineUser />,
          },
        ],
      },
      {
        path: "support",
        name: "Support system",
        icon: <BiSupport />,
        subRoutes: [
          {
            path: "support",
            name: "Support system",
            icon: <BiSupport />,
          },
        ],
      }
    );
  }

  if (
    role === Roles.SUPER_ADMIN ||
    role === Roles.SL_SML ||
    role === Roles.SL_FRANCHISER
  ) {
    SLNavArr
      .push

      // {
      //   path: "hosts",
      //   name: "Property Owners",
      //   icon: <HiOutlineUserGroup />,
      //   subRoutes: [
      //     {
      //       path: "hosts",
      //       name: "Property Owners",
      //       icon: <HiOutlineUserGroup />,
      //     },
      //   ],
      // }
      ();
  }

  if (
    role === Roles.SUPER_ADMIN ||
    role === Roles.SL_SML ||
    role === Roles.SL_FRANCHISER ||
    role === Roles.SL_LANDLORD
  ) {
    SLNavArr.push(
      // {
      //   path: "my-profile",
      //   name: "My Account",
      //   icon: <MdOutlineAccountCircle />,
      // },
      {
        path: "my-profile",
        name: "My Account",
        icon: <MdOutlineAccountCircle />,
        subRoutes: [
          {
            path: "my-profile",
            name: "My Account",
            icon: <MdOutlineAccountCircle />,
          },
        ],
      },
      {
        path: "properties/listing",
        name: "Properties",
        icon: <AiOutlineHome />,
        subRoutes: [
          {
            path: "properties/listing",
            name: "Properties ",
            icon: <AiOutlineHome />,
          },
        ],
      },
      {
        path: "all-bookings",
        name: "All Bookings ",
        icon: <MdOutlineCalendarMonth />,
        subRoutes: [
          {
            path: "all-Bookings",
            name: "All Bookings ",
            icon: <PiCurrencyGbp />,
          },
        ],
      },
      {
        path: "calendar",
        name: "Calendar",
        icon: <BsCalendarMonth />,
        subRoutes: [
          {
            path: "calendar",
            name: "Calendar",
            icon: <BsCalendarMonth />,
          },
        ],
      },
      {
        path: "tasks/all-tasks",
        name: "Tasks",
        icon: <FaTasks />,
        subRoutes: [
          {
            path: "tasks/all-tasks",
            name: "Tasks ",
            icon: <FaTasks />,
          },
        ],
      },
      {
        path: "transactions",
        name: "Transactions",
        icon: <FaTasks />,
        subRoutes: [
          {
            path: "transactions",
            name: "Transactions",
            icon: <GiMoneyStack />,
          },
        ],
      },
      {
        path: "analytics",
        name: "Analytics",
        icon: <GiMoneyStack />,
        subRoutes: [
          {
            path: "analytics",
            name: "Analytics ",
            icon: <GiMoneyStack />,
          },
        ],
      },
      {
        path: "messages",
        name: "Messages",
        icon: <AiOutlineMessage />,
        subRoutes: [
          {
            path: "messages",
            name: "Messages ",
            icon: <BsJournalBookmark />,
          },
        ],
      },
      // {
      //   path: "doumenatation-and-training",
      //   name: "Documentations & Training",
      //   icon: <BsJournalBookmark />,
      //   subRoutes: [
      //     {
      //       path: "doumenatation-and-training",
      //       name: "Documentations & Training",
      //       icon: <BsJournalBookmark />,
      //     },
      //   ],
      // },
      {
        path: "maintenance",
        name: "Maintenance",
        icon: <AiOutlineFileSearch />,
        subRoutes: [
          {
            path: "maintenance",
            name: "Maintenance",
            icon: <HiOutlineUsers />,
          },
        ],
      },
      {
        path: "reports",
        name: "Reports",
        icon: <MdOutlineFileCopy />,
        subRoutes: [
          {
            path: "reports",
            name: "Reports",
            icon: <MdOutlineFileCopy />,
          },
        ],
      },
      {
        path: "reduce-expence",
        name: "Reduce Expence ",
        icon: <PiCurrencyGbp />,
        subRoutes: [
          {
            path: "reduce-expence",
            name: "Reduce Expence ",
            icon: <PiCurrencyGbp />,
          },
        ],
      }
    );
  }

  if (role === Roles.SUPER_ADMIN || role === Roles.SL_SML) {
    SLNavArr.push({
      path: "users/listing",
      name: "Franchisors Login",
      icon: <AiOutlineUser />,
      subRoutes: [
        {
          path: "users/listing",
          name: "Franchisors Login",
          icon: <AiOutlineUser />,
        },
      ],
    });
  }

  return SLNavArr;
};

export default generateSLNavArr;
