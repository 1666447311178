import * as React from 'react';
import Radio from '@mui/material/Radio';

const RadioWithBorder = ({ onChange, value, checked, customClass, id, label, parentClass, subHeading }) => {

    return (

        <label
            htmlFor={id}
            className={`${parentClass} outlined-radio ${checked === value ? "outlined-radio--selected" : ""}`}
            style={{ cursor: "pointer" }}
        >
            <Radio
                className={customClass}
                checked={checked === value}
                onChange={onChange}
                value={value}
                id={id ? id : ""}
            />
            <div style={{ marginLeft: "10px" }}>
                <p   >{label}</p>
                {subHeading && <p style={{ fontSize: "14px", color: "darkgray", }} >{subHeading}</p>}
            </div>

        </label>
    );
}

export default RadioWithBorder;