import TextInput from "components/form/TextInput";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import SelectInput from "components/form/SelectInput";
import { Box, Grid } from "@mui/material";
import useStaticData from "./hooks/useStaticData";

const AddPayment = ({ closeModal, buttonText, handleData }) => {
  const [isLoading, setIsLoading] = React.useState(false);

  const { paymentPeriod, paymentTypes } = useStaticData();

  const formik = useFormik({
    initialValues: {
      paymentAmount: "",
      paymentType: "",
      paymentPeriod: "",
      paymentDue: "",
      paymentRecurring: "",
    },
    validationSchema: Yup.object().shape({
      paymentAmount: Yup.string().required("Please enter account holder name."),
      paymentType: Yup.string().required("Please eselect payment type"),
      paymentPeriod: Yup.string().required("Please select payment period."),
      paymentDue: Yup.string().required("Please select due date"),
      // paymentRecurring: Yup.string()
      // .when('paymentPeriod', {
      //   is: (value) => value === 'One off payment',
      //   then: () => Yup.string().required('Please fill this field when payment period is "off"'),
      // }),
    }),
    onSubmit: (values) => {
      handleData(values);
      closeModal();
    },
  });

  return (
    <>
      <div className="p-4 modal-h overflow-y">
        <div className="pt-4 pb-3">
          <Box sx={{ flexGrow: 1 }} className="d-grid gap-3">
            <Grid
              container
              spacing={2}
              className="payment_form__wrapper"
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className=""
                style={{ paddingTop: "0px" }}
              >
                <h5 className="">What is the payment amount?</h5>
                <p>
                  If you are creating a quarterly payment, please enter the
                  amount for an entire quarter.
                </p>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                className=""
                style={{ paddingTop: "0px" }}
              >
                <TextInput
                  customClass={"w-100 "}
                  type={"number"}
                  name={"paymentAmount"}
                  value={formik.values.paymentAmount}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  placeholder={"Amount"}
                  min={0}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.paymentAmount && formik.errors.paymentAmount
                  }
                >
                  {formik.errors.paymentAmount}
                </ErrorMsg>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="payment_form__wrapper m-0 w-100"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">What is the payment type?</h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <SelectInput
                  customClass={"w-100 payment_form__wrapper2 "}
                  options={paymentTypes}
                  selectedValue={formik.values.paymentType}
                  onChange={(e) => {
                    formik.setFieldValue("paymentType", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.paymentType && formik.errors.paymentType
                  }
                >
                  {formik.errors.paymentType}
                </ErrorMsg>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="payment_form__wrapper m-0 w-100"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">
                  How often do you want to collect the payment?
                </h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <SelectInput
                  customClass={"w-100 payment_form__wrapper2"}
                  options={paymentPeriod}
                  selectedValue={formik.values.paymentPeriod}
                  onChange={(e) => {
                    formik.setFieldValue("paymentPeriod", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.paymentPeriod && formik.errors.paymentPeriod
                  }
                >
                  {formik.errors.paymentPeriod}
                </ErrorMsg>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="payment_form__wrapper m-0 w-100"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">When is the payment due?</h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <TextInput
                  customClass={"w-100 "}
                  type={"date"}
                  name={"paymentDue"}
                  value={formik.values.paymentDue}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  placeholder={"Due date"}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.paymentDue && formik.errors.paymentDue
                  }
                >
                  {formik.errors.paymentDue}
                </ErrorMsg>
              </Grid>
            </Grid>
            {formik.values?.paymentPeriod && formik.values?.paymentPeriod !== "One off payment" && <Grid
              container
              spacing={2}
              className="payment_form__wrapper m-0 w-100"
            >
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <h5 className="small">
                  How many monthly payments do you want to create?
                </h5>
              </Grid>
              <Grid item xs={12} sm={12} md={6} className="p-0">
                <TextInput
                  customClass={"w-100 "}
                  type={"number"}
                  name={"paymentRecurring"}
                  value={formik.values.paymentRecurring}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                  min={0}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.paymentRecurring &&
                    formik.errors.paymentRecurring
                  }
                >
                  {formik.errors.paymentRecurring}
                </ErrorMsg>
              </Grid>
            </Grid>}
          </Box>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <div></div>
          <ButtonLoader
            buttonName={buttonText ? buttonText : "Save"}
            variant={"contained"}
            className={"add_new_btn grow mt-1"}
            // style={{width:"100%"}}
            onClickhandle={formik.handleSubmit}
            isLoading={isLoading}
            loaderColor={"yellow"}
          />
        </div>
      </div>
    </>
  );
};

export default AddPayment;
