import React from 'react';
import { Select, MenuItem } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const SelectInput = ({ customClass, options, selectedValue, onChange, label, onBlur, disabled, sx }) => {
    const menuStyle = { maxHeight: "400px",  };
    return (
        <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{label ? label : ""}</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValue}
                label={label ? label : ""}
                onChange={onChange}
                className={`${customClass}`}
                disabled={disabled}
                onBlur={onBlur}
                MenuProps={{ style: menuStyle }}
                sx={sx}
            >
                {options.map((option) => (
                    <MenuItem className='menu-item' value={option.value}>{option.label}</MenuItem>
                ))}
            </Select>
        </FormControl>


    );
};

export default SelectInput;
