import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';



export default function DataTable({ columns, rows, checkboxSelection,  pageSize, rowCount, onPageChange, onPageSizeChange  }) {
    return (


        <DataGrid
            // style={{ border: 'none' }}
            className='border-top'
            rows={rows}
            columns={columns}
            pageSize={10}
           

            checkboxSelection={checkboxSelection}
            getRowId={(row) => row._id}
        />
        

    );
}