import React from 'react';
import { Navigate } from 'react-router-dom';
// import useAuth from '../hooks/useAuth';

const Role = ({ children, roles }) => {

 // const { user } = useAuth();
  const user = {roles:['landlord', 'sml', 'franchise', 'sml-ll', 'tenant', 'Admin', 'sl-admin', 'll-admin']};
  
 
  if ((!roles || typeof roles === "undefined") || user.roles.includes('admin')){
    return (children);
  }

  const hasFound = roles.filter(elm => user.roles.includes(elm));

  return !hasFound.length ? <Navigate to="/nopermission" /> : children;
 
};

export default Role;

/*
LONG LETTING ROLES:
admin-ll
sml-ll
tanent

SHORT LETTING ROLES
admin-sl
sml
franchise
landlord

*/