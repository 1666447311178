import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePropertiesForm } from "redux/properties/PropertiesSlice";
import Textarea from "components/form/TextareaInput";
import Axios from "utils/axios";
import { useNavigate } from "react-router-dom";
import { Button, Form, Modal } from "react-bootstrap";
import { useState } from "react";
import Loader from "components/Loader";
import TextInput from "components/form/TextInput";
import { AiOutlineDownload } from "react-icons/ai";

function AddDescription({ handleForm }) {
  const userObj = useSelector((state) => state.auth.user);
  let user = localStorage.getItem("user");
  user = JSON.parse(user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const { propertiesForm } = useSelector((state) => {
    const { propertyReducer } = state;

    return { propertiesForm: propertyReducer.propertiesForm };
  });
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleDescription = async () => {
    try {
      const payload = {
        property_id: propertiesForm.property_id,
        listingDescription: propertiesForm.listingDescription,
        spaceDescription: propertiesForm.spaceDescription,
        guestAccessDescription: propertiesForm.guestAccessDescription,
        status: 1,
      };
      const response = await Axios.post("/properties/addDescription", payload);

      handleClose();
      if (response.data?.success) {
        navigate(-1);
      }
    } catch {
      alert("Error occured while saving the property.");
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };
  const handleFileChange1 = (e) => {
    const file = e.target.files[0];
    setSelectedFile1(file);
  };
  const handleFileChange2 = (e) => {
    const file = e.target.files[0];
    setSelectedFile2(file);
  };

  let handleSubmit = async (e) => {
    const formData = new FormData();

    if (!selectedFile || !selectedFile1 || !selectedFile2) {
      alert("please fill all images");
    } else {
      formData.append("property_id", propertiesForm.property_id);
      formData.append("paypal_id", propertiesForm.paypal_id);

      formData.append("images", selectedFile);
      formData.append("images", selectedFile1);
      formData.append("images", selectedFile2);

      try {
        setLoading(true);
        const resp = await Axios.post("/properties/addIdProof", formData);
        setLoading(false);
        if (resp.data?.success) {
          user.p_id = propertiesForm.paypal_id;
          localStorage.setItem("user", JSON.stringify(user));
          handleDescription();
        }
      } catch (error) {
        setLoading(false);
        alert("Error occured while uploading the certificates.");
      }
    }
  };

  const openInNewTab = (url) => {
    window.open(`${url}`, "_blank", "noopener,noreferrer");
  };
  // Function to handle the image upload
  // console.log("propertiesForm", propertiesForm);
  {
    // console.log("user.p_id", user.p_id);
  }
  return (
    <div className="mt-3 pt-3">
      <div className="form-border p-3">
        <div className="mb-3">
          <h5 className="popup-heading mb-0">Property Descriptions</h5>
          <p className="popup-summary">
            Fill the basic details of property to move forward.
          </p>
        </div>
        <div className="">
          <div className=" mt-1 form_input">
            <p className="input-heading">
              Listing Description<span className="asterik2"></span>
            </p>
            <div className="summary">
              Give guests a sense of what it’s like to stay at your place,
              including why they’ll love stayin there.
            </div>

            <Textarea
              customClass={"textarea-input"}
              rows={"5"}
              value={propertiesForm.listingDescription}
              onChange={(e) => {
                dispatch(
                  changePropertiesForm({
                    edited_field: "listingDescription",
                    edited_value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className=" mt-1 form_input">
            <p className="input-heading">
              The space<span className="asterik2"></span>
            </p>
            <div className="summary">
              Provide a general description of what the property and rooms are
              like so guests know what to expect.
            </div>

            <Textarea
              customClass={"textarea-input"}
              rows={"5"}
              value={propertiesForm.spaceDescription}
              onChange={(e) => {
                dispatch(
                  changePropertiesForm({
                    edited_field: "spaceDescription",
                    edited_value: e.target.value,
                  })
                );
              }}
            />
          </div>
          <div className=" mt-1 form_input">
            <p className="input-heading">
              Guest Access<span className="asterik2"></span>
            </p>
            <div className="summary">
              Let guests know which parts of the space they’ll be able to
              access.
            </div>

            <Textarea
              customClass={"textarea-input"}
              rows={"5"}
              value={propertiesForm.guestAccessDescription}
              onChange={(e) => {
                dispatch(
                  changePropertiesForm({
                    edited_field: "guestAccessDescription",
                    edited_value: e.target.value,
                  })
                );
              }}
            />
          </div>
        </div>

        {userObj?.currentType === "ll-admin" && (
          <>
            <div className="mt-5">
              <h5 className="popup-heading mb-0">
                Property verification documents
              </h5>
              <p className="popup-summary"></p>
            </div>

            {propertiesForm?.verifiedProperty.length > 0 && (
              <div className="row mb-5">
                <div className="col-12 col-md-4 col-lg-4 mt-4 form_input">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="input-heading">Address proof</p>
                    {propertiesForm?.verifiedProperty[0]?.addressProof && (
                      <a
                        href={propertiesForm?.verifiedProperty[0]?.addressProof}
                        style={{ fontSize: "14px" }}
                        download
                      >
                        Download <AiOutlineDownload size={22} />
                      </a>
                    )}
                  </div>

                  <div className="col-12  form_input">
                    {propertiesForm?.verifiedProperty[0]?.addressProof?.endsWith(
                      ".pdf"
                    ) ? (
                      <div>
                        <img
                          style={{
                            width: "80%",
                            height: "150px",
                            margin: "20px auto",
                          }}
                          src="/images/pdf.png"
                          alt="PDF"
                        />
                        {/* <p>{propertiesForm?.verifiedProperty[0]?.addressProof.slice(60)}</p> */}
                      </div>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "10px",
                        }}
                        src={propertiesForm?.verifiedProperty[0]?.addressProof}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mt-4 form_input">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="input-heading">ID Proof</p>
                    {propertiesForm?.verifiedProperty[0]?.idProof && (
                      <a
                        href={propertiesForm?.verifiedProperty[0]?.idProof}
                        style={{ fontSize: "14px" }}
                        download
                      >
                        Download <AiOutlineDownload size={22} />
                      </a>
                    )}
                  </div>

                  <div className="col-12  form_input">
                    {propertiesForm?.verifiedProperty[0]?.idProof?.endsWith(
                      ".pdf"
                    ) ? (
                      <div>
                        <img
                          style={{
                            width: "80%",
                            height: "150px",
                            margin: "20px auto",
                          }}
                          src="/images/pdf.png"
                          alt="PDF"
                        />
                        {/* <p>{propertiesForm?.verifiedProperty[0]?.idProof.slice(60)}</p> */}
                      </div>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "10px",
                        }}
                        src={propertiesForm?.verifiedProperty[0]?.idProof}
                      />
                    )}
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 mt-4 form_input">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="input-heading">Ownership Proof</p>
                    {propertiesForm?.verifiedProperty[0]?.ownershipProof && (
                      <a
                        href={
                          propertiesForm?.verifiedProperty[0]?.ownershipProof
                        }
                        style={{ fontSize: "14px" }}
                        download
                      >
                        Download <AiOutlineDownload size={22} />
                      </a>
                    )}
                  </div>

                  <div className="col-12  form_input">
                    {propertiesForm?.verifiedProperty[0]?.ownershipProof?.endsWith(
                      ".pdf"
                    ) ? (
                      <div>
                        <img
                          style={{
                            width: "80%",
                            height: "150px",
                            margin: "20px auto",
                          }}
                          src="/images/pdf.png"
                          alt="PDF"
                        />
                        {/* <p>{propertiesForm?.verifiedProperty[0]?.ownershipProof.slice(60)}</p> */}
                      </div>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "200px",
                          marginTop: "10px",
                        }}
                        src={
                          propertiesForm?.verifiedProperty[0]?.ownershipProof
                        }
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        <div className=" d-flex justify-content-end">
          <button
            className="gray-btn grow "
            onClick={() => {
              handleForm(4);
            }}
          >
            Back
          </button>
          <button
            type="submit"
            className="primary-btn grow  ml-3"
            onClick={() => {
                handleDescription()
            }}
          >
            Submit
          </button>
        </div>
        {/* <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h5>Upload Id Proof Images</h5>
          </Modal.Header>
          <Modal.Body>
            {!propertiesForm?.verifiedProperty && (
              <div className="form-group">
                <Form>
                  <Form.Group controlId="imageUpload">
                    <Form.Label style={{ marginTop: "5px" }}>
                      Valid Id Proof{" "}
                      <span style={{ color: "grey", fontSize: "15px" }}>
                        {" "}
                        (Passport, Driving License, or national ID card)
                      </span>
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileChange}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="imageUpload">
                    <Form.Label
                      style={{ marginTop: "10px", marginBottom: "0" }}
                    >
                      Proof of property ownership
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileChange1}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="imageUpload">
                    <Form.Label
                      style={{ marginTop: "10px", marginBottom: "0" }}
                    >
                      Utility bill for addess proof
                    </Form.Label>
                    <Form.Control
                      type="file"
                      onChange={handleFileChange2}
                      required
                    />
                  </Form.Group>
                </Form>
              </div>
            )}

            {(!user.p_id ||
              user.p_id == "undefined" ||
              user.p_id == undefined) && (
              <div>
                <Modal.Header>
                  <h5 style={{ color: "black" }}>Payment Method</h5>
                </Modal.Header>

                <div className="d-flex justify-between">
                  <label style={{ marginBottom: "10px", marginTop: "5px" }}>
                    Add PayPal Email Id
                  </label>
                </div>
                <input
                  type="email"
                  required
                  className="w-100"
                  value={propertiesForm.paypal_id}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "paypal_id",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
                <div className="d-flex mt-2">
                  <a
                    style={{ fontSize: "15px" }}
                    href="https://www.paypal.com/uk/webapps/mpp/account-selection"
                  >
                    If you don't already have one, please create PayPal account
                  </a>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>

           

            <button
              disabled={isLoading}
              type="button"
              className="primary-btn grow  ml-3 d-flex align-items-center justify-content-center"
              onClick={handleSubmit}
            >
              Upload
              {isLoading ? (
                <span style={{ marginLeft: "5px" }}>
                  {" "}
                  <Loader type="button" />{" "}
                </span>
              ) : null}
            </button>
          </Modal.Footer>
        </Modal> */}
      </div>
    </div>
  );
}

export default AddDescription;
