

// import "./App.css"
import { useState, useEffect } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';

import { FaEdit, FaExclamationCircle, FaEye, FaTrash } from "react-icons/fa";
import { BsList } from 'react-icons/bs';
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from "react-redux";
// import { tenantsListAsync, deleteUsersAsync } from "redux/tenants/Services";
import SendMail from 'components/SendMail';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { handleExport } from "components/functions";
import { clearUserForm } from "redux/users/UsersSlice";
import DataTable2 from "components/DataTable2";
// import EditUser from "./EditUser";
import Axios from "utils/axios";

function UsersList(props) {
    const navigate = useNavigate();
    const userObj = useSelector(state => state.auth.user);
    var userId = userObj._id;

    const [edit, setEdit] = useState(false)
    const [editData, setEditData] = useState([])
    const [show, setShow] = useState(false)
    const [data, setData] = useState([])
    const [mailData, setMailData] = useState('')
    const [mail, setMail] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [rowsPerPage, setRowPerPage] = useState(10);// Number of rows to show per page

    const dispatch = useDispatch();


    const [userList, setUserList] = useState([]);

    const fetchData = async () => {
        try {
            const response = await Axios.get(`/users?limit=${rowsPerPage}&page=${currentPage}`)
                .then((res) => {
                    setTotalRows(res?.data?.total)
                    setUserList(res?.data?.data)
                })
                .catch((err) => {
                    alert(err);
                })
        } catch (error) {
            alert(error);
        }

    };


    useEffect(() => {
        fetchData();
    }, // eslint-disable-next-line
        [edit, currentPage, rowsPerPage])


    function openPopup(e, row) {
        setShow(true)
        setData(row)
    }

    const handleDelete = async (row) => {
        setShow(false)
        try {
            const payload = {
                _id: row._id
            };

            const response = await Axios.post('/franchiser/deleteFranchiser', payload)
                .then((res) => {
                    fetchData();
                })
                .catch((err) => {
                    alert(err);
                })

        } catch (error) {
            alert(error);
        }

    }

    function handleMail(e, to) {
        setMail(true)
        setMailData(to)
    }


    function handleEdit(e, row) {
        dispatch(clearUserForm())
        setEdit(true)
        setEditData(row)
    }

    function handleView(e, row) {
        navigate(userObj?.currentType === 'sl-admin' ? '/sl/users/view' : '/ll/users/view', { state: { row } })
    }

    const handlePageChange = (page) => {
        setCurrentPage(parseInt(page) + 1);
    };


    const generatePdf = () => {
        const doc = new jsPDF();

        const headers = [['ID', 'Title', 'First Name', 'Last Name', 'Email', 'Mobile Number', 'Address', 'Property']];
        const data = userList.map(item => [item.user_id, item.title, item.firstName, item.lastName, item.email, item.mobileNumber, item.address, item.userType]);

        doc.autoTable({
            head: headers,
            body: data.map((elemento) => Object.values(elemento)),
            margin: { top: 30, left: 10, right: 10, bottom: 0 },
            didDrawPage: function (data1) {
                doc.text("Propertyjinni :-  Users List", data1.settings.margin.left + 0, 22);
            }
        });

        // save the PDF
        doc.save('Users.pdf');
    };

    function handleMail2(e) {
        setMail(true)
    }


    const columns = [
        {
            field: '_id', headerName: 'S.N.', width: 70, renderCell: (index) => {
                if (currentPage > 1) {
                    return <div>{index.api.getRowIndex(index.row._id) + ((currentPage - 1) * 10) + 1}</div>
                }
                return <div>{index.api.getRowIndex(index.row._id) + 1}</div>
            }
        },
        {
            field: 'firstName', headerName: 'Name', flex: 1, minWidth: 150, renderCell: params => {
                return (
                    <div>{params.row.title} {params.row.firstName} {params.row.lastName}</div>
                )
            }
        },
        {
            field: 'email', headerName: 'Email', flex: 1, minWidth: 200, renderCell: params => {
                return (
                    <NavLink to='#/' onClick={(e) => handleMail(e, params.row.email)} > {params.row.email}</NavLink>
                )
            }
        },
        { field: 'mobileNumber', headerName: 'Mobile Number', flex: 1, minWidth: 150, },
        {
            field: 'status', headerName: 'Status', flex: 1, minWidth: 150, renderCell: params => {
                return (
                    <div>{params?.row?.status === 1 ? "Active" : params?.row?.status === 2 ? "In-active" : params?.row?.status === 0 ? "Un-authorized" : "NA"}</div>
                )
            }
        },

        {
            field: 'userType', headerName: 'Type', headerAlign: 'center', align: 'center', flex: 1, maxWidth: 120, minWidth: 80, disableColumnMenu: true, sortable: false, renderCell: params => {

                return <div>{params?.row?.userType}</div>
            }
        },
        {
            field: 'actions', headerName: 'Actions', headerAlign: 'center', align: 'center', flex: 1, minWidth: 150, disableColumnMenu: true, sortable: false, renderCell: (params) => {
                return (
                    <>
                        {/* <Button className='mr-2' style={{ color: "#1565c0", background: 'none', marginLeft: "20px", padding: "7px", fontSize: "16px", minWidth: "0px", border: "1px solid #cbd0dd", boxShadow: "none" }}
                            onClick={(e) => handleEdit(e, params.row)}
                            variant="contained" title='Edit'
                        >
                            <FaEdit />
                        </Button>
                        <Button style={{ color: "red", background: 'none', marginLeft: "20px", padding: "7px", fontSize: "16px", minWidth: "0px", border: "1px solid #cbd0dd", boxShadow: "none" }}
                            onClick={(e) => openPopup(e, params.row)}
                            variant="contained" title='Delete'
                        >
                            <FaTrash />
                        </Button> */}
                        <Button style={{ color: "green", background: 'none', marginLeft: "20px", padding: "7px", fontSize: "16px", minWidth: "0px", border: "1px solid #cbd0dd", boxShadow: "none" }}
                            onClick={(e) => handleView(e, params.row)}
                            variant="contained" title='View'
                        >
                            <FaEye />
                        </Button>
                    </>
                );
            }
        }
    ];


    return (
        <>
            <div className='table-heading px-4 px-md-3' >
                <div className='add-heading3 mb-0'>
                    {/* <div className='icon-card'>
                        <BsList style={{ color: '#F4B905' }} />
                    </div> */}
                    <h3 className='margin-b-0'> Users</h3>
                </div>
                {/* <div className='d-flex'>
                    <Dropdown >
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className='dropdown-btn'>
                            Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={(e) => handleMail2(e)}>Email</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={(e) => handleExport('Users_DataSheet', userList)} >Export</Dropdown.Item>
                            <Dropdown.Item href="#" onClick={(e) => generatePdf()}>Print</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <button className='grow addBtn' style={{ marginLeft: "10px" }} onClick={() => {
                        dispatch(clearUserForm());
                        navigate('/sl/users/add-user');
                    }} >Add New </button>
                </div> */}

            </div>
            <div className={`${edit ? "card mg10 table-h blur" : "card mg10 table-h mb5"}`}>
                <div style={{ height: 500, width: '100%' }}>
                    <DataTable2
                        columns={columns}
                        rows={userList}
                        pageSize={rowsPerPage}
                        rowCount={totalRows}
                        onPageChange={handlePageChange}
                        onPageSizeChange={(pageSize) => { setRowPerPage(pageSize) }}
                    />
                </div>
            </div>
            {/* {edit && 
            <div className="popup-overlay">
                <EditUser closePopup={() => setEdit(false)} data={editData} />
            </div> } */}
            {mail && <SendMail closePopup={() => setMail(false)} to={mailData} id={userId} />}
            {show && <div className="card modal" style={{ width: "30%" }} >
                <div className="modal_box" style={{ textAlign: "center" }} >
                    <div style={{ fontSize: "50px" }} ><FaExclamationCircle /></div>
                    <h3>Are You Sure ?</h3>
                    <p>You wanna delete this Row?</p>
                    <div className="modal-btn mt-3">
                        <button type="submit" className="btn form-btn grow " onClick={(e) => handleDelete(data)} >
                            Yes
                        </button>
                        <button type="submit" className="btn form-btn2 grow " onClick={(e) => setShow(false)} >
                            No
                        </button>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default UsersList