import {createSlice } from "@reduxjs/toolkit";
import { calendarEventAsync, allCalendarEventAsync, addCalendarEventAsync, deleteCalendarEventAsync} from "./Services"
import _ from "lodash";

const CalendarEventSlice = createSlice({
  name: "CalendarEventSlice",
  initialState: {
    loading: false,
    calendarEventForm: {},
    calendarEventList: [],
    allCalendarEventList: [],
  },
  reducers: {
    changeRentReminderForm:(state, action) => {
      let update_form = _.cloneDeep(state.calendarEventForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        calendarEventForm:{
          ...state.calendarEventForm,
          ...update_form,
        }
      }
    },
    clearRentReminderForm: (state, action) => {
      state.calendarEventForm = {};
    },
  },
  extraReducers: {

    [addCalendarEventAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addCalendarEventAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addCalendarEventAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [calendarEventAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [calendarEventAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.calendarEventList = action?.payload.data;
    //   state.program = action.payload;
    },
    [calendarEventAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allCalendarEventAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allCalendarEventAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allCalendarEventList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allCalendarEventAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteCalendarEventAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteCalendarEventAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteCalendarEventAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default CalendarEventSlice.reducer;

export const {
  changeRentReminderForm,
  clearRentReminderForm,
} = CalendarEventSlice.actions;
