import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";
import SidebarMenu from "../../components/SidebarMenu";
import { BsArrowBarLeft, BsArrowBarRight } from "react-icons/bs";

// import LLNavArr from "./LLNavItems";
import generateLLNavArr from "./LLNavItems";
import store from "redux/Store";

const LongLettingSidebar = ({ open, checkSidebar, closeModal }) => {
  const state = store.getState();
  const userObj = state?.auth?.user;

  // Call the function to generate the navigation array
  const LLNavArr = generateLLNavArr(userObj);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);
    checkSidebar(newIsOpen); // Call the callback function to update the parent component's state
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <>
      <motion.div
        animate={{
          // width: "100%",
          // maxWidth: isOpen ? "250px" : "64px",
          // overflow: "hidden",

          // transition: {
          //   // duration: 0.5,
          //   type: "spring",
          //   damping: 10,
          // },
        }}
        className={`sidebar ${isOpen ? "sidebar-size2" : "sidebar-size"}`}
      >
        <section className="routes border-bottom pb-3">
          {LLNavArr.map((route, index) => {
            if (route.subRoutes) {
              return (
                <SidebarMenu
                  setIsOpen={setIsOpen}
                  route={route}
                  showAnimation={showAnimation}
                  isOpen={isOpen}
                  closeSidebar={() => {
                    setIsOpen(false);
                    closeModal();
                  }}
                />
              );
            }

            return (
              <NavLink
                to={route.path}
                key={route.name}
                className={` link ${(navData) =>
                  navData.isActive ? "active" : ""} p-2`}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                <div className="route-icon">{route.icon}</div>
                <AnimatePresence>
                  {isOpen && (
                    <motion.div
                      variants={showAnimation}
                      initial="hidden"
                      animate="show"
                      exit="hidden"
                      className="link_text"
                    >
                      {route.name}
                    </motion.div>
                  )}
                </AnimatePresence>
              </NavLink>
            );
          })}
        </section>
        <div className="bottom_section" onClick={toggle}>
          <div className="bars">
            {isOpen === true ? <BsArrowBarLeft /> : <BsArrowBarRight />}
          </div>
          <AnimatePresence>
            {isOpen && (
              <motion.a
                variants={showAnimation}
                initial="hidden"
                animate="show"
                exit="hidden"
                className="collapse-bar mt-1"
              >
                Collapse View
              </motion.a>
            )}
          </AnimatePresence>
        </div>
      </motion.div>

      {/* <main ref={containerRef} className={`${isOpen ? "main2 w-100 wdh1" : "main2 w-100 wdh2"}`} >{children}</main> */}
    </>
  );
};

export default LongLettingSidebar;
