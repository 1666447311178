import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  token: localStorage.getItem("token") ? localStorage.getItem("token") : null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const usrInfo = action.payload.data;
      const usrObj = {
        name: usrInfo.name,
        id: usrInfo.id,
        userType: usrInfo.type,
        profilePic: usrInfo.image,
        currentType: usrInfo.type[0],
        _id: usrInfo._id,
        workwrap_id: usrInfo.workwrap_id,
        p_id: usrInfo.p_id,

        email: usrInfo.email,
      };

      //=== "sml" ? 'franchise' : usrInfo.type[0],

      state.user = usrObj;
      state.token = action.payload.token;

      localStorage.setItem("user", JSON.stringify(usrObj));
      localStorage.setItem("token", action.payload.token);
    },

    updateUser: (state, action) => {
      const usrInfo = action.payload.data;
 
      const usrObj = {
        name: usrInfo.name,
        profilePic: usrInfo.image,
        p_id: usrInfo.p_id,
        id: usrInfo.id,
        userType: usrInfo.userType,       
        currentType: usrInfo.currentType,
        _id: usrInfo._id,
        workwrap_id: usrInfo.workwrap_id,
        email: usrInfo.email,
      };
    
      state.user = usrObj;
      localStorage.setItem("user", JSON.stringify(usrObj));
    },
    setSwitchUser: (state, action) => {
      const usrInfo = action.payload;

      const usrObj = {
        name: usrInfo.name,
        id: usrInfo.id,
        userType: usrInfo.userType,
        profilePic: usrInfo.profilePic,
        currentType: usrInfo.currentType,
        _id: usrInfo._id,
        email: usrInfo.email,
        workwrap_id: usrInfo.workwrap_id,
        p_id: usrInfo.p_id,
      };

      state.user = usrObj;
      localStorage.setItem("user", JSON.stringify(usrObj));
    },

    logut: (state, action) => {
      state.user = null;
      state.token = null;
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    },
  },
  extraReducers: {},
});

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;

export const { setCredentials, setSwitchUser, updateUser, logout } =
  authSlice.actions;
export default authSlice.reducer;
