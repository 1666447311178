import {createSlice } from "@reduxjs/toolkit";
import {addOwnerAsync, editOwnerAsync, deleteOwnerAsync,  ownerListAsync, ownerSelectListAsync} from "./Services"
import _ from "lodash";

const ownerSlice = createSlice({
  name: "ownerSlice",
  initialState: {
    loading: false,
    ownerForm: {},
    ownerList: [],
    ownerSelectList: [],
  },
  reducers: {
    changeOwnerForm:(state, action) => {
      let update_form = _.cloneDeep(state.ownerForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        ownerForm:{
          ...state.ownerForm,
          ...update_form,
        }
      }
    },
    selectOwner: (state, {payload}) => {

      return {
        ...state,
        ownerForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearOwnerForm: (state, action) => {
      state.ownerForm = {};
    },
  },
  extraReducers: {
    [addOwnerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addOwnerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addOwnerAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editOwnerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editOwnerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editOwnerAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteOwnerAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteOwnerAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteOwnerAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [ownerListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [ownerListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.ownerList = action?.payload.data;
    //   state.program = action.payload;
    },
    [ownerListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [ownerSelectListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [ownerSelectListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.ownerSelectList = action?.payload.data;
    //   state.program = action.payload;
    },
    [ownerSelectListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default ownerSlice.reducer;

export const {
  changeOwnerForm,
  selectOwner,
  clearOwnerForm,
} = ownerSlice.actions;
