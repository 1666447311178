// import "./App.css"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { propertySelectListAsync } from "../../../../redux/properties/Services";
import { useState } from "react";
import { addOwnerAsync } from "../../../../redux/propertyOwners/Services";
import { changeOwnerForm } from "../../../../redux/propertyOwners/OwnersSlice";
import Toast from "components/Toast";

function AddOwner(props) {
  const navigate = useNavigate();

  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj.id;
  const userType = userObj.currentType;

  const dispatch = useDispatch();
  const { ownerForm, propertySelectList } = useSelector((state) => {
    const { propertyOwnerReducer, propertyReducer } = state;

    return {
      loading: propertyOwnerReducer.loading,
      ownerForm: propertyOwnerReducer.ownerForm,
      propertySelectList: propertyReducer.propertySelectList,
    };
  });

  const fetchProperty = () => {
    try {
      const payload = {
        userId: userId,
        userType: userType,
      };
      dispatch(propertySelectListAsync(payload))
        .unwrap()
        .then((res) => {});
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
    }
  };

  useEffect(
    () => {
      fetchProperty();

      dispatch(changeOwnerForm({ edited_field: "title", edited_value: "Mr." }));
      dispatch(changeOwnerForm({ edited_field: "status", edited_value: "1" }));
    }, // eslint-disable-next-line
    []
  );

  let handleSubmit = (e) => {
    e.preventDefault();

    try {
      const payload = {
        title: ownerForm.title,
        firstName: ownerForm.firstName,
        lastName: ownerForm.lastName,
        email: ownerForm.email,
        mobileNumber: ownerForm.mobileNumber,
        ownership: ownerForm.ownership,
        address: ownerForm.address,
        status: ownerForm.status,
        additionalDeatils: ownerForm.additionalDeatils
          ? ownerForm.additionalDeatils
          : "",
        // property: ownerForm.property,
        // unit: ownerForm.unit,
        createdBy: userObj._id,
        user_id: userObj._id,
      };
      dispatch(addOwnerAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            Toast(res.error, "error");
          } else {
            props.onClose
              ? props.onClose()
              : navigate("/ll/property-owners/all-property-owners");
            if (props.fetchHostList) {
              props.fetchHostList();
            }
          }
        });
    } catch (error) {
      Toast(error, "error");
    }
  };

  // const handleProperty = (e) => {
  //     dispatch(changeOwnerForm({ edited_field: "property", edited_value: e.target.value }));
  //     if (e.target.value !== "") {
  //         const filteredData = propertySelectList.filter(item => item._id === e.target.value);
  //         setUnits(filteredData[0].ll_units);
  //     }
  //     else {
  //         setUnits([]);
  //     }
  // }

  // const handleUnit = (e) => {
  //     if(e.target.value === 'all'){

  //         const unitIds = units.map(unit => unit._id);

  //         setUnitValue(unitIds)

  //         dispatch(changeOwnerForm({ edited_field: "unit", edited_value: 'all' }));

  //     }
  //     else{
  //         setUnitValue([e.target.value])
  //         dispatch(changeOwnerForm({ edited_field: "unit", edited_value: e.target.value }));
  //     }

  // }

  return (
    <>
      {props?.onClose ? (
        <div className="modal-title py-3 px-2 px-sm-3">
          Add a Property Owner{" "}
          <button
            className="grow modal-button"
            onClick={() => {
              props?.onClose();
            }}
          >
            X
          </button>
        </div>
      ) : (
        <div className="add-heading2">
          <div className="icon-card">
            <FaUserPlus style={{ color: "#F4B905" }} />{" "}
          </div>
          <h3 className="margin-b-0"> Add a Property Owner</h3>
        </div>
      )}

      <div
        className={
          props?.onClose
            ? "vmargin-respon form-h1 v-form-h overflow-y mg10 pb-3 pb-md-0 overflow-x-hidden"
            : "card form-h1 v-form-h overflow-y mg10"
        }
      >
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Title</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={ownerForm.title}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "title",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              >
                <option selected value="Mr.">
                  Mr.
                </option>
                <option value="Mrs.">Mrs.</option>
              </select>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={ownerForm.firstName}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "firstName",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                value={ownerForm.lastName}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "lastName",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Email Address</label>
              <input
                type="email"
                autoComplete="off"
                className="form-control"
                placeholder="Enter email"
                value={ownerForm.email}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "email",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Mobile Number</label>
              <input
                type="tel"
                className="form-control"
                placeholder="Enter Number"
                pattern="(\+44\s?)?[7-9]\d{9}|(0?[6-9]\d{9})"
                maxLength={13} // +44 and 10 digits
                minLength={10} // +44 and 9 digits
                number={true}
                value={ownerForm.mobileNumber}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "mobileNumber",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            {/* <div className='col-sm-12 col-md-6 col-lg-4 mb-3'>
                            <label>Status</label>
                            <select className="form-select" aria-label="Default select example"
                                value={ownerForm.status}
                                onChange={(e) => { dispatch(changeOwnerForm({ edited_field: "status", edited_value: e.target.value })) }}
                                req
                            >
                                <option selected value="1" >Active</option>
                                <option value="0">In Active</option>

                            </select>
                        </div> */}

            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={ownerForm.address}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "address",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Ownership (%)</label>
              <input
                type="Number"
                className="form-control"
                placeholder="Enter Ownership"
                value={ownerForm.ownership}
                min={1}
                max={100}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "ownership",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            {/* <div className='col-sm-12 col-md-6 col-lg-4 mb-3'>
                            <label>Property</label>
                            <select className="form-select" aria-label="Default select example"

                                value={ownerForm.property}
                                onChange={(e) => { handleProperty(e) }}
                                required
                            >
                                <option value="">Select an option</option>
                                {propertySelectList && propertySelectList.map((e, key) => {
                                    return <option key={key} value={e._id}>{e.title}</option>;
                                })}

                            </select>
                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-4 mb-3'>
                            <label>Unit</label>
                            <select className="form-select" aria-label="Default select example"
                                value={ownerForm.unit}
                                onChange={(e) => { dispatch(changeOwnerForm({ edited_field: "unit", edited_value: e.target.value })); }}

                            >
                                <option value="">Select an option</option>
                                <option value="all">All Units</option>
                                {units && units.map((e, key) => {
                                    return <option key={key} value={e._id}>{e.unitNumber}</option>;
                                })}

                            </select>
                        </div> */}
            {/* <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea className="form-control" id="exampleFormControlTextarea1" rows="3" onChange={(e) => { 
                                 setDescription(e.target.value) }}></textarea>
                        </div> */}
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Additional Owner Details
              </label>
              <textarea
                type="text"
                className="form-control "
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder=""
                value={ownerForm.additionalDeatils}
                onChange={(e) => {
                  dispatch(
                    changeOwnerForm({
                      edited_field: "additionalDeatils",
                      edited_value: e.target.value,
                    })
                  );
                }}
              />
              {/* <CKEditor
                                editor={ClassicEditor}
                                data={ownerForm.additionalDeatils}
                                onReady={(editor) => {

                                    // CKEditorInspector.attach(editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    dispatch(changeOwnerForm({ edited_field: "additionalDeatils", edited_value: data }))

                                }}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload"]
                                }}

                                required
                            /> */}
            </div>
            <div className="">
              <button type="submit" className="btn form-btn grow">
                Add Owner
              </button>
              <button
                type="button"
                onClick={() => {
                  props.onClose
                    ? props.onClose()
                    : navigate("/ll/property-managers/all-property-managers");
                }}
                className="btn form-btn2 grow"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddOwner;
