import moment from 'moment';

export const validatePhone = (phone)=>{
    const phoneRegex = /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

    if(!(phone && phone.length > 10 && phone.match(/^\d+$/))){
        return 'failed';
    }
    return 'success';
};

export const getValueByKey = (obj, key)=>{
    let keys = Object.keys(obj);
    for (let i = 0; i < (keys.length); i++) {
        let mainKey = keys[i];
        if(mainKey === key){
            return obj[mainKey];
        }
    }
};

export const getTransArrayByObject = (obj)=>{
    let keys = Object.keys(obj);
    let arr = [];
    for (let i = 0; i < (keys.length - 1); i++) {
        let mainKey = keys[i];
        let data = obj[mainKey];
        if(data !== null){
            data['language'] = mainKey;
        }else{
            data = {
                'language' : mainKey,
                'title' :'',
                'description':''
            }
        }
        arr.push(data);
    }
    return arr;
};



export const groupArrayBy = (array, key) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(
        currentValue
      );
      return result;
    }, {});
};

export const debounce = (func, timeout = 800)=>{
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(this, args); }, timeout);
    };
}