import React, { lazy } from "react";
import AuthGuard from "../utils/guards/AuthGuard";
import Role from "../utils/guards/Role";
import LLAdminLayout from "../layouts/LLAdminLayout";
import EditProperty from "../pages/longLetting/main/properties/EditProperties";
import UsersList from "pages/shortLetting/Admin/userList";
import UsersDetails from "pages/shortLetting/Admin/userDetail";
import MyProfile from "pages/public/MyProfile";
import DemoPage from 'pages/longLetting/main/DemoPage';
import AllCertificate from "pages/longLetting/main/certificate";
import AddCertificate from "pages/longLetting/main/certificate/AddCertificate";
import EditTenancy from "pages/longLetting/main/tenancies/EditTenancy";

const AddOwner = lazy(() =>
  import("../pages/longLetting/main/propertyOwners/AddOwner")
);
const Notifications = lazy(() => import("../pages/shortLetting/Notifications"));
const Chatgpt = lazy(() =>
  import("../pages/longLetting/main/chatgpt")
);
const OwnerDetails = lazy(() =>
  import("../pages/longLetting/main/propertyOwners/OwnerDetails")
);
const Owners = lazy(() => import("../pages/longLetting/main/propertyOwners"));
const Dashboard = lazy(() =>
  import("../pages/longLetting/main/dashboard/Dashboard")
);
// const MyProfile = lazy(() => import('../pages/longLetting/main/MyProfile'));
const AddProperties = lazy(() =>
  import("../pages/longLetting/main/properties/AddProperties")
);
const AddnewProperties = lazy(() =>
  import("../pages/longLetting/main/newProperties/AddProperties")
);
const EditnewProperties = lazy(() =>
  import("../pages/longLetting/main/newProperties/EditProperties")
);
const Properties = lazy(() => import("../pages/longLetting/main/properties"));
const NewProperties = lazy(() =>
  import("../pages/longLetting/main/newProperties")
);
const PropertyNewDetail = lazy(() =>
  import("../pages/longLetting/main/newProperties/PropertyDetail")
);
const PropertyDetail = lazy(() =>
  import("../pages/longLetting/main/properties/PropertyDetail")
);
// const EditProperties = lazy(() => import('../pages/longLetting/main/properties/EditProperties'));
const AddPropertyType = lazy(() =>
  import("../pages/longLetting/main/propertyType/AddPropertyType")
);
const PropertyType = lazy(() =>
  import("../pages/longLetting/main/propertyType")
);
const TypeDetails = lazy(() =>
  import("../pages/longLetting/main/propertyType/TypeDetails")
);
const AddLandlord = lazy(() =>
  import("../pages/longLetting/main/landlords/AddLandlord")
);
const Landlords = lazy(() => import("../pages/longLetting/main/landlords"));
const LandlordDetails = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordDetails")
);
const LandlordStatement = lazy(() =>
  import("../pages/longLetting/main/landlords//LandlordStatement")
);
const LandlordInvoice = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordInvoice")
);
const LandlordBalance = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordBalance")
);
const LandlordStatementDetails = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordStatementDetails")
);
const LandlordInvoiceDetails = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordInvoiceDetails")
);
const LandlordBalanceDetails = lazy(() =>
  import("../pages/longLetting/main/landlords/LandlordBalanceDetails")
);
const Tenants = lazy(() => import("../pages/longLetting/main/tenants"));
const AddTenants = lazy(() =>
  import("../pages/longLetting/main/tenants/AddTenants")
);
const TenantDetails = lazy(() =>
  import("../pages/longLetting/main/tenants/TenantDetails")
);
const Tenancies = lazy(() => import("../pages/longLetting/main/tenancies"));
const AddTenancies = lazy(() =>
  import("../pages/longLetting/main/tenancies/AddTenancy")
);
const TenancyDetails = lazy(() =>
  import("../pages/longLetting/main/tenancies/TenancyDetails")
);
const Guarantors = lazy(() => import("../pages/longLetting/main/guarantors"));
const AddGuarantor = lazy(() =>
  import("../pages/longLetting/main/guarantors/AddGuarantor")
);
const GuarantorDetails = lazy(() =>
  import("../pages/longLetting/main/guarantors/GurantorDetails")
);
// const TenantsId = lazy(() => import('../pages/longLetting/main/tenantsId'));
// const AddTenantsId = lazy(() => import('../pages/longLetting/main/tenantsId/AddTenantsId'));
// const TenantIdDetails = lazy(() => import('../pages/longLetting/main/tenantsId/Tenant_Id_Detail'));
const Expenses = lazy(() => import("../pages/longLetting/main/expenses"));
const AddExpenses = lazy(() =>
  import("../pages/longLetting/main/expenses/AddExpenses")
);
const ExpenseDetails = lazy(() =>
  import("../pages/longLetting/main/expenses/ExpenseDetail")
);
const Applicants = lazy(() => import("../pages/longLetting/main/applicants"));
const AddApplicants = lazy(() =>
  import("../pages/longLetting/main/applicants/AddApplicants")
);
const ApplicantDetails = lazy(() =>
  import("../pages/longLetting/main/applicants/ApplicantDetail")
);
// const PropertyCategory = lazy(() => import('../pages/longLetting/main/propertyCategory'));
// const AddPropertyCategory = lazy(() => import('../pages/longLetting/main/propertyCategory/AddPropertyCategory'));
// const CategoryDetails = lazy(() => import('../pages/longLetting/main/propertyCategory/CategoryDetails'));
// const Journals = lazy(() => import('../pages/longLetting/main/journal'));
// const JournalDetails = lazy(() => import('../pages/longLetting/main/journal/JournalDetails'));
// const AddJournal = lazy(() => import('../pages/longLetting/main/journal/AddJournal'));
const Viewings = lazy(() => import("../pages/longLetting/main/viewings"));
const ViewingDetails = lazy(() =>
  import("../pages/longLetting/main/viewings/viewingDetails")
);
const AddViewing = lazy(() =>
  import("../pages/longLetting/main/viewings/AddViewing")
);
const Tasks = lazy(() => import("../pages/longLetting/main/tasks"));
// const AddTask = lazy(() => import('../pages/longLetting/main/tasks/AddTask'));
const TaskDetails = lazy(() =>
  import("../pages/longLetting/main/tasks/TaskDetails")
);
// const Certificates = lazy(() => import('../pages/longLetting/main/certificates'));
// const CertificateDetails = lazy(() => import('../pages/longLetting/main/certificates/CertificateDetails'));
// const AddCertificate = lazy(() => import('../pages/longLetting/main/certificates/AddCertificate'));
// const Contractors = lazy(() => import('../pages/longLetting/main/contractors'));
// const AddContractors = lazy(() => import('../pages/longLetting/main/contractors/AddContractor'));
// const ContractorDetails = lazy(() => import('../pages/longLetting/main/contractors/ContractorDetails'));
const MailSettings = lazy(() =>
  import("../pages/longLetting/main/messaging/AddMailSettings")
);
const Calendars = lazy(() => import("../pages/longLetting/main/calendar"));
// const UsefulLinks = lazy(() => import('../pages/longLetting/main/usefulLinks'));
// const UsefulDocuments = lazy(() => import('../pages/longLetting/main/usefulDocuments'));
const AllPayments = lazy(() =>
  import("../pages/longLetting/main/rentalPayment/allPayment")
);
const TenantStatement = lazy(() =>
  import("../pages/longLetting/main/rentalPayment/tenantStatement")
);
const RentReminder = lazy(() =>
  import("../pages/longLetting/main/rentalPayment/rentReminder")
);
const TenantInvoices = lazy(() =>
  import("../pages/longLetting/main/rentalPayment/tenantInvoices")
);
const Deposits = lazy(() =>
  import("../pages/longLetting/main/rentalPayment/deposits")
);
const MainAddPage = lazy(() =>
  import("../pages/longLetting/main/tasks/MainAddPage")
);
const PropertyMangers = lazy(() =>
  import("../pages/longLetting/main/propertyManager")
);
const AddManager = lazy(() =>
  import("../pages/longLetting/main/propertyManager/AddManager")
);
const PropertyMangersDetails = lazy(() =>
  import("../pages/longLetting/main/propertyManager/ManagerDetails")
);
const ReduceExpenses = lazy(() =>
  import("pages/longLetting/main/reduceExpenses")
);

// tenants

const AllUnitList = lazy(() => import('../pages/longLetting/main/units/AllUnitList.jsx'));
const PropertyInvenotry = lazy(() => import('../pages/longLetting/main/inventory'));
const AddInvenotry = lazy(() => import('../pages/longLetting/main/inventory/AddInventory'));
const TenantDashboard = lazy(() => import('../pages/longLetting/tenant/TenantDashboard'));
const PropertyManager = lazy(() => import('../pages/longLetting/tenant/PropertyManger'));
const MyTenancy = lazy(() => import('../pages/longLetting/tenant/MyTenancy'));
const TrackIssues = lazy(() => import('../pages/longLetting/tenant/issues/TrackIssues'));
const RaiseIssue = lazy(() => import('../pages/longLetting/tenant/issues/RaiseIssue'));
const IssueDetails = lazy(() => import('../pages/longLetting/tenant/issues/IssueDetails'));
const MyDocuments = lazy(() => import('../pages/longLetting/tenant/MyDocuments'));
const TenancyUpdate = lazy(() => import('../pages/longLetting/tenant/EditTenancy'));
const NoticeServed = lazy(() => import('../pages/longLetting/tenant/NoticeServed'));
const InventoryDetails = lazy(() => import('../pages/longLetting/tenant/inventories/InventoryDetail'));
const Inventory = lazy(() => import('../pages/longLetting/tenant/inventories/Inventory'));
const Utilities = lazy(() => import('../pages/longLetting/tenant/Utilities'));
const InspectionDetails = lazy(() => import('../pages/longLetting/tenant/inspections/InspectionDetails'));
const Inspection = lazy(() => import('../pages/longLetting/tenant/inspections/Inspection'));
const RentSummary = lazy(() => import('../pages/longLetting/tenant/RentSummary'));
const Maintainance = lazy(() => import('../pages/longLetting/main/maintainance'));
const Messages = lazy(() => import('../pages/longLetting/main/messaging/index'))
const TenantMessages = lazy(() => import('../pages/longLetting/tenant/messaging/index'))

const longLettingRoutes = [
  {
    path: '/ll',
    element: <AuthGuard><LLAdminLayout /></AuthGuard>,
    children: [
      {
        path: 'dashboard',
        element: <Role roles={['sml-ll']}><Dashboard /></Role>
      },
      {
        path: 'my-profile',
        element: <Role roles={['sml-ll']}><MyProfile /></Role>
      },
      {
        path: 'properties/all-properties',
        element: <Role roles={['sml-ll']}><Properties /></Role>
      },
      {
        path: "properties/new-properties",
        element: (
          <Role roles={["sml-ll"]}>
            <NewProperties />
          </Role>
        ),
      },
      {
        path: "properties/add-property",
        element: (
          <Role roles={["sml-ll"]}>
            <AddProperties />
          </Role>
        ),
      },
      {
        path: "properties/add-new-property",
        element: (
          <Role roles={["sml-ll"]}>
            <AddnewProperties />
          </Role>
        ),
      },
      {
        path: "properties/edit-new-property/:tab",
        element: (
          <Role roles={["sml-ll"]}>
            <EditnewProperties />
          </Role>
        ),
      },
      {
        path: "properties/edit-property/:tab",
        element: (
          <Role roles={["sml-ll"]}>
            <EditProperty />
          </Role>
        ),
      },
      {
        path: "properties/new-detail/:tab",
        element: (
          <Role roles={["sml-ll"]}>
            <PropertyNewDetail />
          </Role>
        ),
      },
      {
        path: "properties/detail",
        element: (
          <Role roles={["sml-ll"]}>
            <PropertyDetail />
          </Role>
        ),
      },
      // {
      //     path: 'properties/edit',
      //     element: <Role roles={['sml-ll']}><EditProperties /></Role>
      // },
      {
        path: 'property-type/add-property-type',
        element: <Role roles={['sml-ll']}><AddPropertyType /></Role>
      },
      {
        path: 'property-type/all-property-type',
        element: <Role roles={['sml-ll']}><PropertyType /></Role>
      },
      {
        path: 'property-type/view',
        element: <Role roles={['sml-ll']}><TypeDetails /></Role>
      },
      {
        path: 'landlords/add-landlord',
        element: <Role roles={['sml-ll']}><AddLandlord /></Role>
      },
      {
        path: 'landlords/all-landlords',
        element: <Role roles={['sml-ll']}><Landlords /></Role>
      },
      {
        path: 'landlords/view',
        element: <Role roles={['sml-ll']}><LandlordDetails /></Role>
      },
      {
        path: 'landlords/landlord-statement',
        element: <Role roles={['sml-ll']}><LandlordStatement /></Role>
      },
      {
        path: 'landlord-statement/view',
        element: <Role roles={['sml-ll']}><LandlordStatementDetails /></Role>
      },
      {
        path: 'landlords/landlord-invoices',
        element: <Role roles={['sml-ll']}><LandlordInvoice /></Role>
      },
      {
        path: 'landlord-invoice/view',
        element: <Role roles={['sml-ll']}><LandlordInvoiceDetails /></Role>
      },
      {
        path: 'landlords/landlord-balance',
        element: <Role roles={['sml-ll']}><LandlordBalance /></Role>
      },
      {
        path: 'landlord-balance/view',
        element: <Role roles={['sml-ll']}><LandlordBalanceDetails /></Role>
      },
      {
        path: 'tenants/all-tenants',
        element: <Role roles={['sml-ll']}><Tenants /></Role>
      },
      {
        path: 'tenants/add-tenant',
        element: <Role roles={['sml-ll']}><AddTenants /></Role>
      },
      {
        path: 'tenants/view',
        element: <Role roles={['sml-ll']}><TenantDetails /></Role>
      },
      {
        path: 'tenancies/all-tenancies',
        element: <Role roles={['sml-ll']}><Tenancies /></Role>
      },
      {
        path: 'tenancies/add-tenancy',
        element: <Role roles={['sml-ll']}><AddTenancies /></Role>
      },
      {
        path: 'tenancies/edit-tenancy',
        element: <Role roles={['sml-ll']}><EditTenancy /></Role>
      },
      {
        path: 'tenancy/view',
        element: <Role roles={['sml-ll']}><TenancyDetails /></Role>
      },
      {
        path: 'guarantors/all-guarantors',
        element: <Role roles={['sml-ll']}><Guarantors /></Role>
      },
      {
        path: 'guarantors/add-guarantor',
        element: <Role roles={['sml-ll']}><AddGuarantor /></Role>
      },
      {
        path: 'guarantor/view',
        element: <Role roles={['sml-ll']}><GuarantorDetails /></Role>
      },
      {
        path: 'property-owners/all-property-owners',
        element: <Role roles={['sml-ll']}><Owners /></Role>
      },
      {
        path: 'property-owners/add-property-owners',
        element: <Role roles={['sml-ll']}><AddOwner /></Role>
      },
      {
        path: 'property-owners/view',
        element: <Role roles={['sml-ll']}><OwnerDetails /></Role>
      },
      {
        path: 'property-managers/all-property-managers',
        element: <Role roles={['sml-ll']}><PropertyMangers /></Role>
      },
      {
        path: 'property-managers/add-property-managers',
        element: <Role roles={['sml-ll']}><AddManager /></Role>
      },
      {
        path: 'property-managers/view',
        element: <Role roles={['sml-ll']}><PropertyMangersDetails /></Role>
      },
      // {
      //     path: 'tenants-id/all-tenants-id',
      //     element: <Role roles={['sml-ll']}><TenantsId /></Role>
      // },
      // {
      //     path: 'tenants-id/add-tenants-id',
      //     element: <Role roles={['sml-ll']}><AddTenantsId /></Role>
      // },
      // {
      //     path: 'tenants-id/view',
      //     element: <Role roles={['sml-ll']}><TenantIdDetails /></Role>
      // },
      {
        path: 'expenses/all-expenses',
        element: <Role roles={['sml-ll']}><Expenses /></Role>
      },
      {
        path: 'expenses/add-expenses',
        element: <Role roles={['sml-ll']}><AddExpenses /></Role>
      },
      {
        path: 'expenses/view',
        element: <Role roles={['sml-ll']}><ExpenseDetails /></Role>
      },
      {
        path: 'applicants/all-applicants',
        element: <Role roles={['sml-ll']}><Applicants /></Role>
      },
      {
        path: 'applicants/add-applicant',
        element: <Role roles={['sml-ll']}><AddApplicants /></Role>
      },
      {
        path: 'applicant/view',
        element: <Role roles={['sml-ll']}><ApplicantDetails /></Role>
      },
      // {
      //     path: 'categories/all-categories',
      //     element: <Role roles={['sml-ll']}><PropertyCategory /></Role>
      // },
      // {
      //     path: 'categories/add-category',
      //     element: <Role roles={['sml-ll']}><AddPropertyCategory /></Role>
      // },
      // {
      //     path: 'category/view',
      //     element: <Role roles={['sml-ll']}><CategoryDetails /></Role>
      // },
      // {
      //     path: 'journals/all-journals',
      //     element: <Role roles={['sml-ll']}><Journals /></Role>
      // },
      // {
      //     path: 'journals/add-journal',
      //     element: <Role roles={['sml-ll']}><AddJournal /></Role>
      // },
      // {
      //     path: 'journal/view',
      //     element: <Role roles={['sml-ll']}><JournalDetails /></Role>
      // },
      {
        path: 'viewing/all-Viewings',
        element: <Role roles={['sml-ll']}><Viewings /></Role>
      },
      {
        path: 'viewings/add-viewing',
        element: <Role roles={['sml-ll']}><AddViewing /></Role>
      },
      {
        path: 'viewing/view',
        element: <Role roles={['sml-ll']}><ViewingDetails /></Role>
      },
      {
        path: 'tasks/all-tasks',
        element: <Role roles={['sml-ll']}><Tasks /></Role>
      },
      {
        path: 'tasks/add-task',
        element: <Role roles={['sml-ll']}><MainAddPage /></Role>
      },
      {
        path: 'task/view',
        element: <Role roles={['sml-ll']}><TaskDetails /></Role>
      },
      {
        path: 'certificates/all-certificates',
        element: <Role roles={['sml-ll']}><AllCertificate title={"Certificates"} /></Role>
      },
      {
        path: 'certificates/add-certificate',
        element: <Role roles={['sml-ll']}><AddCertificate title={"Add Certificates"} /></Role>
      },
      // {
      //     path: 'certificate/view',
      //     element: <Role roles={['sml-ll']}><CertificateDetails /></Role>
      // },
      // {
      //     path: 'contractors/all-contractors',
      //     element: <Role roles={['sml-ll']}><Contractors /></Role>
      // },
      // {
      //     path: 'contractors/add-contractor',
      //     element: <Role roles={['sml-ll']}><AddContractors /></Role>
      // },
      // {
      //     path: 'contractors/view',
      //     element: <Role roles={['sml-ll']}><ContractorDetails /></Role>
      // },
      {
        path: 'messaging/add-mail-settings',
        element: <Role roles={['sml-ll']}><MailSettings /></Role>
      },
      {
        path: 'calendar',
        element: <Role roles={['sml-ll']}><Calendars /></Role>
      },
      // {
      //     path: 'useful-link/all-links',
      //     element: <Role roles={['sml-ll']}><UsefulLinks /></Role>
      // },
      // {
      //     path: 'useful-documents/all-documents',
      //     element: <Role roles={['sml-ll']}><UsefulDocuments /></Role>
      // },
      {
        path: 'rental-payment/all-payment',
        element: <Role roles={['sml-ll']}><AllPayments /></Role>
      },
      {
        path: 'rental-payment/tenants-statement',
        element: <Role roles={['sml-ll']}><TenantStatement /></Role>
      },
      {
        path: 'rental-payment/deposits',
        element: <Role roles={['sml-ll']}><Deposits /></Role>
      },
      {
        path: 'rental-payment/tenant-invoices',
        element: <Role roles={['sml-ll']}><TenantInvoices /></Role>
      },
      {
        path: 'rental-payment/rent-reminders',
        element: <Role roles={['sml-ll']}><RentReminder /></Role>
      },
      {
        path: 'reduce-expence',
        element: <Role roles={['sml-ll']}><ReduceExpenses /></Role>
      },
      {
        path: 'maintenance',
        element: <Role roles={['sml-ll']}><Maintainance /></Role>
      },
      {
        path: 'users/all-users',
        element: <Role roles={['ll-admin']}><UsersList /></Role>
      },
      {
        path: 'users/view',
        element: <Role roles={['sl-admin']}><UsersDetails /></Role>
      },
      {
        path: 'financial',
        element: <Role roles={['sml-ll']}><DemoPage title={"Financial"} /></Role>
      },
      {
        path: 'financial',
        element: <Role roles={['sml-ll']}><DemoPage title={"Financial"} /></Role>
      },
      {
        path: 'recurring-charges',
        element: <Role roles={['sml-ll']}><DemoPage title={"Recurring Charges"} /></Role>
      },
      {
        path: 'scheduling',
        element: <Role roles={['sml-ll']}><DemoPage title={"Scheduling"} /></Role>
      },
      {
        path: 'units',
        element: <Role roles={['sml-ll']}><AllUnitList title={"Units"} /></Role>
      },
      {
        path: 'inventory',
        element: <Role roles={['sml-ll']}><DemoPage title={"Inventory"} /></Role>
      },
      {
        path: 'add-inventory',
        element: <Role roles={['sml-ll']}><DemoPage title={"Inventory"} /></Role>
      },
      {
        path: 'online-portal',
        element: <Role roles={['sml-ll']}><DemoPage title={"Online Portal"} /></Role>
      },
      {
        path: 'notice',
        element: <Role roles={['sml-ll']}><DemoPage title={"Notice"} /></Role>
      },
      {
        path: 'rental-review',
        element: <Role roles={['sml-ll']}><DemoPage title={"Rental Review"} /></Role>
      },
      {
        path: 'notifications',
        element: <Role roles={['sml-ll']}><Notifications /></Role>
    },
      {
        path: 'tenancies-renew',
        element: <Role roles={['sml-ll']}><DemoPage title={"Tenancies Renew"} /></Role>
      },
      {
        path: 'documents',
        element: <Role roles={['sml-ll']}><DemoPage title={"Documents"} /></Role>
      },
      {
        path: 'e-signature',
        element: <Role roles={['sml-ll']}><DemoPage title={"E-signature"} /></Role>
      },
      {
        path: 'templates',
        element: <Role roles={['sml-ll']}><DemoPage title={"Templates"} /></Role>
      },
      {
        path: 'inspections',
        element: <Role roles={['sml-ll']}><DemoPage title={"Inspections"} /></Role>
      },
      {
        path: 'vendors',
        element: <Role roles={['sml-ll']}><DemoPage title={"Vendors"} /></Role>
      },
      // {
      //   path: 'messaging',
      //   element: <Role roles={['sml-ll']}><DemoPage title={"Messaging"} /></Role>
      // },
      {
        path: 'messaging',
        element: <Role roles={['sml-ll']}><Messages /></Role>
      },
      {
        path: 'group-message',
        element: <Role roles={['sml-ll']}><DemoPage title={"Group Message"} /></Role>
      },
      {
        path: 'announcements',
        element: <Role roles={['sml-ll']}><DemoPage title={"Announcements"} /></Role>
      },
      {
        path: 'email',
        element: <Role roles={['sml-ll']}><DemoPage title={"Email"} /></Role>
      },
      {
        path: 'reports',
        element: <Role roles={['sml-ll']}><DemoPage title={"Reports"} /></Role>
      },
      {
        path: 'knowledge-base',
        element: <Role roles={['sml-ll']}><DemoPage title={"Knowledge Base"} /></Role>
      },
      {
        path: 'jinni',
        element: <Role roles={['sml-ll']}><Chatgpt /></Role>
      },


      // tenants routes

      {
        path: "tenant/dashboard",
        element: (
          <Role roles={["tenant"]}>
            <TenantDashboard />
          </Role>
        ),
      },
      {
        path: "tenant/my-profile",
        element: (
          <Role roles={["tenant"]}>
            <MyProfile />
          </Role>
        ),
      },
      {
        path: "tenant/property-manager",
        element: (
          <Role roles={["tenant"]}>
            <PropertyManager />
          </Role>
        ),
      },
      {
        path: "tenant/my-tenancy",
        element: (
          <Role roles={["tenant"]}>
            <MyTenancy />
          </Role>
        ),
      },
      {
        path: "tenant/rental-payment/all-payment",
        element: (
          <Role roles={["tenant"]}>
            <AllPayments />
          </Role>
        ),
      },
      {
        path: "tenant/rental-payment/tenants-statement",
        element: (
          <Role roles={["tenant"]}>
            <RentSummary />
          </Role>
        ),
      },
      {
        path: "tenant/rental-payment/deposits",
        element: (
          <Role roles={["tenant"]}>
            <Deposits />
          </Role>
        ),
      },
      {
        path: "tenant/rental-payment/tenant-invoices",
        element: (
          <Role roles={["tenant"]}>
            <TenantInvoices />
          </Role>
        ),
      },
      {
        path: "tenant/rental-payment/rent-reminders",
        element: (
          <Role roles={["tenant"]}>
            <RentReminder />
          </Role>
        ),
      },
      {
        path: "tenant/track-issues",
        element: (
          <Role roles={["tenant"]}>
            <TrackIssues />
          </Role>
        ),
      },
      {
        path: "tenant/raise-issue",
        element: (
          <Role roles={["tenant"]}>
            <RaiseIssue />
          </Role>
        ),
      },
      {
        path: "tenant/raised-issue/view",
        element: (
          <Role roles={["tenant"]}>
            <IssueDetails />
          </Role>
        ),
      },
      {
        path: "tenant/my-documents",
        element: (
          <Role roles={["tenant"]}>
            <MyDocuments />
          </Role>
        ),
      },
      {
        path: "tenant/update-tenancy",
        element: (
          <Role roles={["tenant"]}>
            <TenancyUpdate />
          </Role>
        ),
      },
      {
        path: "tenant/notice-served",
        element: (
          <Role roles={["tenant"]}>
            <NoticeServed />
          </Role>
        ),
      },
      {
        path: "tenant/inventory",
        element: (
          <Role roles={["tenant"]}>
            <Inventory />
          </Role>
        ),
      },
      {
        path: "tenant/inventory/view",
        element: (
          <Role roles={["tenant"]}>
            <InventoryDetails />
          </Role>
        ),
      },
      {
        path: "tenant/utilities",
        element: (
          <Role roles={["tenant"]}>
            <Utilities />
          </Role>
        ),
      },
      {
        path: 'tenant/notifications',
        element: <Role roles={['tenant']}><Notifications /></Role>
    },
      {
        path: "tenant/inspection",
        element: (
          <Role roles={["tenant"]}>
            <Inspection />
          </Role>
        ),
      },
      {
        path: 'tenant/messaging',
        element: <Role roles={['sml-ll']}><TenantMessages /></Role>
      },
      {
        path: "tenant/inspection/view",
        element: (
          <Role roles={["tenant"]}>
            <InspectionDetails />
          </Role>
        ),
      },
    ],
  },
];

export default longLettingRoutes;
