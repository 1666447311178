import { configureStore } from "@reduxjs/toolkit";
import tenantReducer from "./tenants/TenantsSlice";
import guarantorReducer from "./guarantors/GuarantorsSlice";
import landlordReducer from "./landlords/LandlordsSlice";
import applicantReducer from "./applicants/ApplicantsSlice";
import expenseReducer from "./expenses/ExpensesSlice";
import taskReducer from "./tasks/TasksSlice";
import viewingReducer from "./viewings/ViewingsSlice";
import tenancyReducer from "./tenancies/TenanciesSlice";
import propertyReducer from "./properties/PropertiesSlice";
import loginReducer from "./login/loginSlice";

import resetPasswordReducer from "./resetPassword/resetPasswordSlice";
import changePasswordReducer from "./changePassword/changePasswordSlice";
import myProfileReducer from "./myProfile/myProfileSlice";
import userReducer from './users/UsersSlice';
import propertyTypeReducer from './propertyType/PropertyTypeSlice';
import sendMailReducer from './sendMail/sendMailSlice';
import landlordStatementReducer from './landlordStatement/LandlordStatementSlice';
import landlordInvoiceReducer from './landlordInvoices/LandlordInvoicesSlice';
import landlordBalanceReducer from './landlordBalance/LandlordBalanceSlice';
import mailSettingReducer from './mailSettings/mailSettingSlice';
import tenantStatementReducer from './tenantStatements/TenantStatementSlice';
import depositReducer from './deposits/DepositSlice';
import tenantInvoiceReducer from './tenantInvoices/TenantInvoiceSlice';
import dashboardReducer from './dashboard/DashboardSlice';
import rentalPaymentReducer from './rentalPayments/RentalPaymentSlice';
import rentReminderReducer from './rentReminder/RentReminderSlice';
import calendarEventReducer from './calendarEvents/CalendarEventSlice';
import sendVerifyMailReducer from './sendVerifyMail/sendVerifyMailSlice';
import issueReducer from './raisedIssues/IssuesSlice';
import signOutReducer from './signOut/signOutSlice';
import searchReducer from './search/searchSlice';
import uploadImagesReducer from './uploadImages/uploadImagesSlice';
import onBoardingReducer from './onBoarding/onBoardingSlice';
import bookingReducer from './bookings/bookingSlice';
import guestPropertiesReducer from './guestProperties/guestPropertiesSlice';
import placeApiReducer from './placesApi/placeApiSlice';
import propertyOwnerReducer from './propertyOwners/OwnersSlice';
import propertyManagerReducer from './propertyManagers/ManagersSlice';
import documentCategoriesReducer from './documents/DocumentsSlice';

import authReducer from './authSlice';
import { apiSlice } from './apiSlice';
import messagingReducer from './messagingSlice';
import socketReducer from "./socketSlice";
import scrollReducer from "./scrollSlice";
import tenancyIdReducer from "./tenancySlice"
import notificationsReducer from "./notificationSlice"


const store = configureStore({
  reducer: {
    auth: authReducer,
    messaging: messagingReducer,
    socket:socketReducer,
    scroll:scrollReducer,
    tenancyId:tenancyIdReducer,
    notifications:notificationsReducer,
    tenantReducer,
    guarantorReducer,
    landlordReducer,
    applicantReducer,
    expenseReducer,
    taskReducer,
    viewingReducer,
    tenancyReducer,
    propertyReducer,
    loginReducer,
    resetPasswordReducer,
    changePasswordReducer,
    myProfileReducer,
    userReducer,
    propertyTypeReducer,
    sendMailReducer,
    landlordStatementReducer,
    landlordInvoiceReducer,
    landlordBalanceReducer,
    mailSettingReducer,
    tenantStatementReducer,
    depositReducer,
    tenantInvoiceReducer,
    dashboardReducer,
    rentalPaymentReducer,
    rentReminderReducer,
    calendarEventReducer,
    sendVerifyMailReducer,
    issueReducer,
    signOutReducer,
    searchReducer,
    uploadImagesReducer,
    onBoardingReducer,
    bookingReducer,
    guestPropertiesReducer,
    placeApiReducer,
    propertyOwnerReducer,
    propertyManagerReducer,
    documentCategoriesReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(apiSlice.middleware),
  devTools: true,
});

export default store;
