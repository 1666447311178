import {createSlice } from "@reduxjs/toolkit";
import {addPropertiesAsync, editPropertiesAsync, deletePropertiesAsync, propertyListAsync, allPropertyListAsync,  erectedBoardStatusListAsync, propertyFeaturesAsync, detailPropertyAsync, propertyListAsync2, detailPropertyAsync2, addPropertiesAsync2, editPropertiesAsync2, propertySelectListAsync, editPropertyDocumentsAsync2, addGeneralInfoAsync, addAddressAsync, addPhotosAsync} from "./Services"
import _ from "lodash";

const propertySlice = createSlice({
  name: "propertySlice",
  initialState: {
    loading: false,
    propertiesForm: {},
    propertyList: [],
    propertySelectList: [],
    detailProperty: [],
    allPropertyList: [],
    erectedBoardStatusList: [],
    propertyFeaturesList:[],
    selectedImages: [],
  },
  reducers: {
    changePropertiesForm:(state, action) => {
      let update_form = _.cloneDeep(state.propertiesForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        propertiesForm:{
          ...state.propertiesForm,
          ...update_form,
        }
      }
    },
    addImage: (state, action) => {
      state.selectedImages = [...state.selectedImages, action.payload];
      
    },
    removeImage: (state, action) => {
      state.selectedImages = state.selectedImages.filter(
        (image, index) => index !== action.payload
      );
    },
    selectProperty: (state, {payload}) => {

      return {
        ...state,
        propertiesForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearPropertiesForm: (state, action) => {  
      state.propertiesForm = {};
    },
  },
  extraReducers: {

    //short letting

    [propertyListAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [propertyListAsync2.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyList = action?.payload.data;
    //   state.program = action.payload;
    },
    [propertyListAsync2.rejected]: (state, action) => {
      state.loading = false;
    },

    [detailPropertyAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [detailPropertyAsync2.fulfilled]: (state, action) => {
      state.loading = false;
      state.detailProperty = action?.payload.data[0];
    //   state.program = action.payload;
    },
    [detailPropertyAsync2.rejected]: (state, action) => {
      state.loading = false;
    },

    [addPropertiesAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [addPropertiesAsync2.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addPropertiesAsync2.rejected]: (state, action) => {
      state.loading = false;
    },

    [editPropertiesAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [editPropertiesAsync2.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editPropertiesAsync2.rejected]: (state, action) => {
      state.loading = false;
    },

    [addGeneralInfoAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addGeneralInfoAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addGeneralInfoAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [addAddressAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addAddressAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addAddressAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [addPhotosAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addPhotosAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addPhotosAsync.rejected]: (state, action) => {
      state.loading = false;
    },



    //long letting

    [propertySelectListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [propertySelectListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertySelectList = action?.payload.data;
    //   state.program = action.payload;
    },
    [propertySelectListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [addPropertiesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addPropertiesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addPropertiesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editPropertiesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editPropertiesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editPropertiesAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [editPropertyDocumentsAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [editPropertyDocumentsAsync2.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editPropertyDocumentsAsync2.rejected]: (state, action) => {
      state.loading = false;
    },
    

    [deletePropertiesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePropertiesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deletePropertiesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [propertyListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [propertyListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyList = action?.payload.data;
    //   state.program = action.payload;
    },
    [propertyListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [detailPropertyAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [detailPropertyAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.detailProperty = action?.payload.data[0];
    //   state.program = action.payload;
    },
    [detailPropertyAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    

    [erectedBoardStatusListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [erectedBoardStatusListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.erectedBoardStatusList = action?.payload.data;
    //   state.program = action.payload;
    },
    [erectedBoardStatusListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [propertyFeaturesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [propertyFeaturesAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyFeaturesList = action?.payload.data;
    //   state.program = action.payload;
    },
    [propertyFeaturesAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [allPropertyListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allPropertyListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allPropertyList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allPropertyListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    
  },
});

export default propertySlice.reducer;

export const {
  changePropertiesForm,
  selectProperty,
  clearPropertiesForm,
  addImage, removeImage
} = propertySlice.actions;
