import {createSlice } from "@reduxjs/toolkit";
import { balanceListAsync, allBalanceListAsync} from "./Services"
import _ from "lodash";

const LandlordStatementSlice = createSlice({
  name: "LandlordStatementSlice",
  initialState: {
    loading: false,
    balanceForm: {},
    balanceList: [],
    allBalanceList: [],
  },
  reducers: {
    changeBalanceForm:(state, action) => {
      let update_form = _.cloneDeep(state.balanceForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        balanceForm:{
          ...state.balanceForm,
          ...update_form,
        }
      }
    },
    clearBalanceForm: (state, action) => {
      state.balanceForm = {};
    },
  },
  extraReducers: {
  

    
    [balanceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [balanceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.balanceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [balanceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allBalanceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allBalanceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allBalanceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allBalanceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default LandlordStatementSlice.reducer;

export const {
  changeBalanceForm,
  clearBalanceForm,
} = LandlordStatementSlice.actions;
