import { toast } from "react-toastify";

const Toast = (message, type) => {
  switch (type) {
    case "success":
      return toast.success(message);
    case "error":
      return toast.error(message);
    case "warning":
      return toast.warning(message);
    default:
      return toast.warning(message);
  }
};
export default Toast;
