import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';



// // post Api
// export const sendMailAsync = createAsyncThunk(
//   'sendVerifyMailSlice/sendMailAsync',
//   async (post, {rejectWithValue}, thunkAPI) => {
//     try {
//       const access_token = localStorage.getItem('token');
// 
// const navigation = useNavigation();
//       const response = await fetch(
//         `${NEW_NEW_API_BASE_URL}/send-mail`,
//         {
//           method: 'POST',
//           body: JSON.stringify(post),
//           headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'source': 'web',
// 'token': `${access_token}`,
//           },
//         },
//       );

//       if (response.status === 200) {
//         // if HTTP-status is 200-299
//         const data = await response.json();

//         return data;
//       } else {
//         const data = await response.json();

//         return data
//       }
//     } catch (err) {


//       return rejectWithValue('Opps there seems to be an error');
//     }
//   },
// );

export const sendVerifyMailAsync = createAsyncThunk(
  'sendVerifyMailSlice/sendVerifyMailAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/send-verify-mail?id=${post.id}&email=${post.email}&token=${post.token}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);
