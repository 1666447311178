import React from "react";
import styled from "styled-components";

const ErrP = styled.p`
  font-size: 0.825rem !important;
  color: red !important;
  margin-bottom: 0.3rem;
  margin-top: 0.2rem;
  font-weight: 600;
`;

const ErrorMsg = ({ hasError, style, ...props }) => {
  if (!hasError) {
    return null;
  }

  return <ErrP style={{ ...style, ErrP }}>{props.children} </ErrP>;
};

export default ErrorMsg;
