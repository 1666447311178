import {createSlice } from "@reduxjs/toolkit";
import { dashboardDataAsync, allDashboardDataAsync, dashboardBlockDataAsync} from "./Services"
import _ from "lodash";

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState: {
    loading: false,
    dashboardDataForm: {},
    dashboardData: [],
    dashboardApiData: [],
    allDashboardData: [],
  },
  reducers: {
    changeDashboardDataForm:(state, action) => {
      let update_form = _.cloneDeep(state.dashboardDataForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        dashboardDataForm:{
          ...state.dashboardDataForm,
          ...update_form,
        }
      }
    },
    clearDashboardDataForm: (state, action) => {
      state.dashboardDataForm = {};
    },
  },
  extraReducers: {
    
    [dashboardDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [dashboardDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardData = action?.payload.data;
    //   state.program = action.payload;
    },
    [dashboardDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [dashboardBlockDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [dashboardBlockDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.dashboardApiData = action?.payload.data;
    //   state.program = action.payload;
    },
    [dashboardBlockDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [allDashboardDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allDashboardDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allDashboardData = action?.payload.data;
    //   state.program = action.payload;
    },
    [allDashboardDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default dashboardSlice.reducer;

export const {
  changeDashboardDataForm,
  clearDashboardDataForm,
} = dashboardSlice.actions;
