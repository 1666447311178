import {createSlice } from "@reduxjs/toolkit";
import {addPropertyTypeAsync, editPropertyTypeAsync, deletePropertyTypeAsync,  propertyTypeListAsync, allPropertyTypeListAsync} from "./Services"
import _ from "lodash";

const propertyTypeSlice = createSlice({
  name: "propertyTypeSlice",
  initialState: {
    loading: false,
    propertyTypeForm: {},
    propertyTypeList: [],
    allPropertyTypeList: [],
  },
  reducers: {
    changePropertyTypeForm:(state, action) => {
      let update_form = _.cloneDeep(state.propertyTypeForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        propertyTypeForm:{
          ...state.propertyTypeForm,
          ...update_form,
        }
      }
    },
    clearPropertyTypeForm: (state, action) => {
      state.propertyTypeForm = {};
    },
  },
  extraReducers: {
    [addPropertyTypeAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addPropertyTypeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addPropertyTypeAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editPropertyTypeAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editPropertyTypeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editPropertyTypeAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deletePropertyTypeAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deletePropertyTypeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deletePropertyTypeAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [propertyTypeListAsync.pending]: (state, action) => { 
      state.loading = true;
    },
    [propertyTypeListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.propertyTypeList = action?.payload.data;
    //   state.program = action.payload;
    },
    [propertyTypeListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allPropertyTypeListAsync.pending]: (state, action) => { 
      state.loading = true;
    },
    [allPropertyTypeListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allPropertyTypeList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allPropertyTypeListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default propertyTypeSlice.reducer;

export const {
  changePropertyTypeForm,
  clearPropertyTypeForm,
} = propertyTypeSlice.actions;
