import {createSlice } from "@reduxjs/toolkit";
import { editUsersAsync, deleteUsersAsync, usersListAsync, userAsync, addUsersAsync, usersListAsync2, editUsersAsync2} from "./Services"
import _ from "lodash";

const userSlice = createSlice({
  name: "userSlice",
  initialState: {
    loading: false,
    userForm: {},
    userList: [],
    user:{}
  },
  reducers: {
    changeUserForm:(state, action) => {
      let update_form = _.cloneDeep(state.userForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        userForm:{
          ...state.userForm,
          ...update_form,
        }
      }
    },
    setUserData: (state, {payload}) => {

      return {
        ...state,
        userForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearUserForm: (state, action) => {
      state.userForm = {};
    },
  },
  extraReducers: {

    //short letting

    [usersListAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [usersListAsync2.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = action?.payload.data;
    //   state.program = action.payload;
    },
    [usersListAsync2.rejected]: (state, action) => {
      state.loading = false;
    },

    [editUsersAsync2.pending]: (state, action) => {
      state.loading = true;
    },
    [editUsersAsync2.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editUsersAsync2.rejected]: (state, action) => {
      state.loading = false;
    },


    //long letting
   
    [userAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [userAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.user = action?.payload.data;
    //   state.program = action.payload;
    
    },
    [userAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [addUsersAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addUsersAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addUsersAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editUsersAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editUsersAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editUsersAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteUsersAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteUsersAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteUsersAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [usersListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [usersListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.userList = action?.payload.data;
    //   state.program = action.payload;
    },
    [usersListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

   
  },
});

export default userSlice.reducer;

export const {
  changeUserForm,
  setUserData,
  clearUserForm,
} = userSlice.actions;
