import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { FaEdit } from "react-icons/fa";
import { Button } from "@mui/material";


import Axios from "utils/axios";
import * as Yup from "yup";

import { useFormik } from "formik";
import ErrorMsg from "components/ErrorMsg";
import DataTable from "components/DataTable";
function Hosts() {
  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj._id;
  var userType = userObj.currentType;

  const [addHost, setAddHost] = useState(false);
  let handleSubmit = (values, { setSubmitting, setErrors }) => {
    if (activeForm2) {
      if (isEditing === true) {
        const payload = {
          _id: formik.values._id,
          userType: userType,
          owner_id: formik.values.owner_id,
          updatedBy: userId,
          userId: userId,
          ...myBody,
        };

        Axios.post(`/propertyOwners/editOwner`, payload)
          .then((res) => {
            if (res.error) {
              alert(res.error);
            } else {
              setAddHost(false);
              setIsEditing(false);
              setActiveForm2(false);
              setActiveForm1(true);
              formik.resetForm();
              setRecall(!recall)
            }
          })
          .catch((error) => {
            setErrors({ submit: error });
          });
      } else {
        const payload = {
          _id: userId,
          userType: userType,
          ...myBody,
          createdBy: userId,
        };
        Axios.post(`/propertyOwners/addOwner`, payload)
          .then((res) => {
            if (res.error) {
              alert(res.error);
            } else {
              setAddHost(false);
              setActiveForm2(false);
              setActiveForm1(true);
              formik.resetForm();
              setRecall(!recall)
            }
          })
          .catch((error) => {
            setErrors({ submit: error }); // toast.error("Program details could not be fetched, please try again!");
          });
      }
    } else {
      handleForm2();
    }
  };
  const [isEditing, setIsEditing] = useState(false);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      mobileNumber: "",
      address: "",
      address1: "",
      city: "",
      country: "",
      companyName: "",
      billingAddress: "",
      billingCountry: "",
      vatId: "",
      country: "",
      status: "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required("Please add first name"),
      lastName: Yup.string().required("Please add last name"),
      mobileNumber: Yup.string().required("Please add mobile number"),

      email: Yup.string()
        .email("Invalid email address")
        .test("custom", "Please enter a valid email address", (value) => {
          // Regular expression to validate email format
          const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

          // Check if the email format is valid
          if (!emailRegex.test(value)) {
            return false;
          }

          // Check if a dot (.) is placed before @
               // Check if a dot (.) is placed before @
               if (value[value.indexOf("@")-1]==='.') {
                return false;
              }

          // Check if there is a space at the beginning
          if (value.trim() !== value) {
            return false;
          }

          // Check if there are no more than 2 domains (e.g., example.com)
          const parts = value.split("@");
          if (parts[1].split(".").length > 3) {
            return false;
          }

          return true;
        })
        .required("Please enter email"),
    }),
    onSubmit: handleSubmit,
  });
  const [activeForm1, setActiveForm1] = useState(true);
  const [activeForm2, setActiveForm2] = useState(false);

  const [rows, setRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const [rowsPerPage, setRowPerPage] = useState(10); // Number of rows to show per page

  const handleForm2 = () => {
    setActiveForm1(false);
    setActiveForm2(true);
  };

  const handleForm1 = () => {
    setActiveForm2(false);
    setActiveForm1(true);
  };

  const fetchHostList = () => {
    const post = {
      userId: userObj._id,
      userType: userType,
      page: currentPage,
      limit: rowsPerPage,
      // searchValue: searchValue
    };
    Axios.get(
      `/propertyOwners?_id=${post.userId}&userType=${post.userType}&page=${post.page}&limit=${post.limit}`
    )
      .then((res) => {
        setRows(res.data.data);
        setTotalRows(res.data.total);
      })
      .catch((err) => {
        alert(err);
      });
  };


  const [recall, setRecall] = useState(false)

  useEffect(
    () => {
      fetchHostList();
    }, // eslint-disable-next-line
    [recall, currentPage, rowsPerPage, searchValue]
  );

  const myBody = {
    firstName: formik.values.firstName,
    lastName: formik.values.lastName,
    email: formik.values.email,
    mobileNumber: formik.values.mobileNumber,
    type: "FL",
    address: formik.values.address,
    address1: formik.values.address1,
    city: formik.values.city,
    country: formik.values.country,
    metaData: {
      companyName: formik.values.companyName,
      billingAddress: formik.values.billingAddress,
      billingCountry: formik.values.billingCountry,
      vatId: formik.values.vatId,
    },
    status: formik.values.status,
  };

  //   const handleSearch = (value) => {
  //     if (value === "") {
  //       setRows(ownerList);
  //     } else {
  //       const filterRows = rows.filter((item) =>
  //         item.firstName.toLowerCase().includes(value.toLowerCase())
  //       );

  //       setRows(filterRows);
  //     }
  //   };

  const handlePageChange = (page) => {
    // The page starts from 0 in MUI DataGrid, so we need to add 1 to match the server API
    setCurrentPage(parseInt(page) + 1);
  };

  const handleEdit = (data) => {
    setIsEditing(true);
    setAddHost(true);

    if (data) {
      const data1 = Object.entries(data);
      data1.forEach(([fieldName, fieldValue]) => {
        if (fieldName !== "metaData") {
          formik.setFieldValue(`${fieldName}`, fieldValue);
        }
      });
    }
    if (data?.metaData) {
      const metaDataArray = Object.entries(data.metaData);

      metaDataArray.forEach(([fieldName, fieldValue]) => {
        // dispatch(
        //   changeformik.values({
        //     edited_field: `${fieldName}`,
        //     edited_value: fieldValue,
        //   })
        // );
        // console.log(fieldName, fieldValue);
        formik.setFieldValue(`${fieldName}`, fieldValue);
      });
    }
  };

  const columns = [
    {
      field: "firstName",
      headerName: "Full Name",
      flex: 1,
      minWidth: 80,
      editable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.firstName} {params.row.lastName}
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "mobileNumber",
      headerName: "Mobile",
      flex: 1,
      minWidth: 110,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      minWidth: 110,
      editable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.address}, {params.row.city}, {params.row.country}
          </div>
        );
      },
    },

    {
      field: "companyName",
      headerName: "Company Name",
      flex: 1,
      minWidth: 110,
      editable: false,
      renderCell: (params) => {
        return <div>{params.row?.metaData?.companyName}</div>;
      },
    },
    {
      field: "billingAddress",
      headerName: "Billing Address",
      flex: 1,
      minWidth: 110,
      editable: false,
      renderCell: (params) => {
        return <div>{params.row.metaData?.billingAddress}</div>;
      },
    },
    {
      field: "billingCountry",
      headerName: "Billing Country",
      width: 130,
      editable: false,
      renderCell: (params) => {
        return <div>{params.row?.metaData?.billingCountry}</div>;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headerAlign: "center",
      align: "center",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Button
              className="mr-2"
              style={{
                padding: "5px 5px 5px 7px",
                fontSize: "20px",
                minWidth: "0px",
              }}
              onClick={(e) => {
                handleEdit(params.row);
              }}
              variant="contained"
              title="Edit"
            >
              <FaEdit />
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className="mt-3 p-3">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h3 className="">Property Owners</h3>
        <div>
          <button
            className="add-btn3 grow"
            onClick={() => {
              setAddHost(true);
              formik.resetForm();
            }}
          >
            Add Owner
          </button>
        </div>
      </div>
      <div className="mt-4 card table-h box-shd">
        {/* <div className='filter mb-3 filter-size'>
                    <AiOutlineSearch fontSize={20} className='search-icon' />
                    <input type='text' placeholder='Search host' onChange={(e) => { setSearchValue(e.target.value) }}></input>
                </div> */}

        <div style={{ height: "565px", width: "100%" }}>
          <DataTable
            columns={columns}
            rows={rows}
            // pageSize={rowsPerPage}
            // rowCount={totalRows}
            // onPageChange={handlePageChange}
            // onPageSizeChange={(pageSize) => {
            //   setRowPerPage(pageSize);
            // }}
          />
        </div>
      </div>

      {addHost && (
        <div className="popup-overlay">
          <div className="add-host">
            <div className="popup-card2">
              {activeForm1 && (
                <div className="first-form">
                  <div className=" add-head">
                    <h5 className="popup-heading mb-0">
                      {isEditing ? "Edit Owner" : "Add New Owner"}
                    </h5>
                    <p>
                      Create a third party owner with billing address to create
                      invoices.{" "}
                    </p>
                  </div>
                  <div className="form-pad">
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">First Name</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="firstName"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.firstName && formik.errors.firstName
                        }
                      >
                        {formik.errors.firstName}
                      </ErrorMsg>
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Last Name</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="lastName"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.lastName && formik.errors.lastName
                        }
                      >
                        {formik.errors.lastName}
                      </ErrorMsg>
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Email</p>
                      <input
                        className="edit-input2"
                        type="email"
                        autoComplete="off"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ErrorMsg
                        hasError={formik.touched.email && formik.errors.email}
                      >
                        {formik.errors.email}
                      </ErrorMsg>
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Mobile Number</p>
                      <input
                        type="tel"
                        className="edit-input2"
                        pattern="(\+44\s?)?[7-9]\d{9}|(0?[6-9]\d{9})"
                        maxLength={13} // +44 and 10 digits
                        minLength={10} // +44 and 9 digits
                        number={true}
                        name="mobileNumber"
                        value={formik.values.mobileNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      <ErrorMsg
                        hasError={
                          formik.touched.mobileNumber &&
                          formik.errors.mobileNumber
                        }
                      >
                        {formik.errors.mobileNumber}
                      </ErrorMsg>
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Address</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Address 1 (optional)</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="address1"
                        value={formik.values.address1}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">City</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="city"
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Country</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="country"
                        value={formik.values.country}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    {/* <div className='border-bottom mt-1 form_input'>
                                    <p className='input-heading'>Vat Id</p>
                                    <input className='edit-input2' type="text" onChange={(e) => setVatId(e.target.value)} value={vatId} ></input>
                                </div> */}
                    <div className="mt-5 pb-4 float-right">
                      <button
                        className="gray-btn grow "
                        onClick={() => {
                          setAddHost(false);
                          setIsEditing(false);
                          formik.resetForm();
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="primary-btn grow  ml-3"
                        onClick={formik.handleSubmit}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {activeForm2 && (
                <div className="second-form">
                  <div className="mb-4 add-head">
                    <h5 className="popup-heading mb-0">Company Details</h5>
                    <p>
                      You can edit your company billing details here. This info
                      shows up in your invoices.
                    </p>
                  </div>
                  <div className="form-pad">
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">
                        Company / Person Name<span className="asterik2"></span>
                      </p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="companyName"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">
                        Billing Address<span className="asterik2"></span>
                      </p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="billingAddress"
                        value={formik.values.billingAddress}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">
                        Billing Country<span className="asterik2"></span>
                      </p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="billingCountry"
                        value={formik.values.billingCountry}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                    <div className="border-bottom mt-1 form_input">
                      <p className="input-heading">Vat Id</p>
                      <input
                        className="edit-input2"
                        type="text"
                        name="vatId"
                        value={formik.values.vatId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className="mt-5 pb-4 float-right">
                      <button
                        className="gray-btn grow "
                        onClick={() => {
                          handleForm1();
                        }}
                      >
                        Back
                      </button>
                      <ErrorMsg hasError={formik.errors.submit}>
                        {formik.errors.submit}
                      </ErrorMsg>
                      <button
                        className="primary-btn grow  ml-3"
                        onClick={formik.handleSubmit}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Hosts;
