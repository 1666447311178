import { FaRegUser, FaTasks, FaUsers } from "react-icons/fa";
import { MdAccessTime, MdOutlineCategory, MdOutlineFileCopy, MdOutlineMapsHomeWork, MdOutlineNotificationsNone, MdOutlinePayment } from "react-icons/md";
import { BiCategoryAlt, BiCog, BiRocket } from "react-icons/bi";
import { AiOutlineEye, AiOutlineFileSearch, AiOutlineHome, AiOutlineIdcard, AiOutlineMessage, AiOutlineUser, } from "react-icons/ai";
import { RiShieldUserLine } from "react-icons/ri";
import { TbCertificate, TbReportMoney, TbReportSearch } from "react-icons/tb";
import { GiMoneyStack } from "react-icons/gi";
import { HiOutlineClipboardCheck, HiOutlineDocumentDuplicate, HiOutlineHome, HiOutlineSearch, HiOutlineUserCircle, HiOutlineUserGroup, HiOutlineUsers } from "react-icons/hi";
import { BsCalendarMonth, BsJournalBookmark } from "react-icons/bs";

import { Roles } from "../../utils/constants/roles";
import { PiCurrencyGbp } from "react-icons/pi";

const generateLLNavArr = (userObj) => {
  let LLNavArr = [];

  // var role = userObj && userObj.userType ? userObj.userType[0] : null;

  var role = userObj && userObj.userType ? userObj.currentType : null;

  if (role === Roles.LL_ADMIN) {
    LLNavArr.push(
      {
        path: "properties/all-properties",
        name: "Properties",
        icon: <AiOutlineHome />,
        subRoutes: [
          {
            path: "properties/all-properties",
            name: "Properties ",
            icon: <AiOutlineHome />,
          },
        ],
      },
      {
        path: "users/all-users",
        name: "Users",
        icon: <AiOutlineUser />,
        subRoutes: [
          {
            path: "users/all-users",
            name: "Users",
            icon: <AiOutlineUser />,
          },
        ],
      }
    );
  }

  if (role === Roles.LL_TENANT) {
    LLNavArr.push(
      {
        path: "tenant/dashboard",
        name: "Dashboard",
        icon: <BiRocket />,
      },
      // {
      //   path: "tenant/messaging",
      //   name: "Messaging",
      //   icon: <BiRocket />,
      // },
      {
        path: "tenant/messaging",
        name: "Messaging",
        icon: <AiOutlineMessage />,
        subRoutes: [
          {
            path: "tenant/messaging",
            name: "Message",
            icon: <BiCog />,
          },
          {
            path: "group-message",
            name: "Group Message",
            icon: <BiCog />,
          },
          {
            path: "announcements",
            name: "Announcements",
            icon: <BiCog />,
          },
          {
            path: "email",
            name: "Email",
            icon: <BiCog />,
          },
        ],
      },
      {
        path: "tenant/my-tenancy",
        name: "My Tenancy",
        icon: <HiOutlineHome />,
        // subRoutes: [
        //   {
        //     path: "tenant/my-tenancy",
        //     name: "My Tenancy ",
        //     icon: <HiOutlineUsers />,
        //   },
        //   // {
        //   //   path: "tenant/update-tenancy",
        //   //   name: "Extend/End Tenancy ",
        //   //   icon: <AiOutlineIdcard />,
        //   // },
        // ],
      },
      {
        path: "tenant/rental-payment/all-payment",
        name: "Payments",
        icon: <MdOutlinePayment />,
        subRoutes: [
          {
            path: "tenant/rental-payment/all-payment",
            name: "All Transactions",
            icon: <MdOutlinePayment />,
          },
          {
            path: "tenant/rental-payment/tenants-statement",
            name: "Rent Summary",
            icon: <TbReportMoney />,
          },
          // {
          //   path: "tenant/rental-payment/rent-reminders",
          //   name: "Sent Request ",
          //   icon: <MdOutlineNotificationsNone />,
          // },
        ],
      },
      {
        path: "tenant/my-documents",
        name: "My Documents",
        icon: <HiOutlineDocumentDuplicate />,
        // subRoutes: [
        //   {
        //     path: "tenant/my-documents",
        //     name: "My Documents",
        //     icon: <HiOutlineUsers />,
        //   },
        // ],
      },
      {
        path: "tenant/track-issues",
        name: "Maintenance",
        icon: <AiOutlineFileSearch />,
        // subRoutes: [
        //   {
        //     path: "tenant/track-issues",
        //     name: "Track Issues",
        //     icon: <HiOutlineUsers />,
        //   },
        // ],
      },
      {
        path: "tenant/notice-served",
        name: "Notice Served",
        icon: <MdAccessTime />,
        // subRoutes: [
        //   {
        //     path: "tenant/notice-served",
        //     name: "Notice Served",
        //     icon: <BiRocket />,
        //   },
        // ],
      },
      {
        path: "tenant/inventory",
        name: "Inventory",
        icon: <HiOutlineClipboardCheck />,
        // subRoutes: [
        //   {
        //     path: "tenant/inventory",
        //     name: "Inventory",
        //     icon: <BiRocket />,
        //   },
        // ],
      },
      {
        path: "tenant/utilities",
        name: "Utilities",
        icon: <MdOutlineCategory />,
        // subRoutes: [
        //   {
        //     path: "tenant/utilities",
        //     name: "Utilities",
        //     icon: <BiRocket />,
        //   },
        // ],
      },
      {
        path: "tenant/inspection",
        name: "Inspection",
        icon: <HiOutlineSearch />,
        // subRoutes: [
        //   {
        //     path: "tenant/inspection",
        //     name: "Inspection",
        //     icon: <BiRocket />,
        //   },
        // ],
      }
    );
  }

  if (role === Roles.LL_SML) {
    LLNavArr.push(
      {
        path: "jinni",
        name: "Jinni",
        icon: <img
          className='sidebar-icon'
          src={'/images/propertyjinni-favicon2.png'}
          alt="Receiver"
        />,
      },

      {
        path: "properties/new-properties",
        name: "Properties",
        icon: <AiOutlineHome />,
        subRoutes: [
          {
            path: "properties/new-properties",
            name: "Properties ",
            icon: <AiOutlineHome />,
          },
          {
            path: "units",
            name: "Units ",
            icon: <MdOutlineMapsHomeWork />,
          },
          {
            path: "inventory",
            name: "Inventory",
            icon: <BiCategoryAlt />,
          },
          {
            path: "certificates/all-certificates",
            name: "Certificates ",
            icon: <TbCertificate />,
          },
          {
            path: "online-portal",
            name: "Online Portal",
            icon: <BiCategoryAlt />,
          },
        ],
      },

      {
        path: "dashboard",
        name: "Analytics",
        icon: <BiRocket />,
      },
      {
        path: "calendar",
        name: "Calendar",
        icon: <BsCalendarMonth />,
        subRoutes: [
          {
            path: "calendar",
            name: "Calendar",
            icon: <BsCalendarMonth />,
          },
          {
            path: "scheduling",
            name: "Scheduling",
            icon: <BsCalendarMonth />,
          },
          {
            path: "viewing/all-Viewings",
            name: "Viewing ",
            icon: <AiOutlineEye />,
          },
        ],
      },
      {
        path: "property-managers/all-property-managers",
        name: "Users",
        icon: <HiOutlineUserGroup />,
        subRoutes: [
          {
            path: "property-managers/all-property-managers",
            name: "Property Managers ",
            icon: <RiShieldUserLine />,
          },
          {
            path: "guarantors/all-guarantors",
            name: "Guarantors ",
            icon: <RiShieldUserLine />,
          },
          {
            path: "property-owners/all-property-owners",
            name: "Property Owners ",
            icon: <RiShieldUserLine />,
          },
          {
            path: "applicants/all-applicants",
            name: "Applicants ",
            icon: <HiOutlineUserCircle />,
          },
        ],
      },
      {
        path: "tenancies/all-tenancies",
        name: "Tenancies",
        icon: <HiOutlineUsers />,
        subRoutes: [
          {
            path: "tenancies/all-tenancies",
            name: "Tenancies ",
            icon: <HiOutlineUsers />,
          },
          {
            path: "tenants/all-tenants",
            name: "Tenants ",
            icon: <FaRegUser />,
          },
          {
            path: "notice",
            name: "Notice ",
            icon: <AiOutlineIdcard />,
          },
          {
            path: "rental-review",
            name: "Rental Review ",
            icon: <AiOutlineIdcard />,
          },
          {
            path: "tenancies-renew",
            name: "Tenancies Renew ",
            icon: <AiOutlineIdcard />,
          },
        ],
      },
      {
        path: "documents",
        name: "Documents",
        icon: <HiOutlineDocumentDuplicate />,
        subRoutes: [
          {
            path: "documents",
            name: "Documents ",
            icon: <FaTasks />,
          },
          {
            path: "e-signature",
            name: "E-signature ",
            icon: <FaTasks />,
          },
          {
            path: "templates",
            name: "Templates ",
            icon: <FaTasks />,
          },
        ],
      },
      {
        path: "tasks/all-tasks",
        name: "Tasks",
        icon: <FaTasks />,
        subRoutes: [
          {
            path: "tasks/all-tasks",
            name: "Tasks ",
            icon: <FaTasks />,
          },
          {
            path: "inspections",
            name: "Inspections",
            icon: <FaTasks />,
          },
        ],
      },
      {
        path: "maintenance",
        name: "Maintenance ",
        icon: <MdOutlineFileCopy />,
        subRoutes: [
          {
            path: "maintenance",
            name: "Work Order ",
            icon: <MdOutlineFileCopy />,
          },
          {
            path: "vendors",
            name: "Vendors ",
            icon: <MdOutlineFileCopy />,
          },
        ],
      },
      {
        path: "financial",
        name: "Financial ",
        icon: <MdOutlineFileCopy />,
        subRoutes: [
          {
            path: "financial",
            name: "Financial ",
            icon: <MdOutlineFileCopy />,
          },
          {
            path: "recurring-charges",
            name: "Recurring Charges ",
            icon: <MdOutlineFileCopy />,
          },
          {
            path: "expenses/all-expenses",
            name: "Expenses ",
            icon: <GiMoneyStack />,
          },
        ],
      },
      {
        path: "messaging",
        name: "Messaging",
        icon: <AiOutlineMessage />,
        subRoutes: [
          {
            path: "messaging",
            name: "Message",
            icon: <BiCog />,
          },
          {
            path: "group-message",
            name: "Group Message",
            icon: <BiCog />,
          },
          {
            path: "announcements",
            name: "Announcements",
            icon: <BiCog />,
          },
          {
            path: "email",
            name: "Email",
            icon: <BiCog />,
          },
        ],
      },
      {
        path: "reduce-expence",
        name: "Reduce Expence ",
        icon: <GiMoneyStack />,
      },
      {
        path: "reports",
        name: "Reports",
        icon: <TbReportSearch />,
      },
      {
        path: "knowledge-base",
        name: "Knowledge Base",
        icon: <BsJournalBookmark />,
      }
    );
  }

  return LLNavArr;
};

export default generateLLNavArr;
