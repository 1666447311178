import { useState } from "react";
// import { setUserData } from "redux/users/UsersSlice";
import Axios from "utils/axios";

const useStaticData = () => {
  
  const rentFrequencies = [
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Quarterly",
      value: "Quarterly",
    },
  ];
  
  const weeks = [
    {
      label: "5 weeks",
      value: 5,
    },
    {
      label: "4 weeks",
      value: 4,
    },
    {
      label: "3 weeks",
      value: 3,
    },
    {
      label: "2 weeks",
      value: 2,
    },
    {
      label: "1 weeks",
      value: 1,
    },
    {
      label: "Other",
      value: 6,
    },
  ];
  
  const depositScheme = [
    {
      label: "TDS",
      value: "TDS",
    },
    {
      label: "DPS",
      value: "DPS",
    },
    {
      label: "My Deposits",
      value: "My Deposits",
    },
  ];
  
  const tenancyPeriods = [
    {
      label: "6 Months",
      value: 6,
    },
    {
      label: "12 Months",
      value: 12,
    },
    {
      label: "24 Months",
      value: 24,
    },
  ];
  
  const deductFromData = [
    {
      label: "Deposit",
      value: "Deposit",
    },
    {
      label: "Rent",
      value: "Rent",
    },
  ];

  const paymentTypes = [
    {
      label: "Car Parking",
      value: "Car Parking",
    },
    {
      label: "Rent",
      value: "Rent",
    },
    {
      label: "Forfeited Deposit",
      value: "Forfeited Deposit",
    },
    {
      label: "Holding Fee",
      value: "Holding Fee",
    },
    {
      label: "Deposit",
      value: "Deposit",
    },
  ];
  
  const paymentPeriod = [
    {
      label: "Monthly",
      value: "Monthly",
    },
    {
      label: "Quarterly",
      value: "Quarterly",
    },
    {
      label: "One off payment",
      value: "One off payment",
    },
  ];

  const statusData = [
    {
      label: "Paid",
      value: "Paid",
    },
    {
      label: "Due",
      value: "Due",
    },
    {
      label: "Overdue",
      value: "Overdue",
    },
    {
      label: "Scheduled",
      value: "Scheduled",
    },
    {
      label: "Waived",
      value: "Waived",
    },
  ];

  const identiTyDocumentTypes = [
    {
      label: "Utility Bill",
      value: "Utility Bill"
    },
    {
      label: "Bank Statement",
      value: "Bank Statement"
    },
    {
      label: "Mortgage Statement",
      value: "Mortgage Statement"
    },
    {
      label: "Council Tax",
      value: "Council Tax"
    },
  ]

  const ownershipDocumentTypes = [
    {
      label: "HM Land Registry title",
      value: "HM Land Registry title",
    },
    {
      label: "Title deed",
      value: "Title deed",
    },
    {
      label: "Mortgage agreement",
      value: "Mortgage agreement",
    },
    {
      label: "Certificate of insurance",
      value: "Certificate of insurance",
    },
    {
      label: "Signed solicitor's letter",
      value: "Signed solicitor's letter",
    },
  ];

  return {
    identiTyDocumentTypes,
    weeks,
    depositScheme,
    tenancyPeriods,
    deductFromData,
    paymentTypes,
    paymentPeriod,
    statusData,
    rentFrequencies,
    ownershipDocumentTypes
  };
};
export default useStaticData;
