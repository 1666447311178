import {createSlice } from "@reduxjs/toolkit";
import {addTasksAsync, editTasksAsync, deleteTasksAsync, tasksListAsync, allTasksListAsync} from "./Services"
import _ from "lodash";

const taskSlice = createSlice({
  name: "taskSlice",
  initialState: {
    loading: false,
    taskForm: {},
    taskList: [],
    allTaskList: [],
  },
  reducers: {
    changeTaskForm:(state, action) => {
      let update_form = _.cloneDeep(state.taskForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        taskForm:{
          ...state.taskForm,
          ...update_form,
        }
      }
    },
    selectTask: (state, {payload}) => {

      return {
        ...state,
        taskForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearTaskForm: (state, action) => {
      state.taskForm = {};
    },
  },
  extraReducers: {
    [addTasksAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addTasksAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addTasksAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editTasksAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editTasksAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editTasksAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteTasksAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTasksAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteTasksAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [tasksListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tasksListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.taskList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tasksListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allTasksListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allTasksListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTaskList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allTasksListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default taskSlice.reducer;

export const {
  changeTaskForm,
  selectTask,
  clearTaskForm,
} = taskSlice.actions;
