import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';



// post Api
export const uploadImagesAsync = createAsyncThunk(
  'uploadImagesSlice/uploadImagesAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      

      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/images/upload`,
        {
          method: 'POST',
          body: post, // JSON.stringify(post)
          headers: {
            // 'Accept': 'application/json',
            // 'Content-Type': 'multipart/form-data',
            'source': 'web',
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        return data
      }
    } catch (err) {


      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const deleteImageAsync = createAsyncThunk(
  'uploadImagesSlice/deleteImageAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/images/delete`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            // 'authorization': `Bearer ${access_token}`
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        return data
      }
    } catch (err) {


      return rejectWithValue('Opps there seems to be an error');
    }
  },
);
