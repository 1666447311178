import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';


//get Api

export const viewingsListAsync = createAsyncThunk(
  'viewingSlice/viewingsListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/viewings?id=${post.userId}&page=${post.page}&limit=${post.limit}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const viewingChartDataAsync = createAsyncThunk(
  'viewingSlice/viewingChartDataAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/viewings/data?id=${post.userId}&dataType=${post.dataType}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const allViewingChartDataAsync = createAsyncThunk(
  'viewingSlice/allViewingChartDataAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/viewings/data?dataType=${post.dataType}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



export const allViewingsListAsync = createAsyncThunk(
  'viewingSlice/allViewingsListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/viewings`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);




// post Api
export const addViewingsAsync = createAsyncThunk(
  'viewingSlice/addViewingsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/viewings/addviewing`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');

        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();


        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const editViewingsAsync = createAsyncThunk(
  'viewingSlice/editViewingsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/viewings/editviewing`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

// Delete Api

export const deleteViewingsAsync = createAsyncThunk(
  'viewingSlice/deleteViewingsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/viewings/deleteviewing`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = response;

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

