import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./redux/Store";
import Routes from "./routes";
import WebLoader from "./components/common/WebLoader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <Provider store={store}>
      {/* <AppRouter /> */}
      <BrowserRouter>
        <Suspense fallback={<WebLoader />}>
          <Routes />
          <ToastContainer theme="colored" />
        </Suspense>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
