import * as React from 'react';



export default function CountInput({ customClass, value, label, onChange, onDecrement, onIncrement }) {


    return (

        <div className={`d-flex align-items-center justify-content-between ${customClass}`}>
            <p className='input-heading w-75'>{label}</p>
            <div className='d-flex number-btn align-items-center justify-content-end '>
                <p onClick={onDecrement}>-</p>
                <input
                    style={{ marginLeft: "10px", marginRight: "10px", textAlign: 'center' }}
                    className='number-input '
                    type="number"
                    value={value}
                    onChange={onChange}
                    min="0"
                />
                <p onClick={onIncrement}>+</p>
            </div>
        </div>



    );
}