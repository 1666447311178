import React, { lazy } from "react";
// import AdminLogin from "../pages/longLetting/admin/admin-login";
import ResetPassword from "pages/public/ResetPassword";
import ThankyouPage from "pages/public/guestPortal/thankyouPage";
import OrderCancel from "pages/public/guestPortal/OrderCancel/OrderCancel";
import { GuestPublicLayout } from "layouts/GuestPublicLayout";
import GuestGuard from "utils/guards/GuestGuard";
import UserReport from 'pages/public/userReport';
// import "../App.css";
// import '../Admin.css'

const AuthLayout = lazy(() => import("../layouts/AuthLayout"));
const NotFound = lazy(() => import("../pages/public/404"));
const NotAuthorized = lazy(() => import("../pages/public/401"));
const HomePage = lazy(() => import("../pages/public/guestPortal/homePage"));
const PropertyDetail = lazy(() =>
  import("../pages/public/guestPortal/propertyDetailPages")
);
const SearchListing = lazy(() =>
  import("../pages/public/guestPortal/searchListing")
);
const Signup = lazy(() => import("../pages/public/SignUp"));
const EmailVerify = lazy(() => import("../components/emailVerify"));
const ForgotPassword = lazy(() => import("../pages/public/ForgotPassword"));
const ChangePassword = lazy(() => import("../pages/public/ChangePassword"));
const ConfirmEmail = lazy(() => import("../components/confirmEmail"));
const LoginPage = lazy(() => import("../pages/public/Login"));
const ConfirmPage = lazy(() => import("pages/public/guestPortal/confirmPage"));

const publicRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <LoginPage />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/change-password",
        element: <ChangePassword />,
      },
      {
        path: "/userverify",
        element: <EmailVerify />,
      },
      {
        path: "/successfully-send",
        element: <ConfirmEmail />,
      },
      {
        path: "/reset-password",
        element: <ResetPassword />,
      },
    //   {
    //     path: "/admin",
    //     element: <AdminLogin />,
    //   },
    ],
  },

  {
    path: "/nopermission",
    element: <NotAuthorized />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: '/user-internal-report',
    element: <UserReport />
},
  // guest navigation

  {
    path: "/guest",
    element: <GuestPublicLayout />,
    children: [
      {
        path: "homepage",
        element: <HomePage />,
      },
      {
        path: "properties",
        element: <PropertyDetail />,
      },
      {
        path: "search-listings",
        element: <SearchListing />,
      },
      {
        path: "confirm-page",
        element: <ConfirmPage />,
      },
      {
        path: "order-conformation",
        element: (
          <GuestGuard>
            <ThankyouPage />
          </GuestGuard>
        ),
      },
      {
        path: "order-cancel",
        element: (
          <GuestGuard>
            <OrderCancel />
          </GuestGuard>
        ),
      },
    ],
  },
];

export default publicRoutes;
