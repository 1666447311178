import * as React from "react";

const NoDataFound = ({ text }) => {
  return (
    <>
      <div className="card shadow-none">
        <div className="card-body text-center py-3 px-3">
          <img
            src="/images/no-data-found.png"
            width={"250px"}
            className="img-fluid mb-3 border-bottom"
            style={{ opacity: "1" }}
          />
          <h5 className="fw-bolder text-black mb-0">
             {text}
          </h5>
        </div>
      </div>
      {/* <div className="p-4">
                    <p>
                      <b>*No data found, please add the tenancy first.</b>
                    </p>
                  </div> */}
    </>
  );
};

export default NoDataFound;
