import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

import { selectCurrentUser, selectCurrentToken, setCredentials, logout } from './authSlice';


const baseQuery = fetchBaseQuery({
    baseUrl: 'https://dev.propertyjinni.co.uk/api/v1',
    credentials:'include',
    prepareHeaders:(headers, {getState})=>{
        const token = getState().auth.token;
        if(token){
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers;
    }
});


// Create base query wraper
const baseQueryWithReauth = async (args, api, extraOptions)=>{

    let result = await baseQuery(args, api, extraOptions);

    // If token got expired and we get 403 then we will have to hit the refresh token. Here we may need to adjust the result?.error?.originalStatus based on the our API
    if(result?.error?.originalStatus === 403){
        
        const refreshResult = await baseQuery('/refresh', api, extraOptions); // Hit the Refresh token query
        
        if(refreshResult?.data){
            const user = api.getState().auth.user; //Get the current user
            // Store the new token in the state We may need to update the same in localstorage as well. Also need to adjust the refreshResult.data as per our api response
            api.dispatch(setCredentials({...refreshResult.data, user}));
            result = await baseQuery(args, api, extraOptions);
        }else{
            api.dispatch(logout());
        }
    }

    return result;
}


export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    endpoints: (builder)=>({})
});