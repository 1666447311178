import {createSlice } from "@reduxjs/toolkit";
import {addIssuesAsync, editIssuesAsync, deleteIssuesAsync, IssuesListAsync, allIssuesListAsync} from "./Services"
import _ from "lodash";

const IssuesSlice = createSlice({
  name: "IssuesSlice",
  initialState: {
    loading: false,
    issueForm: {},
    issueList: [],
    allIssueList: [],
  },
  reducers: {
    changeIssueForm:(state, action) => {
      let update_form = _.cloneDeep(state.issueForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        issueForm:{
          ...state.issueForm,
          ...update_form,
        }
      }
    },
    clearIssueForm: (state, action) => {
      state.issueForm = {};
    },
  },
  extraReducers: {
    [addIssuesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addIssuesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addIssuesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editIssuesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editIssuesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editIssuesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteIssuesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteIssuesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteIssuesAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [IssuesListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [IssuesListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.issueList = action?.payload.data;
    //   state.program = action.payload;
    },
    [IssuesListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allIssuesListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allIssuesListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allIssueList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allIssuesListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default IssuesSlice.reducer;

export const {
  changeIssueForm,
  clearIssueForm,
} = IssuesSlice.actions;
