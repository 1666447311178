import * as React from "react";
import Checkbox from "@mui/material/Checkbox";

// const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export default function Checkboxes({ customClass, value, onChange, name ,onBlur}) {
  return (
    <div>
      <Checkbox
        className={customClass}
        value={value}
        onChange={onChange}
        checked={value}
        name={name}
        // onBlur={onBlur}
      />
    </div>
  );
}
