import React, { useEffect, useState } from "react";
import styles from "./certificate.module.css";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import CertificateCard from "./CertificateCard";
import useCertificateData from "./hooks/useCertificateData";
import BasicPagination from "components/pagination";
import { Box, Grid } from "@mui/material";
import Loader from "components/Loader";
import NoDataFound from "components/CustomComponents/NoDataFound";

const AllCertificate = ({ propertyId, isCertificateAdd }) => {
  const navigate = useNavigate();
  const {
    totalRows,
    certificate,
    getCertificateList,
    isLoading,
    setIsLoading,
  } = useCertificateData();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    getCertificateList(currentPage, itemsPerPage, propertyId);
  }, [currentPage]);

  const [width, setWidth] = useState(window.innerWidth);

  //FUNCTIONS

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;

      setWidth(newWidth);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <>
      <div className="">
        <div className="">
          <div className="p-4">
            <div className="d-flex align-items-center gap-4 justify-content-between mt-1 mb-4">
              <h3 style={{ marginLeft: "10px" }} className="mb-0">
                Certificate
              </h3>
              <div>
                <button
                  onClick={() => {
                    if (propertyId) {
                      isCertificateAdd();
                    } else {
                      navigate("/ll/certificates/add-certificate");
                    }
                  }}
                  class="property-add-btn grow"
                >
                  Add Certificate
                </button>
              </div>
            </div>

            {isLoading ? (
              <div className="w-100 d-flex justify-content-center">
                <div style={{ margin: "auto" }}>
                  <Loader size={"large"} />
                </div>
              </div>
            ) : (
              <div className={"v-table-card-design mt-4 mb-4"}>
                <div className="table-responsive">
                  {width >= 600 && (
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={0}
                        className="form-field__wrapper mt-4 mb-4"
                        style={{ padding: "0px 10px" }}
                      >
                        <Grid item xs={12} sm={4} md={4} className="">
                          <h6 className="prop-head m-0">Description</h6>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} className="">
                          <h6 className="prop-head m-0">Notes</h6>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} className="">
                          <h6 className="prop-head m-0">Last Work Order</h6>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} className="">
                          <h6 className="prop-head m-0">Expiry Date</h6>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} className="">
                          <h6 className="prop-head m-0"> </h6>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  <Box sx={{ flexGrow: 1 }} className="mb-5">
                    {certificate?.length > 0 ? (
                      certificate?.map((data) => {
                        return (
                          <CertificateCard
                            propertyId={propertyId}
                            data={data}
                            getCertificateList={() =>
                              getCertificateList(
                                currentPage,
                                itemsPerPage,
                                propertyId
                              )
                            }
                            currentPage
                            itemsPerPage
                          />
                        );
                      })
                    ) : (
                      <NoDataFound text="No data found, please add the certificate first." />
                    )}
                  </Box>
                </div>
              </div>
            )}
          </div>
        </div>
        {totalRows >= itemsPerPage && (
          <div className="mt-2 pagination-position mb-3">
            <BasicPagination
              count={Math.ceil(totalRows / itemsPerPage)} // Total number of pages, you may need to get this from the API response
              currentPage={currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>

      <div className={styles.right_side_popu + " d-none"}>
        <div className={styles.inner_part + " "}>
          <div className={styles.top_part + " "}>
            <h5>Gas Safety</h5>
            <CloseIcon />
          </div>
          <div className={styles.mid_part + " "}>
            <h5 className="fw-bold">Summary</h5>
            <ul className="list-unstyled ps-0 mb-0 d-grid gap-2">
              <li>
                <p>Certificate Type</p>
                PSD
              </li>
              <li>
                <p>Unit- Property name</p>
                Vishal
              </li>
              <li>
                <p>Status</p>
                PSD
              </li>
              <li>
                <p>Expiry date</p>
                PSD
              </li>
              <li>
                <p>Uploaded date </p>
                PSD
              </li>
            </ul>
          </div>
          <div className={styles.bottom_part + " "}></div>
        </div>
      </div>
    </>
  );
};

export default AllCertificate;
