import {createSlice } from "@reduxjs/toolkit";
import {addInvoiceAsync, editInvoiceAsync, deleteInvoiceAsync, invoiceListAsync, allInvoiceListAsync, otherIncomeAsync,  paymentMethodsAsync} from "./Services"
import _ from "lodash";

const LandlordStatementSlice = createSlice({
  name: "LandlordStatementSlice",
  initialState: {
    loading: false,
    invoiceForm: {},
    invoiceList: [],
    paymentMethods: [],
    allInvoiceList: [],
  },
  reducers: {
    changeInvoiceForm:(state, action) => {
      let update_form = _.cloneDeep(state.invoiceForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        invoiceForm:{
          ...state.invoiceForm,
          ...update_form,
        }
      }
    },
    clearInvoiceForm: (state, action) => {
      state.invoiceForm = {};
    },
  },
  extraReducers: {
    [addInvoiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addInvoiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addInvoiceAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editInvoiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editInvoiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editInvoiceAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteInvoiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteInvoiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteInvoiceAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [otherIncomeAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [otherIncomeAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [otherIncomeAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [paymentMethodsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [paymentMethodsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentMethods = action?.payload.data;
    //   state.program = action.payload;
    },
    [paymentMethodsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [invoiceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [invoiceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.invoiceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [invoiceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [allInvoiceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allInvoiceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allInvoiceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allInvoiceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

  },
});

export default LandlordStatementSlice.reducer;

export const {
  changeInvoiceForm,
  clearInvoiceForm,
} = LandlordStatementSlice.actions;
