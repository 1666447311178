import * as React from 'react';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addPhotosAsync } from "redux/properties/Services";
import { addImage, changePropertiesForm } from 'redux/properties/PropertiesSlice';
import DeleteModal from 'components/CustomComponents/DeleteModal';
import Axios from 'utils/axios';
import Loader from 'components/Loader';


function AddPhotos({ handleForm, handleTabSubmit }) {


    const fileInputRef = useRef(null);
    const dispatch = useDispatch();

    const [isLoading, setLoading] = useState(false);



    // const [selectedImages, setSelectedImages] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteImage, setDeleteImage] = useState('');

    const [imagePreviews, setImagePreviews] = useState([]);



    const { propertiesForm } = useSelector((state) => {
        const { propertyReducer } = state;

        return { propertiesForm: propertyReducer.propertiesForm };
    });

    // const selectedImages = useSelector((state) => state.propertyReducer.selectedImages) || propertiesForm.images;

    // Initialize selectedImages with the values from propertiesForm.images
    const initialSelectedImages = propertiesForm.images || [];
    const [selectedImages, setSelectedImages] = useState(initialSelectedImages);


    // update image state
    function updateImageState(e) {
        // const images = e.target.files;

        // Array.from(images).forEach((image) => {
        //     dispatch(addImage(image));
        // });

        const images = e.target.files;

        // Create a new array to hold the updated selectedImages
        const updatedSelectedImages = [...selectedImages];

        Array.from(images).forEach((image) => {
            // Check if the image is already in the selectedImages array
            const imageExists = updatedSelectedImages.some((existingImage) =>
                existingImage instanceof File
                    ? existingImage.name === image.name
                    : existingImage === image
            );

            // If it's not already in the array, add it
            if (!imageExists) {
                updatedSelectedImages.push(image);
            }
        });

        setSelectedImages(updatedSelectedImages);


    }


    useEffect(() => {
        dispatch(changePropertiesForm({ edited_field: "images", edited_value: selectedImages }));

        const previews = [];
        const urls = [];

        for (let i = 0; i < selectedImages.length; i++) {
            const image = selectedImages[i];

            // If it's a local file (added during editing or adding), create a preview
            if (image instanceof File) {
                const previewURL = URL.createObjectURL(image);
                previews.push(previewURL);
                urls.push('');
            } else {
                // It's a server URL (from editing existing images)
                previews.push(image); // Use the server URL as the preview
                urls.push(image); // Store the server URL separately
            }
        }

        setImagePreviews(previews);

        // const previews = [];

        // for (let i = 0; i < selectedImages.length; i++) {
        //     const image = selectedImages[i];

        //     const previewURL = URL.createObjectURL(image);

        //     previews.push(previewURL);

        // }
        // setImagePreviews(previews);

    }, // eslint-disable-next-line
        [selectedImages])


    let handleSubmit = async (e) => {

        const formData = new FormData();

        formData.append('property_id', propertiesForm.property_id);

        Array.from(selectedImages).forEach(image => {
            formData.append("images", image);
        });

        setLoading(true);

        try {
            const payload = formData;

            const response = await Axios.post('/properties/addPhotos', payload);
            setLoading(false);
            if (response.data?.success) {
                handleForm(4);
                handleTabSubmit(4);
            }
            else {
                alert(response.data?.error);
            }
        } catch (error) {
            setLoading(false);
            // toast.error("Program details could not be fetched, please try again!");
            alert(error);
        }
    };

    const openImageModal = (image) => {

        setDeleteImage(image);
        setDeleteModal(true);

    }

    const handleClick = () => {
        fileInputRef.current.click();
    };

    // Handle removing an image
    const handleRemoveImage = (index) => {

        const updatedSelectedImages = [...selectedImages];
        updatedSelectedImages.splice(index, 1);
        setSelectedImages(updatedSelectedImages);

        setDeleteModal(false);
    };


    return (
        <div className='mt-3 pt-3'>
            <div className="form-border v-form-border p-3 ">
                <div className=' '>
                    <h5 className='popup-heading mb-0'>Property photos</h5>
                    <p className='popup-summary'>Upload property images for create an transparency with user.</p>
                </div>
                <div className=''>
                    <div className='d-flex justify-content-between flex-sm-columns'>
                        <div className=' mt-1 form_input image border-right p-0 p-md-3'>
                            <p className='input-heading '>Images<span className='asterik2'></span></p>
                            <div className='summary'>( Please upload minimum 5 images for better understanding. )</div>
                            <div className="upload-area mt-3" onClick={handleClick}>
                                <span className="upload-icon">+</span>
                                <span className="upload-text">Upload Image</span>
                            </div>
                            <input type="file" accept="image/*" ref={fileInputRef} className="hidden-input" multiple onChange={updateImageState} />

                        </div>
                        <div className='preview p-0 p-md-3 mt-3 mt-md-0'>
                            <p className='input-heading '>Image Previews :<span className='asterik2'></span></p>
                            {selectedImages && selectedImages.length > 0 && <div className='mt-3 row  '>
                                {imagePreviews.map((image, index) => {
                                    return (
                                        <div className='col-6 col-md-6 col-lg-3 mb-3 position-relative'>
                                            <img style={{ width: '100%', height: '100px', objectFit: "fill", borderRadius: "5px" }} src={image} alt='Wrong Url' />
                                            <button className='img-del-btn' onClick={() => { openImageModal(index) }}>x</button>
                                        </div>
                                    )

                                })}
                            </div>}
                        </div>
                    </div>
                    <div className='mt-3 d-flex justify-content-end'>
                        <button className='gray-btn grow ' onClick={() => { handleForm(2) }}>Back</button>
                        {/* <button type='button' className='primary-btn grow  ml-3' onClick={() => { handleSubmit() }} >Next</button> */}

                        <button
                            disabled={isLoading}
                            type="button"
                            className="primary-btn grow  ml-3 d-flex align-items-center justify-content-center"
                            onClick={() => { handleSubmit() }}>
                            Next
                            {isLoading ? <span style={{ marginLeft: '5px' }}> <Loader type='button' /> </span> : null}
                        </button>

                    </div>
                </div>
            </div>

            {deleteModal && <DeleteModal image={deleteImage} text={'image'} closeModal={(val) => { setDeleteModal(val) }} handleDelete={(image) => { handleRemoveImage(image) }} />}

        </div>


    );
}

export default AddPhotos;