import {createSlice } from "@reduxjs/toolkit";
import { addDocumentCategories, documentCategoryListAsync } from "./Services"
import _ from "lodash";

const documentSlice = createSlice({
  name: "documentSlice",
  initialState: {
    loading: false,
    documentForm: {},
    documentCategoriesList: [],
    allDocumentList: [],
  },
  reducers: {
    changeDocumentForm:(state, action) => {
      let update_form = _.cloneDeep(state.documentForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        documentForm:{
          ...state.documentForm,
          ...update_form,
        }
      }
    },
    clearDocumentForm: (state, action) => {
      state.documentForm = {};
    },
  },
  extraReducers: {
    [addDocumentCategories.pending]: (state, action) => {
      state.loading = true;
    },
    [addDocumentCategories.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addDocumentCategories.rejected]: (state, action) => {
      state.loading = false;
    },

    [documentCategoryListAsync.pending]: (state, action) => { 
        state.loading = true;
      },
      [documentCategoryListAsync.fulfilled]: (state, action) => {
        state.loading = false;
        state.documentCategoriesList = action?.payload.data;
      //   state.program = action.payload;
      },
      [documentCategoryListAsync.rejected]: (state, action) => {
        state.loading = false;
      },
  },
});

export default documentSlice.reducer;

export const {
  changeDocumentForm,
  clearDocumentForm,
} = documentSlice.actions;
