import * as React from 'react';
import Radio from '@mui/material/Radio';

export default function RadioButtons({ onChange, value, checked, customClass, id }) {


  // console.log(value , checked  );

  return (

    <Radio
      className={customClass}
      checked={checked === value}
      onChange={onChange}
      value={value}
      variant="outlined"
      id={id ? id : ""}
    />
  );
}