import * as React from 'react';
import {Pagination} from '@mui/material';
import Stack from '@mui/material/Stack';

export default function BasicPagination({count, currentPage, onChange, paginationButtonStyle}) {
   
   return (
      <Stack spacing={2}>
         <Pagination
            count={count}
            color="primary"
            page={currentPage}
            onChange={onChange} 
            />
      </Stack>
   );
}