import * as React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useState, useEffect } from 'react';




export default function MultiSelectWithCheckboxWithoutIcon({ customClass, options, value, onSelectionChange }) {
  // const [personName, setPersonName] = useState([]);

  const menuStyle = { maxHeight: "400px" };

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes('selectAll')) {
      const allOptionValues = options.map((option) => option.value);

      setSelectedOptions(allOptionValues);
      onSelectionChange(allOptionValues);
    } else {

      setSelectedOptions(value);
      onSelectionChange(value);
    }

  };

  useEffect(() => {

    if(value && value.length > 0){
      setSelectedOptions(value);
    }

  },[value])

  



  return (
    // <InputLabel id="demo-multiple-checkbox-label">Tag</InputLabel>
    <Select
      labelId="demo-multiple-checkbox-label"
      id="demo-multiple-checkbox"
      multiple
      value={selectedOptions}
      onChange={handleChange}
      input={<OutlinedInput label="Tag" />}
      renderValue={(selected) => {
        const selectedLabels = selected.map((value) => {
          const option = options.find((opt) => opt.value === value);
          return option ? option.label : value;
        });
        return selectedLabels.join(', ');
      }}
      className={`${customClass} w-100`}
      MenuProps={{ style: menuStyle }}
      placeholder='select an option'
      required
    >
      <MenuItem key="selectAll" value="selectAll">
        <Checkbox
          style={{ padding: "0px 10px 0px 0px" }}
          checked={selectedOptions.length === options.length}
        />
        <ListItemText className='menu-item' primary="Select All" />
      </MenuItem>
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          <Checkbox style={{ padding: "0px 10px 0px 0px" }} checked={selectedOptions.includes(option.value)} />
          <ListItemText className='menu-item' primary={option.label} />
        </MenuItem>
      ))}
    </Select>
  );
}