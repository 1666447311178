import * as React from "react";
import { FaExclamationCircle } from "react-icons/fa";

export default function DeleteModal({ closeModal, handleDelete, text, image }) {
  return (
    <div className="popup-overlay">
      <div className="card modal" style={{ width: "30%" }}>
        <div className="modal_box" style={{ textAlign: "center" }}>
          <div style={{ fontSize: "50px" }}>
            <FaExclamationCircle />
          </div>
          <h3>Are You Sure ?</h3>
          <p>You want to delete this {text}?</p>
          <div className="modal-btn mt-3">
            <button
              type="submit"
              className="btn form-btn grow "
              onClick={(e) => handleDelete(image)}
            >
              Yes
            </button>
            <button
              type="submit"
              className="btn form-btn2 grow "
              onClick={(e) => closeModal(false)}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
