import React from "react";
import { Select, MenuItem } from "@mui/material";

const CountryCodeSelect = ({ customClass, selectedValue, onChange, name }) => {
  const options = [
    {
      label: "UK",
      value: "+44",
    },
    {
      label: "India",
      value: "+91",
    },
  ];

  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={selectedValue || "+44"}
      name={name}
      //   label="Age"
      onChange={onChange}
      className={customClass}
      sx={{ height: "1.437rem", padding: "26px 1px" }}
    >
      {options.map((option) => (
        <MenuItem className="menu-item" value={option.value}>
          {option.value}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CountryCodeSelect;
