import React, { useState, useEffect } from "react";

import { MdPassword } from "react-icons/md";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { AiOutlineRight, AiOutlineUser } from "react-icons/ai";
import { FaUpload } from "react-icons/fa";
import { useRef } from "react";

import Hosts from "../shortLetting/Host";
import Axios from "utils/axios";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";
import { updateUser } from "redux/authSlice";

function MyProfile(props) {
  const [activeDiv, setActiveDiv] = useState(1);

  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj.id;
  var userType = userObj.currentType;

  const [userdata, setuserdata] = useState();
  const dispatch = useDispatch();
  const fetchData = () => {
    const access_token = localStorage.getItem("token");
    Axios.get(`users?id=${userId}`, {
      headers: {
        source: "web",
        authorization: `${access_token}`,
      },
    })
      .then((res) => {
        setuserdata(res?.data?.data);
        const data = {
          ...userObj,
          name: res?.data?.data?.firstName + " " + res?.data?.data?.lastName,
          image: res?.data?.data?.image,
          p_id: res.data?.data?.paypal_id,
        };
        dispatch(updateUser({ data: data }));
        formik.setFieldValue("email", res?.data?.data.email);
        formik.setFieldValue("firstName", res?.data?.data.firstName);
        formik.setFieldValue("lastName", res?.data?.data.lastName);
        formik.setFieldValue("dob", res?.data?.data.dob);
        formik.setFieldValue("address", res?.data?.data.address);
        formik.setFieldValue("companyName", res?.data?.data.companyName);
        formik.setFieldValue(
          "gender",
          res?.data?.data.gender ? res?.data?.data.gender : "male"
        );
        formik.setFieldValue("firstName", res?.data?.data.firstName);
        formik.setFieldValue("mobile", res?.data?.data.mobileNumber);
        formik1.setFieldValue("paypal_id", res?.data?.data.paypal_id);
      })
      .catch((error) => {
        alert(error);
      });
    return 0;
  };

  const editprofile = (values, { setSubmitting, setErrors }) => {
    setIsLoading(true);
    const formData = new FormData();

    formData.append("firstName", values.firstName);
    formData.append("mobileNumber", values.mobile);
    formData.append("gender", values.gender);
    formData.append("lastName", values.lastName);
    formData.append("dob", values.dob);
    formData.append("companyName", values.companyName);
    formData.append("address", values.address);
    formData.append("user_id", userObj.id);
    formData.append("workwrap_id", userObj.workwrap_id);

    values.profile && formData.append("image", values.profile);

    Axios.post(`/users/edituser`, formData, {})
      .then((res) => {
        fetchData();
        setIsLoading(false);
      })
      .catch((err) => {
        setErrors({ submit: err });
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      gender: "male",
      mobile: "",
      dob: "",
      companyName: "",
      address: "",
      profile: null,
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .test("custom", "Please enter a valid email address", (value) => {
          // Regular expression to validate email format
          const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

          // Check if the email format is valid
          if (!emailRegex.test(value)) {
            return false;
          }

          // Check if a dot (.) is placed before @
          if (value[value.indexOf("@") - 1] === ".") {
            return false;
          }

          // Check if there is a space at the beginning
          if (value.trim() !== value) {
            return false;
          }

          // Check if there are no more than 2 domains (e.g., example.com)
          const parts = value.split("@");
          if (parts[1].split(".").length > 3) {
            return false;
          }

          return true;
        })
        .required("Please enter email"),
      firstName: Yup.string().required("Please enter First name"),
      lastName: Yup.string().required("Please enter Last name"),
      gender: Yup.string().required("Please enter your gender"),
      mobile: Yup.string().required("Please enter Mobile number"),
      dob: Yup.string().required("Please enter Birth Date"),
      address: Yup.string().required("please enter address"),
      companyName: Yup.string().required("please enter company name"),
    }),
    onSubmit: editprofile,
  });

  const [isLoading, setIsLoading] = useState(false);

  const editpaypal_id = (values, { setSubmitting, setErrors }) => {
    const formData = new FormData();
    setIsLoading(true);
    formData.append("paypal_id", values.paypal_id);

    formData.append("user_id", userObj.id);

    Axios.post(`/users/edituser`, formData, {})
      .then((res) => {
        fetchData();
        setIsLoading(false);
      })
      .catch((err) => {
        setErrors({ submit: err });
        setIsLoading(false);
      });
  };

  const formik1 = useFormik({
    initialValues: {
      paypal_id: "",
    },
    validationSchema: Yup.object().shape({
      paypal_id: Yup.string()
        .email("Invalid PayPal-Id address")
        .required("Please enter PayPal-Id"),
    }),
    onSubmit: editpaypal_id,
  });

  useEffect(
    () => {
      fetchData();
    }, // eslint-disable-next-line
    []
  );

  const fileInputRef = useRef(null);

  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="main-profile">
      <div className="profile-sidebar1 only-large">
        <div className="profile-heading">
          <h5>Account</h5>
        </div>
        <div
          className={
            activeDiv === 1 ? "profile-option active" : "profile-option"
          }
          onClick={() => {
            setActiveDiv(1);
          }}
        >
          <div>
            <h6>Profile details</h6>
            <p>Provide your full legal name and updated contact number.</p>
          </div>
          <AiOutlineRight className="option-icon" />
        </div>

        {userType !== "sl-admin" && userType !== "ll-admin" && userType !== "tenant" && (
          <div
            className={
              activeDiv === 4 ? "profile-option active" : "profile-option"
            }
            onClick={() => {
              setActiveDiv(4);
            }}
          >
            <div>
              <h6>Link your PayPal account</h6>
              <p>
                Your all booking payments are captured in your PayPal account.
              </p>
            </div>
            <AiOutlineRight className="option-icon" />
          </div>
        )}
        {userType !== "sl-admin" &&
          userType !== "landlord" &&
          userType !== "sml-ll" &&
          userType !== "ll-admin" &&
          userType !== "tenant" && (
            <div
              className={
                activeDiv === 2 ? "profile-option active" : "profile-option"
              }
              onClick={() => {
                setActiveDiv(2);
              }}
            >
              <div>
                <h6>Property Owners</h6>
                <p>You can add and see property owners.</p>
              </div>
              <AiOutlineRight className="option-icon" />
            </div>
          )}
      </div>
      <div className="profile-sidebar2 only-mobile1 d-none">
        <div
          className={
            activeDiv === 1 ? "profile-option active" : "profile-option"
          }
          onClick={() => {
            setActiveDiv(1);
          }}
        >
          <AiOutlineUser className="option-icon" />
        </div>

        <div
          className={
            activeDiv === 4 ? "profile-option active" : "profile-option"
          }
          onClick={() => {
            setActiveDiv(4);
          }}
        >
          <MdPassword className="option-icon" />
        </div>
      </div>
      <div className="profile-right">
        {activeDiv === 1 && (
          <div className="div1">
            <div style={{ textAlign: "center" }}>
              {formik.values.profile ? (
                <img
                  className="profile_img"
                  src={
                    URL.createObjectURL(formik.values.profile)
                      ? URL.createObjectURL(formik.values.profile)
                      : "/images/user-demo.jpg"
                  }
                  alt="wrong-url"
                />
              ) : (
                <img
                  className="profile_img"
                  src={
                    userdata?.image ? userdata.image : "/images/user-demo.jpg"
                  }
                  alt="wrong-url"
                />
              )}
            </div>

            <h5>Profile Details</h5>
            <div className="profile-input-card">
              <p>First Name</p>
              <input
                className="edit-input"
                type="text"
                id="firstName"
                name="firstName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.firstName}
              ></input>
              <ErrorMsg
                hasError={formik.touched.firstName && formik.errors.firstName}
              >
                {formik.errors.firstName}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Last Name</p>
              <input
                className="edit-input"
                type="text"
                id="lastName"
                name="lastName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.lastName}
              ></input>
              <ErrorMsg
                hasError={formik.touched.lastName && formik.errors.lastName}
              >
                {formik.errors.lastName}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Gender</p>
              <select
                className="edit-input"
                id="gender"
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
              >
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>

              <ErrorMsg
                hasError={formik.touched.gender && formik.errors.gender}
              >
                {formik.errors.gender}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Mobile Number</p>
              <input
                className="edit-input"
                type="tel"
                id="mobile"
                name="mobile"
                pattern="(\+44\s?)?[7-9]\d{9}|(0?[6-9]\d{9})"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobile}
              ></input>
              <ErrorMsg
                hasError={formik.touched.mobile && formik.errors.mobile}
              >
                {formik.errors.mobile}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Email</p>
              <input
                className="edit-input"
                type="email"
                autoComplete="off"
                id="email"
                name="email"
                disabled
                value={formik.values.email}
              ></input>
            </div>

            <div className="profile-input-card">
              <p>Birth Date</p>
              <input
                className="edit-input"
                type="date"
                id="dob"
                name="dob"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.dob}
              ></input>
              <ErrorMsg hasError={formik.touched.dob && formik.errors.dob}>
                {formik.errors.dob}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Company</p>
              <input
                className="edit-input"
                type="text"
                id="companyName"
                name="companyName"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
              ></input>
              <ErrorMsg
                hasError={
                  formik.touched.companyName && formik.errors.companyName
                }
              >
                {formik.errors.companyName}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Address</p>
              <input
                className="edit-input"
                type="text"
                id="address"
                name="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              ></input>
              <ErrorMsg
                hasError={formik.touched.address && formik.errors.address}
              >
                {formik.errors.address}
              </ErrorMsg>
            </div>
            <div className="profile-input-card">
              <p>Profile Image</p>
              <div className="d-flex">
                <div style={{ width: "5%", marginLeft: "10px" }}>
                  <FaUpload
                    onClick={handleClick}
                    style={{ cursor: "pointer" }}
                  />
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/*"
                    name="profile"
                    onChange={(event) => {
                      formik.setFieldValue(
                        "profile",
                        event.currentTarget.files[0]
                      );
                    }}
                    className="hidden"
                    ref={fileInputRef}
                  />
                </div>
              </div>
            </div>
            <p style={{ marginTop: "1rem" }}>
              <ErrorMsg hasError={formik.errors.submit}>
                {formik.errors.submit}
              </ErrorMsg>
            </p>
            <ButtonLoader
              className={"save-btn grow"}
              type={"button"}
              onClickhandle={formik.handleSubmit}
              buttonName={"Save"}
              isLoading={isLoading}
              loaderColor={"white"}
            >
              Save
            </ButtonLoader>
          </div>
        )}
        {activeDiv === 4 && (
          <div className="div4">
            {/* <ChangePassword /> */}
            <h5>Payment Details</h5>
            <div className="profile-input-card">
              <div className="mb-3">
                <p>Add your PayPal account</p>
                <input
                  className="password-input"
                  type="email"
                  autoComplete="off"
                  name="paypal_id"
                  id="paypal_id"
                  onChange={formik1.handleChange}
                  onBlur={formik1.handleBlur}
                  value={formik1.values.paypal_id}
                ></input>
                <ErrorMsg
                  hasError={
                    formik1.touched.paypal_id && formik1.errors.paypal_id
                  }
                >
                  {formik1.errors.paypal_id}
                </ErrorMsg>
              </div>
              <p style={{ marginTop: "1rem" }}>
                <ErrorMsg hasError={formik1.errors.submit}>
                  {formik1.errors.submit}
                </ErrorMsg>
              </p>
              <ButtonLoader
                className={"save-btn grow"}
                type={"button"}
                onClickhandle={formik1.handleSubmit}
                buttonName={"Save"}
                isLoading={isLoading}
                loaderColor={"white"}
              >
                Save
              </ButtonLoader>
            </div>
          </div>
        )}

        {activeDiv === 2 && <Hosts />}
      </div>
    </div>
  );
}

export default MyProfile;
