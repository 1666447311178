import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { RxTriangleRight } from "react-icons/rx";

const menuAnimation = {
  hidden: {
    opacity: 0,
    height: 0,
    padding: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
  show: {
    opacity: 1,
    height: "auto",
    transition: {
      duration: 0.3,
      when: "beforeChildren",
    },
  },
};
const menuItemAnimation = {
  hidden: (i) => ({
    padding: 0,
    y: "0%",
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
  show: (i) => ({
    y: 0,
    transition: {
      duration: (i + 1) * 0.1,
    },
  }),
};

const SidebarMenu = ({
  route,
  showAnimation,
  isOpen,
  setIsOpen,
  closeSidebar,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    setIsOpen(true);
  };

  useEffect(() => {
    if (!isOpen && window.innerWidth > 767) {
      setIsMenuOpen(false);
    }
  }, [isOpen]);
  return (
    <>
      <div className="menu" style={{ display: "block" }}>
        <div
          // to={route.path}
          className="main-link"
        >
          <div className="menu_item" onClick={toggleMenu}>
            <Link
              style={{ justifyContent: "flex-start" }}
              to={route.path}
              className="new_menu main-link "
              onClick={() => {
                if (window.innerWidth <= 767) {
                  closeSidebar();
                }
                else{
                  toggleMenu()
                }
               
              }}
            >
              <div className="route-icon">{route.icon}</div>
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </Link>

            {isOpen && (
              <motion.div
                className="dropdown-btn-width"
                style={{ transition: "all 0.3s ease 0s" }}
                animate={
                  isMenuOpen
                    ? {
                        rotate: 90,
                      }
                    : { rotate: 0 }
                }
              >
                <button className="drop-btn">
                  <RxTriangleRight
                    onClick={() => {
                      toggleMenu();
                    }}
                    className="grow"
                  />
                </button>
              </motion.div>
            )}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            variants={menuAnimation}
            initial="hidden"
            animate="show"
            exit="hidden"
            className="menu_container"
          >
            {route.subRoutes.map((subRoute, i) => (
              <motion.div variants={menuItemAnimation} key={i} custom={i}>
                {subRoute.path === "/wp-rental/dashboard" ? (
                  <a
                    className="link sublink"
                    href="http://stage.tridentguaranteedrent.com/propjrental"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <motion.div className="link_text">
                      {subRoute.name}
                    </motion.div>
                  </a>
                ) : (
                  <NavLink
                    exact
                    to={subRoute.path}
                    className="link sublink"
                    onClick={() => {
                      if (window.innerWidth <= 767) {
                        closeSidebar();
                      }
                    }}
                  >
                    {/* <div className="icon">{subRoute.icon}</div> */}
                    <motion.div className="link_text">
                      {subRoute.name}
                    </motion.div>
                  </NavLink>
                )}
              </motion.div>
            ))}
          </motion.div>
        )}{" "}
      </AnimatePresence>
    </>
  );
};

export default SidebarMenu;
