import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import * as React from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { clearPropertiesForm } from 'redux/properties/PropertiesSlice';
import { clearTenantForm } from 'redux/tenants/TenantsSlice';




const SelectAndAdd = ({ onChange, options, value, openModal, buttonText, label, onBlur }) => {

    const dispatch = useDispatch();

    const menuStyle = { maxHeight: "400px", };
    return (
        <>
            <div className="d-flex justify-content-between align-items-center gap-2 text-center text-sm-start">
                <div className="w-100">
                    <FormControl fullWidth>
                        <InputLabel style={{ lineHeight: "1em" }} id="demo-simple-select-label">{label ? label : ""}</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label={label ? label : ""}
                            onChange={onChange}
                            className="select__input"
                            onBlur={onBlur}
                            MenuProps={{ style: menuStyle }}
                        >
                            {options.map((option) => (
                                <MenuItem
                                    className='menu-item'
                                    value={option.value}
                                >{option.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <span>OR</span>
                <button
                    className="add_new_btn"
                    onClick={(() => {
                        openModal(true);
                        dispatch(clearPropertiesForm());
                        dispatch(clearTenantForm());
                    })}
                >
                    <FaPlus style={{ marginRight: "5px" }} />
                    <span className='d-none d-sm-block'>{buttonText ? buttonText : "add"}</span>
                </button>
            </div>
        </>


    );
}

export default SelectAndAdd;