import * as React from "react";
import { useState } from "react";
import { useLocation, } from "react-router-dom";
import { useSelector } from "react-redux";
import AddGeneralInfo from "./add/AddGeneralInfo";
import AddAddress from "./add/AddAddress";
import AddPhotos from "./add/AddPhotos";
import AddAmenities from "./add/AddAmenities";
import AddDescription from "./add/AddDescription";
import { useEffect } from "react";
import HorizontalLinearStepper from "components/Stepper";
import AddUnit from "./add/AddUnit";
import { AddOwnership } from "./add/AddOwnership";
import Axios from "utils/axios";
import Toast from "components/Toast";

const amenitiesBathroomInputs = [
  {
    title: "Bath",
    summary: "",
    name: "Bath",
  },
  {
    title: "Dryer",
    summary: "",
    name: "dryer",
  },
  {
    title: "Hair dryer",
    summary: "",
    name: "hair-dryer",
  },
  {
    title: "Bidet",
    summary: "",
    name: "bidet",
  },
  {
    title: "Body soap",
    summary: "",
    name: "body-soap",
  },
  {
    title: "Conditioner",
    summary: "",
    name: "conditioner",
  },
  {
    title: "Hot water",
    summary: "pots and pans, oil, salt and pepper.",
    name: "hot-water",
  },
  {
    title: "Outdoor shower",
    summary: "",
    name: "outdoor-shower",
  },
  {
    title: "Shampoo",
    summary: "",
    name: "shampoo",
  },
  {
    title: "Shower gel",
    summary: "",
    name: "shower-gel",
  },
  {
    title: "Washing machine",
    summary: "Free - in building.",
    name: "washing-machine",
  },
  {
    title: "Hot tub",
    summary: "",
    name: "hot-tub",
  },
  {
    title: "Cleaning products",
    summary: "",
    name: "cleaning-products",
  },

  {
    title: "Pool",
    summary: "",
    name: "pool",
  },
];

const amenitiesBedroomInputs = [
  {
    title: "Essential",
    summary: "towel, bedsheet, toilet paper, soap and pillow.",
    name: "essential",
  },
  {
    title: "Bed linen",
    summary: "Cotton",
    name: "bed-linen",
  },
  {
    title: "Clothes storage",
    summary: "Wardrobe",
    name: "clothes-storage",
  },
  {
    title: "Clothes dryer rack",
    summary: "",
    name: "clothes-dryer-rack",
  },
  {
    title: "Extra pillow and blankets",
    summary: "Space where guests can ccok their own meals.",
    name: "extra-pillow-blanckets",
  },
  {
    title: "Hangers",
    summary: "",
    name: "hangers",
  },
  {
    title: "Iron",
    summary: "",
    name: "iron",
  },
  {
    title: "Mosquito net",
    summary: "",
    name: "mosquito net",
  },
  {
    title: "Room-darkening shade",
    summary: "Available throughout the listing.",
    name: "room-darkening-shade",
  },
  {
    title: "Safe",
    summary: "",
    name: "safe",
  },
];

const amenitiesEntertaimentInputs = [
  {
    title: "Arcade games",
    summary: "",
    name: "arcade-games",
  },
  {
    title: "Batting cage",
    summary: "",
    name: "batting-cage",
  },
  {
    title: "TV",
    summary: "",
    name: "TV",
  },
  {
    title: "Books and reading material",
    summary: "",
    name: "books-and-reading-material",
  },
  {
    title: "Bowling alley",
    summary: "",
    name: "bolling-alley",
  },
  {
    title: "Climbing wall",
    summary: "",
    name: "climbing-wall",
  },
  {
    title: "Ethernet Connection",
    summary: "",
    name: "ethernet-connection",
  },
  {
    title: "Exercise Equipment",
    summary: "",
    name: "excercise-equipment",
  },
  {
    title: "Games Console",
    summary: "",
    name: "games-console",
  },
  {
    title: "Laser tag",
    summary: "",
    name: "laser-tag",
  },
  {
    title: "Life-size games",
    summary: "",
    name: "life-size-games",
  },
  {
    title: "Mini golf",
    summary: "",
    name: "mini-golf",
  },
  {
    title: "Cinema",
    summary: "",
    name: "cinema",
  },
  {
    title: "Piano",
    summary: "",
    name: "piano",
  },
  {
    title: "Ping pong table",
    summary: "",
    name: "ping-pong-table",
  },
  {
    title: "Pool table",
    summary: "",
    name: "pool-table",
  },
  {
    title: "Record player",
    summary: "",
    name: "record-player",
  },
  {
    title: "Skate ramp",
    summary: "",
    name: "skate-ramp",
  },
  {
    title: "Sound system",
    summary: "",
    name: "sound-system",
  },
  {
    title: "Theme room",
    summary: "",
    name: "theme-room",
  },
];

const amenitiesFamiltInputs = [
  {
    title: "Baby Bath",
    summary: "",
    name: "baby-bath",
  },
  {
    title: "Baby monitor",
    summary: "",
    name: "baby-monitor",
  },
  {
    title: "Children's room",
    summary: " a indoor room with toys, books and games.",
    name: "Childrens-room",
  },
  {
    title: "Baby safety gates",
    summary: "",
    name: "baby-safety-gates",
  },
  {
    title: "Babysitter recommendations",
    summary: "",
    name: "babysitter-recommendations",
  },
  {
    title: "Board games",
    summary: "",
    name: "board-games",
  },
  {
    title: "Changing tables",
    summary: "",
    name: "changing-tables",
  },
  {
    title: "Children's books and toys",
    summary: "",
    name: "childrens-books-and-toys",
  },
  {
    title: "Children's tableware",
    summary: "",
    name: "childrens-tableware",
  },
  {
    title: "Cot",
    summary: "",
    name: "cot",
  },
  {
    title: "Fireplace guards",
    summary: "",
    name: "fireplace-guards",
  },
  {
    title: "High chair",
    summary: "",
    name: "high-chair",
  },
  {
    title: "Outdoor Playground",
    summary: "A outdoor area equipped with a play structure for children.",
    name: "outdoor-playground",
  },
  {
    title: "Plug socket covers",
    summary: "",
    name: "plug-socket-covers",
  },
  {
    title: "Travel Cot",
    summary: "",
    name: "travel-cot",
  },
  {
    title: "Table corner guards",
    summary: "",
    name: "table-corner-guards",
  },
  {
    title: "Window guards",
    summary: "",
    name: "window-guards",
  },
];

const amenitiesHeatingInputs = [
  {
    title: "Air conditioning",
    summary: "",
    name: "air-conditioning",
  },
  {
    title: "Heating",
    summary: "",
    name: "heating",
  },
  {
    title: "Ceiling fan",
    summary: "",
    name: "ceiling-fan",
  },
  {
    title: "Portable fans",
    summary: "",
    name: "portable-fans",
  },
  {
    title: "Indoor fireplace",
    summary: "",
    name: "indoor-fireplace",
  },
];

const amenitiesHomeSafety = [
  {
    title: "Carbon monoxide alarm",
    summary: "",
    name: "carbon-monoxide-alrm",
  },
  {
    title: "Fire extinguisher",
    summary: "",
    name: "fire-extinguisher",
  },
  {
    title: "First aid kit",
    summary: "",
    name: "first-air kid",
  },
  {
    title: "Smoke Alarm",
    summary: "",
    name: "smoke-alarm",
  },
];

const amenitiesKitchen = [
  {
    title: "Kitchen",
    summary: "Space where guests can ccok their own meals.",
    name: "kitchen",
  },
  {
    title: "Cooking basics",
    summary: "pots and pans, oil, salt and pepper.",
    name: "cooking-basics",
  },

  {
    title: "Dishes and silverware",
    summary: "Bowls, chopsticks, plates, cups etc.",
    name: "dishes-silverware",
  },
  {
    title: "Baking sheet",
    summary: "",
    name: "baking-sheet",
  },
  {
    title: "Barbecue utensils",
    summary: "Grill, charcoal, bamboo skewers/iron skewer, etc. ",
    name: "barbecue-utensils",
  },
  {
    title: "Bread maker",
    summary: "",
    name: "bread-maker",
  },
  {
    title: "Blender",
    summary: "",
    name: "blender",
  },
  {
    title: "Coffee",
    summary: "",
    name: "Coffee",
  },
  {
    title: "Coffee maker",
    summary: "",
    name: "Coffee-maker",
  },
  {
    title: "Dining table",
    summary: "",
    name: "dining-table",
  },
  {
    title: "Dishwasher",
    summary: "",
    name: "dishwasher",
  },
  {
    title: "Freezer",
    summary: "",
    name: "freezer",
  },
  {
    title: "Kettle",
    summary: "",
    name: "kettle",
  },
  {
    title: "Kitchenette",
    summary: "",
    name: "kitchenette",
  },
  {
    title: "Microwave",
    summary: "",
    name: "microwave",
  },
  {
    title: "Mini fridge",
    summary: "",
    name: "mini-fridge",
  },
  {
    title: "Oven",
    summary: "",
    name: "oven",
  },
  {
    title: "Refrigerator",
    summary: "",
    name: "refrigerator",
  },
  {
    title: "Rice cooker",
    summary: "",
    name: "rice-cooker",
  },
  {
    title: "Stove",
    summary: "",
    name: "stove",
  },
  {
    title: "Toaster",
    summary: "",
    name: "toaster",
  },
  {
    title: "Waste Compactor",
    summary: "",
    name: "waste-compactor",
  },
  {
    title: "Wine glasses",
    summary: "",
    name: "wine-glasses",
  },
];

const amenitiesLocationInputs = [
  {
    title: "Beach access",
    summary: "Guest can enjoy a nearby beach.",
    name: "beach-access",
  },
  {
    title: "Lake access",
    summary: "guest can get to a lake using a path or dock.",
    name: "lake-access",
  },
  {
    title: "Launderette nearby",
    summary: "",
    name: "launderette-nearby",
  },
  {
    title: "Private entrance",
    summary: "Separate street or building",
    name: "private-entrance",
  },
  {
    title: "Resort access",
    summary: "Guest can use a nearby resort facilities.",
    name: "resort-access",
  },
  {
    title: "Sky-in/Sky-out",
    summary:
      "Guests can access sky lifts without driving or taking paid transport.",
    name: "sky-in-sky-out",
  },
  {
    title: "Water front",
    summary: "Right next to a body of water.",
    name: "water-front",
  },
  {
    title: "Garden",
    summary: "A open space on the property usually covered in grass.",
    name: "garden",
  },
  {
    title: "BBQ grill",
    summary: "",
    name: "bbq-grill",
  },
  {
    title: "Beach essentials",
    summary: "Beach towel, umbrella, beach blanket, snorkelling gear.",
    name: "beach-essentials",
  },
  {
    title: "Bikes",
    summary: "",
    name: "bikes",
  },
  {
    title: "Boat berth",
    summary: "",
    name: "boat-berth",
  },
  {
    title: "Fire pit",
    summary: "",
    name: "fire-pit",
  },
  {
    title: "Hammok",
    summary: "",
    name: "hammok",
  },
  {
    title: "Kayak",
    summary: "",
    name: "kayak",
  },
  {
    title: "Outdoor dining area",
    summary: "",
    name: "outdoor-dining-area",
  },
  {
    title: "Outdoor furniture",
    summary: "",
    name: "outdoor-furniture",
  },
  {
    title: "Outdoor kitchen",
    summary: "",
    name: "outdoor-kitchen",
  },
  {
    title: "Patio or balcony",
    summary: "",
    name: "patio-or-balcony",
  },
  {
    title: "Sun loungers",
    summary: "",
    name: "sun-loungers",
  },
];

const amenitiesParkingInputs = [
  {
    title: "Lift",
    summary: "",
    name: "lift",
  },
  {
    title: "EV charger",
    summary: "",
    name: "ev-charger",
  },
  {
    title: "Free parking on premises",
    summary: "",
    name: "free-parking-on-premises",
  },
  {
    title: "Hockey rink",
    summary: "",
    name: "hockey-rink",
  },
  {
    title: "Free on street-parking",
    summary: "",
    name: "free-on-street-parking",
  },
  {
    title: "Gym",
    summary: "",
    name: "gym",
  },
  {
    title: "Paid parking off premises",
    summary: "",
    name: "paid-parking-off-premises",
  },
  {
    title: "Paid parking on premises",
    summary: "",
    name: "paid-parking-on-premises",
  },
  {
    title: "Private living room",
    summary: "",
    name: "private-parking-room",
  },
  {
    title: "Sauna",
    summary: "",
    name: "sauna",
  },
  {
    title: "Breakfast",
    summary: "breakfast is provided.",
    name: "breakfast",
  },
  {
    title: "Cleaning available during stay",
    summary: "",
    name: "cleaning-available",
  },
  {
    title: "Long-term stays allowed",
    summary: "",
    name: "long-term-stays-allowed",
  },
  {
    title: "Luggage drop-off allowed",
    summary: "",
    name: "luggage-drop-off-allowed",
  },
];

const amenitiesInternet = [
  {
    title: "Dedicated workSpace",
    summary: "In a common space.",
    name: "dedicated-workspace",
  },
  {
    title: "Wifi",
    summary: "Available throughout the listing.",
    name: "wifi",
  },
  {
    title: "Pocket wifi",
    summary: "",
    name: "pocket-wifi",
  },
];

const AddForm = ({ onClose, modal }) => {
  const [property, setProperty] = useState();

  const location = useLocation();

  const { property_id } = location.state || {};

  const userObj = useSelector((state) => state.auth.user);

  const fatchProperty = (property_id) => {
    Axios.get(
      `/properties/detail-property?id=${userObj.id}&property_id=${property_id}&userType=${userObj.currentType}`
    )
      .then((res) => {
        setProperty(res.data.data[0]);
        if (res.data.data[0]?._id) {
          setActiveStep(res.data.data[0].stepperState);
        }
      })
      .catch((err) => {
        // toast.error(err, { position: "bottom-left" });
        Toast(err, 'error');
      });
  };

  useEffect(() => {
    if (property_id) {
      fatchProperty(property_id);
    }
  }, [property_id]);

  const [amenitiesData, setAmenitiesData] = useState([]);

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = [
    "General Info",
    "Address",
    "Units",
    "Photos",
    "Additional Info",
    "Description",
    "Ownership",
  ];

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

 
  useEffect(
    () => {
      const allData = [
        amenitiesBathroomInputs,
        amenitiesBedroomInputs,
        amenitiesEntertaimentInputs,
        amenitiesFamiltInputs,
        amenitiesHeatingInputs,
        amenitiesHomeSafety,
        amenitiesInternet,
        amenitiesKitchen,
        amenitiesLocationInputs,
        amenitiesParkingInputs,
      ];

      const updatedData = {};
      allData.forEach((item) => {
        const extractedData = item.map((subItem) => subItem.name);
        extractedData.forEach((value) => {
          updatedData[value] = false;
        });
      });

      // Update the state with the accumulated data

      var intialAmenities = {};
      intialAmenities = { ...intialAmenities, ...updatedData };

      setAmenitiesData(intialAmenities);
    }, // eslint-disable-next-line
    []
  );



  return (
    <div>
      <HorizontalLinearStepper
        formSteps={steps}
        alternativeLabel={true}
        form={
          activeStep === 0 ? (
            <AddGeneralInfo
              handleNext={handleNext}
              handleBack={handleBack}
              property={property}
              setProperty={setProperty}
            />
          ) : activeStep === 1 ? (
            <AddAddress
              handleNext={handleNext}
              property={property}
              setProperty={setProperty}
              handleBack={handleBack}
            />
          ) : activeStep === 2 ? (
            <AddUnit
              handleNext={handleNext}
              handleBack={handleBack}
              property={property}
              setProperty={setProperty}
            />
          ) : activeStep === 3 ? (
            <AddPhotos
              handleNext={handleNext}
              handleBack={handleBack}
              property={property}
              setProperty={setProperty}
            />
          ) : activeStep === 4 ? (
            <AddAmenities
              amenitiesData={amenitiesData}
              handleNext={handleNext}
              handleBack={handleBack}
              property={property}
              setProperty={setProperty}
            />
          ) : activeStep === 5 ? (
            <AddDescription
              handleNext={handleNext}
              handleBack={handleBack}
              property={property}
              setProperty={setProperty}
            />
          ) : (
            <AddOwnership
              fatchProperty={fatchProperty}
              handleNext={handleNext}
              property={property}
              setProperty={setProperty}
              handleBack={handleBack}
              modal={modal}
              onCloseModal={() => {
                onClose();
              }}
            />
          )
        }
        activeStep={activeStep}
        isStepSkipped={(val) => {
          isStepSkipped(val);
        }}
        isStepOptional={(val) => {
          isStepOptional(val);
        }}
      />
    </div>
  );
};

export default AddForm;
