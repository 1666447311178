import {createSlice } from "@reduxjs/toolkit";
import {loginAsync, adminLoginAsync} from "./Services"
import _ from "lodash";

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {
    loading: false,
    loginForm: {},
    userId: {},
  },
  reducers: {
    changeLoginForm:(state, action) => {
      let update_form = _.cloneDeep(state.loginForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        loginForm:{
          ...state.loginForm,
          ...update_form,
        }
      }
    },
    clearLoginForm: (state, action) => {
      state.loginForm = {};
    },
  },
  extraReducers: {
    [loginAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [loginAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
      
      
      // state.userId = action?.payload.data.id;
    //   state.program = action.payload;
    
    },
    [loginAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [adminLoginAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [adminLoginAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [adminLoginAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default loginSlice.reducer;

export const {
  changeLoginForm,
  clearLoginForm,
} = loginSlice.actions;
