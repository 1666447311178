import TextInput from "components/form/TextInput";
import React, { useRef } from "react";
import styles from "../newProperty.module.css";
import { FieldArray, useFormik, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import SelectInput from "components/form/SelectInput";
import ErrorMsg from "components/ErrorMsg";
import Axios from "utils/axios";
import { ButtonLoader } from "components/form/ButtonLoader";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import SelectSuboptionInput from "components/form/SelectSubOptionInput";
import AddManager from "../../propertyManager/AddManager";
import Checkboxes from "components/form/Checkbox";
import { useDispatch } from "react-redux";
import { clearManagerForm } from "redux/propertyManagers/ManagersSlice";

const propertyunitTypeList = [
  { label: "Single Occupancy " },
  { label: "Studio flat", value: "Studio flat" },
  { label: "Bed sit", value: "Bed sit" },
  { label: "Entire House " },
  { label: "Detach house", value: "Detach house" },
  { label: "Semi-detach house", value: "Semi-detach House" },
  { label: "Terraced House", value: "Terraced house" },
  { label: "Bungalow", value: "Bungalow" },
  { label: "End Terrace", value: "End terrace" },
  { label: "Entire flat " },
  { label: "Flat", value: "Flat" },
  { label: "Pent House", value: "pent house" },
  { label: "Maisonette", value: "maisonette" },
  { label: "Room in a shared Property" },
  { label: "Room in a shared flat", value: "Room in a shared flat" },
  { label: "Room in a shared house", value: "Room in a shared house" },
  { label: "Other" },
  { label: "Other", value: "Other" },
];

export default function AddUnit({
  handleNext,
  handleBack,
  property,
  setProperty,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const userObj = useSelector((state) => state.auth.user);
  const userType = userObj?.currentType;
  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const ll_units = [];
    values.allUnits.map((unit) => {
      if (unit._id === "") {
        for (let k in unit) unit[k] == "" && delete unit[k];

        ll_units.push(unit);
      } else {
        ll_units.push(unit);
      }
    });

    setIsLoading(true);
    const payload = {
      propertyId: property._id,
      ll_units: ll_units,
    };

    if (property?._id) {
      Axios.post("/longlettingProperties/addUnit", payload)
        .then((response) => {
          handleNext();

          setProperty(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrors({ submit: err });
          setIsLoading(false);
        });
    }
  };

  const validationSchema = Yup.object().shape({
    allUnits: Yup.array()
      .of(
        Yup.object().shape({
          unitName: Yup.string().required("Unit Name*"),
          unitType: Yup.string().required("Unit Type*"),
          unitBeds: Yup.number()
            .min(1, "No. of Beds*")
            .required("No. of Beds*"),
          unitBathrooms: Yup.number()
            .min(1, "No. of Baths*")
            .required("No. of Baths*"),
          unitFurnished: Yup.string().required("Furnished status*"),
          // manager: Yup.string().when("manageByMe", {
          //   is: false,
          //   then: () => Yup.string().required("Please select manager"),
          // }),
        })
      )
      .min(1, "At least one unit is required"),
  });
  const validationSchema1 = Yup.object().shape({
    allUnits: Yup.array()
      .of(
        Yup.object().shape({
          unitName: Yup.string().required("Unit Name*"),

          unitFurnished: Yup.string().required("Furnished status*"),
        })
      )
      .min(1, "At least one unit is required"),
  });

  const [ManagerList, setManagerList] = useState([]);

  const getManagerList = () => {
    Axios.get(`/property-managers?id=${userObj.id}&page=${1}&limit=${200}`)
      .then((res) => {
        setManagerList(
          res.data.data?.map((data) => {
            return {
              value: data._id,
              label: data.first_name + " " + data?.last_name,
            };
          })
        );
      })
      .catch(() => { });
  };
  const dispatch = useDispatch();
  const debug = true;

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (index) => {
    setShow(index);
  };

  const formikRef = useRef();

  useEffect(() => {
    getManagerList();
    if (property?.ll_units?.length > 0) {
      formikRef.current.setFieldValue(
        "allUnits",
        property.propertyKind === "Residential"
          ? property?.ll_units.map((unit, index) => ({
            unitName: property.ll_units[index].unitName,
            unitType: property.ll_units[index].unitType,
            unitBeds: property.ll_units[index].unitBeds,
            manager: property.ll_units[index].manager,
            agent: property.ll_units[index].agent,
            _id: property.ll_units[index]._id,
            size: property.ll_units[index].size,
            marketRent: property.ll_units[index].marketRent,
            unitFurnished: property.ll_units[index].unitFurnished,
            unitBathrooms: property.ll_units[index].unitBathrooms,
            manageByMe: property.ll_units[index].manageByMe,
          }))
          : property?.ll_units.map((unit, index) => ({
            unitName: property.ll_units[index].unitName,
            unitType: "",
            unitBeds: "",
            manager: property.ll_units[index].manager,
            agent: property.ll_units[index].agent,
            _id: property.ll_units[index]._id,
            size: property.ll_units[index].size,
            marketRent: property.ll_units[index].marketRent,
            unitFurnished: property.ll_units[index].unitFurnished,
            unitBathrooms: "",
            manageByMe: property.ll_units[index].manageByMe,
          }))
      );
    }
  }, [property]);

  return (
    <div className="row mt-5">
      <div className="col-md-12">
        <div className={styles.main_heading + " text-center mb-4 "}>
          <h3 className="fw-bold h4 mb-0">Units</h3>
        </div>
      </div>

      <Formik
        innerRef={formikRef}
        initialValues={{
          allUnits: [
            {
              _id: "",
              unitName: "",
              unitType: "",
              agent: "",
              marketRent: "",
              size: "",
              unitBeds: "",
              unitBathrooms: "",
              unitFurnished: "",
              manager: "",
              manageByMe: false,
            },
          ],
        }}
        validationSchema={
          property?.propertyKind === "Residential"
            ? validationSchema
            : validationSchema1
        }
        onSubmit={handleSubmit}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setFieldValue,
          handleSubmit,
        }) => (
          <Form noValidate autoComplete="off">
            <FieldArray name="allUnits">
              {({ push, remove }) => (
                <div>
                  {values.allUnits?.map((p, index) => {
                    const unitName = `allUnits[${index}].unitName`;
                    const touchedUnitName = getIn(touched, unitName);
                    const errorUnitName = getIn(errors, unitName);

                    const unitType = `allUnits[${index}].unitType`;
                    const touchedunitType = getIn(touched, unitType);
                    const errorunitType = getIn(errors, unitType);

                    const agent = `allUnits[${index}].agent`;
                    const marketRent = `allUnits[${index}].marketRent`;
                    const size = `allUnits[${index}].size`;
                    const manager = `allUnits[${index}].manager`;
                    const touchedmanager = getIn(touched, manager);
                    const errormanager = getIn(errors, manager);

                    const manageByMe = `allUnits[${index}].manageByMe`;

                    const unitBeds = `allUnits[${index}].unitBeds`;
                    const touchedunitBeds = getIn(touched, unitBeds);
                    const errorunitBeds = getIn(errors, unitBeds);

                    const unitBathrooms = `allUnits[${index}].unitBathrooms`;
                    const touchedunitBathrooms = getIn(touched, unitBathrooms);
                    const errorunitBathrooms = getIn(errors, unitBathrooms);

                    const unitFurnished = `allUnits[${index}].unitFurnished`;
                    const touchedunitFurnished = getIn(touched, unitFurnished);
                    const errorunitFurnished = getIn(errors, unitFurnished);

                    return (
                      <div key={index} className="col-md-12">
                        <div className={styles.v_card + " px-1 px-sm-3 py-3 mb-3"}>
                          <div
                            className={
                              styles.v_card_header +
                              " d-flex align-items-center justify-content-center p-0 gap-2"
                            }
                          >
                            <span className={styles.number}>{index + 1}</span>
                            <div className={styles.v_card_body + " w-100 row "}>
                              <div
                                className={
                                  styles.unit_v_grid + " unit-v-grid w-100 d-grid d-xl-flex gap-1 "
                                }
                              >
                                <div
                                  className={styles.v_col}
                                  style={{ width: "100%" }}
                                >
                                  <TextInput
                                    customClass={
                                      styles.vinput + " w-100 bg-white rounded mb-2 mb-xl-0"
                                    }
                                    label="Unit name*"
                                    name={unitName}
                                    value={p.unitName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />

                                  <ErrorMsg
                                    hasError={touchedUnitName && errorUnitName}
                                  >
                                    {errorUnitName}
                                  </ErrorMsg>
                                </div>

                                {property?.propertyKind === "Residential" && (
                                  <div
                                    className={styles.v_col}
                                    style={{ width: "100%" }}
                                  >
                                    <SelectSuboptionInput
                                      customClass={
                                        styles.vselect +
                                        " w-100 bg-white rounded mb-2 mb-xl-0"
                                      }
                                      label="Unit Type*"
                                      name={unitType}
                                      selectedValue={p.unitType}
                                      options={propertyunitTypeList}
                                      onChange={(e) =>
                                        setFieldValue(unitType, e)
                                      }
                                    />
                                    <ErrorMsg
                                      hasError={
                                        touchedunitType && errorunitType
                                      }
                                    >
                                      {errorunitType}
                                    </ErrorMsg>
                                  </div>
                                )}
                                {property?.propertyKind === "Residential" && (
                                  <div
                                    className={styles.v_col}
                                    style={{ minWidth: "85px", width: "11%" }}
                                  >
                                    <TextInput
                                      customClass={
                                        styles.vinput +
                                        " w-100 bg-white rounded mb-2 mb-xl-0"
                                      }
                                      label="Beds*"
                                      type="number"
                                      name={unitBeds}
                                      value={p.unitBeds}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />

                                    <ErrorMsg
                                      hasError={
                                        touchedunitBeds && errorunitBeds
                                      }
                                    >
                                      {errorunitBeds}
                                    </ErrorMsg>
                                  </div>
                                )}
                                {property?.propertyKind === "Residential" && (
                                  <div
                                    className={styles.v_col}
                                    style={{ minWidth: "85px", width: "12%" }}
                                  >
                                    <TextInput
                                      customClass={
                                        styles.vinput +
                                        " w-100 bg-white rounded mb-2 mb-xl-0"
                                      }
                                      label="Baths*"
                                      type="number"
                                      name={unitBathrooms}
                                      value={p.unitBathrooms}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                    />

                                    <ErrorMsg
                                      hasError={
                                        touchedunitBathrooms &&
                                        errorunitBathrooms
                                      }
                                    >
                                      {errorunitBathrooms}
                                    </ErrorMsg>
                                  </div>
                                )}
                                <div
                                  className={styles.v_col}
                                  style={{ width: "100%" }}
                                >
                                  <SelectInput
                                    customClass={
                                      styles.vselect + " w-100 bg-white rounded mb-2 mb-xl-0"
                                    }
                                    label="Furnished Option*"
                                    name={unitFurnished}
                                    selectedValue={p.unitFurnished}
                                    options={[
                                      {
                                        label: "Furnished",
                                        value: "Furnished",
                                      },
                                      {
                                        label: "Semi Furnished",
                                        value: "Semi Furnished",
                                      },
                                      {
                                        label: "Furnished as per Tenant choice",
                                        value: "Furnished as per Tenant choice",
                                      },
                                    ]}
                                    onChange={(e) => {
                                      setFieldValue(
                                        unitFurnished,
                                        e.target.value
                                      );
                                    }}
                                  />

                                  <ErrorMsg
                                    hasError={
                                      touchedunitFurnished && errorunitFurnished
                                    }
                                  >
                                    {errorunitFurnished}
                                  </ErrorMsg>
                                </div>
                                <div
                                  className={styles.v_col}
                                  style={{ minWidth: "90px", width: "12%" }}
                                >
                                  <TextInput
                                    customClass={
                                      styles.vinput + " w-100 bg-white rounded mb-2 mb-xl-0"
                                    }
                                    label="Size(Sq.ft)"
                                    type="number"
                                    name={size}
                                    value={p.size}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div
                                  className={styles.v_col}
                                  style={{ width: "100%" }}
                                >
                                  <TextInput
                                    customClass={
                                      styles.vinput + " w-100 bg-white rounded mb-2 mb-xl-0"
                                    }
                                    label="Market Rent (£)"
                                    type="number"
                                    name={marketRent}
                                    value={p.marketRent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                                <div
                                  className={styles.v_col}
                                  style={{ width: "100%" }}
                                >
                                  <SelectInput
                                    customClass={
                                      styles.vselect + " w-100 bg-white rounded mb-2 mb-xl-0"
                                    }
                                    label="Manager"
                                    name={manager}
                                    selectedValue={p.manager}
                                    options={ManagerList}
                                    onChange={(e) => {
                                      setFieldValue(manager, e.target.value);
                                    }}
                                    disabled={ManagerList?.length === 0}
                                  />
                                  {/* <ErrorMsg
                                    hasError={
                                      touchedmanager && errormanager
                                    }
                                  >
                                    {errormanager}
                                  </ErrorMsg> */}
                                  {ManagerList?.length === 0 && (
                                    <p
                                      onClick={() => {
                                        handleShow(index + 1);
                                        dispatch(clearManagerForm());
                                      }}
                                      style={{
                                        width: "fit-content",
                                        fontSize: "0.8rem",
                                      }}
                                      className="pointer text-primary gap-2 mt-1"
                                    >
                                      <span>+</span> Add Manager
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={styles.v_col}
                                  style={{ width: "100%" }}
                                >
                                  <TextInput
                                    customClass={
                                      styles.vinput + " w-100 bg-white rounded"
                                    }
                                    label="Agent"
                                    name={agent}
                                    value={p.agent}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <div className="v-check-box d-flex align-items-center">
                                    <Checkboxes
                                      customClass={"amenities-checked"}
                                      value={p.manageByMe}
                                      name={manageByMe}
                                      onChange={(e) =>
                                        setFieldValue(manageByMe, !p.manageByMe)
                                      }
                                    />
                                    <label className="input-heading check-label">
                                      Manage by me
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <span
                              onClick={() => remove(index)}
                              className={styles.cross + " pointer "}
                            >
                              X
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className="text-right mt-1">
                    <p className=" d-flex align-items-center gap-2 text-primary justify-content-end ">
                      <p
                        onClick={() =>
                          push({
                            _id: "",
                            unitName: "",
                            unitType: "",
                            agent: "",
                            marketRent: "",
                            size: "",
                            unitBeds: "",
                            unitBathrooms: "",
                            unitFurnished: "",
                            manager: "",
                            manageByMe: false,
                          })
                        }
                        style={{ width: "fit-content" }}
                        className="pointer"
                      >
                        <span>+</span> Add another unit
                      </p>
                    </p>
                  </div>
                </div>
              )}
            </FieldArray>
            <p style={{ marginTop: "1rem" }}>
              <ErrorMsg hasError={errors.submit}>{errors.submit}</ErrorMsg>
            </p>
            <p style={{ marginTop: "1rem" }}>
              <ErrorMsg hasError={typeof errors.allUnits === "string"}>
                {typeof errors.allUnits === "string" && errors.allUnits}
              </ErrorMsg>
            </p>

            <div className="mt-3 d-flex  justify-content-center justify-content-md-end">
              <div className="mt-3 d-flex justify-content-end">
                <button
                  className="gray-btn grow "
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Back
                </button>
                <ButtonLoader
                  type="button"
                  className="primary-btn grow  ml-3"
                  isLoading={isLoading}
                  buttonName={"Next"}
                  loaderColor={"white"}
                  onClickhandle={handleSubmit}
                >
                  Next
                </ButtonLoader>
              </div>
            </div>
          </Form>
        )}
      </Formik>

      {show && (
        <div className="popup-overlay">
          <div
            className="bg-card report-card  overflow-y owner-width"
            style={{ padding: "0.51rem" }}
          >
            <AddManager
              property={property}
              onClose={handleClose}
              getManagerList={getManagerList}
              setformikValue={(e) =>
                formikRef.current.setFieldValue(
                  `allUnits[${show - 1}].manager`,
                  e
                )
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}
