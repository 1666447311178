import SwitchButton from "components/form/SwitchButton";
import * as React from "react";

export default function CertificateDetails({ closeModal, data }) {
  const dateFormat = (dateString) => {
    const date = new Date(dateString);
    // Get the date components
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are zero-based, so add 1
    const day = date.getDate();
    // Create the formatted date string
    const formattedDate = `${day.toString().padStart(2, "0")}/${month
      .toString()
      .padStart(2, "0")}/${year}`;
    return formattedDate;
  };

  return (
    <div className="row w-100 m-0 align align-content-between d-grid d-md-flex min-vh-100">
      <div className="col-12 col-md-6 col-lg-9" style={{ textAlign: "center" }}>
        <div style={{ padding: "0px 30px", height: "100%" }}>
          <div className="document-heading">{data.certificateType}</div>
          {data?.file?.slice(-4) === ".pdf" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                height: "75%",
              }}
            >
              <a
                style={{ width: "fit-content", margin: "auto" }}
                href={data?.file}
                download
              >
                <img
                  className="document-img"
                  src={"/images/pdf image.png"}
                  style={{
                    objectFit: "contain",
                    height: "250px",
                    width: "250px",
                    margin: "auto",
                  }}
                  alt="Invalid Url"
                />
              </a>
            </div>
          ) : (
            <img className="document-img" src={data?.file} alt="File is missing" />
          )}
        </div>
      </div>
      <div className="col-12 col-md-6 col-lg-3 p-0">
        <div className="document_detail_modal">
          <div className="modal_document-container ">
            <div className="modal_title">
              <h1>{data.certificateType}</h1>
              <button onClick={closeModal}>X</button>
            </div>
            <div className="">
              <h6 className="document-title">Summary</h6>

              <div className="document-detail">
                <h6>Property or unit</h6>
                <p>
                  {data?.unit?.unitName ? data?.unit?.unitName + " - " : ""}{" "}
                  {data?.property?.title}
                </p>
              </div>
              <div className="document-detail">
                <h6>notes</h6>
                <p>{data.remark}</p>
              </div>
              <div className="document-detail">
                <h6>Uploaded</h6>
                <p>{dateFormat(data.createdAt)}</p>
              </div>
              <div className="document-detail">
                <h6>Expiry</h6>
                <p>{dateFormat(data.expiryDate)}</p>
              </div>
              <div className="document-detail">
                <h6>Created by</h6>
                <p>{data?.user?.firstName + " " + data?.user?.lastName}</p>
              </div>
            </div>
            <div className="">
              <h6 className="document-title">Sharing</h6>
              <div className="sharing-card d-flex mt-3">
                <SwitchButton checked={data?.share?.landlord} />
                <div>
                  <div className="file-sharing-toggle__title">Landlord</div>
                  <p className="file-sharing-toggle__description">
                    Share with Landlord of this property
                  </p>
                </div>
              </div>
              <div className="sharing-card d-flex mt-3">
                <SwitchButton checked={data?.share?.lettingAgents} />
                <div>
                  <div className="file-sharing-toggle__title">
                    Letting Agents
                  </div>
                  <p className="file-sharing-toggle__description">
                    Share with Letting Agents of this property
                  </p>
                </div>
              </div>
              <div className="sharing-card d-flex mt-3">
                <SwitchButton checked={data?.share?.tenants} />
                <div>
                  <div className="file-sharing-toggle__title">All Tenants</div>
                  <p className="file-sharing-toggle__description">
                    Share with All Tenants of this property
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
