import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function PasswordInputWithToggle({
  customClass,
  placeholder,
  password,
  setPassword,
  name,
  onChange,
  onBlur
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

 
  return (
    <TextField
      className={customClass}
      style={{ width: "100%" }}
      type={showPassword ? "text" : "password"}
      autoComplete="new-password"
      // label="Password"
      name={name}
      variant="outlined"
      value={password}
      placeholder={placeholder ? placeholder : "Password"}
      onChange={onChange}
      onBlur={onBlur}
      InputProps={{
        autoComplete:"off",
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handlePasswordToggle} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default PasswordInputWithToggle;
