import { useEffect, useRef, useState } from "react"
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Sidebar from "./navigation/Sidebar";
import SLHeader from "./headers/SLHeader";
import useSocket from "hooks/useSocket";
import "../franchise.css";


const SLAdminLayout = (props) => {

  const { socketConnect } = useSocket();
  const location = useLocation();
  const containerRef = useRef(null);

  const [isOpen, setIsOpen] = useState(window.innerWidth <= 768 ? false : true);
  const toggle = () => setIsOpen(!isOpen);

  // Callback function to update the isOpen state in the parent component
  const handleSidebarToggle = (newIsOpen) => {
    setIsOpen(newIsOpen);
  };

  useEffect(() => {
    containerRef.current.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    socketConnect();
  }, []);

  return (
    <>
      <SLHeader changeToggle={() => toggle()} />

      <div className="main-container ">
        <Sidebar open={isOpen} checkSidebar={handleSidebarToggle} />
        <div ref={containerRef} className={`${isOpen ? "main2 w-100 wdh1" : "main2 w-100 wdh2"}`}>
          <Outlet />
        </div>
      </div>

      {/* This is used to show toast notifications */}
      <ToastContainer />
    </>
  )
}

export default SLAdminLayout;
