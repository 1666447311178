import * as React from "react";
import LoginForm from "../components/loginForm";
import styles from "./LoginPage.module.css";
import { useState } from "react";
import SignUpPage from "../signUp";
import ForgotPassword from "../forgotPassword";

function LoginPage({ onClose, formType }) {
  const [activeForm, setActiveForm] = useState(formType);

  return (
    <div>
      <div className={styles.popup_overlay}>
        <div className={styles.login_background}>
          {activeForm === "signin" && (
            <>
              <div className={styles.modal_header}>
                <h2 className={styles.modal_title_big}>
                  Log in to your account
                </h2>
              </div>
              <LoginForm
                onNavigate={(form) => {
                  setActiveForm(form);
                }}
                onClose={onClose}
              />
            </>
          )}

          {activeForm === "signup" && (
            <SignUpPage
              onNavigate={(form) => {
                setActiveForm(form);
              }}
              onClose={onClose}
            />
          )}

          {activeForm === "forgot" && (
            <ForgotPassword
              onNavigate={(form) => {
                setActiveForm(form);
              }}
            />
          )}

          <button type="button" className={styles.close} onClick={onClose}>
            ×
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
