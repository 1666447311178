import * as React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "components/form/TextInput";
import styles from "../newProperty.module.css";
import Axios from "utils/axios";
import { useCallback } from "react";
import { debounce } from "helpers/commonHelp";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";
import { MdLocationOn } from "react-icons/md";
// import { toast } from "react-toastify";
import Toast from "components/Toast";

function AddAddress({
  handleForm,
  handleNext,
  handleBack,
  property,
  setProperty,
}) {
  const [placesData, setPlacesData] = useState([]);
  const [showList, setShowList] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const { propertiesForm } = useSelector((state) => {
    const { propertyReducer } = state;

    return { propertiesForm: propertyReducer.propertiesForm };
  });

  const locationRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // If the popup is open and the click is outside the popup, close it
      if (
        showList &&
        locationRef.current &&
        !locationRef.current.contains(event.target)
      ) {
        setShowList(false);
      }
    };

    if (showList) {
      document.addEventListener("click", handleOutsideClick);
    }

    // Clean up the event listener when the component is unmounted
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [showList]);

  useEffect(() => {
    if (property?._id) {
      formik.setFieldValue("address", property?.address);
      formik.setFieldValue("city", property?.city || "");
      formik.setFieldValue("country", property?.country || "");
      formik.setFieldValue("county", property?.county || "");
      formik.setFieldValue("zip", property?.zip || "");
    }
  }, [property]);

  const [isLoading, setIsLoading] = useState(false);
  const userObj = useSelector((state) => state.auth.user);
  const userType = userObj?.currentType;
  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => fetchPlacesList(txtval), 800),
    []
  );

  const onChangeAddress = (e) => {
    formik.setFieldValue("address", e.target.value);
    userTxtChangeDebounce(e.target.value);
    if (e.target.value.length > 0) {
      setShowList(true);
    }
  };

  const fetchPlacesList = (place) => {
    Axios.get(`/places/address-autocomplete?text=${place}`)
      .then((res) => {
        if (res.error) {
          setError(res?.error);

          setPlacesData([]);
        } else {
          setPlacesData(res.data.data);
        }
      })
      .catch(() => {
        setPlacesData([]);
      });
  };

  const handleLocationClick = (location) => {
    if (location) {
      fetchPlaceDetail(location.place_id);

      formik.setFieldValue("address", location.description);
    }
    setPlacesData([]);
    setShowList(false);
  };

  const handleSubmit = (values, { setSubmitting, setErrors }) => {
    const data = new FormData();

    property?._id && data.append("propertyId", property?._id);

    data.append("address", values.address);
    data.append("city", values.city);
    data.append("country", values.country);
    data.append("county", values.county);
    data.append("zip", values.zip);

    setIsLoading(true);

    const payload = data;

    if (property?._id) {
      Axios.post("/longlettingProperties/addAddress", payload)
        .then((response) => {
          handleNext();

          setProperty(response.data.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setErrors({ submit: err });
          setIsLoading(false);
        });
    }
  };
  const formikRef = useRef();
  const formik = useFormik({
    initialValues: {
      address: "",
      city: "",
      country: "",
      county: "",
      zip: "",
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required("Please enter address"),
      city: Yup.string().required("Please enter city"),
      country: Yup.string().required("Please enter country"),
      zip: Yup.string().required("Please enter postcode"),
    }),
    onSubmit: handleSubmit,
  });

  const fetchPlaceDetail = (place_id) => {
    Axios.get(`/places/place-detail-by-address-id?place_id=${place_id}`)
      .then((res) => {
        if (res.error) {
          // toast.error(res.error, { position: "bottom-left" });
          Toast(res.error, 'error');
        } else {
          formik.setFieldValue(
            "city",
            res.data.address.City
              ? res.data.address.City
              : res.data.address.state
              ? res.data.address.state
              : ""
          );
          formik.setFieldValue("country", res.data.address.Country || "");
          formik.setFieldValue("county", res.data.address.county || "");
          formik.setFieldValue("zip", res.data.address.zip || "");

          setTimeout(() => {
            if (res.data.address.Country) {
              formik.setFieldError("country", "");
            } else {
              formik.setFieldError("country", "Please enter country");
            }

            if (res.data.address.City || res.data.address.state) {
              formik.setFieldError("city", "");
            } else {
              formik.setFieldError("city", "Please enter city");
            }

            if (res.data.address.zip) {
              formik.setFieldError("zip", "");
            } else {
              formik.setFieldError("zip", "Please enter postcode");
            }
          }, 100);
        }
      })
      .catch((error) => {
        // toast.error(error, { position: "bottom-left" });
        Toast(error, 'error');
      });
  };

  return (
    <div>
      <div className="row mt-5">
        <div className="col-md-12">
          <div className={styles.main_heading + " text-center mb-4"}>
            <h3 className="fw-bold h4 mb-0">Address</h3>
          </div>
        </div>
        <div className="col-md-12">
          <div className="row g-3">
            <div className="col-md-10 col-lg-8 mx-auto">
              <div className="row g-3">
                <div
                  onClick={() => {
                    setShowList(true);
                  }}
                  name="address"
                  className="col-md-12"
                >
                  <TextInput
                    customClass={"w-100 mb-1"}
                    label={"Address"}
                    value={formik.values.address}
                    name="address"
                    onChange={onChangeAddress}
                  />
                  <ErrorMsg
                    hasError={formik.touched.address && formik.errors.address}
                  >
                    {formik.errors.address}
                  </ErrorMsg>

                  {showList &&
                    (placesData?.length > 0 ? (
                      <div
                        style={{ marginTop: "0px", width: "50%" }}
                        className="place_card2"
                      >
                        {placesData?.map((place, index) => (
                          <div className="placeList">
                            <MdLocationOn width={20} />
                            <p
                              key={index}
                              onClick={() => handleLocationClick(place)}
                            >
                              {place.description}
                            </p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      error && (
                        <div
                          style={{ marginTop: "0px", width: "50%" }}
                          className="place_card2 "
                        >
                          {" "}
                          <div className="placeList">{error}</div>
                        </div>
                      )
                    ))}
                </div>
                <div name="zip" className="col-md-6">
                  <TextInput
                    customClass={"w-100"}
                    label={"Postcode"}
                    name="zip"
                    value={formik.values.zip}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMsg hasError={formik.touched.zip && formik.errors.zip}>
                    {formik.errors.zip}
                  </ErrorMsg>
                </div>
                <div name="city" className="col-md-6">
                  <TextInput
                    ref={formikRef}
                    customClass={"w-100 mb-0 mt-2"}
                    label={"City"}
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMsg
                    hasError={formik.touched.city && formik.errors.city}
                  >
                    {formik.errors.city}
                  </ErrorMsg>
                </div>
                <div name="country" className="col-md-6">
                  <TextInput
                    customClass={"w-100"}
                    label={"Country"}
                    name="country"
                    value={formik.values.country}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMsg
                    hasError={formik.touched.country && formik.errors.country}
                  >
                    {formik.errors.country}
                  </ErrorMsg>
                </div>
                <div name="county" className="col-md-6">
                  <TextInput
                    customClass={"w-100"}
                    label={"County (optional)"}
                    value={formik.values.county}
                    name="county"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <p style={{ marginTop: "1rem" }}>
          <ErrorMsg hasError={formik.errors.submit}>
            {formik.errors.submit}
          </ErrorMsg>
        </p>
        <div className="mt-3 d-flex justify-content-center justify-content-md-end">
          <div className="mt-3 d-flex justify-content-end">
            <button
              className="gray-btn grow "
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </button>
            <ButtonLoader
              type="button"
              className="primary-btn grow  ml-3"
              isLoading={isLoading}
              buttonName={"Next"}
              loaderColor={"white"}
              onClickhandle={formik.handleSubmit}
            >
              Next
            </ButtonLoader>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;

// <form className="first-form form-border p-3">
// <div className="">
//   <h5 className="popup-heading mb-0">Confirm your address</h5>
//   <p className="popup-summary">
//     Your address is only shared with guests after they've made
//     reservation.{" "}
//   </p>
// </div>

// <div className="row mt-3">
//   <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//     <p className="input-heading">Property Address</p>
//     <div ref={locationRef} style={{ position: "relative" }}>
//       <div
//       onClick={() => {
//         setShowList(true);
//       }}
//       >
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.address}
//           onChange={onChangeAddress}

//         />
//       </div>

//       {showList && (

//         placesData?.length > 0 ? (<div className="place_card2">
//           {placesData?.map((place, index) => (
//             <div className="placeList">
//               <MdLocationOn width={20} />
//               <p
//                 key={index}
//                 onClick={() => handleLocationClick(place)}
//               >
//                 {place.description}
//               </p>
//             </div>
//           ))}
//         </div>
//         ) : (
//           error && <div className="place_card2"> <div className="placeList">
//             {error}
//           </div>
//           </div>
//         )
//       )}
//     </div>
//   </div>
//   {propertiesForm.address && (
//     <>
//       <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//         <p className="input-heading">Property Name (if applicable)</p>
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.propertyName}
//           onChange={(e) => {
//             dispatch(
//               changePropertiesForm({
//                 edited_field: "propertyName",
//                 edited_value: e.target.value,
//               })
//             );
//           }}
//         />
//       </div>
//       <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//         <p className="input-heading">Street No</p>
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.street}
//           onChange={(e) => {
//             dispatch(
//               changePropertiesForm({
//                 edited_field: "street",
//                 edited_value: e.target.value,
//               })
//             );
//           }}
//         />
//       </div>

//       <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//         <p className="input-heading">City/town</p>
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.city}
//           onChange={(e) => {
//             dispatch(
//               changePropertiesForm({
//                 edited_field: "city",
//                 edited_value: e.target.value,
//               })
//             );
//           }}
//         />
//       </div>
//       <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//         <p className="input-heading">Country (if applicable)</p>
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.country}
//           onChange={(e) => {
//             dispatch(
//               changePropertiesForm({
//                 edited_field: "country",
//                 edited_value: e.target.value,
//               })
//             );
//           }}
//         />
//       </div>
//       <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
//         <p className="input-heading">Postcode</p>
//         <TextInput
//           customClass={"property-form-input"}
//           type={"text"}
//           value={propertiesForm.zip}
//           onChange={(e) => {
//             dispatch(
//               changePropertiesForm({
//                 edited_field: "zip",
//                 edited_value: e.target.value,
//               })
//             );
//           }}
//         />
//       </div>
//     </>
//   )}
//   <div className="row">
//     <div className="col-12 col-md-6 col-lg-6 mt-5 form_input">
//       <MapWithPin
//         coordinates={coordinates}
//         height={"300px"}
//         borderRadius={"10px"}
//       />
//     </div>
//   </div>

//   <div className="mt-3 d-flex justify-content-end">
//     <div className="mt-3 d-flex justify-content-end">
//       <button
//         className="gray-btn grow "
//         onClick={() => {
//           handleForm(1);
//         }}
//       >
//         Back
//       </button>
//       <button
//         type="button"
//         className="primary-btn grow  ml-3"
//         onClick={() => {
//           handleSubmit();
//         }}
//       >
//         Next
//       </button>
//     </div>
//   </div>
// </div>
// </form>
