import * as React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import DeleteModal from "components/CustomComponents/DeleteModal";
import Axios from "utils/axios";
import styles from "../newProperty.module.css";
import { ButtonLoader } from "components/form/ButtonLoader";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ErrorMsg from "components/ErrorMsg";
// import { toast } from "react-toastify";
import Toast from "components/Toast";
function AddPhotos({ handleNext, handleBack, property, setProperty }) {
  const fileInputRef = useRef(null);

  const [isLoading, setLoading] = useState(false);

  // const [selectedImages, setSelectedImages] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteImage, setDeleteImage] = useState("");
  const [error, seterror] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [mainImage, setMainImage] = useState(property?.defaultImageIndex || 1);
  const [selectedImages, setSelectedImages] = useState(property?.images || []);

  useEffect(() => {
    setSelectedImages(property?.images || []);
    setMainImage(property?.defaultImageIndex || 1);
  }, [property]);

  // update image state
  function updateImageState(e) {
    const images = e.target.files;

    // Create a new array to hold the updated selectedImages
    const updatedSelectedImages = [...selectedImages];

    Array.from(images).forEach((image) => {
      // Check if the image is already in the selectedImages array
      const imageExists = updatedSelectedImages.some((existingImage) =>
        existingImage instanceof File
          ? existingImage.name === image.name
          : existingImage === image
      );

      // If it's not already in the array, add it
      if (!imageExists) {
        updatedSelectedImages.push(image);
      }
    });

    setSelectedImages(updatedSelectedImages);
    seterror("");
  }

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFiles = Array.from(e.dataTransfer.files);

    const imageFiles = droppedFiles.filter((file) =>
      file.type.startsWith("image/")
    );

    const updatedSelectedImages = [...selectedImages];

    Array.from(imageFiles).forEach((image) => {
      // Check if the image is already in the selectedImages array
      const imageExists = updatedSelectedImages.some((existingImage) =>
        existingImage instanceof File
          ? existingImage.name === image.name
          : existingImage === image
      );

      // If it's not already in the array, add it
      if (!imageExists) {
        updatedSelectedImages.push(image);
      }
    });

    setSelectedImages(updatedSelectedImages);
  };

  useEffect(
    () => {
      const previews = [];
      const urls = [];

      for (let i = 0; i < selectedImages.length; i++) {
        const image = selectedImages[i];

        // If it's a local file (added during editing or adding), create a preview
        if (image instanceof File) {
          const previewURL = URL.createObjectURL(image);
          previews.push(previewURL);
          urls.push("");
        } else {
          // It's a server URL (from editing existing images)
          previews.push(image); // Use the server URL as the preview
          urls.push(image); // Store the server URL separately
        }
      }

      setImagePreviews(previews);
    }, // eslint-disable-next-line
    [selectedImages]
  );

  let handleSubmit = async (e) => {
    const formData = new FormData();

    formData.append("propertyId", property._id);
    formData.append("defaultImageIndex", mainImage);

    if (selectedImages?.length < 5) {
      seterror("please select minimum 5 images");
      return;
    }
    Array.from(selectedImages).forEach((image) => {
      formData.append("images", image);
    });

    setLoading(true);

    try {
      const payload = formData;

      const response = await Axios.post(
        "/longlettingProperties/addPhotos",
        payload
      );
      setLoading(false);
      if (response.data?.success) {
        handleNext();
        setProperty(response.data?.data);
      } else {
        // toast.error(response.data?.error, { position: "bottom-left" });
        Toast(response.data?.error, 'error');
      }
    } catch (error) {
      setLoading(false);
      // toast.error(error, { position: "bottom-left" });
      Toast(error, 'error');
    }
  };

  const openImageModal = (image) => {
    setDeleteImage(image);
    setDeleteModal(true);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  // Handle removing an image
  const handleRemoveImage = (index) => {
    const updatedSelectedImages = [...selectedImages];
    updatedSelectedImages.splice(index, 1);
    setSelectedImages(updatedSelectedImages);
    if (index === mainImage - 1) {
      setMainImage(1);
    }
    if (index < mainImage - 1) {
      setMainImage(mainImage - 1);
    }
    setDeleteModal(false);
  };

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(selectedImages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    if (result.source.index == mainImage - 1) {
      setMainImage(result.destination.index + 1);
    } else if (
      Math.min(result.source.index, result.destination.index) <=
        mainImage - 1 &&
      Math.max(result.source.index, result.destination.index) >= mainImage - 1
    ) {
      if (result.destination.index > mainImage - 1) {
        setMainImage(mainImage - 1);
      } else if (result.destination.index === mainImage - 1) {
        if (result.source.index < mainImage - 1) {
          setMainImage(mainImage - 1);
        } else {
          setMainImage(mainImage + 1);
        }
      } else {
        setMainImage(mainImage + 1);
      }
    }

    setSelectedImages(items);
  }

  return (
    <div className="mt-5">
      <div className="form">
        <div className={styles.main_heading + " text-center mb-4"}>
          <h3 className="fw-bold h4 mb-0">Photos</h3>
          {/* <p className="popup-summary">
            Upload property images for create an transparency with user.
          </p> */}
        </div>
        <div className="">
          <div className="d-flex justify-content-between flex-sm-columns">
            <div
              onDrop={handleDrop}
              onDragOver={(e) => e.preventDefault()}
              className=" mt-1 form_input image border-right p-0 p-md-3"
            >
              <p className="input-heading ">
                Images<span className="asterik2"></span>
              </p>
              <div className="summary">
                ( Please upload minimum 5 images for better understanding. )
              </div>
              <div className="upload-area mt-3" onClick={handleClick}>
                <span className="upload-icon">+</span>
                <span className="upload-text">Upload Image</span>
              </div>
              <p style={{ marginTop: "1rem" }}>
                <ErrorMsg hasError={error}>{error}</ErrorMsg>
              </p>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                draggable
                className="hidden-input"
                multiple
                onChange={updateImageState}
              />
            </div>
            <div hidden className="preview p-0 p-md-3 mt-3 mt-md-0">
              <p className="input-heading ">
                Image Previews :<span className="asterik2"></span>
              </p>
              {selectedImages && selectedImages.length > 0 && (
                <div className="mt-3 row  ">
                  {imagePreviews.map((image, index) => {
                    return (
                      <div className="col-6 col-md-6 col-lg-3 mb-3 position-relative">
                        <img
                          onClick={() => {
                            setMainImage(index + 1);
                          }}
                          style={{
                            width: "100%",
                            height: "100px",
                            objectFit: "fill",
                            borderRadius: "5px",
                            cursor: "pointer",
                          }}
                          src={image}
                          alt="Wrong Url"
                        />
                        <button
                          className="img-del-btn"
                          onClick={() => {
                            openImageModal(index);
                          }}
                        >
                          x
                        </button>
                        {mainImage === index + 1 && (
                          <button
                            style={{ left: "9%", top: "2%" }}
                            className="img-del-btn"
                            onClick={() => {
                              setMainImage(index + 1);
                            }}
                          >
                            <FmdGoodIcon
                              style={{ width: "20px", color: "gray" }}
                            />
                          </button>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters" direction="horizontal">
                {(provided) => (
                  <div
                    className="v-preview preview p-0 p-md-3 mt-3 mt-md-0 list-unstyled row"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {selectedImages &&
                      selectedImages.length > 0 &&
                      imagePreviews.map((image, index) => (
                        <Draggable
                          key={index.toString()}
                          draggableId={index.toString()}
                          index={index}
                        >
                          {(dragProvided) => (
                            <li
                              className="col-6 col-md-6 col-lg-3 mb-3"
                              ref={dragProvided.innerRef}
                              {...dragProvided.draggableProps}
                              {...dragProvided.dragHandleProps}
                            >
                              <div className="pt-3 position-relative">
                                <img
                                  onClick={() => {
                                    setMainImage(index + 1);
                                  }}
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    objectFit: "contain",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                    background: "#eee",
                                  }}
                                  src={image}
                                  alt="Wrong Url"
                                />
                                <button
                                  className="img-del-btn"
                                  onClick={() => {
                                    openImageModal(index);
                                  }}
                                >
                                  x
                                </button>
                                {mainImage === index + 1 && (
                                  <button
                                    style={{
                                      left: "-2%",
                                      background: "#205ff2",
                                    }}
                                    className="img-del-btn"
                                    onClick={() => {
                                      setMainImage(index + 1);
                                    }}
                                  >
                                    <FmdGoodIcon
                                      style={{ width: "20px", color: "white" }}
                                    />
                                  </button>
                                )}
                              </div>
                            </li>
                          )}
                        </Draggable>
                      ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div className="mt-3 d-flex justify-content-center justify-content-md-end">
            <button
              className="gray-btn grow "
              onClick={() => {
                handleBack();
              }}
            >
              Back
            </button>
            {/* <button type='button' className='primary-btn grow  ml-3' onClick={() => { handleSubmit() }} >Next</button> */}

            <ButtonLoader
              disabled={isLoading}
              type="button"
              className="primary-btn grow  ml-3 d-flex align-items-center justify-content-center"
              onClickhandle={() => {
                handleSubmit();
              }}
              loaderColor={"white"}
              isLoading={isLoading}
              buttonName={"Next"}
            >
              Next
            </ButtonLoader>
          </div>
        </div>
      </div>

      {deleteModal && (
        <DeleteModal
          image={deleteImage}
          text={"image"}
          closeModal={(val) => {
            setDeleteModal(val);
          }}
          handleDelete={(image) => {
            handleRemoveImage(image);
          }}
        />
      )}
    </div>
  );
}

export default AddPhotos;
