import {createSlice } from "@reduxjs/toolkit";
import {payLandlordAsync, editStatementAsync, deleteStatementAsync, statementListAsync, allStatementListAsync, paymentMethodsAsync, balanceListAsync} from "./Services"
import _ from "lodash";

const LandlordStatementSlice = createSlice({
  name: "LandlordStatementSlice",
  initialState: {
    loading: false,
    statementForm: {},
    statementList: [],
    paymentMethods: [],
    balanceList: [],
    allStatementList: [],
  },
  reducers: {
    changeStatementForm:(state, action) => {
      let update_form = _.cloneDeep(state.statementForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        statementForm:{
          ...state.statementForm,
          ...update_form,
        }
      }
    },
    clearStatementForm: (state, action) => {
      state.statementForm = {};
    },
  },
  extraReducers: {
    [payLandlordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [payLandlordAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [payLandlordAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editStatementAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editStatementAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editStatementAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [deleteStatementAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteStatementAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteStatementAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [paymentMethodsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [paymentMethodsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentMethods = action?.payload.data;
    //   state.program = action.payload;
    },
    [paymentMethodsAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [balanceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [balanceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.balanceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [balanceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
    [statementListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [statementListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.statementList = action?.payload.data;
    //   state.program = action.payload;
    },
    [statementListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allStatementListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allStatementListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allStatementList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allStatementListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    
  },
});

export default LandlordStatementSlice.reducer;

export const {
  changeStatementForm,
  clearStatementForm,
} = LandlordStatementSlice.actions;
