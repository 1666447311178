import React from "react";
import styles from "./certificate.module.css";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditCertificate from "./EditCertificate";
import { useState } from "react";
import Axios from "utils/axios";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaExclamationCircle } from "react-icons/fa";
import { Popover } from "@mui/material";
import moment from "moment";
import CertificateDetails from "./CertificateDetail";
import { Grid } from "@mui/material";

const CertificateCard = ({ data, getCertificateList }) => {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [detailPage, setDetailPage] = useState(false);
  const handleDelete = (id) => {
    Axios.post(`/certificate/deleteUnit`, {
      id: data._id,
    })
      .then((res) => {
        getCertificateList();
        setShow(false);
      })
      .catch((err) => {
        toast.error(err, { position: "bottom-left" });
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <Grid
        container
        spacing={0}
        className="tenancy-card"
        style={{ cursor: "auto" }}
      >
        <Grid item xs={12} sm={4} md={4} className="type tenancy-value">
          <div className={styles.v_unit + " d-flex gap-2 align-items-center"}>
            <div className="w-100">
              <h3 className="mt-0 h6 mb-1 fw-bold">
                {data?.certificateType}
                {data?.status == 0 && (
                  <span className={styles.v_certificate_missing}>missing</span>
                )}
              </h3>
              {/* <p className="mb-0 small">(Make, Model)</p>
              <p className="mb-0 small">Purchased from</p> */}
              <p className="mb-0 small">
                {data?.unit?.unitName ? data?.unit?.unitName + " - " : ""}{" "}
                {data?.property?.title}
              </p>
              <p className="mb-0 small">{data?.property?.address} </p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} className="type tenancy-value ">
          <div className={styles.v_current_status + " "}>
            <h6 className="mb-0 small">{data?.remark}</h6>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} className="type tenancy-value">
          <div className={styles.v_managed_By + " pt-0"}>
            <p className="mb-0 small">No work order</p>
          </div>
        </Grid>
        <Grid item xs={12} sm={2} md={2} className="type tenancy-value">
          <div className={styles.v_available_from + " pt-0"}>
            <p className="mb-0 small pt-0">
              <b>
                {data?.expiryDate
                  ? moment(data?.expiryDate).format("DD MMM YYYY")
                  : "- - -"}
              </b>
            </p>
            <p className="mb-0 small">Created by</p>
            <p style={{ color: "#3874ff" }} className="mb-0 small">
              <b>{data?.user?.firstName + " " + data?.user?.lastName}</b>
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          className="type_unit unit justify-content-end"
        >
          <div
            className={styles.v_listing + " d-flex gap-3 justify-content-end"}
          >
            <a className="d-none d-md-block" href={data?.file} download>
              {" "}
              <FileDownloadIcon />
            </a>
            <VisibilityIcon
              className="d-none d-md-block"
              onClick={() => setDetailPage(true)}
            />
            <div className={styles.v_listing_dropdown + " "}>
              <MoreVertIcon
                className="pointer"
                onClick={(e) => {
                  setShow(false);
                  handleClick(e);
                }}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={{ padding: "0.71rem" }}>
                  <ul
                    className={
                      styles.v_listing_dropdown_list +
                      " d-grid list-unstyled mb-0"
                    }
                  >
                    <li className="d-block d-md-none">
                      <a href={data?.file} className="text-dark" download>
                        {" "}
                        <FileDownloadIcon /> Download
                      </a>
                    </li>
                    <li
                      onClick={() => {
                        setDetailPage(true);
                        handleClose();
                      }}
                      className="d-block d-md-none"
                    >
                      <VisibilityIcon /> View
                    </li>
                    <li
                      onClick={() => {
                        setShowEdit(true);
                        handleClose();
                      }}
                    >
                      <EditIcon /> Edit
                    </li>
                    <li
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      className="red"
                    >
                      <ClearIcon /> Delete
                    </li>
                  </ul>
                </div>
              </Popover>
            </div>
          </div>
          {show && (
            <div className="card modal" style={{ width: "30%" }}>
              <div className="modal_box" style={{ textAlign: "center" }}>
                <div style={{ fontSize: "50px" }}>
                  <FaExclamationCircle />
                </div>
                <h3>Are You Sure ?</h3>
                <p>You want to delete this certificate?</p>
                <div className="modal-btn mt-3">
                  <button
                    type="submit"
                    className="btn form-btn grow "
                    onClick={(e) => handleDelete(show)}
                  >
                    Yes
                  </button>
                  <button
                    type="submit"
                    className="btn form-btn2 grow "
                    onClick={(e) => setShow(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}

          {detailPage && (
            <div className="document-overlay">
              <CertificateDetails
                closeModal={() => {
                  setDetailPage(false);
                }}
                data={data}
              />
            </div>
          )}

          {showEdit && (
            <div className="popup-overlay">
              <EditCertificate
                closePopup={() => setShowEdit(false)}
                closeShow={() => {
                  handleClose();
                }}
                getCertificateList={getCertificateList}
                data={data}
              />
            </div>
          )}
        </Grid>
        {/* <Grid item xs={12} sm={1} md={1} className="">
          <div className={styles.v_listing_dropdown + ""}>
            <MoreVertIcon
              className="pointer"
              onClick={(e) => {
                setShow(false);
                handleClick(e);
              }}
            />
          </div>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div style={{ padding: "0.51rem" }}>
              <ul
                className={
                  styles.v_listing_dropdown_list + " d-grid list-unstyled mb-0"
                }
              >
                <li>
                  <VisibilityIcon /> View
                </li>
                <li
                  onClick={() => {
                    setShowEdit(true);
                    handleClose();
                  }}
                >
                  <EditIcon /> Edit
                </li>
                <li onClick={() => navigate("/ll/tenancies/add-tenancy")}>
                  <AddIcon /> Create Tenancy
                </li>
                <li className="red">
                  <CancelIcon /> Make Inactive
                </li>

                <li
                  onClick={() => {
                    setShow(true);
                    handleClose();
                  }}
                  className="red"
                >
                  <ClearIcon /> Delete
                </li>
              </ul>
            </div>
          </Popover>
          {show && (
            <div className="card modal" style={{ width: "30%" }}>
              <div className="modal_box" style={{ textAlign: "center" }}>
                <div style={{ fontSize: "50px" }}>
                  <FaExclamationCircle />
                </div>
                <h3>Are You Sure ?</h3>
                <p>You want to delete this unit?</p>
                <div className="modal-btn mt-3">
                  <button
                    type="submit"
                    className="btn form-btn grow "
                    onClick={(e) => handleDelete(show)}
                  >
                    Yes
                  </button>
                  <button
                    type="submit"
                    className="btn form-btn2 grow "
                    onClick={(e) => setShow(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}

          {showEdit && (
            <div className="popup-overlay">
              <EditUnit
                closePopup={() => setShowEdit(false)}
                closeShow={() => {
                  handleClose();
                }}
                data={unit}
                fetchUnitList={fetchUnitList}
              />
            </div>
          )}
        </Grid> */}
      </Grid>

      {/* <tr>
        <td>
          <div className={styles.v_unit + " d-flex gap-3"}>
            <div className="w-100">
              <h3 className="mt-0 h6 mb-1 fw-bold">
                {data?.certificateType}
                {data?.status == 0 && (
                  <span className={styles.v_certificate_missing}>missing</span>
                )}
              </h3> */}
      {/* <p className="mb-0 small">(Make, Model)</p>
              <p className="mb-0 small">Purchased from</p> */}
      {/* <p className="mb-0 small">
                <b>
                  {data?.unit?.unitName ? data?.unit?.unitName + " - " : ""}{" "}
                  {data?.property?.title}
                </b>
              </p>
              <p className="mb-0 small">
                <b>{data?.property?.address}</b>{" "}
              </p>
            </div>
          </div>
        </td>

        <td>
          <div className={styles.v_current_status + " "}>
            <h6 className="mb-0 small">{data?.remark}</h6>
          </div>
        </td>
        <td>
          <div className={styles.v_managed_By + " pt-0"}>
            <p className="mb-0 small">No work order</p>
          </div>
        </td>
        <td>
          <div className={styles.v_available_from + " pt-0"}>
            <p className="mb-0 small pt-0">
              <b>
                {data?.expiryDate
                  ? moment(data?.expiryDate).format("DD MMM YYYY")
                  : "- - -"}
              </b>
            </p>
            <p className="mb-0 small">Created by</p>
            <p style={{ color: "#3874ff" }} className="mb-0 small">
              <b>{data?.user?.firstName + " " + data?.user?.lastName}</b>
            </p>
          </div>
        </td>
        <td>
          <div className={styles.v_listing + " d-flex gap-3 justify-content-end"}>
            <a href={data?.file} download>
              {" "}
              <FileDownloadIcon />
            </a>
            <VisibilityIcon onClick={() => setDetailPage(true)} />
            <div className={styles.v_listing_dropdown + " "}>
              <MoreVertIcon
                className="pointer"
                onClick={(e) => {
                  setShow(false);
                  handleClick(e);
                }}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div style={{ padding: "0.71rem" }}>
                  <ul
                    className={
                      styles.v_listing_dropdown_list +
                      " d-grid list-unstyled mb-0"
                    }
                  >
                    <li
                      onClick={() => {
                        setShowEdit(true);
                        handleClose();
                      }}
                    >
                      <EditIcon /> Edit
                    </li>
                    <li
                      onClick={() => {
                        setShow(true);
                        handleClose();
                      }}
                      className="red"
                    >
                      <ClearIcon /> Delete
                    </li>
                  </ul>
                </div>
              </Popover>
            </div>
          </div>
          {show && (
            <div className="card modal" style={{ width: "30%" }}>
              <div className="modal_box" style={{ textAlign: "center" }}>
                <div style={{ fontSize: "50px" }}>
                  <FaExclamationCircle />
                </div>
                <h3>Are You Sure ?</h3>
                <p>You want to delete this certificate?</p>
                <div className="modal-btn mt-3">
                  <button
                    type="submit"
                    className="btn form-btn grow "
                    onClick={(e) => handleDelete(show)}
                  >
                    Yes
                  </button>
                  <button
                    type="submit"
                    className="btn form-btn2 grow "
                    onClick={(e) => setShow(false)}
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          )}

          {detailPage && (
            <div className="document-overlay">
              <CertificateDetails
                closeModal={() => {
                  setDetailPage(false);
                }}
                data={data}
              />
            </div>
          )}

          {showEdit && (
            <div className="popup-overlay">
              <EditCertificate
                closePopup={() => setShowEdit(false)}
                closeShow={() => {
                  handleClose();
                }}
                getCertificateList={getCertificateList}
                data={data}
              />
            </div>
          )}
        </td>
      </tr> */}
    </>
  );
};

export default CertificateCard;
