import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const HorizontalLinearStepper = ({
  formSteps,
  form,
  activeStep,
  isStepSkipped,
  isStepOptional,
  alternativeLabel,
}) => {
  const steps = formSteps || [];

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper
        alternativeLabel={alternativeLabel}
        activeStep={activeStep}
        style={{ marginBottom: "30px", marginTop: "20px" }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Typography sx={{ mt: 2, mb: 1 }}>{form}</Typography>
      </React.Fragment>
    </Box>
  );
};

export default HorizontalLinearStepper;
