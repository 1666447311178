import TextInput from "components/form/TextInput";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import ErrorMsg from "components/ErrorMsg";
import { ButtonLoader } from "components/form/ButtonLoader";
import SelectInput from "components/form/SelectInput";
import InputWithCurrency from "components/form/TextInputwithCurrency";
import RadioWithBorder from "components/CustomComponents/RadioWithBorder";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import { useRef } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import useStaticData from "./hooks/useStaticData";
import { useEffect } from "react";
import useSelectionData from "./hooks/useSelectionData";

const ContractForm = ({ handleBack, formik }) => {
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const {
    rentFrequencies,
    weeks,
    depositScheme,
    tenancyPeriods,
    deductFromData,
  } = useStaticData();

  const { checkOverlapping, overlappingData } = useSelectionData();
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  function generateDateOptions() {
    const options = [];
    const daysInMonth = 31; // Adjust this based on the month and year

    for (let i = 1; i <= daysInMonth; i++) {
      let label = i;
      if (i === 1 || i === 21 || i === 31) {
        label += "st";
      } else if (i === 2 || i === 22) {
        label += "nd";
      } else if (i === 3 || i === 23) {
        label += "rd";
      } else {
        label += "th";
      }

      options.push({ label, value: i });
    }
    return options;
  }

  function addMonthsToDate(inputDate, monthsToAdd) {
    formik.setFieldValue("tenancyPeriod", monthsToAdd);

    const newDate = new Date(inputDate);
    newDate.setMonth(newDate.getMonth() + monthsToAdd);
    // Subtract one day from the calculated date
    newDate.setDate(newDate.getDate() - 1);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1; // months are zero-based
    const year = newDate.getFullYear();
    const formattedDate = `${year}-${month < 10 ? "0" : ""}${month}-${day < 10 ? "0" : ""
      }${day}`;

    formik.setFieldValue("tenancyEndDate", `${formattedDate}`);
  }

  useEffect(() => {
    if (formik.values?.depositCertificate) {
      if (formik.values?.depositCertificate instanceof File) {
        const imagePreviewURL = URL.createObjectURL(
          formik.values?.depositCertificate
        );
        setSelectedImagePreview(imagePreviewURL);
      } else {
        setSelectedImagePreview(formik.values?.depositCertificate);
      }
    }
  }, [formik.values?.depositCertificate]);

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];

    if (selectedImage) {
      if (selectedImage.type.startsWith("image/")) {
        const imagePreviewURL = URL.createObjectURL(selectedImage);
        setSelectedImagePreview(imagePreviewURL);
      } else if (selectedImage.type === "application/pdf") {
        const pdfImageURL = "/images/pdf.png";
        setSelectedImagePreview(pdfImageURL);
      } else {
        setSelectedImagePreview(null);
      }
      formik.setFieldValue("depositCertificate", selectedImage);
    } else {
      setSelectedImagePreview(null);
    }
  };

  useEffect(() => {
    if (formik.values?.tenancyStartDate && formik.values?.tenancyEndDate) {
      checkOverlapping(
        formik.values?.property,
        formik.values?.unit,
        formik.values?.tenancyStartDate,
        formik.values?.tenancyEndDate
      );
    }
  }, [formik.values?.tenancyStartDate, formik.values?.tenancyEndDate]);

  const formattedDate = (targetDate) => {
    const date = new Date(targetDate);

    const format = date.toLocaleDateString("en-US", {
      weekday: "short",
      month: "short",
      day: "2-digit",
      year: "numeric",
    });

    return format;
  };

  return (
    <>
      <div className="">
        <h5 style={{ fontWeight: "700" }}>Terms & Obligations</h5>
        <h6 style={{ fontWeight: "700" }}>Tenancy details</h6>
        <Box sx={{ flexGrow: 1 }} className="px-0">
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Rent frequency</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <SelectInput
                customClass={"w-100"}
                //label="Document Type*"
                options={rentFrequencies}
                selectedValue={formik.values.rentFrequency}
                onChange={(e) => {
                  formik.setFieldValue("rentFrequency", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <ErrorMsg
                hasError={
                  formik.touched.rentFrequency && formik.errors.rentFrequency
                }
              >
                {formik.errors.rentFrequency}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Rent Amount</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <InputWithCurrency
                customClass={"w-100"}
                name="rentAmount"
                label={""}
                value={formik.values.rentAmount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                min={0}
              />
              <ErrorMsg
                hasError={formik.touched.rentAmount && formik.errors.rentAmount}
              >
                {formik.errors.rentAmount}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Did you take a deposit for this tenancy?</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <Grid container spacing={2} className="">
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={1}
                    checked={formik.values.deposit}
                    onChange={() => formik.setFieldValue("deposit", 1)}
                    label="Yes"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={0}
                    checked={formik.values.deposit}
                    onChange={() => formik.setFieldValue("deposit", 0)}
                    label="No"
                    parentClass={"d-flex align-items-center "}
                  />
                </Grid>
              </Grid>
              <ErrorMsg
                hasError={formik.touched.deposit && formik.errors.deposit}
              >
                {formik.errors.deposit}
              </ErrorMsg>
            </Grid>
          </Grid>
          {formik.values.deposit === 1 && (
            <>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Deposit Amount</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <InputWithCurrency
                    customClass={"w-100"}
                    name="depositAmount"
                    label={""}
                    value={formik.values.depositAmount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    min={0}
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.depositAmount &&
                      formik.errors.depositAmount
                    }
                  >
                    {formik.errors?.depositAmount}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">
                    If someone who is not a tenant has paid towards the deposit,
                    you must provide their name and address.
                  </h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"text"}
                    name="depositPayer.name"
                    value={formik.values?.depositPayer?.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    placeholder={"Name"}
                    label={"Name"}
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.depositPayer?.name &&
                      formik.errors.depositPayer?.name
                    }
                  >
                    {formik.errors.depositPayer?.name}
                  </ErrorMsg>
                  <TextInput
                    customClass={"w-100 mt-4"}
                    type="text"
                    name="depositPayer.address"
                    value={formik.values?.depositPayer?.address}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    placeholder="Address"
                    label="Address"
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.depositPayer?.address &&
                      formik.errors.depositPayer?.address
                    }
                  >
                    {formik.errors.depositPayer?.address}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Deposit scheme</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <Grid container spacing={2} className="">
                    <Grid item xs={6} sm={6} md={6} className="">
                      <RadioWithBorder
                        customClass={"radio-size "}
                        value={"Insured"}
                        id={"Insured"}
                        checked={formik.values.depositScheme.name}
                        onChange={() =>
                          formik.setFieldValue("depositScheme.name", "Insured")
                        }
                        label="Insured"
                        parentClass={"d-flex align-items-center"}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} className="">
                      <RadioWithBorder
                        customClass={"radio-size "}
                        value={"Custodial"}
                        id={"Custodial"}
                        checked={formik.values.depositScheme.name}
                        onChange={() =>
                          formik.setFieldValue(
                            "depositScheme.name",
                            "Custodial"
                          )
                        }
                        label="Custodial"
                        parentClass={"d-flex align-items-center "}
                      />
                    </Grid>
                  </Grid>
                  <ErrorMsg
                    hasError={
                      formik.touched?.depositScheme?.name &&
                      formik.errors?.depositScheme?.name
                    }
                  >
                    {formik.errors?.depositScheme?.name}
                  </ErrorMsg>
                  <div className="mt-4">
                    <SelectInput
                      name="depositScheme.company"
                      customClass={"w-100"}
                      label="Deposit scheme company"
                      options={depositScheme}
                      selectedValue={formik.values.depositScheme.company}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "depositScheme.company",
                          e.target.value
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <ErrorMsg
                      hasError={
                        formik.touched.depositScheme?.company &&
                        formik.errors.depositScheme?.company
                      }
                    >
                      {formik.errors.depositScheme?.company}
                    </ErrorMsg>
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Deposit holder</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"text"}
                    name={"depositHolder"}
                    value={formik.values.depositHolder}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    placeholder={"Deposit holder"}
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.depositHolder &&
                      formik.errors.depositHolder
                    }
                  >
                    {formik.errors.depositHolder}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Deposit due date</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"date"}
                    name={"depositDueDate"}
                    value={formik.values.depositDueDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.depositDueDate &&
                      formik.errors.depositDueDate
                    }
                  >
                    {formik.errors.depositDueDate}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Deposit Certificate</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  {formik.values?.depositCertificate ? (
                    <div
                      className="image_border p-2 d-flex justify-content-between align-items-center w-100"
                      style={{ borderRadius: "4px" }}
                    >
                      {selectedImagePreview &&
                        (typeof formik.values?.depositCertificate ===
                          "string" ? (
                          selectedImagePreview.endsWith(".pdf") ? (
                            <div>
                              <img
                                className="image-object3"
                                src="/images/pdf.png"
                                alt="PDF"
                              />
                            </div>
                          ) : (
                            <div>
                              <img
                                className="image-object3"
                                src={formik.values?.depositCertificate}
                                alt="Selected Image Preview"
                              />
                            </div>
                          )
                        ) : formik.values?.depositCertificate?.type ===
                          "application/pdf" ? (
                          <div>
                            <img
                              className="image-object3"
                              src="/images/pdf.png"
                              alt="PDF"
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              className="image-object3"
                              src={selectedImagePreview}
                              alt="Selected Image Preview"
                            />
                          </div>
                        ))}
                      {/* {formik.values?.depositCertificate?.type ===
                      "application/pdf" ? (
                        <div>
                          <img
                            className="image-object3"
                            src="/images/pdf.png"
                            alt="PDF"
                          />
                        </div>
                      ) : (
                        <div>
                          <img
                            className="image-object3"
                            src={URL.createObjectURL(
                              formik.values?.depositCertificate
                            )}
                            alt="Selected Image Preview"
                          />
                        </div>
                      )} */}
                      {typeof formik.values?.depositCertificate === "string" ? (
                        <p className="image_name">Deposit Certificate</p>
                      ) : (
                        <p className="image_name">
                          {formik.values?.depositCertificate?.name}
                        </p>
                      )}
                      <AiOutlineDelete
                        fontSize={22}
                        onClick={() => {
                          formik.setFieldValue("depositCertificate", null);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  ) : (
                    <div>
                      <button
                        type="button"
                        className="btn__info"
                        onClick={handleClick}
                      >
                        + Add Certificate
                      </button>
                      <input
                        class="form-control"
                        className="hidden-input"
                        ref={fileInputRef}
                        type="file"
                        id="formFile"
                        onChange={handleImageChange}
                      />
                      <p className="image_summary">
                        We support: PDF, DOC, DOCX, JPG, JPEG, JPEG & PNG files
                      </p>
                      <ErrorMsg
                        hasError={
                          formik.touched.astDocument &&
                          formik.errors.astDocument
                        }
                      >
                        {formik.errors.astDocument}
                      </ErrorMsg>
                    </div>
                  )}
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Tenancy start date</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <TextInput
                customClass={"w-100 "}
                type={"date"}
                name={"tenancyStartDate"}
                value={formik.values.tenancyStartDate}
                onChange={(e) => {
                  formik.setFieldValue("tenancyStartDate", e.target.value);
                  formik.setFieldValue("firstMonthDueDate", e.target.value);
                  if (formik.values?.tenancyPeriod) {
                    addMonthsToDate(
                      e.target.value,
                      formik.values?.tenancyPeriod
                    );
                  }
                }}
                onBlur={formik.handleBlur}
                variant="outlined"
              />
              <ErrorMsg
                hasError={
                  formik.touched.tenancyStartDate &&
                  formik.errors.tenancyStartDate
                }
              >
                {formik.errors.tenancyStartDate}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">What day of the month do you receive rent?</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <SelectInput
                customClass={"w-100 "}
                options={generateDateOptions()}
                selectedValue={formik.values.rentDueDate}
                onChange={(e) => {
                  formik.setFieldValue("rentDueDate", e.target.value);
                }}
                onBlur={formik.handleBlur}
              />
              <ErrorMsg
                hasError={
                  formik.touched.rentDueDate && formik.errors.rentDueDate
                }
              >
                {formik.errors.rentDueDate}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">When was the first month’s rent due?</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <TextInput
                customClass={"w-100 "}
                type={"date"}
                name={"firstMonthDueDate"}
                value={formik.values.firstMonthDueDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                minDate={formik.values?.tenancyStartDate}
              />
              <ErrorMsg
                hasError={
                  formik.touched.firstMonthDueDate &&
                  formik.errors.firstMonthDueDate
                }
              >
                {formik.errors.firstMonthDueDate}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Tenancy Period</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <SelectInput
                customClass={"w-100 "}
                options={tenancyPeriods}
                selectedValue={formik.values.tenancyPeriod}
                onChange={(e) => {
                  addMonthsToDate(
                    formik.values.tenancyStartDate,
                    e.target.value
                  );
                }}
                onBlur={formik.handleBlur}
              />
              <ErrorMsg
                hasError={
                  formik.touched.tenancyPeriod && formik.errors.tenancyPeriod
                }
              >
                {formik.errors.tenancyPeriod}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">Tenancy end date</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <TextInput
                customClass={"w-100 "}
                type={"date"}
                name={"tenancyEndDate"}
                value={formik.values.tenancyEndDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                minDate={
                  formik?.values?.tenancyStartDate
                    ? formik?.values?.tenancyStartDate
                    : ""
                }
              />
              <ErrorMsg
                hasError={
                  formik.touched.tenancyEndDate && formik.errors.tenancyEndDate
                }
              >
                {formik.errors.tenancyEndDate}
              </ErrorMsg>
            </Grid>
          </Grid>
          {overlappingData?.length > 0 && (
            <div className="block-info block_warning">
              <h6>
                CRITICAL NOTICE{" "}
                <span style={{ color: "red", fontSize: "28px" }}>*</span>
              </h6>
              <p className="mt-3 mb-3" style={{ color: "#55545e" }}>
                This tenancy overlaps with an existing active tenancy. Please
                update the dates of the active tenancy before proceeding with
                this one.
              </p>
              {overlappingData.map((item, index) => {
                return (
                  <div className="d-flex align-items-center">
                    <p style={{ fontWeight: "700", marginRight: "5px" }}>
                      Tenancy {index + 1} :
                    </p>
                    <p style={{ fontSize: "14px" }}>
                      {formattedDate(item?.tenancyStartDate)} -{" "}
                      {formattedDate(item?.tenancyEndDate)}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="small">
                Did you take a holding deposit for this tenancy?
              </h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <Grid container spacing={2} className="">
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={1}
                    checked={formik.values.holdingDeposit}
                    onChange={() => formik.setFieldValue("holdingDeposit", 1)}
                    label="Yes"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={0}
                    checked={formik.values.holdingDeposit}
                    onChange={() => formik.setFieldValue("holdingDeposit", 0)}
                    label="No"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
              </Grid>
              <ErrorMsg
                hasError={
                  formik.touched.holdingDeposit && formik.errors.holdingDeposit
                }
              >
                {formik.errors.holdingDeposit}
              </ErrorMsg>
            </Grid>
          </Grid>
          {formik?.values?.holdingDeposit === 1 && (
            <>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Has the holding fee already been paid?</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <Grid container spacing={2} className="">
                    <Grid item xs={6} sm={6} md={6} className="">
                      <RadioWithBorder
                        customClass={"radio-size "}
                        value={1}
                        checked={formik.values.holdingFeePaid}
                        onChange={() =>
                          formik.setFieldValue("holdingFeePaid", 1)
                        }
                        label="Yes"
                        parentClass={"d-flex align-items-center"}
                      />
                    </Grid>
                    <Grid item xs={6} sm={6} md={6} className="">
                      <RadioWithBorder
                        customClass={"radio-size "}
                        value={0}
                        checked={formik.values.holdingFeePaid}
                        onChange={() =>
                          formik.setFieldValue("holdingFeePaid", 0)
                        }
                        label="No"
                        parentClass={"d-flex align-items-center"}
                      />
                    </Grid>
                  </Grid>
                  <ErrorMsg
                    hasError={
                      formik.touched.holdingDeposit &&
                      formik.errors.holdingDeposit
                    }
                  >
                    {formik.errors.holdingDeposit}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">What was the holding fee amount?</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"number"}
                    name={"amount"}
                    value={formik.values.holding.amount}
                    onChange={(e) => {
                      formik.setFieldValue("holding.amount", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.holding?.amount &&
                      formik.errors.holding?.amount
                    }
                  >
                    {formik.errors.holding?.amount}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">
                    Where would you like to deduct the holding fee from?
                  </h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <SelectInput
                    customClass={"w-100 "}
                    options={deductFromData}
                    selectedValue={formik.values.holding.deductFrom}
                    onChange={(e) => {
                      formik.setFieldValue(
                        "holding.deductFrom",
                        e.target.value
                      );
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.holding?.deductFrom &&
                      formik.errors.holding?.deductFrom
                    }
                  >
                    {formik.errors.holding?.deductFrom}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">Select the holding fee due date</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"date"}
                    name={"dueDate"}
                    value={formik.values.holding.dueDate}
                    onChange={(e) => {
                      formik.setFieldValue("holding.dueDate", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.holding?.dueDate &&
                      formik.errors.holding?.dueDate
                    }
                  >
                    {formik.errors.holding?.dueDate}
                  </ErrorMsg>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={2}
                className="form-field__wrapper mt-4 mb-4 w-100 mx-0"
              >
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <h5 className="small">What reference code do you want to use?</h5>
                </Grid>
                <Grid item xs={12} sm={12} md={6} className="p-0">
                  <TextInput
                    customClass={"w-100 "}
                    type={"text"}
                    name={"reference"}
                    value={formik.values.holding.reference}
                    onChange={(e) => {
                      formik.setFieldValue("holding.reference", e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    variant="outlined"
                    placeholder={"Reference"}
                  />
                  <ErrorMsg
                    hasError={
                      formik.touched.holding?.reference &&
                      formik.errors.holding?.reference
                    }
                  >
                    {formik.errors.holding?.reference}
                  </ErrorMsg>
                </Grid>
              </Grid>
            </>
          )}
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5>Maximum number of occupants</h5>
              {/* <p>Leave blank if not required</p> */}
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <TextInput
                customClass={"w-100 "}
                type={"number"}
                name={"occupants"}
                value={formik.values.occupants}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                placeholder={"Occupants"}
              />
              <ErrorMsg
                hasError={formik.touched.occupants && formik.errors.occupants}
              >
                {formik.errors.occupants}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="">Permitted occupiers</h5>
              {/* <h5>Names of any permitted occupants</h5> */}
              <p>Please use a comma to separate the names</p>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <TextInput
                customClass={"w-100 "}
                type={"text"}
                name={"occupiers"}
                value={formik.values.occupiers}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                variant="outlined"
                placeholder={"Permitted occupiers"}
              />
              <ErrorMsg
                hasError={formik.touched.occupiers && formik.errors.occupiers}
              >
                {formik.errors.occupiers}
              </ErrorMsg>
            </Grid>
          </Grid>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4 w-100 mx-0">
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <h5 className="">Does this property have a mortgage?</h5>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="p-0">
              <Grid container spacing={2} className="">
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={1}
                    checked={formik.values.mortgage}
                    onChange={() => formik.setFieldValue("mortgage", 1)}
                    label="Yes"
                    parentClass={"d-flex align-items-center"}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} className="">
                  <RadioWithBorder
                    customClass={"radio-size "}
                    value={0}
                    checked={formik.values.mortgage}
                    onChange={() => formik.setFieldValue("mortgage", 0)}
                    label="No"
                    parentClass={"d-flex align-items-center "}
                  />
                </Grid>
              </Grid>
              <ErrorMsg
                hasError={formik.touched.mortgage && formik.errors.mortgage}
              >
                {formik.errors.mortgage}
              </ErrorMsg>
            </Grid>
          </Grid>
        </Box>
        <div className="d-flex justify-content-between align-items-center">
          <button className="btn_verify mt-4 grow" onClick={handleBack}>
            Back
          </button>
          <ButtonLoader
            buttonName="Next"
            variant={"contained"}
            className={"add_new_btn grow mt-4"}
            onClickhandle={() => {
              if (overlappingData?.length <= 0) {
                formik.handleSubmit();
              }
            }}
            loaderColor={"yellow"}
          />
        </div>
      </div>
    </>
  );
};

export default ContractForm;
