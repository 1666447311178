import React from "react";
import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";

import "../App.css";
import "../Admin.css";
import Header from "../pages/public/guestPortal/components/header";

import Footer from "../pages/public/guestPortal/components/footer";
export const GuestPublicLayout = () => {
  const location = useLocation();

  const headerTextColor1 = "black";
  const logo1 = "PropertyJinni-Logo.png";
  const headerStyle1 = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    background: "white",
    boxShadow: `none !important`,
    transition: "background-color 0.3s ease-in-out",
    borderBottom: "1px solid lightgray",
  };

  


  
  const [headerBackground, setHeaderBackground] = useState('transparent');
  const [headerTextColor, setHeaderTextColor] = useState('white');
  const [boxShadow, setBoxShadow] = useState('none');
  const [logo, setLogo] = useState('ProprtyJinni_Logo_White.png');
  const headerRef = useRef(null); 


  useEffect(() => {
      const handleScroll = () => {
          if (headerRef.current) {
            const scrollY = headerRef.current.scrollTop;
            if (scrollY > 0) {
              setHeaderBackground('white');
              setHeaderTextColor('black');
              setBoxShadow('0 1px 4px 0 rgba(0,0,0,.2)');
              setLogo('PropertyJinni-Logo.png')
          } else {
              setHeaderBackground('transparent');
              setHeaderTextColor('white');
              setBoxShadow('none');
              setLogo('ProprtyJinni_Logo_White.png');
          }
          
          }
        };

      if (headerRef.current) {
          headerRef.current.addEventListener('scroll', handleScroll);
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (headerRef.current) {
            headerRef.current.removeEventListener('scroll', handleScroll);
          }
        };
  }, []);

  

  const headerStyle = {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      background: headerBackground,
      boxShadow:`${boxShadow} !important`,
      transition: 'background-color 0.3s ease-in-out',
    };


   

  return (
    <div className='guest-home-page' ref={headerRef}>
      {(
        <Header
          headerBackground={location.pathname==='/guest/homepage'? headerStyle:headerStyle1}
          textColor={location.pathname==='/guest/homepage'?headerTextColor:headerTextColor1}
          logo={location.pathname==='/guest/homepage'?logo:logo1}
        />
      )}
      <div >

      <Outlet  />
      </div>
       <div  >
       <Footer />
       </div>
    
    </div>
  );
};
