import * as React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useLocation } from "react-router-dom";
import { LiaFileInvoiceSolid } from "react-icons/lia";
import { useEffect } from "react";
import Axios from "utils/axios";
import { useState } from "react";

export default function BookingDetail({booking_id}) {
    const location = useLocation();
    const { data, id } = location.state || {};
  
    const title = data ? data : "Upcoming Booking";
    const bookingid = id ? id : booking_id;
    const [bookinfdata, setBookinfdata] = useState();
  
    useEffect(() => {
      if (bookingid) {
        Axios.get(`/bookings/bookingbyid?id=${bookingid}`)
          .then((res) => {
            setBookinfdata(res?.data);
          })
          .catch((error) => {
            alert(error);
          });
      }
    }, [bookingid]);
  
    return (
      <div className={booking_id?"mb-5":"mb-5 mt-3"}>
        <div className="reservation-card1  ">
          <div className="pb-3 border-bottom d-flex align-items-center justify-content-between ">
            <h4 className="bold-700">Booking Details</h4>
            <button hidden className="export-btn grow">
              Print
            </button>
          </div>
          <div className="d-flex justify-content-between">
            <div className="mt-3">
              <p style={{ color: "#ff5555" }}>{bookinfdata?.bookingstatus}</p>
              <div className="property_name">
                {bookinfdata?.data?.property?.title}
              </div>
              <p className="room">
                 {bookinfdata?.data?.property?.address}
              </p>
              <div className="d-flex align-items-center">
                <div className="date">
                  {new Date(bookinfdata?.data?.check_in).getDate()}{" "}
                  {`${new Date(bookinfdata?.data?.check_in).toLocaleString(
                    "default",
                    {
                      month: "short",
                    }
                  )}`}{" "}
                </div>
                <div className="ml-1 "> - </div>
                <div className="date">
                  {" "}
                  {new Date(bookinfdata?.data?.check_out).getDate()}{" "}
                  {`${new Date(bookinfdata?.data?.check_out).toLocaleString(
                    "default",
                    {
                      month: "short",
                    }
                  )}`} ({Math.floor((new Date(bookinfdata?.data?.check_out) - new Date(bookinfdata?.data?.check_in)) / (1000 * 60 * 60 * 24))}- night)
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="room">
                  {bookinfdata?.data?.guests} guests - £
                  {bookinfdata?.data?.totalAmount}
                </div>
              </div>
            </div>
            <div>
              <img
                className="guest-property-img mt-3"
                src={
                  bookinfdata?.data?.property?.images[0]
                    ? bookinfdata?.data?.property?.images[0]
                    : `/images/property.jpg`
                }
                alt="Wrong Url"
              ></img>
            </div>
          </div>
        </div>
  
        <div className="reservation-card1  mt-3">
          <div className="">
            <h5 className="bold-700">Booking Details</h5>
          </div>
          <div className="border-bottom pb-3 mt-3">
            <h6 className="mb-0">Guests</h6>
            <p className="mt-0 booking-detail">
              {bookinfdata?.data?.guests} adults
            </p>
          </div>
          <div className="border-bottom pb-3 mt-3">
            <h6 className="mb-0">Check-in</h6>
            <p className="mt-0 booking-detail">
              {new Date(bookinfdata?.data?.check_in).toLocaleDateString("en-US", {
                weekday: "short",
                day: "numeric",
                month: "long",
                year: "numeric",
              })}
            </p>
          </div>
          <div className="border-bottom pb-3 mt-3">
            <h6 className="mb-0">Checkout</h6>
            <p className="mt-0 booking-detail">
              {new Date(bookinfdata?.data?.check_out).toLocaleDateString(
                "en-US",
                {
                  weekday: "short",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }
              )}
            </p>
          </div>
          <div className="border-bottom pb-3 mt-3"> 
            <h6 className="mb-0">Booking date</h6>
            <p className="mt-0 booking-detail">{new Date(bookinfdata?.data?.createdOn).toLocaleDateString(
                "en-US",
                {
                  weekday: "short",
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                }
              )}</p>
          </div>
          <div className="border-bottom pb-3 mt-3">
            <h6 className="mb-0">Confirmation Code</h6>
            {/* <p className="mt-0 booking-detail">HMREK3PHKH</p> */}
          </div>
        </div>
  
        <div className="reservation-card1  mt-3">
          <div className="mb-3">
            <h5 className="bold-700">Guest Paid</h5>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <h6 className="">
            £{bookinfdata?.data?.amount} * 
              {Math.floor((new Date(bookinfdata?.data?.check_out) - new Date(bookinfdata?.data?.check_in)) / (1000 * 60 * 60 * 24))} Nights</h6>
            <p className="">£{bookinfdata?.data?.totalAmount}</p>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <h6 className="">Guest service fee</h6>
            <p className="">£0 </p>
          </div>
  
          <div className="d-flex align-items-center justify-content-between mt-3 border-top pt-3">
            <h6 className="bold-700">Total (GBP)</h6>
            <p className="bold-700">£{bookinfdata?.data?.totalAmount} </p>
          </div>
        </div>
  
        <div className="reservation-card1  mt-3">
          <div className="mb-3">
            <h5 className="bold-700">Host Payout</h5>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <h6 className="">{Math.floor((new Date(bookinfdata?.data?.check_out) - new Date(bookinfdata?.data?.check_in)) / (1000 * 60 * 60 * 24))}-night room fee</h6>
            <p className="">£{bookinfdata?.data?.totalAmount}</p>
          </div>
          <div className="d-flex align-items-center justify-content-between ">
            <h6 className="">Host service fee</h6>
            <p className="">£0 </p>
          </div>
  
          <div className="d-flex align-items-center justify-content-between mt-3 border-bottom pb-2 border-top pt-3">
            <h6 className="bold-700">Total (GBP)</h6>
            <p className="bold-700">£{bookinfdata?.data?.totalAmount} </p>
          </div>
  
          {/* <div className="d-flex align-items-center justify-content-between mt-3 border-bottom pb-2">
            <h6  className=" d-flex align-items-center">
              {" "}
              <span style={{ marginRight: "5px" }}>
                <LiaFileInvoiceSolid fontSize={20} />
              </span>{" "}
              <span className="bold-700 mt-1">VAT Invoice</span>
            </h6>
            <MdKeyboardArrowRight fontSize={24} />
          </div> */}
        </div>
      </div>
    );
  }
  
  