import { useCallback, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { MdOutlineNotifications } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { BsArrowRightCircle, BsBoxArrowDownRight } from "react-icons/bs";

import { FaSync } from "react-icons/fa";

import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import { signoutAsync } from "redux/signOut/Services";
import { searchAsync } from "redux/search/Services";
import useHeader from "./useHeader";
import Toast from "components/Toast";
import Axios from "utils/axios";
import moment from "moment";
import { toast } from "react-toastify";
import { setNotification } from "redux/notificationSlice";
import { setTenancyId } from "redux/tenancySlice";

function LLHeader(props) {
  const navigate = useNavigate();
  const { isHeaderSwitching, switchUser } = useHeader();

  const [notifications, setNotifications] = useState(false);
  const socket = useSelector((state) => state.socket.socket);
  const userObj = useSelector((state) => state.auth.user);

  const notificationData = useSelector(
    (state) => state.notifications.notifications
  );
  const fetchNotifications = async () => {
    try {
      const response = await Axios.get(
        `/notifcations/getNotifications?id=${userObj?._id}&page=1&limit=20`
      );
      // setnotificationData(response.data.notifications);
      dispatch(setNotification(response.data.notifications));
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };
  useEffect(() => {
    fetchNotifications();
  }, []);
  var userId = userObj.id;
  const userType = userObj.currentType;

  const [allBtn, setAllBtn] = useState(0);

  const [show, setShow] = useState(false);

  const [showList, setShowList] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const dispatch = useDispatch();

  let menuRef = useRef(null);

  function signOut() {
    try {
      const payload = {
        id: userId,
      };
      dispatch(signoutAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            Toast(res.error, "error");
          } else {
            navigate("/");
            // navigate(0);
            localStorage.clear();

            setShow(false);
          }
        });
    } catch (error) {
      Toast(error, "error");
    }
  }

  // eslint-disable-next-line
  const debouncedFunction = useCallback(
    debounce((value) => {
      // Perform the desired action with the debounced value
      try {
        const payload = {
          searchWord: value,
          userId: userId,
          userType: userType,
        };
        dispatch(searchAsync(payload))
          .unwrap()
          .then((res) => {
            if (res.error) {
              Toast(res.error, "error");
            } else {
              setShowList(true);
            }
          });
      } catch (error) {
        Toast(error, "error");
      }
      //
    }, 500),
    []
  );

  function debounce(func, delay) {
    let timerId;

    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  }

  const { searchList } = useSelector((state) => {
    const { searchReducer } = state;

    return { searchList: searchReducer.searchList };
  });

  const handleSearch = (event) => {
    setInputValue(event.target.value);

    debouncedFunction(event.target.value);
  };

  const handleClick = () => {
    setInputValue("");
    setShowList("");
  };

  useEffect(
    () => {
      const handleOutsideClick = (event) => {
        if (show && !event.target.closest(".profile-popup")) {
          setTimeout(() => {
            setShow(false);
          }, 250);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, // eslint-disable-next-line
    [show]
  );

  useEffect(
    () => {
      const handleOutsideClick = (event) => {
        if (notifications && !event.target.closest(".notification-popup")) {
          setTimeout(() => {
            setNotifications(false);
          }, 250);
        }
      };

      document.addEventListener("mousedown", handleOutsideClick);

      return () => {
        document.removeEventListener("mousedown", handleOutsideClick);
      };
    }, // eslint-disable-next-line
    [notifications]
  );

  const handlePopupClick = () => {
    setShow(!show);
    setNotifications(false);
  };

  const handleNotificationPopup = () => {
    setNotifications(!notifications);
    setShow(false);
    setAllBtn(0);
  };

  const handleLink = (value) => {
    if (value === "all") {
      setNotifications(false);
      if (userType === "landlord") {
        navigate("/sl/notifications");
      } else if (userType === "sml-ll") {
        navigate("/ll/notifications");
      } else if (userType === "tenant") {
        navigate("/ll/tenant/notifications");
      } else {
        navigate("/sl/notifications");
      }
    } else {
      // setNotifications(false);
      if (userType === "landlord") {
        navigate("/sl/properties/detail?id=1");
      } else {
        // navigate("/sl/properties/detail?id=1");
      }
    }
  };
  const HandleNotificationClick = (id) => {
    Axios.post("/notifcations/updateNotification", { _id: id }).then(() => {
      dispatch(
        setNotification(
          notificationData.map((notification) => {
            if (notification._id === id) {
              // Update the 'read' property of the object at the specified index
              return {
                ...notification,
                read: true,
              };
            }
            return notification;
          })
        )
      );
    });
  };

  const handlenotificationNavaigate = (item) => {
    if (item.notificationType === "job") {
      navigate("/ll/maintenance");
      setNotifications(false);
    } else if (
      item.userType === "tenant" &&
      item.notificationType === "tenancy"
    ) {
      navigate("/ll/tenant/my-tenancy");
      setNotifications(false);
    } else if (
      item.userType === "ll" &&
      item.notificationType === "certificate"
    ) {
      navigate("/ll/certificates/all-certificates");
      setNotifications(false);
    } else if (
      item.userType === "sml-ll" &&
      item.notificationType === "tenant"
    ) {
      navigate("/ll/tenants/all-tenants");
      setNotifications(false);
    }
  };
  const HandleUnreadNotification = async () => {
    setAllBtn(1);
  };
  const HandleAllNotfication = async () => {
    setAllBtn(0);
  };

  const [oldnorification, setOldnorification] = useState([]);

  useEffect(() => {
    setOldnorification(notificationData);
  }, [notificationData]);

  useEffect(() => {
    console.log(oldnorification);
    if (oldnorification.length > notificationData.length) {
      dispatch(setNotification(oldnorification));
    }
  }, [oldnorification]);

  const receiveMsgHandler = useCallback((socketData) => {
    toast.info(socketData.message);
    setOldnorification((prevData) => [socketData, ...prevData]);
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("receive-notification", receiveMsgHandler);
      return () => socket.off("receive-notification", receiveMsgHandler);
    }
  }, [socket?.connected]);

  const location = useLocation();

  const fetchTenancy = () => {
   
    Axios.get(
      `/tenants/getTantentDashboard?id=${userObj?._id}&type=${"tenant"}`
    )
      .then((res) => {
        dispatch(setTenancyId(res?.data?.data?._id));
      })
      .catch((error) => {
        Toast(error, "error");
      });
  };

  useEffect(
    () => {
      if (userType === "tenant") {
        fetchTenancy();
      }
    }, // eslint-disable-next-line
    []
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-gray header-card">
      <button
        className="navbar-toggler"
        type="button"
        onClick={() => {
          props.changeToggle();
        }}
      >
        <span
          className="navbar-toggler-icon"
          style={{ fontSize: "1rem" }}
        ></span>
      </button>

      <NavLink
        className="navbar-brand"
        to={userType === "tenant" ? `/ll/tenant/dashboard` : `/ll/dashboard`}
      >
        <img
          className="logo2"
          src="/images/PropertyJinni-Logo.png"
          alt="wrong Url"
        ></img>{" "}
      </NavLink>

      <div className="btn  profile-btn navbar-toggler">
        <img
          className="user-img3"
          onClick={() => setShow(!show)}
          src={
            userObj?.profilePic !== "" && userObj?.profilePic !== null
              ? userObj?.profilePic
              : "/images/user-demo.jpg"
          }
          alt="Wrong Url"
        ></img>
        {show && (
          <div className="profile-popup border">
            <div className="profile-pic mb-3">
              <img
                className="profile-img2"
                src={
                  userObj?.profilePic
                    ? userObj?.profilePic
                    : "/images/user-demo.jpg"
                }
                alt="Wrong Url"
              />
              <h6>{userObj?.name} </h6>
            </div>

            <div className=" border-bottom pb-2">
              {userType === "tenant" ||
              userType === "franchise" ||
              userType === "landlord" ? (
                <>
                  {userType === "tenant" && (
                    <>
                      <NavLink
                        className="profile-url "
                        to="/ll/tenant/dashboard"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>Dashboard</span>
                      </NavLink>
                      <NavLink
                        className="profile-url "
                        to="/ll/tenant/my-profile"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>My Account</span>
                      </NavLink>
                    </>
                  )}
                  {userType === "franchise" && (
                    <>
                      <NavLink
                        className="profile-url "
                        to="/sl/dashboard"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>Dashboard</span>
                      </NavLink>
                      <NavLink
                        className="profile-url "
                        to="/sl/my-profile"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>My Account</span>
                      </NavLink>
                    </>
                  )}
                  {userType === "landlord" && (
                    <>
                      <NavLink
                        className="profile-url "
                        to="/sl/dashboard"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>Dashboard</span>
                      </NavLink>
                      <NavLink
                        className="profile-url "
                        to="/sl/my-profile"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>My Account</span>
                      </NavLink>
                    </>
                  )}
                </>
              ) : (
                <>
                  <NavLink
                    className="profile-url "
                    to="/ll/my-profile"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <AiOutlineUser />
                    <span>My Account</span>
                  </NavLink>
                </>
              )}

              {userType !== "tenant" &&
                userType !== "franchise" &&
                userType !== "landlord" && (
                  <NavLink
                    className="profile-url "
                    to="/change-password"
                    onClick={() => {
                      setShow(false);
                    }}
                  >
                    <AiOutlineUser />
                    <span>Change Password</span>
                  </NavLink>
                )}
            </div>

            <div className=" pb-2 mt-3">
              <NavLink
                className="detail-btn2 w-100"
                to=""
                onClick={() => signOut()}
              >
                <BsBoxArrowDownRight /> Sign Out
              </NavLink>
            </div>
          </div>
        )}
      </div>

      <div className="collapse navbar-collapse " id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto m-auto">
          <li className="nav-item active">
            <div className="search search-input">
              <FaSearch fontSize={12} style={{ color: "#8a94ad" }} />
              <input
                type="text"
                className="form-control chart-input w-100 search-icon"
                value={inputValue}
                onChange={handleSearch}
                placeholder="Search..."
              />
              {showList && inputValue !== "" && (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={handleClick}
                >
                  X
                </button>
              )}
            </div>
            {showList && searchList.length > 0 && (
              <div className="search-list">
                {userType === "tenant"
                  ? searchList.map(
                      (item) =>
                        item.tenant_table !== "undefined" && (
                          <NavLink
                            to={{
                              pathname: `/ll${item.tenantPath}`,
                              state: { data: item.path },
                              search: `?data=${item.search_word}`,
                            }}
                            onClick={handleClick}
                            className="d-flex justify-content-between align-items-center border-bottom"
                          >
                            {item.search_word} in {item.tenant_table}{" "}
                            <BsArrowRightCircle fontSize={20} />{" "}
                          </NavLink>
                        )
                    )
                  : searchList.map((item) => (
                      <NavLink
                        to={{
                          pathname: `/ll${item.path}`,
                          state: { data: item.path },
                          search: `?data=${item.search_word}`,
                        }}
                        onClick={handleClick}
                        className="d-flex justify-content-between align-items-center border-bottom"
                      >
                        {item.search_word} in {item.table_name}{" "}
                        <BsArrowRightCircle fontSize={16} />
                      </NavLink>
                    ))}{" "}
              </div>
            )}
            {showList && searchList.length === 0 && inputValue !== "" && (
              <div className="search-list">
                <div style={{ textAlign: "center" }}>No Data Found !!</div>
              </div>
            )}
          </li>

          <div className="header-list">
            <li>
              <NavLink className="nav-link" to="" onClick={signOut}>
                Sign Out
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/ll/my-profile">
                My Account
              </NavLink>
            </li>
            <li>
              <NavLink className="nav-link" to="/ll/change-password">
                Change Password
              </NavLink>
            </li>
          </div>
        </ul>
        <div className="d-flex align-items-center header-list2 v-header-list2">
          {userType === "sml-ll" && (
            <div className="position-relative" style={{ cursor: "pointer" }}>
              <button
                className="property-add-btn grow"
                onClick={(e) => {
                  switchUser("sml", "/sl/dashboard");
                }}
              >
                <FaSync style={{ marginRight: "10px" }} />
                Switch to Short Letting
              </button>
            </div>
          )}

          <div className="position-relative" style={{ cursor: "pointer" }}>
            <MdOutlineNotifications
              className="grow"
              fontSize={24}
              style={{ marginLeft: "10px", color: "#000" }}
              onClick={handleNotificationPopup}
            />
            {/* <NotificationsNoneIcon  /> */}
            <sup>
              {
                notificationData.filter((notification) => !notification.read)
                  .length
              }
            </sup>
            {notifications && (
              <div className="notification-popup border">
                <div className="p-3 pb-0">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h5 className="bold-700 mb-0">Notifications</h5>
                    <div
                      className="see_all_link"
                      onClick={() => {
                        handleLink("all");
                      }}
                    >
                      See all
                    </div>
                  </div>
                  <div className="mt-2 d-flex">
                    <button
                      onClick={HandleAllNotfication}
                      className={`all-btn2 ${allBtn === 0 ? "active" : ""}`}
                    >
                      All
                    </button>
                    <button
                      onClick={HandleUnreadNotification}
                      className={`unread-btn ${allBtn === 1 ? "active" : ""}`}
                    >
                      Unread
                    </button>
                  </div>
                </div>
                <div className="notification-scroll">
                  {notificationData && allBtn === 1
                    ? notificationData
                        .filter((item) => item.read == false)
                        .map((item) => {
                          return (
                            <div
                              className={`notification ${
                                item.read == false ? "unread-notification" : ""
                              }`}
                              onClick={() => {
                                handleLink("notification");
                              }}
                            >
                              <div
                                onClick={() => {
                                  if (item?.read == false) {
                                    HandleNotificationClick(item?._id);
                                  }
                                  handlenotificationNavaigate(item);
                                }}
                                className="d-flex gap-3 justify-content-between"
                              >
                                <MdOutlineNotifications
                                  fontSize={32}
                                  style={{ color: "#3874ff" }}
                                />
                                <div className="w-100 d-grid gap-1">
                                  <h6 className="mb-0 mt-1 noti-heading ">
                                    <b>{item.propertyName}</b> {item.message}
                                  </h6>
                                  <p className="noti-summary small">
                                    {moment(item.createdOn).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          );
                        })
                    : notificationData.map((item) => {
                        return (
                          <div
                            className={`notification ${
                              item.read == false ? "unread-notification" : ""
                            }`}
                            onClick={() => {
                              handleLink("notification");
                            }}
                          >
                            <div
                              onClick={() => {
                                if (item?.read == false) {
                                  HandleNotificationClick(item?._id);
                                }
                                handlenotificationNavaigate(item);
                              }}
                              className="d-flex gap-3 justify-content-between"
                            >
                              <MdOutlineNotifications
                                fontSize={32}
                                style={{ color: "#3874ff" }}
                              />
                              <div className="w-100 d-grid gap-1">
                                <h6 className="mb-0 mt-1 noti-heading ">
                                  <b>{item.propertyName}</b> {item.message}
                                </h6>
                                <p className="noti-summary small">
                                  {moment(item.createdOn).format("DD/MM/YYYY")}
                                </p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                </div>
              </div>
            )}
          </div>

          <div className="position-relative" style={{ cursor: "pointer" }}>
            {/* <BiCategoryAlt className="grow" fontSize={24} style={{ marginLeft: "10px", color: "#000" }} /> */}
          </div>

          <div
            className="btn profile-btn"
            style={{ color: "#F4B905" }}
            ref={menuRef}
          >
            <img
              className="user-img"
              onClick={handlePopupClick}
              src={
                userObj.profilePic
                  ? userObj.profilePic
                  : "/images/user-demo.jpg"
              }
              alt="Wrong Url"
            />
            {show && (
              <div className="profile-popup border">
                <div className="profile-pic mb-3">
                  <img
                    className="profile-img2"
                    src={
                      userObj.profilePic
                        ? userObj.profilePic
                        : "/images/user-demo.jpg"
                    }
                    alt="Wrong Url"
                  />
                  <h6>{userObj.name} </h6>
                </div>

                <div className=" border-bottom pb-2">
                  {userType === "tenant" ||
                  userType === "franchise" ||
                  userType === "landlord" ? (
                    <>
                      {userType === "tenant" && (
                        <>
                          <NavLink
                            className="profile-url "
                            to="/ll/tenant/dashboard"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>Dashboard</span>
                          </NavLink>
                          <NavLink
                            className="profile-url "
                            to="/ll/tenant/my-profile"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>My Account</span>
                          </NavLink>
                        </>
                      )}
                      {userType === "franchise" && (
                        <>
                          <NavLink
                            className="profile-url "
                            to="/sl/dashboard"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>Dashboard</span>
                          </NavLink>
                          <NavLink
                            className="profile-url "
                            to="/sl/my-profile"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>My Account</span>
                          </NavLink>
                        </>
                      )}
                      {userType === "landlord" && (
                        <>
                          <NavLink
                            className="profile-url "
                            to="/sl/dashboard"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>Dashboard</span>
                          </NavLink>
                          <NavLink
                            className="profile-url "
                            to="/sl/my-profile"
                            onClick={() => {
                              setShow(false);
                            }}
                          >
                            <AiOutlineUser />
                            <span>My Account</span>
                          </NavLink>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      <NavLink
                        className="profile-url "
                        to="/ll/my-profile"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>My Account</span>
                      </NavLink>
                    </>
                  )}

                  {userType !== "tenant" &&
                    userType !== "franchise" &&
                    userType !== "landlord" && (
                      <NavLink
                        className="profile-url "
                        to="/change-password"
                        onClick={() => {
                          setShow(false);
                        }}
                      >
                        <AiOutlineUser />
                        <span>Change Password</span>
                      </NavLink>
                    )}
                </div>
                <div className=" pb-2 mt-3">
                  <NavLink
                    className="detail-btn2 w-100"
                    to=""
                    onClick={() => signOut()}
                  >
                    <BsBoxArrowDownRight /> Sign Out
                  </NavLink>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default LLHeader;
