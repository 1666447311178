import * as React from 'react';
import { useState, useRef } from 'react';
import { useEffect } from 'react';
import { AiOutlineHome } from 'react-icons/ai';
import { MdOutlineLocationOn } from 'react-icons/md';
import { PiUsersThree } from 'react-icons/pi';
import { LiaHandPeace } from 'react-icons/lia';
import { useDispatch, useSelector } from 'react-redux';
import { propertyTypeListAsync } from "redux/propertyType/Services";
import { TbHorseToy } from 'react-icons/tb';
import { BsBookmarkStar } from 'react-icons/bs';
import { changePropertiesForm } from 'redux/properties/PropertiesSlice';
import Textarea from 'components/form/TextareaInput';
import MultiSelectWithCheckbox from 'components/form/MultiSelectWithCheckbox';
import SelectInput from 'components/form/SelectInput';
import TextInput from 'components/form/TextInput';
import CountInput from 'components/CustomComponents/CountInput';
import Checkboxes from 'components/form/Checkbox';
import RadioButtons from 'components/form/RadioButton';
import { ownerSelectListAsync } from 'redux/propertyOwners/Services';
import Axios from 'utils/axios';
import { log } from 'util';

import Loader from 'components/Loader';
import Toast from 'components/Toast';


const PlaceTypeData = [

    {
        label: "An Entire Place",
        value: "An Entire Place"
    },
    {
        label: "A Room",
        value: "A Room"
    },
    {
        label: "A Shared Room",
        value: "A Shared Room"
    }

]

const roomTypeData = [

    {
        label: "Single",
        value: "single"
    },
    {
        label: "Double",
        value: "double"
    },
    {
        label: "Triple",
        value: "triple"
    },
    {
        label: "Kingsize",
        value: "kingsize"
    },

]

const otherPersons = [
    {
        label: "Me",
        value: "me"
    },
    {
        label: "My Family",
        value: "my family"
    },
    {
        label: "Other Guests",
        value: "other guests"
    },
    {
        label: "Flatmates/Housemates",
        value: "flatmates/housemates"
    },
]


function AddGeneralInfo({ handleForm, handleTabSubmit }) {

    const [isLoading, setLoading] = useState(false);
    const userObj = useSelector(state => state.auth.user);
    const userType = userObj?.currentType;
    var userId = userObj?.id;

    const dispatch = useDispatch();
    const { propertyTypeList, propertiesForm, ownerSelectList } = useSelector((state) => {
        const { propertyTypeReducer, propertyReducer, propertyOwnerReducer } = state;

        return { propertyTypeList: propertyTypeReducer.propertyTypeList, propertiesForm: propertyReducer.propertiesForm, ownerSelectList: propertyOwnerReducer.ownerSelectList };
    });

    const initialGuests = propertiesForm.guests || 0;
    const initialBedrooms = propertiesForm.bedrooms || 0;
    const initialBeds = propertiesForm.beds || 0;
    const initialPrivateBathroom = propertiesForm.privateBathroom || 0;
    const initialDedicatedBathroom = propertiesForm.dedicatedBathroom || 0;
    const initialSharedBathroom = propertiesForm.sharedBathroom || 0;
    const initialPrivateKitchen = propertiesForm.privateKitchen || 0;
    const initialDedicatedKitchen = propertiesForm.dedicatedKitchen || 0;
    const initialSharedKitchen = propertiesForm.sharedKitchen || 0;


    const fileInputRef = useRef(null);


    const [hostList, setHostList] = useState([]);

    const fetchHostList = () => {
        try {

            const payload = {
                userId: userObj._id,
                userType: userType,
            };
            dispatch(ownerSelectListAsync(payload))
                .unwrap()
                .then((res) => {

                });
        } catch (error) {
            // toast.error("Program details could not be fetched, please try again!");
            alert(error);
        }


    };


    const fetchPropertyType = () => {
        try {

            const payload = {
                userType: userType
            };
            dispatch(propertyTypeListAsync(payload))
                .unwrap()
                .then((res) => {

                });
        } catch (error) {
            // toast.error("Program details could not be fetched, please try again!");
        }


    };


    useEffect(() => {
        fetchHostList()
        fetchPropertyType();
        // Update the state with the accumulated data

        dispatch(changePropertiesForm({ edited_field: "status", edited_value: 0 }));
        dispatch(changePropertiesForm({ edited_field: "createdBy", edited_value: userId }));
        dispatch(changePropertiesForm({ edited_field: "user_id", edited_value: userId }));
        dispatch(changePropertiesForm({ edited_field: "lettingType", edited_value: userType }));


    }, // eslint-disable-next-line
        [])



    const handleChange = (options) => {
        dispatch(changePropertiesForm({ edited_field: "features", edited_value: options }));
    };


    const propertyFeaturesList = [
        { value: 'peaceful', label: 'Peaceful', icon: <LiaHandPeace /> },
        { value: 'unique', label: 'Unique', icon: <BsBookmarkStar /> },
        { value: 'family-friendly', label: 'Family Friendly', icon: <TbHorseToy /> },
        { value: 'stylish', label: 'Stylish', icon: <AiOutlineHome /> },
        { value: 'central', label: 'Central', icon: <MdOutlineLocationOn /> },
        { value: 'spacious', label: 'Spacious', icon: <PiUsersThree /> },
    ];


    const [unitDetails, setUnitDetails] = useState(propertiesForm.unitDetails || []);

    const handleRoomTypeChange = (index, value) => {
        setUnitDetails(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = { ...updatedInputs[index], roomType: value };
            return updatedInputs;
        });
    };

    const handleOccupancyChange = (index, value) => {
        setUnitDetails(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = { ...updatedInputs[index], occupancy: value };
            return updatedInputs;
        });
    };

    const handleImageChange = (index, value) => {

        setUnitDetails(prevInputs => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = { ...updatedInputs[index], image: value[0] };
            return updatedInputs;
        });

    };


    const renderInputFields = () => {
        const inputFields = [];
        for (let i = 1; i < parseInt(propertiesForm.units) + 1; i++) {
            const unitDetail = unitDetails[i - 1] || {}; // Get the corresponding unit details from the state

            inputFields.push(
                <div className='row p-0' style={{ marginLeft: "0.1rem" }}>
                    <p className='mt-3 input-heading'>Unit {i}</p>
                    <div className='col-12 col-md-4 col-lg-3 form_input'>
                        <p className='input-heading'>Room Type{i}</p>
                        <SelectInput
                            customClass={'property-select-input'}
                            selectedValue={unitDetail.roomType || ''}
                            options={roomTypeData}
                            onChange={(e) => handleRoomTypeChange(i - 1, e.target.value)}
                        />
                    </div>
                    <div className='col-12 col-md-4 col-lg-3  form_input'>
                        <p className='input-heading'>Occupancy{i}</p>
                        <TextInput
                            customClass={'property-form-input'}
                            type={'number'}
                            value={unitDetail.occupancy || ''}
                            onChange={(e) => handleOccupancyChange(i - 1, e.target.value)}
                        />
                    </div>
                    <div className='col-12 col-md-4 col-lg-3  form_input'>
                        <p className='input-heading'>Image{i}</p>
                        <input type="file" accept="image/*" ref={fileInputRef} className="property-form-input pt-1" onChange={(e) => handleImageChange(i - 1, e.target.files)} />
                    </div>

                    {unitDetail?.image &&

                        (unitDetail?.image instanceof Blob ? (
                            <div className='col-12 col-md-4 col-lg-3  form_input'>
                                <img style={{ width: '100px', height: '75px', objectFit: "fill", borderRadius: "5px" }} src={URL.createObjectURL(unitDetail.image)} alt="Selected Image Preview" />
                            </div>
                        )
                            :
                            <div className='col-12 col-md-4 col-lg-3  form_input'>
                                <img style={{ width: '100px', height: '75px', objectFit: "fill", borderRadius: "5px" }} src={unitDetail.image} />
                            </div>)
                    }

                </div>
            );
        }
        return inputFields;
    };

    useEffect(() => {
        dispatch(changePropertiesForm({ edited_field: "unitDetails", edited_value: unitDetails }));
    }, // eslint-disable-next-line
        [unitDetails])

    const handleSubmit = async (e) => {

        const data = new FormData();

        // Append the JSON data as a single value
        const generalFormData = JSON.stringify(propertiesForm);
        data.append('data', generalFormData);

        // Append each file to the FormData
        unitDetails.forEach((fileData) => {
            data.append('images', fileData.image);
        });

        data.append('property_id', propertiesForm?.property_id)

        setLoading(true);

        try {
            const payload = data;

            if (propertiesForm?.property_id) {
                const response = await Axios.post('/properties/editGeneralInfo', payload);

                setLoading(false);

                if (response.data?.success) {
                    // dispatch(changePropertiesForm({ edited_field: "property_id", edited_value: response?.data?.data?.addProperty?.property_id }));
                    handleForm(2);
                    handleTabSubmit(2);
                }
                else {
                    alert(response.error);
                }
            }
            else {
                const response = await Axios.post('/properties/addGeneralInfo', payload);

                // console.log(response);

                if (response.data?.success) {
                    dispatch(changePropertiesForm({ edited_field: "property_id", edited_value: response?.data?.data?.addProperty?.property_id }));
                    handleForm(2);
                    handleTabSubmit(2);
                }
                else {
                    // console.log('1');
                    // alert(response.error.message);
                    Toast(response.error.message, 'error');
                }
            }



        } catch (error) {
            // toast.error("Program details could not be fetched, please try again!");
            setLoading(false);
            alert(error);
        }
    };

    const convertJsonToOptions = (data) => {
        return data?.map((item) => ({
            label: item.property_type_name,
            value: item.property_type_name,
        }));
    };

    const convertJsonToOptions2 = (data) => {
        var convertedData = data?.map((item) => ({
            label: `${item.firstName} ${item.lastName}`,
            value: item._id,
        }));
        var addobject = {
            label: `${userObj?.name} (Me)`,
            value: userObj._id
        }
        convertedData = [addobject, ...convertedData]

        return convertedData

    };


    return (
        <div className="mt-3 pt-3">
            <form className='first-form v-first-form form-border v-form-border p-3'>
                <div className=''>
                    <h5 className='popup-heading mb-0'>Add New Property</h5>
                    <p className='popup-summary'>Create a third party client to with billing address to create invoices. </p>
                </div>

                <div className='row mt-3'>
                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Property Owner</p>
                        <SelectInput customClass={'property-select-input'} options={ownerSelectList ? convertJsonToOptions2(ownerSelectList) : []} selectedValue={propertiesForm.host} onChange={(e) => dispatch(changePropertiesForm({ edited_field: "host", edited_value: e.target.value }))} />
                    </div>
                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Property Title</p>
                        <TextInput
                            customClass={'property-form-input'}
                            type={'text'}
                            value={propertiesForm.title}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "title", edited_value: e.target.value }))}
                        />
                    </div>
                    {/* <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Internal Name</p>
                        <TextInput
                            customClass={'property-form-input'}
                            type={'text'}
                            value={propertiesForm.internalName}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "internalName", edited_value: e.target.value }))}
                        />
                    </div>
                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Property Name</p>
                        <TextInput
                            customClass={'property-form-input'}
                            type={'text'}
                            value={propertiesForm.propertyName}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "propertyName", edited_value: e.target.value }))}
                        />
                    </div> */}

                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Property Type</p>

                        <SelectInput
                            customClass={'property-select-input'}
                            // options={convertJsonToOptions(propertyTypeList)}
                            options={propertyTypeList ? convertJsonToOptions(propertyTypeList) : []}
                            selectedValue={propertiesForm.propertyType}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "propertyType", edited_value: e.target.value }))} />

                    </div>
                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Place Type</p>

                        <SelectInput
                            customClass={'property-select-input'}
                            options={PlaceTypeData}
                            selectedValue={propertiesForm.placeType}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "placeType", edited_value: e.target.value }))}
                        />
                    </div>
                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Units</p>

                        <TextInput
                            customClass={'property-form-input'}
                            type={'number'}
                            value={propertiesForm.units}
                            onChange={(e) => {
                                dispatch(changePropertiesForm({ edited_field: "units", edited_value: e.target.value }))
                            }}
                        />
                    </div>
                    {propertiesForm.units > 0 &&
                        <>

                            {renderInputFields()}


                            {/* <div className=' mt-1 form_input'>
                                <p className='input-heading mb-2 mt-2'>Unit Description<span className='asterik2'></span></p>

                                <Textarea
                                    customClass={'textarea-input'}
                                    rows={"5"}
                                    value={propertiesForm.unitDescription}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "unitDescription", edited_value: e.target.value })) }}
                                />
                            </div> */}
                        </>
                    }

                    <h5 className='popup-heading mb-0 mt-4 border-top pt-3' style={{ fontSize: "0.9rem", fontWeight: "700" }}> </h5>
                    {/* <div className='col-12 col-md-6 col-lg-3 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'People'}
                            value={initialGuests}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "guests", edited_value: parseInt(initialGuests) + 1 })) }}
                            onDecrement={() => {
                                if (initialGuests > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "guests", edited_value: parseInt(initialGuests) - 1 }))
                                }
                            }}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "guests", edited_value: e.target.value })) }}
                        />
                    </div> */}
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Bedrooms'}
                            value={initialBedrooms}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "bedrooms", edited_value: parseInt(initialBedrooms) + 1 })) }}
                            onDecrement={() => {
                                if (initialBedrooms > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "bedrooms", edited_value: parseInt(initialBedrooms) - 1 }))
                                }
                            }}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "bedrooms", edited_value: e.target.value })) }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Beds'}
                            value={initialBeds}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "beds", edited_value: parseInt(initialBeds) + 1 })) }}
                            onDecrement={() => {
                                if (initialBeds > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "beds", edited_value: parseInt(initialBeds) - 1 }))
                                }
                            }}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "beds", edited_value: e.target.value })) }}
                        />
                    </div>

                    <div className='col-12 col-md-4 col-lg-4 mt-3 form_input'>
                        <p className='input-heading mb-2'>Does every bedroom have locks? </p>
                        <div className='d-flex '>
                            <div className='d-flex align-items-center' style={{ marginRight: "20px" }}>
                                <RadioButtons
                                    customClass={'radio-size'}
                                    value="yes"
                                    checked={propertiesForm.locks || 0}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "locks", edited_value: e.target.value })) }}
                                />
                                <p style={{ marginLeft: "10px" }} >Yes</p>
                            </div>

                            <div className='d-flex align-items-center'>
                                <RadioButtons
                                    customClass={'radio-size'}
                                    value="no"
                                    checked={propertiesForm.locks || 0}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "locks", edited_value: e.target.value })) }}
                                />
                                <p style={{ marginLeft: "10px" }} >No</p>
                            </div>
                        </div>
                    </div>


                    {/* <h5 className='popup-heading mb-0 mt-4 border-top  pt-3' style={{ fontSize: "0.9rem", fontWeight: "700" }}>What kinds of bathrooms are available to guests? </h5>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Private and attached'}
                            value={initialPrivateBathroom}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "privateBathroom", edited_value: parseInt(initialPrivateBathroom) + 1 })) }}
                            onDecrement={() => {
                                if (initialPrivateBathroom > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "privateBathroom", edited_value: parseInt(initialPrivateBathroom) - 1 }))
                                }
                            }}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "privateBathroom", edited_value: e.target.value })) }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Dedicated'}
                            value={initialDedicatedBathroom}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "dedicatedBathroom", edited_value: parseInt(initialDedicatedBathroom) + 1 })) }}
                            onDecrement={() => {
                                if (initialDedicatedBathroom > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "dedicatedBathroom", edited_value: parseInt(initialDedicatedBathroom) - 1 }))
                                }
                            }}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "dedicatedBathroom", edited_value: e.target.value })) }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={''}
                            label={'Shared'}
                            value={initialSharedBathroom}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "sharedBathroom", edited_value: e.target.value })) }}
                            onIncrement={() => {
                                
                                    dispatch(changePropertiesForm({ edited_field: "sharedBathroom", edited_value: parseInt(initialSharedBathroom) + 1 }))
                                
                            }}
                            onDecrement={() => { 
                                if (initialSharedBathroom > 0) {
                                dispatch(changePropertiesForm({ edited_field: "sharedBathroom", edited_value: parseInt(initialSharedBathroom) - 1 })) 
                            }}}
                        />

                    </div>



                    <h5 className='popup-heading mb-0 mt-4 border-top  pt-3' style={{ fontSize: "0.9rem", fontWeight: "700" }}>What kinds of kitchens are available to guests? </h5>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Private and attached'}
                            value={initialPrivateKitchen}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "privateKitchen", edited_value: e.target.value })) }}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "privateKitchen", edited_value: parseInt(initialPrivateKitchen) + 1 })) }}
                            onDecrement={() => {
                                if (initialPrivateKitchen > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "privateKitchen", edited_value: parseInt(initialPrivateKitchen) - 1 }))
                                }
                            }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={'divide'}
                            label={'Dedicated'}
                            value={initialDedicatedKitchen}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "dedicatedKitchen", edited_value: e.target.value })) }}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "dedicatedKitchen", edited_value: parseInt(initialDedicatedKitchen) + 1 })) }}
                            onDecrement={() => {
                                if (initialDedicatedKitchen > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "dedicatedKitchen", edited_value: parseInt(initialDedicatedKitchen) - 1 }))
                                }
                            }}
                        />
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mt-3 form_input  '>
                        <CountInput
                            customClass={''}
                            label={'Shared'}
                            value={initialSharedKitchen}
                            onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "sharedKitchen", edited_value: e.target.value })) }}
                            onIncrement={() => { dispatch(changePropertiesForm({ edited_field: "sharedKitchen", edited_value: parseInt(initialSharedKitchen) + 1 })) }}
                            onDecrement={() => {
                                if (initialSharedKitchen > 0) {
                                    dispatch(changePropertiesForm({ edited_field: "sharedKitchen", edited_value: parseInt(initialSharedKitchen) - 1 }))
                                }
                            }}
                        />

                    </div>


                    <h5 className='popup-heading mb-0 mt-4 border-top  pt-3' style={{ fontSize: "0.9rem", fontWeight: "700" }}>Extra Informations :</h5>

                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Who else might be there?</p>

                        <SelectInput
                            customClass={'property-select-input height-2'}
                            options={otherPersons}
                            selectedValue={propertiesForm.otherPersons}
                            onChange={(e) => dispatch(changePropertiesForm({ edited_field: "otherPersons", edited_value: e.target.value }))}
                        />
                    </div>

                    <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                        <p className='input-heading'>Describe your property</p>

                        <MultiSelectWithCheckbox
                            customClass={'basic-multi-select2'}
                            options={propertyFeaturesList}
                            onSelectionChange={handleChange}
                            value={propertiesForm.features}
                        />
                    </div> */}


                    <h5 className='popup-heading mb-0 mt-4 border-top  pt-3' style={{ fontSize: "0.9rem", fontWeight: "700" }}>Just one last step!! </h5>

                    <p className='input-heading mt-3 mb-3'>Does your place have any of these?</p>
                    <div className='col-12 col-md-6 col-lg-4 mb-3 form_input  '>
                        <div className=''>
                            <div className='d-flex  align-items-center justify-content-between security'>
                                <p >Security Camera(s)</p>

                                <Checkboxes
                                    customClass={'security-checked'}
                                    value={propertiesForm.securityCamera}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "securityCamera", edited_value: e.target.checked })) }}
                                />
                            </div>
                            {propertiesForm.securityCamera && <div className='mt-4'>
                                <label className='security-description'>
                                    <h6 className='mb-0'>What the location of all security cameras and audio recordings devices.</h6>
                                    <p>Describe each device,its location,and if it will be on or off.</p>
                                </label>
                                <Textarea
                                    customClass={'textarea-input mt-1 w-100'}
                                    rows={"5"}
                                    value={propertiesForm.cameraDetails}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "cameraDetails", edited_value: e.target.value })) }}
                                />
                            </div>}

                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mb-3 form_input  '>
                        <div className=''>
                            <div className='d-flex  align-items-center justify-content-between security '>
                                <p >Weapons</p>
                                <Checkboxes
                                    customClass={'security-checked'}
                                    value={propertiesForm.weapons}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "weapons", edited_value: e.target.checked })) }}
                                />
                            </div>
                            {propertiesForm.weapons && <div className='mt-4'>
                                <label className='security-description'>
                                    <h6 className='mb-0'>What the location of all security cameras and audio recordings devices.</h6>
                                    <p>Describe each device,its location,and if it will be on or off.</p>
                                </label>
                                <Textarea
                                    customClass={'textarea-input mt-1 w-100'}
                                    rows={"5"}
                                    value={propertiesForm.weaponsDetails}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "weaponsDetails", edited_value: e.target.value })) }}
                                />
                            </div>}

                        </div>
                    </div>
                    <div className='col-12 col-md-6 col-lg-4 mb-3 form_input  '>
                        <div className=''>
                            <div className='d-flex  align-items-center justify-content-between security '>
                                <p >Dangerous animals</p>
                                <Checkboxes
                                    customClass={'security-checked'}
                                    value={propertiesForm.dangerousAnimals}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "dangerousAnimals", edited_value: e.target.checked })) }}
                                />
                            </div>
                            {propertiesForm.dangerousAnimals && <div className='mt-4'>
                                <label className='security-description'>
                                    <h6 className='mb-0'>What the location of all security cameras and audio recordings devices.</h6>
                                    <p>Describe each device,its location,and if it will be on or off.</p>
                                </label>
                                <Textarea
                                    customClass={'textarea-input mt-1 w-100'}
                                    rows={"5"}
                                    value={propertiesForm.animalsDetails}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "animalsDetails", edited_value: e.target.value })) }}
                                />
                            </div>}

                        </div>
                    </div>

                    <div className='col-12 col-md-6 col-lg-4 mb-3 form_input  '>
                        <div className=''>
                            <div className='d-flex  align-items-center justify-content-between security '>
                                <p >Smoke detector</p>
                                <Checkboxes
                                    customClass={'security-checked'}
                                    value={propertiesForm.smokeDetector}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "smokeDetector", edited_value: e.target.checked })) }}
                                />
                            </div>
                            {propertiesForm.smokeDetector && <div className='mt-4'>
                                <label className='security-description'>
                                    <h6 className='mb-0'>What the location of all security cameras and audio recordings devices.</h6>
                                    <p>Describe each device,its location,and if it will be on or off.</p>
                                </label>
                                <Textarea
                                    customClass={'textarea-input mt-1 w-100'}
                                    rows={"5"}
                                    value={propertiesForm.smokeDetectorDetails}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "smokeDetectorDetails", edited_value: e.target.value })) }}
                                />
                            </div>}

                        </div>
                    </div>

                    <div className='col-12 col-md-6 col-lg-4 mb-3 form_input  '>
                        <div className=''>
                            <div className='d-flex  align-items-center justify-content-between security '>
                                <p >Carbon monoxide detector</p>
                                <Checkboxes
                                    customClass={'security-checked'}
                                    value={propertiesForm.monoxideDetector}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "monoxideDetector", edited_value: e.target.checked })) }}
                                />
                            </div>
                            {propertiesForm.monoxideDetector && <div className='mt-4'>
                                <label className='security-description'>
                                    <h6 className='mb-0'>What the location of all security cameras and audio recordings devices.</h6>
                                    <p>Describe each device,its location,and if it will be on or off.</p>
                                </label>
                                <Textarea
                                    customClass={'textarea-input mt-1 w-100'}
                                    rows={"5"}
                                    value={propertiesForm.monoxideDetectorDetails}
                                    onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "monoxideDetectorDetails", edited_value: e.target.value })) }}
                                />
                            </div>}

                        </div>
                    </div>


                    <div className='mt-3 d-flex justify-content-end'>
                        <button
                            disabled={isLoading}
                            type="button"
                            className="primary-btn grow  ml-3 d-flex align-items-center justify-content-center"
                            onClick={() => { handleSubmit() }}>
                            Next
                            {isLoading ? <span style={{ marginLeft: '5px' }}> <Loader type='button' /> </span> : null}
                        </button>
                    </div>


                </div>

            </form>

        </div>


    );
}

export default AddGeneralInfo;