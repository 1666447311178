import {createSlice } from "@reduxjs/toolkit";
import { placeApiListAsync, placeDetailApiAsync} from "./Services"


const placeApiSlice = createSlice({
  name: "placeApiSlice",
  initialState: {
    loading: false,
    placeApiList: [],
    placeDetail: {},
  },
  reducers: {
    
  },
  extraReducers: {
   

    
    [placeApiListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [placeApiListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.placeApiList = action?.payload.data;
    //   state.program = action.payload;
    
    },
    [placeApiListAsync.rejected]: (state, action) => {
      state.loading = false;
    },


    [placeDetailApiAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [placeDetailApiAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.placeDetail = action?.payload;
    //   state.program = action.payload;
    
    },
    [placeDetailApiAsync.rejected]: (state, action) => {
      state.loading = false;
    },

  },
});

export default placeApiSlice.reducer;


