import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';



export default function DataTable2({ columns, rows, checkboxSelection,  pageSize, rowCount, onPageChange, onPageSizeChange  }) {
    return (


        <DataGrid
            // style={{ border: 'none' }}
            className='border-top'
            rows={rows}
            columns={columns}
            pagination
            paginationMode="server" // Enables server-side pagination
            pageSize={pageSize}
            rowsPerPageOptions={[10, 20, 30, 40, 50, 75, 100]}
            rowCount={rowCount}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
            checkboxSelection={checkboxSelection}
            getRowId={(row) => row._id}
        />
        

    );
}