import { useState } from "react";
import { useDispatch } from "react-redux";
import { setScrollToTop } from "redux/scrollSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSelectionData from "./useSelectionData";

const useStepperState = () => {
  const { AddTenancy, isLoading } = useSelectionData();

  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());

  const steps = [
    "Tenancy Details",
    "Contract",
    "Rent Scheduler",
    "Assured Tenancy Agreement",
  ];

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    dispatch(setScrollToTop());

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    dispatch(setScrollToTop());
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const formik = useFormik({
    initialValues: {
      property: "",
      unit: "",
      tenant: "",
      identity: false,
      ownership: false,
      bank: "",
      rentFrequency: "",
      rentAmount: "",
      deposit: 0,
      depositAmount: "",
      depositPayer: {
        name: "",
        address: "",
      },
      depositScheme: {
        name: "",
        company: "",
      },
      depositHolder: "",
      depositDueDate: "",
      depositCertificate: "",
      tenancyStartDate: "",
      rentDueDate: "",
      firstMonthDueDate: "",
      tenancyPeriod: 0,
      tenancyEndDate: "",
      holdingDeposit: 0,
      holdingFeePaid: 0,
      holding: {
        amount: "",
        deductFrom: "",
        dueDate: "",
        reference: "",
      },
      occupants: "",
      occupiers: "",
      mortgage: "",
      rentSchedule: 0,
      agreement: 0,
      astDocument: "",
      propertyOwnerType: "",
      companyDetails: {
        registrationNo: "",
        companyName: "",
        companyEmail: "",
        address: "",
        postCode: "",
      },
      scheduleData: [],
    },
    validationSchema: () => getValidationSchema(activeStep, formik.values),
    onSubmit: (values) => {
      if (activeStep < 3) {
        handleNext();
        formik.setTouched({});
      } else {
        AddTenancy(values);
      }
    },
  });

  const getValidationSchema = (activeStep, formikValues) => {
    switch (activeStep) {
      case 0:
        return Yup.object().shape({
          property: Yup.string().required("Please enter property."),
          unit: Yup.string().required("Please enter unit."),
          tenant: Yup.string().required("Please enter tenant."),
          identity: Yup.boolean().oneOf(
            [true],
            "Please upload identity document"
          ),
          ownership: Yup.boolean().oneOf(
            [true],
            "Please upload ownership document"
          ),
        });
      case 1:
        return Yup.object().shape({
          rentFrequency: Yup.string().required("Please select rent frequency."),
          rentAmount: Yup.number().required("Please enter rent amount."),
          deposit: Yup.number().required("Please enter deposit."),
          depositAmount: Yup.number().when("deposit", {
            is: 1,
            then: () => Yup.number().required("Please enter deposit amount."),
          }),
          depositScheme: Yup.object().shape({
            name:
              formik.values.deposit === 1
                ? Yup.string().required("Please enter deposit scheme name.")
                : Yup.string(),
            company:
              formik.values.deposit === 1
                ? Yup.string().required("Please enter deposit scheme company.")
                : Yup.string(),
          }),
          depositDueDate: Yup.string().when("deposit", {
            is: 1,
            then: () => Yup.string().required("Please enter deposit due date."),
          }),
          tenancyStartDate: Yup.string().required(
            "Please enter tenancy start date."
          ),
          rentDueDate: Yup.string().required("Please enter rent due date."),
          firstMonthDueDate: Yup.string().required(
            "Please enter first month due date."
          ),
          tenancyPeriod: Yup.number().required("Please enter tenancy period."),
          tenancyEndDate: Yup.string().required(
            "Please enter tenancy end date."
          ),
          holdingDeposit: Yup.number().required(
            "Please enter holding deposit."
          ),
          holdingFeePaid: Yup.number().required(
            "Please enter holding fee paid."
          ),
          holding: Yup.object().shape({
            amount:
              formik.values.holdingDeposit === 1
                ? Yup.number().required("Please enter holding amount.")
                : Yup.number(),
            deductFrom:
              formik.values.holdingDeposit === 1
                ? Yup.string().required("Please enter holding deduct from.")
                : Yup.string(),
            dueDate:
              formik.values.holdingDeposit === 1
                ? Yup.string().required("Please enter holding due date.")
                : Yup.string(),
            reference:
              formik.values.holdingDeposit === 1
                ? Yup.string().required("Please enter holding reference.")
                : Yup.string(),
          }),
          occupants: Yup.string().required("Please enter occupants."),
          occupiers: Yup.string().required("Please enter occupiers."),
          mortgage: Yup.string().required("Please enter mortgage."),
        });
      case 2:
        return Yup.object().shape({
          rentSchedule: Yup.string().required(
            "Please select rent schedule option."
          ),
        });
      case 3:
        return Yup.object().shape({
          astDocument: Yup.string().when("agreement", {
            is: 0,
            then: () => Yup.string().required("Please upload Ast Document."),
          }),
          propertyOwnerType: Yup.string().when("agreement", {
            is: 1,
            then: () => Yup.string().required("Please select owner type"),
          }),
          companyDetails: Yup.object().shape({
            registrationNo:
              formik.values.propertyOwnerType === "Company"
                ? Yup.string().required("Please select Registartion no.")
                : Yup.string(),
            companyName:
              formik.values.propertyOwnerType === "Company"
                ? Yup.string().required("Please enter company name")
                : Yup.string(),
            companyEmail:
              formik.values.propertyOwnerType === "Company"
                ? Yup.string()
                    .email("Invalid email address")
                    .required("Please enter email")
                : Yup.string(),
            address:
              formik.values.propertyOwnerType === "Company"
                ? Yup.string().required("Please enter address")
                : Yup.string(),
            postCode:
              formik.values.propertyOwnerType === "Company"
                ? Yup.string().required("Please enter postal code")
                : Yup.string(),
          }),
        });
      default:
        return Yup.object().shape({});
    }
  };

  return {
    steps,
    activeStep,
    skipped,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    formik,
    isLoading
  };
};
export default useStepperState;
