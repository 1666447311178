import React from "react";
import { TextField } from "@mui/material";

const TextInput = ({
  customClass,
  type,
  value,
  onChange,
  placeholder,
  variant,
  name,
  onBlur,
  minDate,
  min,
  label
}) => {
  const today = new Date().toISOString().split("T")[0];
  const telInputPattern = "/^[1-9][0-9]*$/";
  return (
    <TextField
      id="outlined-basic"
      name={name}
      className={`${customClass}`}
      type={type}
      value={value}
      variant={variant ? variant : "outlined"}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      autocomplete="off"
      label={label ? label : ""}
      InputLabelProps={(type === "date" || type === "time")  && { shrink: true }}
      InputProps={
        type === "number"
          ? { inputProps: { min: min ? min : 0 } }
          : type === "date"
          ? { inputProps: { min: minDate ? minDate : today } }
          : type === "tel"
          ? {
              inputProps: {
                inputMode: "numeric",
                maxLength: 13,
                minLength: 10,
                number: true,
              },
            }
          : {}
      }
    />
  );
};

export default TextInput;
