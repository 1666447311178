import * as React from "react";
import { useState, useRef } from "react";
import { useEffect } from "react";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { addAddressAsync } from "redux/properties/Services";
import { changePropertiesForm } from "redux/properties/PropertiesSlice";
import TextInput from "components/form/TextInput";
import {
  placeApiListAsync,
  placeDetailApiAsync,
} from "redux/placesApi/Services";
import MapWithPin from "components/map";
import Axios from "utils/axios";
import { useCallback } from "react";
import { debounce } from "helpers/commonHelp";
import Toast from "components/Toast";

function AddAddress({ handleForm, handleTabSubmit }) {
  const [placesData, setPlacesData] = useState([]);
  const [showList, setShowList] = useState(false);
  const [coordinates, setCoordinates] = useState([]);
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const { propertiesForm } = useSelector((state) => {
    const { propertyReducer } = state;

    return { propertiesForm: propertyReducer.propertiesForm };
  });

  const locationRef = useRef(null);

  useEffect(
    () => {
      const handleOutsideClick = (event) => {
        // If the popup is open and the click is outside the popup, close it
        if (
          showList &&
          locationRef.current &&
          !locationRef.current.contains(event.target)
        ) {
          setShowList(false);
        }
      };

      if (showList) {
        document.addEventListener("click", handleOutsideClick);
      }

      // Clean up the event listener when the component is unmounted
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }, // eslint-disable-next-line
    [showList]
  );

  const userTxtChangeDebounce = useCallback(
    debounce((txtval) => fetchPlacesList(txtval), 800),
    []
  );

  const onChangeAddress = (e) => {
    dispatch(
      changePropertiesForm({
        edited_field: "address",
        edited_value: e.target.value,
      })
    );
    // dispatch(
    //   changePropertiesForm({
    //     edited_field: "address",
    //     edited_value: e.target.value,
    //   })
    // );
    // if (e.target.value.length > 0) {
    //   setShowList(true);
    // }
    userTxtChangeDebounce(e.target.value);
    if (e.target.value.length > 0) {
      setShowList(true);
    }
  };

  // Fetch Apis

  const { loading, placeApiList, placeDetail } = useSelector((state) => {
    const { placeApiReducer } = state;

    return {
      loading: placeApiReducer.loading,
      placeApiList: placeApiReducer.placeApiList,
      placeDetail: placeApiReducer.placeDetail,
    };
  });

  const fetchPlacesList = (place) => {
    try {
      const payload = {
        text: place,
      };
      dispatch(placeApiListAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            setError(res?.error);
            // alert(res.error);
            setPlacesData([]);
          } else {
            setPlacesData(res.data);
          }
        });
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
      // alert(error);
    }
  };

  const fetchPlaceDetail = (place_id) => {
    try {
      const payload = {
        place_id: place_id,
      };
      dispatch(placeDetailApiAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            // console.log(res?.error);
            Toast(res.error, 'error');
            // alert(res.error);
          } else {
            const coordinatesObject = res.address.coordinates;
            const coordinatesArray = [
              coordinatesObject.lat,
              coordinatesObject.lng,
            ];

            setCoordinates(coordinatesArray);

            dispatch(
              changePropertiesForm({
                edited_field: "street",
                edited_value: res?.address?.locName,
              })
            );
            dispatch(
              changePropertiesForm({
                edited_field: "locality",
                edited_value: res?.address?.locality,
              })
            );
            dispatch(
              changePropertiesForm({
                edited_field: "city",
                edited_value: res?.address?.City,
              })
            );
            dispatch(
              changePropertiesForm({
                edited_field: "country",
                edited_value: res?.address?.Country,
              })
            );
            dispatch(
              changePropertiesForm({
                edited_field: "coordinates",
                edited_value: res?.address?.coordinates,
              })
            );
            dispatch(
              changePropertiesForm({
                edited_field: "zip",
                edited_value: res?.address?.zip,
              })
            );
          }
        });
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
      // alert(error);
    }
  };

  // useEffect(
  //   () => {
  //     if (showList && propertiesForm.address) {
  //       fetchPlacesList(propertiesForm.address);
  //     }
  //   }, // eslint-disable-next-line
  //   [showList, propertiesForm.address]
  // );

  const handleLocationClick = (location) => {
    if (location) {
      fetchPlaceDetail(location.place_id);

      dispatch(
        changePropertiesForm({
          edited_field: "address",
          edited_value: location.description,
        })
      );
    }
    setPlacesData([]);
    setShowList(false);
  };

  let handleSubmit = async (e) => {
    try {
      const payload = {
        property_id: propertiesForm.property_id,
        address: propertiesForm.address,
        street: propertiesForm.street,
        locality: propertiesForm.locality,
        city: propertiesForm.city,
        country: propertiesForm.country,
        zip: propertiesForm.zip,
        coordinates: propertiesForm.coordinates,
      };
      const response = await Axios.post("/properties/addAddress", payload);

      if (response.data?.success) {
        handleForm(3);
        handleTabSubmit(3);
      } else {
        alert(response.data?.error);
      }
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
      alert(error);
    }
  };

  useEffect(() => {
    if (propertiesForm?.coordinates) {
      const coordinatesObject = propertiesForm?.coordinates;
      const coordinatesArray = [coordinatesObject.lat, coordinatesObject.lng];

      setCoordinates(coordinatesArray);
    }
  }, [propertiesForm.coordinates]);

  return (
    <div className="mt-3 pt-3">
      <form className="first-form form-border v-form-border p-3">
        <div className="">
          <h5 className="popup-heading mb-0">Confirm your address</h5>
          <p className="popup-summary">
            Your address is only shared with guests after they've made
            reservation.{" "}
          </p>
        </div>

        <div className="row mt-3">
          <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
            <p className="input-heading">Property Address</p>
            <div ref={locationRef} style={{ position: "relative" }}>
              <div
                onClick={() => {
                  setShowList(true);
                }}
              >
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.address}
                  onChange={onChangeAddress}
                // onChange={(e) => {
                //   dispatch(
                //     changePropertiesForm({
                //       edited_field: "address",
                //       edited_value: e.target.value,
                //     })
                //   );
                //   if (e.target.value.length > 0) {
                //     setShowList(true);
                //   }
                // }}
                />
              </div>

              {showList && (

                placesData?.length > 0 ? (<div className="place_card2">
                  {placesData?.map((place, index) => (
                    <div className="placeList">
                      <MdLocationOn width={20} />
                      <p
                        key={index}
                        onClick={() => handleLocationClick(place)}
                      >
                        {place.description}
                      </p>
                    </div>
                  ))}
                </div>
                ) : (
                  error && <div className="place_card2"> <div className="placeList">
                    {error}
                  </div>
                  </div>
                )
              )}
            </div>
          </div>
          {propertiesForm.address && (
            <>
              <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
                <p className="input-heading">Property Name (if applicable)</p>
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.propertyName}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "propertyName",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
                <p className="input-heading">Street No</p>
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.street}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "street",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              {/* <div className='col-12 col-md-4 col-lg-3 mt-3 form_input'>
                            <p className='input-heading'>Locality (if applicable)</p>
                            <TextInput
                                customClass={'property-form-input'}
                                type={'text'}
                                value={propertiesForm.locality}
                                onChange={(e) => { dispatch(changePropertiesForm({ edited_field: "locality", edited_value: e.target.value })); }}
                            />
                        </div> */}
              <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
                <p className="input-heading">City/town</p>
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.city}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "city",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
                <p className="input-heading">Country (if applicable)</p>
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.country}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "country",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
              <div className="col-12 col-md-4 col-lg-3 mt-3 form_input">
                <p className="input-heading">Postcode</p>
                <TextInput
                  customClass={"property-form-input"}
                  type={"text"}
                  value={propertiesForm.zip}
                  onChange={(e) => {
                    dispatch(
                      changePropertiesForm({
                        edited_field: "zip",
                        edited_value: e.target.value,
                      })
                    );
                  }}
                />
              </div>
            </>
          )}
          <div className="row1">
            <div className="col-12 col-md-6 col-lg-6 mt-5 form_input">
              <MapWithPin
                coordinates={coordinates}
                height={"300px"}
                borderRadius={"10px"}
              />
            </div>
          </div>

          <div className="mt-3 d-flex justify-content-end">
            <div className="mt-3 d-flex justify-content-end">
              <button
                className="gray-btn grow "
                onClick={() => {
                  handleForm(1);
                }}
              >
                Back
              </button>
              <button
                type="button"
                className="primary-btn grow  ml-3"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddAddress;
