import {createSlice } from "@reduxjs/toolkit";
import {addExpensesAsync, editExpensesAsync, deleteExpensesAsync, paymentMethodsAsync, expenseListAsync, allExpenseListAsync} from "./Services"
import _ from "lodash";

const expenseSlice = createSlice({
  name: "expenseSlice",
  initialState: {
    loading: false,
    expenseForm: {},
    paymentMethods: [],
    expenseList: [],
    allExpenseList: [],
  },
  reducers: {
    changeExpenseForm:(state, action) => {
      let update_form = _.cloneDeep(state.expenseForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        expenseForm:{
          ...state.expenseForm,
          ...update_form,
        }
      }
    },
    clearExpenseForm: (state, action) => {
      state.expenseForm = {};
    },
  },
  extraReducers: {
    [addExpensesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addExpensesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addExpensesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editExpensesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editExpensesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editExpensesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteExpensesAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteExpensesAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteExpensesAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [paymentMethodsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [paymentMethodsAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.paymentMethods = action?.payload.data;
    //   state.program = action.payload;
    },
    [paymentMethodsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [expenseListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [expenseListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.expenseList = action?.payload.data;
    //   state.program = action.payload;
    },
    [expenseListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allExpenseListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allExpenseListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allExpenseList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allExpenseListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default expenseSlice.reducer;

export const {
  changeExpenseForm,
  clearExpenseForm,
} = expenseSlice.actions;
