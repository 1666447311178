import React from "react";
import { Select, MenuItem, MenuList } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const SelectInput = ({
  customClass,
  options,
  selectedValue,
  onChange,
  label,
}) => {
  const menuStyle = { maxHeight: "400px" };
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">
        {label ? label : ""}
      </InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedValue}
        label={label ? label : ""}
        MenuProps={{ style: menuStyle }}
        className={`${customClass}`}
      >
        {options.map((option) =>
          option.value ? (
            <MenuItem
              style={{border:"none !important"}}
              className="menu-item"
              value={option.value}
              onClick={() => onChange(option.value)}
            >
              {option.label}
            </MenuItem>
          ) : (
            <MenuList
              style={{
                fontWeight: "700",
                marginLeft: "5px",
                marginRight:"5px",
                fontSize:"1rem",
                paddingBottom: "0",
                paddingLeft: "5px",
                paddingRight: "5px",
                border:'none !important'
              }}
              className="menu-item"
            >
              {option.label}
            </MenuList>
          )
        )}
      </Select>
    </FormControl>
  );
};

export default SelectInput;
