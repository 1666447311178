import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';


//get Api

export const applicantStatusAsync = createAsyncThunk(
  'applicantSlice/applicantStatusAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicant-status`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const applicantsListAsync = createAsyncThunk(
  'applicantSlice/applicantsListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicants?id=${post.userId}&page=${post.page}&limit=${post.limit}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();


        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const applicantSelectListAsync = createAsyncThunk(
  'applicantSlice/applicantSelectListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicants/select-applicant-list?id=${post.userId}&property=${post.property}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();


        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const applicantsChartDataAsync = createAsyncThunk(
  'applicantSlice/applicantsChartDataAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicants/data?id=${post.userId}&dataType=${post.dataType}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();


        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

export const allApplicantsChartDataAsync = createAsyncThunk(
  'applicantSlice/allApplicantsChartDataAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicants/data?dataType=${post.dataType}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();


        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



export const allApplicantsListAsync = createAsyncThunk(
  'applicantSlice/allApplicantsListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/applicants`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);



// post Api
export const addApplicantsAsync = createAsyncThunk(
  'applicantSlice/addApplicantsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/applicants/addapplicant`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();



        return data;
      }
    } catch (err) {


      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const editApplicantsAsync = createAsyncThunk(
  'applicantSlice/editApplicantsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/applicants/editapplicant`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

//delete Api

export const deleteApplicantsAsync = createAsyncThunk(
  'applicantSlice/deleteApplicantsAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

      // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/applicants/deleteapplicant`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = response;

        return data;
      } else {
        const data = await response.json();

        return data;
      }
    } catch (err) {

      return rejectWithValue('Opps there seems to be an error');
    }
  },
);
