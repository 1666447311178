import {createAsyncThunk} from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';


//get Api

export const paymentMethodsAsync = createAsyncThunk(
  'LandlordInvoiceSlice/paymentMethodsAsync',
  async (post, {rejectWithValue}, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/payment-methods`,
      {
        headers: {
          'source': 'web',
            'authorization': `Bearer ${access_token}`,

        },
      },
      );
      
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        
        return data;
      } else {
        const data = await response.json();
        
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {
      
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

  export const invoiceListAsync = createAsyncThunk(
    'LandlordInvoiceSlice/invoiceListAsync',
    async (post, {rejectWithValue}, thunkAPI) => {
      try {
        const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
        const response = await fetch(`${NEW_API_BASE_URL}/landlord-invoices?id=${post.userId}`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
        );
        
        if (response.status === 200) {
          // if HTTP-status is 200-299
          const data = await response.json();
          
          // navigate('BottomNav');
          // navigation.navigate('BottomNav');
          
          return data;
        } else {
          const data = await response.json();
          
          // Toast.show(data.message);
          return data;
        }
      } catch (err) {
        
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
      }
    },
  );


  export const allInvoiceListAsync = createAsyncThunk(
    'LandlordInvoiceSlice/allInvoiceListAsync',
    async (post, {rejectWithValue}, thunkAPI) => {
      try {
        const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
        const response = await fetch(`${NEW_API_BASE_URL}/landlord-invoices`,
        {
          headers: {
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
        );
        
        if (response.status === 200) {
          // if HTTP-status is 200-299
          const data = await response.json();
          
          // navigate('BottomNav');
          // navigation.navigate('BottomNav');
          
          return data;
        } else {
          const data = await response.json();
          
          // Toast.show(data.message);
          return data;
        }
      } catch (err) {
        
        // You can choose to use the message attached to err or write a custom error
        return rejectWithValue('Opps there seems to be an error');
      }
    },
  );





// post Api

// add Invoice

export const addInvoiceAsync = createAsyncThunk(
  'LandlordInvoiceSlice/addInvoiceAsync',
  async (post, {rejectWithValue}, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-invoices/addinvoice`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();
        
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {
      
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


export const editInvoiceAsync = createAsyncThunk(
  'LandlordInvoiceSlice/editInvoiceAsync',
  async (post, {rejectWithValue}, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-invoices/editinvoice`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();
        
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {
      
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);

// Delete Api

export const deleteInvoiceAsync = createAsyncThunk(
  'LandlordInvoiceSlice/deleteInvoiceAsync',
  async (post, {rejectWithValue}, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-invoices/deleteinvoice`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data =  response;
        
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response;
        
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {
      
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


// add Other Incomes

export const otherIncomeAsync = createAsyncThunk(
  'LandlordInvoiceSlice/otherIncomeAsync',
  async (post, {rejectWithValue}, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');

        // const navigation = useNavigation();
      const response = await fetch(
        `${NEW_API_BASE_URL}/landlord-other-income`,
        {
          method: 'POST',
          body: JSON.stringify(post),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'source': 'web',
            'authorization': `Bearer ${access_token}`,

          },
        },
      );
      
      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();
        
        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();
        
        // Toast.show(data.message);
        return data;
      }
    } catch (err) {
      
      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


