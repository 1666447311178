import {createSlice } from "@reduxjs/toolkit";
import { depositListAsync, allDepositListAsync, deleteDepositAsync, addDepositAsync} from "./Services"
import _ from "lodash";

const depositSlice = createSlice({
  name: "depositSlice",
  initialState: {
    loading: false,
    depositForm: {},
    depositList: [],
    allDepositList: [],
  },
  reducers: {
    changeDepositForm:(state, action) => {
      let update_form = _.cloneDeep(state.depositForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        depositForm:{
          ...state.depositForm,
          ...update_form,
        }
      }
    },
    clearDepositForm: (state, action) => {
      state.depositForm = {};
    },
  },
  extraReducers: {
    [addDepositAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addDepositAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addDepositAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    // [editTenanciesAsync.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [editTenanciesAsync.fulfilled]: (state, action) => {
    //   state.loading = false;
    // //   state.program = action.payload;
    // },
    // [editTenanciesAsync.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    [deleteDepositAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteDepositAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteDepositAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [depositListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [depositListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.depositList = action?.payload.data;
    //   state.program = action.payload;
    },
    [depositListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allDepositListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allDepositListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allDepositList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allDepositListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default depositSlice.reducer;

export const {
  changeDepositForm,
  clearDepositForm,
} = depositSlice.actions;
