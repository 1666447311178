import {createSlice } from "@reduxjs/toolkit";
import { rentReminderAsync, allRentReminderAsync} from "./Services"
import _ from "lodash";

const rentReminderSlice = createSlice({
  name: "rentReminderSlice",
  initialState: {
    loading: false,
    rentReminderForm: {},
    rentReminderList: [],
    allRentReminderList: [],
  },
  reducers: {
    changeRentReminderForm:(state, action) => {
      let update_form = _.cloneDeep(state.rentReminderForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        rentReminderForm:{
          ...state.rentReminderForm,
          ...update_form,
        }
      }
    },
    clearRentReminderForm: (state, action) => {
      state.rentReminderForm = {};
    },
  },
  extraReducers: {
    
    [rentReminderAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [rentReminderAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.rentReminderList = action?.payload.data;
    //   state.program = action.payload;
    },
    [rentReminderAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allRentReminderAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allRentReminderAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allRentReminderList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allRentReminderAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default rentReminderSlice.reducer;

export const {
  changeRentReminderForm,
  clearRentReminderForm,
} = rentReminderSlice.actions;
