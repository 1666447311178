import React from 'react';
import { GoogleMap, Marker, LoadScript, useJsApiLoader } from '@react-google-maps/api';
import { useState } from 'react';
import { googleMapsApiKey } from 'constants/constants';

const MapWithPin = ({ coordinates, height, borderRadius }) => {

  const mapContainerStyle = {
    width: '100%',
    height: height,
    borderRadius: borderRadius
  };

  const center = {
    lat: (coordinates[0] && coordinates[0] !== undefined) ? coordinates[0] : 51.5072, // Provide default values if needed
    lng: (coordinates[1] && coordinates[1] !== undefined) ? coordinates[1] : 0.1276,
  };

  // Create a custom marker icon
  const markerIcon = {
    url: '/images/location-pin.png',
    scaledSize: { width: 20, height: 30 }, // Adjust the size as needed
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleMapsApiKey
  })

  const [map, setMap] = useState(null);

  const onLoad = React.useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!

    // Set a proper zoom level based on your requirements
    // const bounds = new window.google.maps.LatLngBounds();
    // bounds.extend(new window.google.maps.LatLng(center.lat, center.lng));
    // map.fitBounds(bounds);
    map.setZoom(14)

    setMap(map);
  }, [center])

  const onUnmount = React.useCallback(function callback() {
    setMap(null);
  }, [])

  

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      center={center}
      zoom={10} // You can adjust this initial zoom level as needed
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {coordinates.length > 0 &&
        <Marker
          position={{ lat: coordinates[0], lng: coordinates[1] }}
          icon={markerIcon}
        />}
    </GoogleMap>
  ) : <></>
};

export default MapWithPin;
