import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TenancyDetailForm from "./TenancyDetailForm";
import HorizontalLinearStepper from "components/Stepper";
import ContractForm from "./ContractForm";
import RentScheduler from "./RentScheduler";
import Agreement from "./Agreement";
import useSelectionData from "./hooks/useSelectionData";
import Axios from "utils/axios";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import useStepperState from "./hooks/useStepperState";
import Loader from "components/Loader";
import Toast from "components/Toast";

const EditTenancy = (props) => {
  const userObj = useSelector((state) => state.auth.user);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.get("id");
  const [dataLoading, setDataLoading] = useState(false);

  const { properties, getPropertyList, getBankList, bankList } =
    useSelectionData();

  const {
    steps,
    activeStep,
    skipped,
    isStepOptional,
    isStepSkipped,
    handleNext,
    handleBack,
    handleSkip,
    handleReset,
    isLoading,
    formik,
  } = useStepperState();

  const checkUserIdentity = () => {
    Axios.get(`users?id=${userObj?.id}`)
      .then((res) => {
        if (
          res?.data?.data?.identity &&
          Object.keys(res?.data?.data?.identity).length > 0
        ) {
          formik.setFieldValue("identity", true);
        }
      })
      .catch((error) => {
        Toast(error, "error");
      });
  };

  const getTenancyById = (id) => {
    setDataLoading(true);
    Axios.get(`/tenancies/getById?id=${id}`)
      .then((res) => {
        formik.setValues(res?.data?.data);
        formik.setFieldValue("identity", true);
        formik.setFieldValue("ownership", true);
        formik.setFieldValue("tenancyStartDate", "");
        formik.setFieldValue("tenancyEndDate", "");
        {
          res?.data?.data?.depositDueDate &&
            formik.setFieldValue("depositDueDate", "");
        }
        formik.setFieldValue("firstMonthDueDate", "");
        res?.data?.data?.holding?.dueDate &&
          formik.setFieldValue("holding.dueDate", "");
        formik.setFieldValue("scheduleData", []);
        formik.setFieldValue("rentSchedule", 0);
        formik.setFieldValue("agreement", 0);
        formik.setFieldValue("astDocument", "");
        setDataLoading(false);
      })
      .catch((error) => {
        Toast(error, "error");
        setDataLoading(false);
      });
  };

  useEffect(
    () => {
      getTenancyById(id);
      getPropertyList();
      getBankList();
      checkUserIdentity();
    }, // eslint-disable-next-line
    []
  );

  return (
    <>
      <div className="container_pad v-add-tenancy-responsive">
        <div className="card__base  mg10">
          <div className="mb-5">
            <h3 className=""> Edit Tenancy</h3>
          </div>
          {dataLoading ? (
            <Loader />
          ) : (
            <HorizontalLinearStepper
              formSteps={steps}
              form={
                activeStep === 0 ? (
                  <TenancyDetailForm
                    handleNext={handleNext}
                    formik={formik}
                    properties={properties}
                    bankList={bankList}
                    getPropertyList={() => {
                      getPropertyList();
                    }}
                    getBankList={() => {
                      getBankList();
                    }}
                  />
                ) : activeStep === 1 ? (
                  <ContractForm
                    handleNext={handleNext}
                    handleBack={handleBack}
                    formik={formik}
                  />
                ) : activeStep === 2 ? (
                  <RentScheduler
                    handleNext={handleNext}
                    handleBack={handleBack}
                    formik={formik}
                  />
                ) : (
                  <Agreement
                    handleBack={handleBack}
                    formik={formik}
                    isLoading={isLoading}
                  />
                )
              }
              activeStep={activeStep}
              isStepSkipped={(val) => {
                isStepSkipped(val);
              }}
              isStepOptional={(val) => {
                isStepOptional(val);
              }}
              handleNext={() => {
                handleNext();
              }}
              handleBack={handleBack}
              handleSkip={handleSkip}
              handleReset={handleReset}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default EditTenancy;
