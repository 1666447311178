import {createSlice } from "@reduxjs/toolkit";
import { tenantInvoiceListAsync, allTenantInvoiceListAsync, deleteTenantInvoiceAsync, addTenantInvoiceAsync} from "./Services"
import _ from "lodash";

const depositSlice = createSlice({
  name: "depositSlice",
  initialState: {
    loading: false,
    tenantInvoiceForm: {},
    tenantInvoiceList: [],
    allTenantInvoiceList: [],
  },
  reducers: {
    changeTenantInvoiceForm:(state, action) => {
      let update_form = _.cloneDeep(state.tenantInvoiceForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        tenantInvoiceForm:{
          ...state.tenantInvoiceForm,
          ...update_form,
        }
      }
    },
    clearTenantInvoiceForm: (state, action) => {
      state.tenantInvoiceForm = {};
    },
  },
  extraReducers: {
    [addTenantInvoiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addTenantInvoiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addTenantInvoiceAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    // [editTenanciesAsync.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [editTenanciesAsync.fulfilled]: (state, action) => {
    //   state.loading = false;
    // //   state.program = action.payload;
    // },
    // [editTenanciesAsync.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    [deleteTenantInvoiceAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTenantInvoiceAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteTenantInvoiceAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [tenantInvoiceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tenantInvoiceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tenantInvoiceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tenantInvoiceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allTenantInvoiceListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allTenantInvoiceListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTenantInvoiceList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allTenantInvoiceListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default depositSlice.reducer;

export const {
  changeTenantInvoiceForm,
  clearTenantInvoiceForm,
} = depositSlice.actions;
