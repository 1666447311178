import * as React from "react";
import Switch from "@mui/material/Switch";

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function SwitchButton({ checked }) {
  return (
    <div>
      {/* <Switch {...label} defaultChecked /> */}
      <Switch {...label} checked={checked ? true : false} />
      {/* <Switch {...label} disabled defaultChecked />
      <Switch {...label} disabled /> */}
    </div>
  );
}
