import { createSlice } from '@reduxjs/toolkit';
import {io} from 'socket.io-client';
import { API_HOST} from 'constants/constants';

const initialState = {
    socket:null
};

const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers: {

      connectToSocket: (state, action) =>{
        const token = action.payload;
        
        const socket = io(API_HOST, {
            // path: "/api", Use This if we need to add /api in backend in socket URL
            query: {token}, 
            // query: {token: localStorage.getItem("token") ? localStorage.getItem("token") : null}, 
            transports: ["websocket", "polling"],
            reconnection: true
        });
        state.socket = socket;
        console.log('Scoket Connected');
      },
    },

  });

  export const {connectToSocket} = socketSlice.actions;
  export default socketSlice.reducer;