import { createSlice } from '@reduxjs/toolkit';

  const initialState = {
    chatMessages:[],
    chatUser:{},
    userList:[],
    selectedUsr:{},
    isSocketConnected:true
  }
  
  const messagingSlice = createSlice({
    name: 'messaging',
    initialState,
    reducers: {

      saveMessages: (state, action) =>{
        const chatUsrObj = (({ chatmessages, id, ...rest }) => rest)(action.payload); //Removing the chatmessages from object
        state.chatUser = chatUsrObj;
        if(action.payload.chatmessages){
          state.chatMessages = action.payload.chatmessages;
        }else{
          state.chatMessages = [];
        }        
      },
      appendMessages: (state, action) =>{
        state.chatMessages.push(action.payload);
      },
      setUserList:(state, action)=>{
        state.userList = action.payload;
      },
      setSelectedUser:(state, action)=>{
        state.selectedUsr = action.payload;
      },
      setSocketConnect:(state, action)=>{
        state.isSocketConnected = action.payload;
      },
    },
    extraReducers: {},
  });


  export const {saveMessages, appendMessages, setUserList, setSelectedUser, setSocketConnect} = messagingSlice.actions;
  export default messagingSlice.reducer;