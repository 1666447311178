// import "./App.css"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaUserPlus } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { propertySelectListAsync } from "../../../../redux/properties/Services";
import { useState } from "react";
import { addManagerAsync } from "../../../../redux/propertyManagers/Services";
import {
  changeManagerForm,
} from "../../../../redux/propertyManagers/ManagersSlice";
import MultiSelectWithCheckboxWithoutIcon from "../../../../components/form/MultiSelectWithCheckboxWithoutIcon";
import Toast from "components/Toast";


function AddManager(props) {
  const navigate = useNavigate();

  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj.id;
  const userType = userObj.currentType;
  const [units, setUnits] = useState([]);
  const [propertyList, setPropertyList] = useState([]);

  const dispatch = useDispatch();
  const { managerForm, propertySelectList } = useSelector((state) => {
    const { propertyManagerReducer, propertyReducer } = state;

    return {
      loading: propertyManagerReducer.loading,
      managerForm: propertyManagerReducer.managerForm,
      propertySelectList: propertyReducer.propertySelectList,
    };
  });

  const fetchProperty = () => {
    try {
      const payload = {
        userId: userId,
        userType: userType,
      };
      dispatch(propertySelectListAsync(payload))
        .unwrap()
        .then((res) => {
          const newArray = res.data.map((item) => ({
            label: item.title,
            value: item._id,
          }));

          setPropertyList(newArray);
        });
    } catch (error) {
      // toast.error("Program details could not be fetched, please try again!");
    }
  };

  useEffect(
    () => {
      fetchProperty();

      dispatch(
        changeManagerForm({ edited_field: "title", edited_value: "Mr." })
      );
      dispatch(
        changeManagerForm({ edited_field: "status", edited_value: "1" })
      );
    }, // eslint-disable-next-line
    []
  );

  let handleSubmit = (e) => {
    e.preventDefault();

    try {
      const payload = {
        title: managerForm.title,
        firstName: managerForm.firstName,
        lastName: managerForm.lastName,
        email: managerForm.email,
        mobileNumber: managerForm.mobileNumber,
        address: managerForm.address,
        status: managerForm.status,
        additionalDeatils: managerForm.additionalDeatils
          ? managerForm.additionalDeatils
          : "",
        property: props?.property?._id
          ? props?.property?._id
          : managerForm.property,
        createdBy: userId,
        user_id: userId,
      };
      dispatch(addManagerAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            Toast(res.error, 'error');
          } else {
            // console.log(res.data._id);
            if (props.setformikValue) {
              props.setformikValue(res.data?._id);
            }

            props.getManagerList
              ? props.getManagerList()
              : navigate("/ll/property-managers/all-property-managers");

            if (props.onClose) {
              props.onClose();
            }
          }
        });
    } catch (error) {
      Toast(error, 'error');
    }
  };

  const handleChange = (options) => {
    dispatch(
      changeManagerForm({ edited_field: "property", edited_value: options })
    );
  };

  return (
    <>
      {props?.onClose ? (
        <div className="modal-title py-3 px-2 px-sm-3 mb-0">
          Add a Property Manager{" "}
          <button
            className="grow modal-button"
            onClick={() => {
              props?.onClose();
            }}
          >
            X
          </button>
        </div>
      ) : (
        <div className="add-heading2">
          <div className="icon-card">
            <FaUserPlus style={{ color: "#F4B905" }} />{" "}
          </div>
          <h3 className="margin-b-0"> Add a Property Manager</h3>
        </div>
      )}

      <div className={props?.onClose ? "vmargin-respon form-h1 v-form-h overflow-y mg10 pb-3 pb-md-0 overflow-x-hidden" : "card form-h1 v-form-h overflow-y mg10"}>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Title</label>
              <select
                className="form-select"
                aria-label="Default select example"
                value={managerForm.title}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "title",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              >
                <option selected value="Mr.">
                  Mr.
                </option>
                <option value="Mrs.">Mrs.</option>
              </select>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                value={managerForm.firstName}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "firstName",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                value={managerForm.lastName}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "lastName",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Email Address</label>
              <input
                type="email"
                autoComplete="off"
                className="form-control"
                placeholder="Enter email"
                value={managerForm.email}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "email",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Mobile Number</label>
              <input
                type="tel"
                className="form-control"
                placeholder="Enter Number"
                pattern="(\+44\s?)?[7-9]\d{9}|(0?[6-9]\d{9})"
                maxLength={13} // +44 and 10 digits
                minLength={10} // +44 and 9 digits
                number={true}
                value={managerForm.mobileNumber}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "mobileNumber",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            {/* <div className='col-sm-12 col-md-6 col-lg-4 mb-3'>
                            <label>Status</label>
                            <select className="form-select" aria-label="Default select example"
                                value={managerForm.status}
                                onChange={(e) => { dispatch(changeManagerForm({ edited_field: "status", edited_value: e.target.value })) }}
                                req
                            >
                                <option selected value="1" >Active</option>
                                <option value="0">In Active</option>

                            </select>
                        </div> */}

            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                value={managerForm.address}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "address",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
            </div>
            {!props.property && (
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                <label>Property</label>
                <MultiSelectWithCheckboxWithoutIcon
                  customClass={"basic-multi-select3"}
                  options={propertyList}
                  value={managerForm.property}
                  onSelectionChange={(values) => {
                    handleChange(values);
                  }}
                />
              </div>
            )}

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Additional Manager Details
              </label>
              <textarea
                type="text"
                className="form-control "
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder=""
                value={managerForm.additionalDeatils}
                onChange={(e) => {
                  dispatch(
                    changeManagerForm({
                      edited_field: "additionalDeatils",
                      edited_value: e.target.value,
                    })
                  );
                }}
              />
              {/* <CKEditor
                                editor={ClassicEditor}
                                data={managerForm.additionalDeatils}
                                onReady={(editor) => {

                                    // CKEditorInspector.attach(editor);
                                }}
                                onChange={(event, editor) => {
                                    const data = editor.getData();
                                    dispatch(changeManagerForm({ edited_field: "additionalDeatils", edited_value: data }))

                                }}
                                config={{
                                    removePlugins: ["EasyImage", "ImageUpload"]
                                }}

                                required
                            /> */}
            </div>
            <div className="">
              <button type="submit" className="btn form-btn grow">
                Add Manager
              </button>
              <button
                type="button"
                onClick={() => {
                  props.onClose
                    ? props.onClose()
                    : navigate("/ll/property-managers/all-property-managers");
                }}
                className="btn form-btn2 grow"
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddManager;
