import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { FaEye, FaEyeSlash } from "react-icons/fa";
import Axios from "utils/axios";

import * as Yup from "yup";

import { useFormik } from "formik";
import { ButtonLoader } from "components/form/ButtonLoader";
import ErrorMsg from "components/ErrorMsg";

function ResetPassword(props) {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const data = new URLSearchParams(window.location.search);

  const [error, setError] = useState("");
  const id = data.get("id");
  const token = data.get("token");

  const loginhandle = (values, { setSubmitting, setErrors }) => {
    if (values.password !== values.confirmPassword) {
      setError("New password is not matching");
      return 0;
    }
    setIsLoading(true);
    const payload = {
      //email: resetPasswordForm.email,
      password: values.password,
      id: id,
      token: token,
    };

    Axios.post("/reset-password", payload)
      .then((res) => {
        setIsLoading(false);
        if (!res.error) {
          navigate("/");
        } else {
          setErrors({ submit: res.error });
        }
      })
      .catch((err) => {
        setIsLoading(false);

        setErrors({ submit: err });
      });
  };
  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .test(
          "len",
          "Password sholud contain minimum  8 character ",
          (val) => val.toString().length >= 8
        )
        .test(
          "specialChar",
          "Password should contain at least one special character",
          (val) => /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]/.test(val)
        )
        .required("Please enter password"),
      confirmPassword: Yup.string()
        .test(
          "len",
          "Confirm password sholud contain minimum  8 character ",
          (val) => val.toString().length >= 8
        )
        .test(
          "specialChar",
          "Confirm password should contain at least one special character",
          (val) => /[!@#$%^&*()_+|~=`{}\[\]:";'<>?,./]/.test(val)
        )
        .required("Please enter confirm password"),
    }),
    onSubmit: loginhandle,
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  function toggleShowPassword2() {
    setShowConfirmPassword(!showConfirmPassword);
  }

  return (
    <div className=" vh-100 app-bg overflow-y">
      <div className="container py-5">
        <div className="row d-flex justify-content-center align-items-center min-vh-100 m-0">
          <div className="col-12 col-md-8 col-lg-7 mb-4 mb-md-0 p-0">
            <h1 className="login-heading">
              {" "}
              Find Your Property That{" "}
              <b
                className=""
                style={{ color: "#F4B905", backgroundColor: "#d4d4d4" }}
              >
                {" "}
                Suits You.{" "}
              </b>
            </h1>
            <p className="login-summary2">
              Build and scale your portfolio with confidence. From a single property to managing advanced rental portfolio including HMOs and Student Let —we’ve got you covered.
            </p>

            {/* <div><img className='login-image' src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"></img></div> */}
          </div>
          <div className="col-12 col-md-8 col-lg-4 offset-lg-1  card">
            <div className="">
              <form onSubmit={formik.handleSubmit}>
                <img
                  className="sign-in-logo"
                  src="/images/propertyjinni-favicon.png"
                  alt="wrong Url"
                ></img>
                <h3 className="text-center mt-3 mb-4 log-heading">
                  Reset Password
                </h3>
                {error && (
                  <div
                    className="mb-3"
                    style={{
                      backgroundColor: "#f06a6a",
                      color: "black",
                      padding: "0.5rem",
                      textAlign: "center",
                      fontWeight: "600",
                    }}
                  >
                    {error}
                  </div>
                )}
                <div className="mb-2">
                  <label>
                    New Password<span>*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      autoComplete="new-password"
                      className="form-control login-input"
                      placeholder="Enter password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-group-btn mt-1">
                      <span className="" onClick={toggleShowPassword}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                  <ErrorMsg
                    hasError={formik.touched.password && formik.errors.password}
                  >
                    {formik.errors.password}
                  </ErrorMsg>
                </div>
                <div className="mb-3">
                  <label>
                    Confirm New Password<span>*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control login-input"
                      name="confirmPassword"
                      placeholder="Enter Confirm password"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <div className="input-group-btn mt-1">
                      <span className="" onClick={toggleShowPassword2}>
                        {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                      </span>
                    </div>
                  </div>
                  <ErrorMsg
                    hasError={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  >
                    {formik.errors.confirmPassword}
                  </ErrorMsg>
                </div>
                <ErrorMsg hasError={formik.errors.submit}>
                  {formik.errors.submit}
                </ErrorMsg>
                <div className="d-grid">
                  <ButtonLoader
                    type="submit"
                    className="btn btn-auth"
                    buttonName={"Submit"}
                    isLoading={isLoading}
                    loaderColor={"yellow"}
                  >
                    Submit
                  </ButtonLoader>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
