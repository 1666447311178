import {createSlice } from "@reduxjs/toolkit";
import {signoutAsync, adminSignoutAsync} from "./Services"
import _ from "lodash";

const signoutSlice = createSlice({
  name: "signoutSlice",
  initialState: {
    loading: false,
    signoutForm: {},
  },
  reducers: {
    changeSignoutForm:(state, action) => {
      let update_form = _.cloneDeep(state.signoutForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        signoutForm:{
          ...state.signoutForm,
          ...update_form,
        }
      }
    },
    clearSignoutForm: (state, action) => {
      state.signoutForm = {};
    },
  },
  extraReducers: {
    [signoutAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [signoutAsync.fulfilled]: (state, action) => {
      state.loading = false;

      // state.userId = action?.payload.data.id;
      
    //   state.program = action.payload;
    
    },
    [signoutAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [adminSignoutAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [adminSignoutAsync.fulfilled]: (state, action) => {
      state.loading = false;

      // state.userId = action?.payload.data.id;
      
    //   state.program = action.payload;
    
    },
    [adminSignoutAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default signoutSlice.reducer;

export const {
  changeSignoutForm,
  clearSignoutForm,
} = signoutSlice.actions;
