import {createSlice } from "@reduxjs/toolkit";
import {sendVerifyMailAsync} from "./Services"
import _ from "lodash";

const sendVerifyMailSlice = createSlice({
  name: "sendVerifyMailSlice",
  initialState: {
    loading: false,
    sendVerifyMailForm: {},
  },
  reducers: {
    changeSendVerifyMailForm:(state, action) => {
      let update_form = _.cloneDeep(state.sendVerifyMailForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        sendVerifyMailForm:{
          ...state.sendVerifyMailForm,
          ...update_form,
        }
      }
    },
    clearSendVerifyMailForm: (state, action) => {
      state.sendVerifyMailForm = {};
    },
  },
  extraReducers: {
    [sendVerifyMailAsync.pending]: (state, action) => {
      state.loading = true;
     
    },
    [sendVerifyMailAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [sendVerifyMailAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default sendVerifyMailSlice.reducer;

export const {
  changeSendVerifyMailForm,
  clearSendVerifyMailForm,
} = sendVerifyMailSlice.actions;
