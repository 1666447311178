

// import "./App.css"
import { useEffect } from "react";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import CKEditorInspector from '@ckeditor/ckeditor5-inspector';

import { useDispatch, useSelector } from "react-redux";
import { sendMailAsync } from "../redux/sendMail/Services";
import  { changeSendMailForm, clearSendMailForm } from "../redux/sendMail/sendMailSlice"

function SendMail(props) {
    

    let closeModal = () => {
        props.closePopup();
        dispatch(clearSendMailForm());
    }

    useEffect(() => {
        dispatch(clearSendMailForm());
        if(props.to){
            dispatch(changeSendMailForm({ edited_field: "to", edited_value: `${props.to}` }));

        }
        
    }, // eslint-disable-next-line
    [])



    const dispatch = useDispatch();
    const { loading, sendMailForm } = useSelector((state) => {
        const { sendMailReducer } = state;

        return { loading: sendMailReducer.loading, sendMailForm: sendMailReducer.sendMailForm };
    });



    let handleSubmit = (e) => {

        e.preventDefault();
        try {
            const payload = {
                to: sendMailForm.to,
                bcc: sendMailForm.bcc,
                subject: sendMailForm.subject,
                body: sendMailForm.body ? sendMailForm.body : "",
                user_id: props.id
            };
            dispatch(sendMailAsync(payload))
                .unwrap()
                .then((res) => {

                    props.closePopup();
                    

                });
        } catch (error) {
            // toast.error("Program details could not be fetched, please try again!");
        }

    };



    return (
        <>
            <div className=' modal2'>
                <div className="card table-h overflow-y mg10" >
                {loading && <div className="" style={{ color:'#00838c'}} ><div style={{marginRight:"10px"}}  >please Wait... mail sending is in progress !! </div></div>}

                    <div className='modal-title'>Send Mail <button className='grow modal-button' onClick={closeModal}  >X</button></div>
                    <form onSubmit={handleSubmit} >
                        <div className='row'>
                        <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                                <label>To</label>
                                <input type="email" className="form-control"
                                    placeholder="To"
                                    value={sendMailForm.to}
                                    onChange={(e) => { dispatch(changeSendMailForm({ edited_field: "to", edited_value: e.target.value })) }}
                                />
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 mb-3'>
                                <label>Bcc</label>
                                <input type="email" className="form-control"
                                    placeholder="BCC"
                                    value={sendMailForm.bcc}
                                    onChange={(e) => { dispatch(changeSendMailForm({ edited_field: "bcc", edited_value: e.target.value })) }}
                                />
                            </div>

                            <div className='col-sm-12 col-md-12 col-lg-12 mb-3'>
                                <label>Subject</label>
                                <input type="text" className="form-control"
                                    placeholder="Subject"
                                    value={sendMailForm.subject}
                                    onChange={(e) => { dispatch(changeSendMailForm({ edited_field: "subject", edited_value: e.target.value })) }}
                                />
                            </div>
                            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
                                <label for="exampleFormControlTextarea1" class="form-label">Remark</label>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={sendMailForm.body}
                                    onReady={(editor) => {
                                        
                                        // CKEditorInspector.attach(editor);
                                    }}
                                    onChange={(event, editor) => {
                                        const data = editor.getData();
                                        dispatch(changeSendMailForm({ edited_field: "body", edited_value: data }));

                                        
                                    }}
                                    config={{
                                        removePlugins: ["EasyImage","ImageUpload"]
                                    }}

                                    required
                                /></div>
                            <div className="">
                                <button type="submit" className="btn form-btn grow">
                                    Send Mail
                                </button>

                            </div>



                        </div>



                    </form>
                </div>

            </div>

        </>




    )
}

export default SendMail