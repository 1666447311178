import {createSlice } from "@reduxjs/toolkit";
import {resetPasswordAsync, adminResetPasswordAsync} from "./Services"
import _ from "lodash";

const resetPasswordSlice = createSlice({
  name: "resetPasswordSlice",
  initialState: {
    loading: false,
    resetPasswordForm: {},
  },
  reducers: {
    changeResetPasswordForm:(state, action) => {
      let update_form = _.cloneDeep(state.resetPasswordForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        resetPasswordForm:{
          ...state.resetPasswordForm,
          ...update_form,
        }
      }
    },
    clearResetPasswordForm: (state, action) => {
      state.resetPasswordForm = {};
    },
  },
  extraReducers: {
    [resetPasswordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [resetPasswordAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [resetPasswordAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [adminResetPasswordAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [adminResetPasswordAsync.fulfilled]: (state, action) => {
      state.loading = false;
      
    //   state.program = action.payload;
    
    },
    [adminResetPasswordAsync.rejected]: (state, action) => {
      state.loading = false;
    },
   
  },
});

export default resetPasswordSlice.reducer;

export const {
  changeResetPasswordForm,
  clearResetPasswordForm,
} = resetPasswordSlice.actions;
