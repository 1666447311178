import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "utils/axios";
import { ButtonLoader } from "components/form/ButtonLoader";
import { useEffect } from "react";
import useCertificateData from "./hooks/useCertificateData";
import ErrorMsg from "components/ErrorMsg";
import { useNavigate } from "react-router-dom";

const EditCertificate = ({ closePopup, data , getCertificateList  }) => {
  const {
    getPropertyList,
    properties,
    isLoading,
    setIsLoading,
    convertUnitPropertyToOptions,
  } = useCertificateData();

  useEffect(() => {
    getPropertyList();
  }, []);

  useEffect(() => {
    if (data) {
      formik.setFieldValue("certificateType", data?.certificateType);
      formik.setFieldValue("notifyMe", data?.notifyMe);
      formik.setFieldValue("property", data?.property?._id);
      formik.setFieldValue("unit", data?.unit?._id);
      data?.expiryDate && formik.setFieldValue("expiryDate", data?.expiryDate?.slice(0, 10));
      data?.share && formik.setFieldValue("share", data?.share);
      data?.alertMethod &&   formik.setFieldValue("alertMethod", data?.alertMethod);
      formik.setFieldValue("remark", data?.remark);
      formik.setFieldValue("reminder", data?.reminder);
    }
  }, [data, properties]);

  
  let handleSubmit = (values, { setSubmitting, setErrors }) => {
    const formdata = new FormData();
    formdata.append("property", values.property);
    formdata.append("id", data._id);
    values.unit && formdata.append("unit", values.unit);
    formdata.append("certificateType", values.certificateType);
    formdata.append("notifyMe", values.notifyMe);
    formdata.append("expiryDate", values.expiryDate);
    formdata.append("share", JSON.stringify(values.share));
    formdata.append("alertMethod", JSON.stringify(values.alertMethod));
    formdata.append("remark", values.remark);
    formdata.append("reminder", values.reminder);
    values.file && formdata.append("images", values.file);

    setIsLoading(true);
    Axios.post(`/certificate/editCertificate`, formdata)
      .then((res) => {
        setIsLoading(false);
        getCertificateList();
        closePopup()
      })
      .catch((err) => {
        setErrors({ submit: err });
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: {
      property: "",
      unit: "",
      certificateType: "",
      file: null,
      notifyMe: false,
      expiryDate: "",
      share: {
        tenants: false,
        landlord: false,
        lettingAgents: false,
      },
      remark: "",
      reminder: "",
      alertMethod: {
        notification: false,
        message: false,
        email: false,
      },
    },
    validationSchema: Yup.object().shape({
      property: Yup.string().required("Please select a property or unit"),
      certificateType: Yup.string().required("Please select  certificate type"),
      expiryDate: Yup.date().required("Please select a expiry date"),
      reminder: Yup.string().when("notifyMe", {
        is: true,
        then: () => Yup.string().required("Please select alert time"),
      }),
    }),
    onSubmit: handleSubmit,
  });

 
  return (
    <>
      <div className="card form-h mx-0 overflow-y mg10" style={{ width: "80%" }}>
        <p className="unit-heading">Update your certificate information</p>
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label>
                Select a property or unit to relate this certificate
              </label>
              <div class="input-group">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="property"
                  value={
                    (formik.values.unit ? formik.values.unit + "-" : "") +
                    formik.values.property
                  }
                  onChange={(e) => {
                    if (e.target.value.includes("-")) {
                      formik.setFieldValue(
                        "property",
                        e.target.value.split("-")[1]
                      );
                      formik.setFieldValue(
                        "unit",
                        e.target.value.split("-")[0]
                      );
                    } else {
                      formik.setFieldValue("property", e.target.value);
                      formik.setFieldValue("unit", "");
                    }
                  }}
                >
                  <option value={""} hidden>
                    Select an option
                  </option>
                  {properties.length > 0 &&
                    convertUnitPropertyToOptions(properties)?.map(
                      (data, index) => {
                        return <option value={data.value}>{data.label}</option>;
                      }
                    )}
                </select>
              </div>
              <ErrorMsg
                hasError={formik.touched.property && formik.errors.property}
              >
                {formik.errors.property}
              </ErrorMsg>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Certificate Type</label>
              <select
                className="form-select"
                aria-label="Default select example"
                name="certificateType"
                value={formik.values.certificateType}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
              >
                <option value={""} hidden>
                  Select an option
                </option>
                <option value="Gas Certificate">Gas Certificate</option>
                <option value="PAT Test">PAT Test</option>
                <option value="Electricity Certificate">
                  Electricity Certificate
                </option>
                <option value="EPC">
                  EPC (Energy Performance Certificate)
                </option>
                <option value="Insurance Certificate">
                  Insurance Certificate
                </option>
                <option value="Legionella Risk Assessment Date">
                  Legionella Risk Assessment Date
                </option>
                <option value="Smoke & CO Alarm Expiry Date">
                  Smoke & CO Alarm Expiry Date
                </option>
                <option value="Property Licence Expiry Date">
                  Property Licence Expiry Date
                </option>
                <option value="HMO Certificate">HMO Certificate</option>
                <option value="Fire Alarm">Fire Alarm</option>
                <option value="Fire Risk Assessment">
                  Fire Risk Assessment
                </option>
                <option value="Smoke Detector">Smoke Detector</option>
                <option value="TV License">TV License</option>
                <option value="Council Tax Exemption">
                  Council Tax Exemption
                </option>
                <option value="Locality Risk Assessment">
                  Locality Risk Assessment
                </option>
                <option value="Boiler Warranty">Boiler Warranty</option>
                <option value="Appliance Risk Assessment">
                  Appliance Risk Assessment
                </option>
                <option value="Deposit">Deposit</option>
              </select>
              <ErrorMsg
                hasError={
                  formik.touched.certificateType &&
                  formik.errors.certificateType
                }
              >
                {formik.errors.certificateType}
              </ErrorMsg>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>Expiry Date</label>
              <input
                type="date"
                className="form-control text-uppercase"
                placeholder="Enter Date"
                name="expiryDate"
                min={new Date().toISOString().split('T')[0]} 
                value={formik.values.expiryDate}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <ErrorMsg
                hasError={formik.touched.expiryDate && formik.errors.expiryDate}
              >
                {formik.errors.expiryDate}
              </ErrorMsg>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
              <label>File</label>
              <input
                className="form-control"
                type="file"
                id="formFile"
                accept=".jpg, .jpeg, .png, .gif, .pdf"
                name="file"
                onChange={(e) => {
                  formik.setFieldValue("file", e.target.files[0]);
                }}
              />
              <ErrorMsg hasError={formik.touched.file && formik.errors.file}>
                {formik.errors.file}
              </ErrorMsg>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <p className="fw-bold">Share with</p>
              <p className="small">
                &#9432; Please ensure you tick the appropriate person to whom
                this field should be shared with
              </p>
              <div className="d-flex flex-wrap align-items-center gap-4 mt-2">
                <div className="mb-1">
                  <div className="custom-control custom-checkbox d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="custom-control-input mr-2"
                      id="customCheck1"
                      name={"share.landlord"}
                      checked={formik.values.share.landlord}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "share.landlord",
                          !formik.values.share.landlord
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      Landlord
                    </label>
                  </div>
                </div>
                <div className="mb-1">
                  <div className="custom-control custom-checkbox d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="custom-control-input mr-2"
                      id="customCheck2"
                      name={"share.lettingAgents"}
                      checked={formik.values.share.lettingAgents}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "share.lettingAgents",
                          !formik.values.share.lettingAgents
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck2"
                    >
                      Letting Agents
                    </label>
                  </div>
                </div>
                <div className="mb-1">
                  <div className="custom-control custom-checkbox d-flex align-items-center">
                    <input
                      type="checkbox"
                      className="custom-control-input mr-2"
                      id="customCheck3"
                      name={"share.tenants"}
                      checked={formik.values.share.tenants}
                      onChange={(e) => {
                        formik.setFieldValue(
                          "share.tenants",
                          !formik.values.share.tenants
                        );
                      }}
                      onBlur={formik.handleBlur}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor="customCheck3"
                    >
                      All Tenants
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3 mt-2">
              <div className="custom-control custom-checkbox d-flex align-items-center">
                <input
                  type="checkbox"
                  className="custom-control-input mr-2"
                  id="notifyMe"
                  name="notifyMe"
                  checked={formik.values.notifyMe}
                  onChange={formik.handleChange}
                />
                <label className="custom-control-label" htmlFor="notifyMe">
                  Notify me
                </label>
              </div>
            </div>
            {formik.values.notifyMe && (
              <div className="col-sm-12 col-md-12 col-lg-4 mb-3">
                <p className="fw-bold">Raise alert method</p>

                <div className="d-flex flex-wrap align-items-center gap-4 mt-2">
                  <div className="mb-1">
                    <div className="custom-control custom-checkbox d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="custom-control-input mr-2"
                        id="customCheck4"
                        name={"alertMethod.notification"}
                        checked={formik.values.alertMethod.notification}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "alertMethod.notification",
                            !formik.values.alertMethod.notification
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck4"
                      >
                        Notification
                      </label>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="custom-control custom-checkbox d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="custom-control-input mr-2"
                        id="customCheck5"
                        name={"alertMethod.message"}
                        checked={formik.values.alertMethod.message}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "alertMethod.message",
                            !formik.values.alertMethod.message
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck5"
                      >
                        Message
                      </label>
                    </div>
                  </div>
                  <div className="mb-1">
                    <div className="custom-control custom-checkbox d-flex align-items-center">
                      <input
                        type="checkbox"
                        className="custom-control-input mr-2"
                        id="customCheck6"
                        name={"alertMethod.email"}
                        checked={formik.values.alertMethod.email}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "alertMethod.email",
                            !formik.values.alertMethod.email
                          );
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customCheck6"
                      >
                        Email
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {formik.values.notifyMe && (
              <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
                <label>Raise alert before</label>
                <select
                  className="form-select"
                  aria-label="Default select example"
                  name="reminder"
                  value={formik.values.reminder}
                  onChange={formik.handleChange}
                  onBlur={formik.onBlur}
                >
                  <option value="" hidden>
                    Select an option
                  </option>
                  <option value="1d">1 Day</option>
                  <option value="2d">2 Day</option>
                  <option value="5d">5 Day</option>
                  <option value="7d">7 Day</option>
                  <option value="15d">15 Day</option>
                  <option value="30d">30 Day</option>
                </select>
                <ErrorMsg
                  hasError={formik.touched.reminder && formik.errors.reminder}
                >
                  {formik.errors.reminder}
                </ErrorMsg>
              </div>
            )}

            <div className="col-sm-12 col-md-12 col-lg-12 mb-3">
              <label for="exampleFormControlTextarea1" class="form-label">
                Note
              </label>
              <textarea
                type="text"
                className="form-control "
                id="exampleFormControlTextarea1"
                rows="3"
                placeholder=""
                name="remark"
                value={formik.values.remark}
                onChange={formik.handleChange}
                onBlur={formik.onBlur}
              />
            </div>
            <ErrorMsg hasError={formik.errors.submit}>
              {formik.errors.submit}
            </ErrorMsg>
            <div className="">
              <ButtonLoader
                buttonName={"Edit Certificate"}
                isLoading={isLoading}
                loaderColor={"white"}
                type="submit"
                className="btn form-btn grow"
              >
                Edit Certificate
              </ButtonLoader>
              <button
                type="button"
                className="btn form-btn2 grow"
                onClick={() => {
                  closePopup();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default EditCertificate;
