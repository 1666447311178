import { Box, Grid } from "@mui/material";
import ErrorMsg from "components/ErrorMsg";
import Toast from "components/Toast";
import { ButtonLoader } from "components/form/ButtonLoader";
import SelectInput from "components/form/SelectInput";
import { useFormik } from "formik";
import * as React from "react";
import { useRef } from "react";
import { useState } from "react";
import { HiOutlineDocumentText } from "react-icons/hi";
// import { toast } from "react-toastify";
import Axios from "utils/axios";
import * as Yup from "yup";

const documentTypes = [
  {
    label: "HM Land Registry title",
    value: "HM Land Registry title",
  },
  {
    label: "Title deed",
    value: "Title deed",
  },
  {
    label: "Mortgage agreement",
    value: "Mortgage agreement",
  },
  {
    label: "Certificate of insurance",
    value: "Certificate of insurance",
  },
  {
    label: "Signed solicitor's letter",
    value: "Signed solicitor's letter",
  },
];

const UploadOwnership = ({ property, setProperty, onClose, fatchProperty }) => {
  const [selectedImagePreview, setSelectedImagePreview] = useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fileInputRef = useRef(null);

  const formik = useFormik({
    initialValues: {
      document: "",
      documentType: "",
    },
    validationSchema: Yup.object().shape({
      documentType: Yup.string().required("Please select document type"),
    }),
    onSubmit: (values) => {
      handleSubmit();
    },
  });

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      formik.setFieldValue("document", selectedImage);
      // Create a URL for the selected image and set it in the state
      const imagePreviewURL = URL.createObjectURL(selectedImage);
      setSelectedImagePreview(imagePreviewURL);
    } else {
      setSelectedImagePreview(null);
      formik.setFieldValue("document", null);
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("property_id", property?._id);
    formData.append("document_Type", formik.values?.documentType);
    formData.append("files", formik.values?.document);

    Axios.post(`/properties/addOwnership`, formData)
      .then((res) => {
        fatchProperty(property?.property_id)
        onClose();
        setIsLoading(false);
      })
      .catch((error) => {
        // toast.error(error, { position: "bottom-left" });
        Toast(error, 'error');
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="py-1 py-md-4 px-1 px-md-4 modal-h overflow-y">
        <div className="block-info" style={{ fontSize: "14px" }}>
          <b>Required documents to upload ONE </b> of the following: HM Land
          Registry title, Title deed, Mortgage agreement, Certificate of
          insurance or Signed solicitor's letter.
          <p className="mt-2">
            We support: PDF, DOC, DOCX, JPG, JPEG & PNG files - Max 5MB
          </p>
        </div>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="form-field__wrapper mt-4 mb-4">
            <Grid item xs={12} sm={12} md={6} className="">
              <div className="document_upload">
                <h5 className="">Proof of ownership</h5>
                {!selectedImagePreview && (
                  <div>
                    <HiOutlineDocumentText />
                    <p>Drag & drop file here</p>
                    <p>or</p>
                    <button className="add_new_btn2" onClick={handleClick}>
                      Browse file
                    </button>
                    <input
                      class="form-control"
                      className="hidden-input"
                      ref={fileInputRef}
                      type="file"
                      id="formFile"
                      onChange={handleImageChange}
                    />
                  </div>
                )}
                {formik.values?.document && (
                  <div>
                    {selectedImagePreview &&
                      formik.values?.document?.type === "application/pdf" ? (
                      <div>
                        <img
                          className="image-object2"
                          src="/images/pdf.png"
                          alt="PDF"
                        />
                        <p style={{ fontSize: "13px", marginTop: "5px", wordWrap: "break-word" }}>
                          {formik.values?.document?.name}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <img
                          className="image-object2"
                          src={selectedImagePreview}
                          alt="Selected Image Preview"
                        />
                        <p style={{ fontSize: "13px", marginTop: "5px", wordWrap: "break-word" }}>
                          {formik.values?.document?.name}
                        </p>
                      </div>
                    )}
                    <div>
                      <button className="mt-3 edit_btn" onClick={handleClick}>
                        Edit
                      </button>
                    </div>
                    <input
                      class="form-control"
                      className="hidden-input"
                      ref={fileInputRef}
                      type="file"
                      id="formFile"
                      onChange={handleImageChange}
                    />
                  </div>
                )}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} className="">
              <div className="">
                <SelectInput
                  customClass={"w-100"}
                  label="Document Type*"
                  options={documentTypes}
                  selectedValue={formik.values.documentType}
                  onChange={(e) => {
                    formik.setFieldValue("documentType", e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
                <ErrorMsg
                  hasError={
                    formik.touched.documentType && formik.errors.documentType
                  }
                >
                  {formik.errors.documentType}
                </ErrorMsg>
              </div>
            </Grid>
          </Grid>
        </Box>
        <div className="d-flex justify-content-end mt-2">
          <button type="button" className="cancel_btn" onClick={onClose}>
            Cancel
          </button>
          <div style={{ marginLeft: "10px" }}>
            <ButtonLoader
              buttonName="Submit"
              variant={"contained"}
              className={"add_new_btn grow"}
              onClickhandle={formik.handleSubmit}
              loaderColor={"yellow"}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadOwnership;
