import {createSlice } from "@reduxjs/toolkit";
import { guestPropertiesListAsync, guestDetailPropertyAsync} from "./Services"


const guestPropertiesSlice = createSlice({
  name: "guestPropertiesSlice",
  initialState: {
    loading: false,
    guestPropertiesList: [],
    guestDetailProperty: {},
  },
  reducers: {
    
  },
  extraReducers: {
   

    
    [guestPropertiesListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [guestPropertiesListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.guestPropertiesList = action?.payload.data;
    //   state.program = action.payload;
    },
    [guestPropertiesListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [guestDetailPropertyAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [guestDetailPropertyAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.guestDetailProperty = action?.payload.data;
    //   state.program = action.payload;
    },
    [guestDetailPropertyAsync.rejected]: (state, action) => {
      state.loading = false;
    },

  },
});

export default guestPropertiesSlice.reducer;


