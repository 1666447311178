import { createAsyncThunk } from '@reduxjs/toolkit';
import { NEW_API_BASE_URL } from '../../constants/constants';


//post Api

export const guestPropertiesListAsync = createAsyncThunk(
  'guestPropertiesSlice/guestPropertiesListAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/guest/properties?page=${post.page}&limit=${post.limit}`,
      {
        method: 'POST',
        body: JSON.stringify(post),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'source': 'web',
          // 'token': `${access_token}`,
          'authorization': `Bearer ${access_token}`
        },
      },
        // {
        //   headers: {
        //     'source': 'web',
        //     // 'token': `${access_token}`,
        //     'authorization': `Bearer ${access_token}`
        //   },
        // },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');

        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


// Get Api

export const guestDetailPropertyAsync = createAsyncThunk(
  'propertySlice/guestDetailPropertyAsync',
  async (post, { rejectWithValue }, thunkAPI) => {
    try {
      const access_token = localStorage.getItem('token');
      
      // const navigation = useNavigation();
      const response = await fetch(`${NEW_API_BASE_URL}/guest/properties/detail?property_id=${post.property_id}`,
        {
          headers: {
            'source': 'web',
            // 'token': `${access_token}`,
            'authorization': `Bearer ${access_token}`
          },
        },
      );

      if (response.status === 200) {
        // if HTTP-status is 200-299
        const data = await response.json();

        // navigate('BottomNav');
        // navigation.navigate('BottomNav');
        return data;
      } else {
        const data = await response.json();

        // Toast.show(data.message);
        return data;
      }
    } catch (err) {

      // You can choose to use the message attached to err or write a custom error
      return rejectWithValue('Opps there seems to be an error');
    }
  },
);


// export const addPropertiesAsync2 = createAsyncThunk(
//   'propertySlice/addPropertiesAsync2',
//   async (post, { rejectWithValue }, thunkAPI) => {
//     try {
//       const access_token = localStorage.getItem('token');
//       
//       // const navigation = useNavigation();
//       const response = await fetch(
//         `${NEW_API_BASE_URL}/properties/addProperty`,
//         {
//           method: 'POST',
//           body: JSON.stringify(post),
//           headers: {
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'source': 'web',
//             // 'token': `${access_token}`,
//             'authorization': `Bearer ${access_token}`
//           },
//         },
//       );

//       if (response.status === 200) {
//         // if HTTP-status is 200-299
//         const data = await response.json();

//         // navigate('BottomNav');
//         // navigation.navigate('BottomNav');
//         return data;
//       } else {
//         const data = await response.json();

//         // Toast.show(data.message);
//         return data;
//       }
//     } catch (err) {

//       // You can choose to use the message attached to err or write a custom error

//       return rejectWithValue('Opps there seems to be an error');
//     }
//   },
// );

