import Toast from "components/Toast";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Axios from "utils/axios";

const useCertificateData = () => {
  const [properties, setProperties] = useState([]);
  const [certificate, setCertificate] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const userObj = useSelector((state) => state.auth.user);

  const getPropertyList = () => {
    setIsLoading(true);
    Axios.get(
      `/properties/all-select-list?id=${userObj.id}&userType=${userObj.currentType}`
    )
      .then((res) => {
        setIsLoading(false);
        setProperties(res?.data?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        Toast(error, "error");
      });
  };

  const getCertificateList = (page, limit, propertyId) => {
    setIsLoading(true);
    Axios.get(
      `/certificate/view?page=${page}&limit=${limit}&propertyId=${
        propertyId ? propertyId : ""
      }`
    )
      .then((res) => {
        setIsLoading(false);
        setCertificate(res?.data?.data);
        setTotalRows(res.data?.totalCount);
      })
      .catch((error) => {
        setIsLoading(false);
        Toast(error, "error");
      });
  };

  const convertUnitPropertyToOptions = (data) => {
    return data?.flatMap((item) => [
      {
        label: item?.title,
        value: item?._id,
      },
      ...item.ll_units?.map((unit) => ({
        label: unit.unitName + " - " + item?.title,
        value: unit._id + "-" + item?._id,
      })),
    ]);
  };

  const convertISODate = (isoDate) => {
    const date = new Date(isoDate);
    const formattedDate = date.toISOString().split("T")[0];
    return formattedDate;
  };

  return {
    getPropertyList,
    properties,
    isLoading,
    convertUnitPropertyToOptions,
    setIsLoading,
    convertISODate,
    certificate,
    totalRows,
    getCertificateList,
  };
};
export default useCertificateData;
