import * as React from "react";
import { useSelector } from "react-redux";
import Textarea from "components/form/TextareaInput";
import Axios from "utils/axios";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import styles from "../newProperty.module.css";
import { ButtonLoader } from "components/form/ButtonLoader";
import { useEffect } from "react";
import ErrorMsg from "components/ErrorMsg";
import InfoIcon from "@mui/icons-material/Info";
function AddDescription({
  handleForm,
  handleNext,
  handleBack,
  property,
  setProperty,
}) {
  const userObj = useSelector((state) => state.auth.user);
  let user = localStorage.getItem("user");
  user = JSON.parse(user);

  const [isLoading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    formik.setFieldValue("listingDescription", property?.listingDescription);
  }, [property]);

  const handleDescription = async (values, { setSubmitting, setErrors }) => {
    try {
      setLoading(true);
      const payload = {
        propertyId: property._id,
        listingDescription: values.listingDescription,
      };
      const response = await Axios.post(
        "/longlettingProperties/addDescription",
        payload
      );

      handleClose();
      setLoading(false);
      if (response.data?.success) {
        handleNext();
        setProperty(response.data.data);
      }
    } catch (error) {
     
      setErrors({ submit: error });
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      listingDescription: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: handleDescription,
  });

  return (
    <div className="mt-3 pt-3">
      <div className="form">
        <div className={styles.main_heading + " text-center mb-4"}>
          <h3 className="fw-bold h4 mb-0">Description</h3>
        </div>

        <div className="">
          <div className=" mt-1 form_input">
            <p className="input-heading">
              Listing Description
              <span onClick={handleShow} className="asterik2">
                <InfoIcon
                  style={{
                    color: "gray",
                    fontSize: "1.25rem",
                    marginLeft: "0.5rem",
                    cursor: "pointer",
                  }}
                />
              </span>
            </p>
            <div className="summary">
              Give tenant a glimpse of what it's like to live in your property,
              highlighting the reasons they'll enjoy residing there.
            </div>

            <Textarea
              customClass={"textarea-input"}
              rows={"5"}
              name="listingDescription"
              value={formik.values.listingDescription}
              onChange={formik.handleChange}
            />
          </div>
        </div>

        {show && (
          <div className="popup-overlay">
            <div
              className="bg-card report-card  overflow-y p-3"
              style={{ width: "calc(100% - 30px)", maxWidth: "400px" }}
            >
              <div className="modal-title">
                Property Description
                <button className="grow modal-button" onClick={handleClose}>
                  X
                </button>
              </div>
              <div>
                You might be writing your property description for the first
                time and wondering how best to show off your property. This
                depends on which type of advertising you have chosen for your
                property, but all descriptions should include:
              </div>

              <ol className={styles.listDescription}>
                <li>
                  {" "}
                  <div className="mt-1 mb-1">
                    Overview of the state of the property.
                  </div>
                </li>
                <li>
                  <div className="mt-1 mb-1">
                    {" "}
                    Details of local shopping, restaurants, and entertainment.
                    This will help tenants get a better feel for your property
                    and immediate area.
                  </div>
                </li>
                <li>
                  <div className="mt-1 mb-1">
                    {" "}
                    Any details not on the 'Add listing' form but that would be
                    of interest to prospective tenants. (E.g. "property has
                    recently been refurbished throughout".)
                  </div>
                </li>
              </ol>
            </div>
          </div>
        )}
        <p style={{ marginTop: "1rem" }}>
          <ErrorMsg hasError={formik.errors.submit}>
            {formik.errors.submit}
          </ErrorMsg>
        </p>
        <div className=" d-flex  justify-content-center justify-content-md-end">
          <button
            className="gray-btn grow "
            onClick={() => {
              handleBack();
            }}
          >
            Back
          </button>
          <ButtonLoader
            type="submit"
            className="primary-btn grow  ml-3"
            onClickhandle={formik.handleSubmit}
            isLoading={isLoading}
            buttonName={"Next"}
            loaderColor={"white"}
          >
            Next
          </ButtonLoader>
        </div>
      </div>
    </div>
  );
}

export default AddDescription;
