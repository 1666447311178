import {createSlice } from "@reduxjs/toolkit";
import { tenantStatementListAsync, allTenantStatementListAsync, deleteTenantStatementAsync, addTenantStatementAsync} from "./Services"
import _ from "lodash";

const tenantStatementSlice = createSlice({
  name: "tenantStatementSlice",
  initialState: {
    loading: false,
    tenantStatementForm: {},
    tenantStatementList: [],
    allTenantStatementList: [],
  },
  reducers: {
    changeTenantStatementForm:(state, action) => {
      let update_form = _.cloneDeep(state.tenantStatementForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        tenantStatementForm:{
          ...state.tenantStatementForm,
          ...update_form,
        }
      }
    },
    clearTenantStatementForm: (state, action) => {
      state.tenantStatementForm = {};
    },
  },
  extraReducers: {
    [addTenantStatementAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addTenantStatementAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addTenantStatementAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    // [editTenanciesAsync.pending]: (state, action) => {
    //   state.loading = true;
    // },
    // [editTenanciesAsync.fulfilled]: (state, action) => {
    //   state.loading = false;
    // //   state.program = action.payload;
    // },
    // [editTenanciesAsync.rejected]: (state, action) => {
    //   state.loading = false;
    // },

    [deleteTenantStatementAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteTenantStatementAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteTenantStatementAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [tenantStatementListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [tenantStatementListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.tenantStatementList = action?.payload.data;
    //   state.program = action.payload;
    },
    [tenantStatementListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allTenantStatementListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allTenantStatementListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allTenantStatementList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allTenantStatementListAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default tenantStatementSlice.reducer;

export const {
  changeTenantStatementForm,
  clearTenantStatementForm,
} = tenantStatementSlice.actions;
