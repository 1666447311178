
const DemoPage = ({ title }) => {


  return (
    <div className="p-4 p-md-5">
      <h2 className="mb-3">{title}</h2>
      <p>Coming Soon ...</p>
    </div>
  );
}

export default DemoPage;

