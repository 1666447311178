import React, { lazy } from "react";


import GuestGuard from "utils/guards/GuestGuard";

import GauestLayout from "layouts/GuestLayout";




const BookingPage = lazy(() => import("../pages/public/guestPortal/Bookings"));
const SupportPage = lazy(() => import("../pages/public/guestPortal/Support"));
const AddIssue = lazy(() => import("../pages/public/guestPortal/Support/AddTicket"));
const SupportIssueDetail = lazy(() => import("../pages/public/guestPortal/Support/SupportIssueDetail"));
const BookingDetail = lazy(() =>
  import("../pages/public/guestPortal/Bookings/BookingDetail")
);

const guestRoutes = [
  {
    path: "/guest/dashbaord",
    element: (
      <GuestGuard>
        <GauestLayout />
      </GuestGuard>
    ),
    children: [
      {
        path: "booking",
        element: <BookingPage />,
      },
      {
        path: "booking-details",
        element: <BookingDetail />,
      },
      {
        path: "support",
        element: <SupportPage />,
      },
      {
        path: "add-issue",
        element: <AddIssue />,
      },
      {
        path: "support-issue-detail",
        element: <SupportIssueDetail />,
      },
    ],
  },
];

export default guestRoutes;
