import {createSlice } from "@reduxjs/toolkit";
import { onBoardingListAsync} from "./Services"


const onBoardingSlice = createSlice({
  name: "onBoardingSlice",
  initialState: {
    loading: false,
    onBoardingList: [],
  },
  reducers: {
    
  },
  extraReducers: {
   

    
    [onBoardingListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [onBoardingListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.onBoardingList = action?.payload.data;
    //   state.program = action.payload;
    },
    [onBoardingListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

  },
});

export default onBoardingSlice.reducer;


