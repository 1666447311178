
import {useRoutes } from 'react-router-dom';

import shortLettingRoutes from './ShortLettingRoutes';
import guestRoutes from './GuestRoutes';
import publicRoutes from './PublicRoutes';
import longLettingRoutes from './LongLettingRoutes';
// import LLAdminRoutes from './LLAdminRoutes';

//...LLAdminRoutes ,

const Routes = ()=>{
    const routes = [...publicRoutes, ...shortLettingRoutes, ...longLettingRoutes, ...guestRoutes ];
    // 
    return useRoutes(routes);
}

export default Routes;