import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  llScroll: false
};

const scrollSlice = createSlice({
  name: "scroll",
  initialState,
  reducers: {
    setScrollToTop: (state) => {
      state.llScroll = !state.llScroll;
    },

  },
});

export const { setScrollToTop} =
scrollSlice.actions;
export default scrollSlice.reducer;
