import {createSlice } from "@reduxjs/toolkit";
import {addViewingsAsync, editViewingsAsync, deleteViewingsAsync, viewingsListAsync, allViewingsListAsync, viewingChartDataAsync, allViewingChartDataAsync} from "./Services"
import _ from "lodash";

const taskSlice = createSlice({
  name: "taskSlice",
  initialState: {
    loading: false,
    viewingForm: {},
    viewingList: [],
    allViewingList: [],
    viewingChartData: [],
    allViewingChartData: []
  },
  reducers: {
    changeViewingForm:(state, action) => {
      let update_form = _.cloneDeep(state.viewingForm);
      update_form[action.payload.edited_field] = action.payload.edited_value;
      return{
        ...state,
        viewingForm:{
          ...state.viewingForm,
          ...update_form,
        }
      }
    },
    selectViewing: (state, {payload}) => {

      return {
        ...state,
        viewingForm: payload,

        // propertiesForm: _.cloneDeep(payload),
      };

    },
    clearViewingForm: (state, action) => {
      state.viewingForm = {};
    },
  },
  extraReducers: {
    [addViewingsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [addViewingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [addViewingsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [editViewingsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [editViewingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [editViewingsAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [deleteViewingsAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [deleteViewingsAsync.fulfilled]: (state, action) => {
      state.loading = false;
    //   state.program = action.payload;
    },
    [deleteViewingsAsync.rejected]: (state, action) => {
      state.loading = false;
    },
    
    [viewingsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [viewingsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.viewingList = action?.payload.data;
    //   state.program = action.payload;
    },
    [viewingsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allViewingsListAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allViewingsListAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allViewingList = action?.payload.data;
    //   state.program = action.payload;
    },
    [allViewingsListAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [viewingChartDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [viewingChartDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.viewingChartData = action?.payload.data;
    //   state.program = action.payload;
    },
    [viewingChartDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },

    [allViewingChartDataAsync.pending]: (state, action) => {
      state.loading = true;
    },
    [allViewingChartDataAsync.fulfilled]: (state, action) => {
      state.loading = false;
      state.allViewingChartData = action?.payload.data;
    //   state.program = action.payload;
    },
    [allViewingChartDataAsync.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default taskSlice.reducer;

export const {
  changeViewingForm,
  selectViewing,
  clearViewingForm,
} = taskSlice.actions;
