import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tenancyId: ''
};

const tenancySlice = createSlice({
  name: "tenancy",
  initialState,
  reducers: {
    setTenancyId: (state, action) => {
      // console.log('action', action);
      state.tenancyId = action.payload;
    },

  },
});

export const { setTenancyId} =
tenancySlice.actions;
export default tenancySlice.reducer;
