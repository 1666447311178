// import "./App.css"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash, FaUserPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { addTenantsAsync } from "../../../../redux/tenants/Services";
import {
  changeTenantForm,
  clearTenantForm,
} from "../../../../redux/tenants/TenantsSlice";
import {
  propertyListAsync,
  propertySelectListAsync,
} from "../../../../redux/properties/Services";
import { MdErrorOutline } from "react-icons/md";
import Toast from "components/Toast";
const AddTenantForm = ({ onCancel, modal, data }) => {
  const navigate = useNavigate();
  const userObj = useSelector((state) => state.auth.user);
  var userId = userObj.id;
  const userType = userObj.currentType;
  const [units, setUnits] = useState([]);
  const [error, setError] = useState("");

  const dispatch = useDispatch();
  const { tenantForm, propertySelectList } = useSelector((state) => {
    const { tenantReducer, propertyReducer } = state;

    return {
      loading: tenantReducer.loading,
      tenantForm: tenantReducer.tenantForm,
      propertySelectList: propertyReducer.propertySelectList,
    };
  });
  const fetchData = () => {
    try {
      const payload = {
        userId: userId,
        userType: userType,
      };
      dispatch(propertySelectListAsync(payload))
        .unwrap()
        .then((res) => {});
    } catch (error) {
      Toast(error, 'error');
    }
  };

  useEffect(
    () => {
      fetchData();

      dispatch(
        changeTenantForm({ edited_field: "title", edited_value: "Mr." })
      );

      if (data?.property) {
        handleProperty(data?.property);
      }

      dispatch(
        changeTenantForm({
          edited_field: "unit",
          edited_value: data?.unit ? data?.unit : "",
        })
      );
    }, // eslint-disable-next-line
    []
  );
  let handleSubmit = (e) => {
    e.preventDefault();
    try {
      const payload = {
        title: tenantForm.title,
        firstName: tenantForm.firstName,
        lastName: tenantForm.lastName,
        email: tenantForm.email.toLowerCase(),
        password: tenantForm.password,
        mobileNumber: tenantForm.mobileNumber,
        tenantStatus: tenantForm.tenantStatus,
        property: tenantForm.property,
        unit: tenantForm.unit,
        address: tenantForm.address,
        createdBy: userObj?._id,
        // user_id: userId,
      };
      dispatch(addTenantsAsync(payload))
        .unwrap()
        .then((res) => {
          if (res.error) {
            Toast(res.error, 'error');
          } else {
            if (modal) {
              onCancel(res.data);
            } else {
              navigate("/ll/tenants/all-tenants");
            }
          }
        });
    } catch (error) {
      Toast(error, 'error');
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  function toggleShowPassword() {
    setShowPassword(!showPassword);
  }

  const handleProperty = (val) => {
    dispatch(
      changeTenantForm({
        edited_field: "property",
        edited_value: val,
      })
    );
    if (val !== "") {
      const filteredData = propertySelectList.filter(
        (item) => item._id === val
      );
      setUnits(filteredData[0]?.ll_units);
    } else {
      setUnits([]);
    }
  };
  return (
    <>
      <form autoComplete="off" onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Title</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={tenantForm.title}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "title",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            >
              <option selected value="Mr.">
                Mr.
              </option>
              <option value="Mrs.">Mrs.</option>
            </select>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>First Name</label>
            <input
              type="text"
              autoComplete="off"
              className="form-control"
              placeholder="First Name"
              value={tenantForm.firstName}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "firstName",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              value={tenantForm.lastName}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "lastName",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Email Address</label>
            <input
              type="email"
              // name="abcd"
              // autoComplete="off"
              className="form-control"
              placeholder="Enter email"
              value={tenantForm.email}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "email",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Password</label>
            <div className="input-group">
              <input
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                className="form-control"
                placeholder="Enter password"
                value={tenantForm.password}
                onChange={(e) => {
                  dispatch(
                    changeTenantForm({
                      edited_field: "password",
                      edited_value: e.target.value,
                    })
                  );
                }}
                required
              />
              <div className="input-group-btn mt-1">
                <span className="" onClick={toggleShowPassword}>
                  {" "}
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </span>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Mobile Number</label>
            <input
              type="tel"
              className="form-control"
              placeholder="Enter Mobile Number"
              pattern="(\+44\s?)?[7-9]\d{9}|(0?[6-9]\d{9})"
              maxLength={13} // +44 and 10 digits
              minLength={10} // +44 and 9 digits
              number={true}
              value={tenantForm.mobileNumber}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "mobileNumber",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Status</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={tenantForm.tenantStatus}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "tenantStatus",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            >
              <option selected value="">
                Select an option
              </option>
              <option value="confirmed">Confirmed</option>
              <option value="staying">Staying</option>
              <option value="checked out">Checked Out</option>
            </select>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 mb-3">
            <label>Previous Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Previous Address"
              value={tenantForm.address}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "address",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Property</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={tenantForm.property}
              onChange={(e) => {
                handleProperty(e.target.value);
              }}
              required
            >
              <option value="">Select an option</option>
              {propertySelectList &&
                propertySelectList.map((e, key) => {
                  return (
                    <option key={key} value={e._id}>
                      {e.title}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 mb-3">
            <label>Unit</label>
            <select
              className="form-select"
              aria-label="Default select example"
              value={tenantForm.unit}
              onChange={(e) => {
                dispatch(
                  changeTenantForm({
                    edited_field: "unit",
                    edited_value: e.target.value,
                  })
                );
              }}
              required
            >
              <option value="">Select an option</option>
              <option value="all">All Units</option>
              {units &&
                units.map((e, key) => {
                  return (
                    <option key={key} value={e._id}>
                      {e.unitName}
                    </option>
                  );
                })}
            </select>
          </div>
          {error && (
            <div className="mt-2" style={{ color: "red" }}>
              {error}
            </div>
          )}
          <div className="mt-3">
            <button type="submit" className="btn form-btn grow">
              Add Tenant
            </button>
            <button
              type="button"
              onClick={() => {
                if (modal) {
                  onCancel();
                } else {
                  navigate(-1);
                }
              }}
              className="btn form-btn2 grow"
            >
              Cancel
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AddTenantForm;
